.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 10PX !important;
    padding-left: 13PX !important;
}
.dorpdown {
    position: absolute;
    background: #fff;
    color: #111;
    border-radius: 5px;
    width: 100vh;
    top: 100px;
    
    visibility: hidden;
    opacity: 0;
    z-index: 100;
    transition: 0.5s ease-in-out;
    margin: 0px;
    /* overflow-y: scroll; */
}

.dropdown_list {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    padding: 10px;
}

.dropdown_list a {
    color: #111;
    font-size: 18px;
    text-transform: capitalize;
    padding: 5px 0px;
    text-decoration: none;
    color: var(---primeColor);
    margin: 0px;
    letter-spacing: normal;
}

.dropdown_list a:hover {
    color: var(---primaryColor);
}

.dropdownShow {
    background: unset;
    border: unset;
    text-align: left;
    font-weight: 200;
    text-decoration: none;
    color: white;
}

.dropdownShow:focus .dorpdown,
.dropdownShow:hover .dorpdown {
    top: 70px;
    visibility: visible;
    opacity: 1;
    width: 110px;
    z-index: 100;
    transition: 0.5s ease-in-out;
}

@media (max-width: 500px) {

    .dropdownShow:focus .dorpdown,
    .dropdownShow:hover .dorpdown {
        top: 180px;
        visibility: visible;
        opacity: 1;
        z-index: 100;
        transition: 0.5s ease-in-out;
    }

    .dropdownShow {
        padding: 0px;
    }

}

/* ////////////////////////////////////////////////// */

/*
 *  STYLE 1
 */

#style-1::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar {
    width: 7px;
    background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: var(---primeColor);
}

.navbardrop{
    display: flex;
    /* justify-content: center; */
    border-bottom: 1px solid rgb(211, 211, 211);
    border-right: 1px solid rgb(211, 211, 211);
    align-items: center;
}
.navbar-toggler {
    background-color: white;
}
.bg-body-tertiary{
background-color:#702d72 !important;
}
.navbar-expand-lg .navbar-collapse{
    justify-content: center !important;
}
.btn-primary {
   
    --bs-btn-hover-bg:transparent;
}