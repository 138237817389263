
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Satisfy&display=swap');
.marginbottom {
    margin-bottom: 20px;
}
.service {
    overflow: hidden;
    position: relative;
}

.text-center {
    text-align: center!important;
}
.service img {
    transition: all .5s ease 0s;
    width: 100%;
}

img {
    max-width: 100%;
}
.service .service-content-wrap {
    align-items: center;
    background: #fff;
    border: 1px solid rgba(0,0,0,.08);
    display: flex;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: all .3s ease 0s;
    width: 100%;
}
.service:hover .service-content-wrap {
    background: rgba(0,0,0,.4);
}
.service .service-content-wrap {
    align-items: center;
    background: #fff;
    border: 1px solid rgba(0,0,0,.08);
    display: flex;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: all .3s ease 0s;
    width: 100%;
}
.service:hover .service-content-wrap .service-content .title, .service:hover .service-content-wrap .service-content p {
    color: #fff;
}

.service .service-content-wrap .service-content p {
    transition: all .4s ease 0s;
}
.service .service-content-wrap .service-content {
    padding: 0 45px;
}
.service .service-content-wrap .service-content .service-icon {
    align-items: center;
    background: #d01f7d;
    border-radius: 100%;
    color: #fff;
    display: flex;
    font-size: 35px;
    height: 80px;
    justify-content: center;
    margin: auto;
    text-align: center;
    transition: all .7s ease 0s;
    width: 80px;
}
svg {
    vertical-align: middle;
}
.service .service-content-wrap .service-content .title {
    font-size: 22px;
    margin: 20px 0 7px;
    transition: all .4s ease 0s;
}
.padbot40{
    padding-bottom: 40px;
}
.fontweight700{
    font-weight: 700;
}
.padd100{
    padding: 0px 100px;
}
.fontstyle{
  font-family: 'Roboto', sans-serif !important;

}
.swiper-button-prev {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 50px !important;
    height: 50px !important;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:#702d72;
    border-radius: 50%;
    border: 7px solid #fff;
  }
  .swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 50px !important;
    height: 50px !important;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #702d72;
    border-radius: 50%;
    border: 7px solid #fff;
  }
  .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 16px !important;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    color: #ffbe30 !important;
  }
  .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: 16px !important;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    color: #ffbe30 !important;
    line-height: 1;
  }
  
  @media (max-width: 980px) {
    .swiper-button-prev {
      position: absolute;
      top: var(--swiper-navigation-top-offset, 50%);
      width: 30px !important;
      height: 30px !important;
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffbe30;
      border: 3px solid #fff;
    }
    .swiper-button-next {
      position: absolute;
      top: var(--swiper-navigation-top-offset, 50%);
      width: 30px !important;
      height: 30px !important;
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffbe30;
      border: 3px solid #fff;
    }
    .swiper-button-prev:after {
      font-family: swiper-icons;
      font-size: 10px !important;
      text-transform: none !important;
      letter-spacing: 0;
      font-variant: initial;
      line-height: 1;
      color: #111 !important;
    }
    .swiper-button-next:after {
      font-family: swiper-icons;
      font-size: 10px !important;
      text-transform: none !important;
      letter-spacing: 0;
      font-variant: initial;
      color: #111 !important;
      line-height: 1;
    }
  }



  .jarallax{
    background-size: 100% 100%;
  }

  .swiperer .swiper .swiper-wrapper{
    width: 55%;
  }