
@charset "UTF-8";@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Satisfy&display=swap');.countdown {
    display: inline-flex;
    align-items: center;
    list-style: none;
    padding-left: 0;
    text-align: center
}
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Satisfy&display=swap');
.countdown .date-time {
    display: block;
    min-width: 90px;
    border-radius: var(--bs-border-radius-lg);
    background-color: rgba(var(--bs-white-rgb),.15);
    padding: .5rem
}

.countdown .date-time:not(:last-child) {
    margin-right: 1rem
}

.countdown .date-time .num {
    font-size: 2.15rem;
    display: block;
    font-weight: 500;
    line-height: 1.1
}

.countdown .date-time .word {
    font-size: .85rem;
    display: block;
    text-transform: uppercase
}

@media(max-width: 576.98px) {
    .countdown .date-time {
        min-width:70px
    }

    .countdown .date-time:not(:last-child) {
        margin-right: .5rem
    }

    .countdown .date-time .num {
        font-size: 1.85rem
    }

    .countdown .date-time .word {
        font-size: .75rem
    }
}

.dselect-wrapper .form-select {
    padding-left: .75rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .25rem;
    text-align: left
}

.dselect-wrapper .form-select.dselect-clearable {
    padding-right: 3.25rem
}

.dselect-wrapper .form-select.form-select-sm {
    padding-left: .5rem
}

.dselect-wrapper .form-select.form-select-sm.dselect-clearable {
    padding-right: 3.125rem
}

.dselect-wrapper .form-select.form-select-lg {
    padding-left: 1rem
}

.dselect-wrapper .form-select.form-select-lg.dselect-clearable {
    padding-right: 3.375rem
}

.dselect-wrapper .dropdown-menu {
    padding: .375rem;
    width: 100%
}

.dselect-wrapper .dropdown-menu>.flex-column {
    gap: .375rem
}

.dselect-wrapper .form-select-sm+.dropdown-menu {
    padding: .25rem;
    font-size: .875rem
}

.dselect-wrapper .form-select-lg+.dropdown-menu {
    padding: .5rem;
    font-size: 1.25rem
}

.dselect-wrapper .dropdown-header,.dselect-wrapper .dropdown-item {
    padding-left: .375rem;
    padding-right: .375rem
}

.dselect-wrapper .form-select-sm+.dropdown-menu .dropdown-header,.dselect-wrapper .form-select-sm+.dropdown-menu .dropdown-item {
    padding-left: .25rem;
    padding-right: .25rem
}

.dselect-wrapper .form-select-lg+.dropdown-menu .dropdown-header,.dselect-wrapper .form-select-lg+.dropdown-menu .dropdown-item {
    padding-left: .5rem;
    padding-right: .5rem
}

.dselect-wrapper .form-control {
    border-radius: 0;
    box-shadow: none!important;
    border-color: #ced4da!important;
    padding: .25rem calc(.375rem - 1px);
    font-size: inherit
}

.dselect-wrapper .form-select-sm+.dropdown-menu .form-control {
    padding: calc(.25rem - 1px) calc(.25rem - 1px)
}

.dselect-wrapper .form-select-lg+.dropdown-menu .form-control {
    padding: .25rem calc(.5rem - 1px)
}

.dselect-wrapper .dselect-no-results {
    padding: .25rem .375rem
}

.dselect-wrapper .form-select-sm+.dropdown-menu .dselect-no-results {
    padding: calc(.25rem - 1px) .25rem
}

.dselect-wrapper .form-select-lg+.dropdown-menu .dselect-no-results {
    padding: .25rem .5rem
}

.dselect-wrapper .dselect-tag {
    background-color: #6c757d;
    color: #f8f9fa;
    padding-left: calc(.5rem+14px);
    padding-right: .5rem;
    border-radius: .25rem;
    height: calc(1.5 * 1rem);
    line-height: calc((1.5 * 1rem) - 1px);
    position: relative
}

.dselect-wrapper .form-select-sm .dselect-tag {
    height: auto;
    line-height: inherit
}

.dselect-wrapper .form-select-lg .dselect-tag {
    height: calc(1.5 * 1.25rem);
    line-height: calc(1.5 * 1.25rem)
}

.dselect-wrapper .dselect-tag-remove {
    position: absolute;
    left: .25rem;
    top: 50%;
    margin-top: -7px;
    color: rgba(248,249,250,.35);
    transition: color .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .dselect-wrapper .dselect-tag-remove {
        transition: none
    }
}

.dselect-wrapper .dselect-tag-remove:hover {
    color: #f8f9fa
}

.dselect-wrapper .dselect-placeholder {
    color: #6c757d
}

.dselect-wrapper .dropdown-header~.dropdown-item {
    padding-left: .75rem;
    padding-right: .75rem
}

.dselect-wrapper .form-select-sm+.dropdown-menu .dropdown-header {
    font-size: .85em
}

.dselect-wrapper .form-select-sm+.dropdown-menu .dropdown-header~.dropdown-item {
    padding-left: .5rem;
    padding-right: .5rem
}

.dselect-wrapper .form-select-lg+.dropdown-menu .dropdown-header {
    font-size: .85em
}

.dselect-wrapper .form-select-lg+.dropdown-menu .dropdown-header~.dropdown-item {
    padding-left: 1rem;
    padding-right: 1rem
}

.dselect-wrapper .dselect-clear {
    position: absolute;
    padding: 0;
    border: 0;
    box-shadow: none;
    top: 0;
    bottom: 0;
    right: 2.25rem;
    display: flex;
    align-items: center;
    color: #6c757d;
    cursor: pointer
}

.dselect-wrapper .dselect-clear:hover {
    color: inherit
}

.dselect-wrapper .dselect-clear svg {
    width: .625rem;
    height: .625rem
}

.dselect-wrapper [data-dselect-text=""]~.dselect-clear {
    display: none
}

.form-select.is-invalid+.dselect-wrapper .form-select,.was-validated .form-select:invalid+.dselect-wrapper .form-select {
    border-color: var(--bs-danger);
    box-shadow: none
}

.form-select.is-valid+.dselect-wrapper .form-select,.was-validated .form-select:valid+.dselect-wrapper .form-select {
    border-color: var(--bs-success);
    box-shadow: none
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time,.numInputWrapper {
    height: auto
}

.flatpickr-calendar {
    background: #fff;
    opacity: 0;
    display: block;
    text-align: center;
    visibility: hidden;
    padding: .75rem;
    direction: ltr;
    border: 0;
    font-size: .95rem;
    line-height: 24px;
    border-radius: var(--bs-border-radius);
    position: absolute;
    max-width: 300px;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-box-shadow: var(--bs-box-shadow);
    box-shadow: var(--bs-box-shadow);
    top: calc(100%+.125rem - 1rem)
}

.numInputWrapper input,.numInputWrapper span {
    display: inline-block
}

.flatpickr-calendar.inline,.flatpickr-calendar.open {
    opacity: 1;
    max-height: 640px;
    visibility: visible
}

.flatpickr-calendar.open {
    z-index: 1990;
    top: calc(100%+.125rem);
    transition: none
}

.flatpickr-calendar.inline {
    display: block;
    position: relative;
    top: .125rem
}

.flatpickr-calendar.static {
    position: absolute;
    transition: all .3s
}

.flatpickr-months .flatpickr-next-month i,.flatpickr-months .flatpickr-prev-month i,.flatpickr-wrapper,.numInputWrapper {
    position: relative
}

.flatpickr-calendar.static.open {
    z-index: 999;
    display: block
}





.flatpickr-calendar .hasWeeks .dayContainer {
    border-left: 0
}

.flatpickr-calendar.hasTime .flatpickr-time {
    height: 40px
}

.flatpickr-calendar.arrowRight:after,.flatpickr-calendar.arrowRight:before,.flatpickr-calendar.rightMost:after,.flatpickr-calendar.rightMost:before {
    left: auto;
    right: 22px
}

.flatpickr-calendar.arrowCenter:after,.flatpickr-calendar.arrowCenter:before {
    left: 50%;
    right: 50%
}

.flatpickr-calendar:before {
    border-width: 5px;
    margin: 0 -5px
}

.flatpickr-calendar:after {
    border-width: 4px;
    margin: 0 -4px
}

.flatpickr-calendar.arrowTop:after,.flatpickr-calendar.arrowTop:before {
    bottom: 100%
}

.flatpickr-calendar.arrowTop:before {
    border-bottom-color: #e6e6e6
}

.flatpickr-calendar.arrowTop:after {
    border-bottom-color: #fff
}

.flatpickr-calendar.arrowBottom:after,.flatpickr-calendar.arrowBottom:before {
    top: 100%
}

.flatpickr-calendar.arrowBottom:before {
    border-top-color: #e6e6e6
}

.flatpickr-calendar.arrowBottom:after {
    border-top-color: #fff
}

.flatpickr-calendar:focus,.flatpickr-current-month .flatpickr-monthDropdown-months:active,.flatpickr-current-month .flatpickr-monthDropdown-months:focus,.flatpickr-current-month input.cur-year:focus,.flatpickr-current-month input.cur-year:hover,.flatpickr-days:focus {
    outline: 0
}

.flatpickr-months,.flatpickr-weekdays {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.flatpickr-months {
    display: flex
}

.flatpickr-months .flatpickr-month {
    background: 0;
    color: rgba(0,0,0,.9);
    fill: rgba(0,0,0,.9);
    height: 48px;
    line-height: 1;
    text-align: center;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.flatpickr-months .flatpickr-next-month,.flatpickr-months .flatpickr-prev-month {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none;
    position: absolute;
    top: 0;
    height: 40px;
    padding: 10px;
    z-index: 3;
    color: rgba(0,0,0,.9);
    fill: rgba(0,0,0,.9)
}

.flatpickr-months .flatpickr-next-month.flatpickr-disabled,.flatpickr-months .flatpickr-prev-month.flatpickr-disabled {
    display: none
}

.flatpickr-months .flatpickr-next-month.flatpickr-prev-month,.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
    left: 0
}

.flatpickr-months .flatpickr-next-month.flatpickr-next-month,.flatpickr-months .flatpickr-prev-month.flatpickr-next-month {
    right: 0
}

.flatpickr-months .flatpickr-next-month:hover,.flatpickr-months .flatpickr-prev-month:hover {
    color: #959ea9
}

.flatpickr-months .flatpickr-next-month:hover svg,.flatpickr-months .flatpickr-prev-month:hover svg {
    fill: #f64747
}

.flatpickr-months .flatpickr-next-month svg,.flatpickr-months .flatpickr-prev-month svg {
    width: 14px;
    height: 14px
}

.flatpickr-months .flatpickr-next-month svg path,.flatpickr-months .flatpickr-prev-month svg path {
    -webkit-transition: fill .1s;
    transition: fill .1s;
    fill: inherit
}

.numInputWrapper input {
    width: 100%
}

.numInputWrapper input::-ms-clear {
    display: none
}

.numInputWrapper input::-webkit-inner-spin-button,.numInputWrapper input::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none
}

.numInputWrapper span {
    position: absolute;
    right: 0;
    width: 16px;
    padding: 0 4px 0 2px;
    height: 50%;
    line-height: 50%;
    opacity: 0;
    border: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.numInputWrapper span:active,.numInputWrapper span:hover {
    color: #e45858
}

.numInputWrapper span:after {
    display: block;
    content: "";
    position: absolute
}

.numInputWrapper span.arrowUp {
    top: 3px;
    border-bottom: 0
}

.numInputWrapper span.arrowUp:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid rgba(57,57,57,.6);
    top: 26%
}

.numInputWrapper span.arrowDown {
    top: calc(50% - 2px)
}

.numInputWrapper span.arrowDown:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid rgba(57,57,57,.6);
    top: 40%
}

.numInputWrapper span svg {
    width: inherit;
    height: auto
}

.numInputWrapper span svg path {
    fill: rgba(0,0,0,.5)
}

.numInputWrapper:hover span {
    opacity: 1
}

.flatpickr-current-month {
    font-size: 1.1rem;
    font-weight: 300;
    color: inherit;
    width: 75%;
    left: 12.5%;
    line-height: 1;
    display: inline-block;
    text-align: center;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    display: flex;
    justify-content: center;
    width: 100%
}

.flatpickr-current-month span.cur-month {
    font-family: inherit;
    font-weight: 700;
    color: inherit;
    display: inline-block;
    margin-left: .5ch;
    padding: 0
}

.flatpickr-current-month .flatpickr-monthDropdown-months,.flatpickr-current-month input.cur-year {
    background: #f3f3f3;
    color: inherit;
    font-family: inherit;
    height: auto;
    line-height: inherit;
    vertical-align: initial;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1rem
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover,.flatpickr-current-month span.cur-month:hover {
    background: rgba(0,0,0,.05)
}

.flatpickr-current-month .numInputWrapper {
    width: 6ch;
    display: inline-block
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
    border-bottom-color: rgba(0,0,0,.9)
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
    border-top-color: rgba(0,0,0,.9)
}

.flatpickr-current-month input.cur-year {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    display: inline-block;
    border: 0;
    border-radius: var(--bs-border-radius);
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    padding: .3rem
}

.flatpickr-day,.flatpickr-time input.flatpickr-minute,.flatpickr-time input.flatpickr-second {
    font-weight: 400
}

.flatpickr-current-month input.cur-year[disabled],.flatpickr-current-month input.cur-year[disabled]:hover {
    font-size: 100%;
    color: rgba(0,0,0,.5);
    background: 0;
    pointer-events: none
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
    appearance: menulist;
    border: 0;
    border-radius: var(--bs-border-radius);
    box-sizing: border-box;
    margin: -1px .5rem 0 0;
    outline: 0;
    padding: .25rem;
    position: relative;
    -webkit-box-sizing: border-box;
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    width: auto
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
    background-color: transparent;
    outline: 0;
    padding: 0
}

.flatpickr-weekdays {
    background: 0;
    text-align: center;
    overflow: hidden;
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 28px
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

span.flatpickr-weekday {
    background: 0;
    color: #565656;
    line-height: 1;
    margin: 0;
    text-align: center;
    display: block;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-transform: uppercase;
    font-size: .75rem;
    font-weight: 400
}

.dayContainer,.flatpickr-weeks {
    padding: 1px 0 0
}

.flatpickr-days {
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: auto
}

.dayContainer {
    padding: 0;
    outline: 0;
    text-align: left;
    width: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-around;
    justify-content: space-around;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    opacity: 1
}

.dayContainer .dayContainer {
    -webkit-box-shadow: -1px 0 0 #e6e6e6;
    box-shadow: -1px 0 0 #e6e6e6
}

.flatpickr-day {
    background: 0;
    border: 1px solid transparent;
    border-radius: var(--bs-border-radius);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #24262b;
    width: 14.2857143%;
    -webkit-flex-basis: 14.2857143%;
    -ms-flex-preferred-size: 14.2857143%;
    flex-basis: 14.2857143%;
    max-width: 40px;
    height: 34px;
    line-height: 34px;
    margin: 0;
    display: inline-block;
    position: relative;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    cursor: pointer
}

.flatpickr-day.inRange,.flatpickr-day.nextMonthDay.inRange,.flatpickr-day.nextMonthDay.today.inRange,.flatpickr-day.nextMonthDay:focus,.flatpickr-day.nextMonthDay:hover,.flatpickr-day.prevMonthDay.inRange,.flatpickr-day.prevMonthDay.today.inRange,.flatpickr-day.prevMonthDay:focus,.flatpickr-day.prevMonthDay:hover,.flatpickr-day.today.inRange,.flatpickr-day:focus,.flatpickr-day:hover {
    outline: 0;
    border-color: var(--bs-primary);
    background: var(--bs-primary);
    color: #fff
}

.flatpickr-day.today {
    border-color: var(--bs-gray-dark);
    background: var(--bs-gray-dark);
    color: #fff
}

.flatpickr-day.today:focus,.flatpickr-day.today:hover {
    border-color: var(--bs-gray-500);
    background: var(--bs-gray-500);
    color: #fff
}

.flatpickr-day.endRange,.flatpickr-day.endRange.inRange,.flatpickr-day.endRange.nextMonthDay,.flatpickr-day.endRange.prevMonthDay,.flatpickr-day.endRange:focus,.flatpickr-day.endRange:hover,.flatpickr-day.selected,.flatpickr-day.selected.inRange,.flatpickr-day.selected.nextMonthDay,.flatpickr-day.selected.prevMonthDay,.flatpickr-day.selected:focus,.flatpickr-day.selected:hover,.flatpickr-day.startRange,.flatpickr-day.startRange.inRange,.flatpickr-day.startRange.nextMonthDay,.flatpickr-day.startRange.prevMonthDay,.flatpickr-day.startRange:focus,.flatpickr-day.startRange:hover {
    background: var(--bs-primary);
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    border-color: var(--bs-primary)
}

.flatpickr-day.endRange.startRange,.flatpickr-day.selected.startRange,.flatpickr-day.startRange.startRange {
    border-radius: 50px 0 0 50px
}

.flatpickr-day.endRange.endRange,.flatpickr-day.selected.endRange,.flatpickr-day.startRange.endRange {
    border-radius: 0 50px 50px 0
}

.flatpickr-day.endRange.startRange .endRange:not(:nth-child(7n1)),.flatpickr-day.selected.startRange .endRange:not(:nth-child(7n1)),.flatpickr-day.startRange.startRange .endRange:not(:nth-child(7n1)) {
    -webkit-box-shadow: -10px 0 0 var(--bs-gray-600);
    box-shadow: -10px 0 0 var(--bs-gray-600)
}

.flatpickr-day.endRange.startRange.endRange,.flatpickr-day.selected.startRange.endRange,.flatpickr-day.startRange.startRange.endRange {
    border-radius: 50px
}

.flatpickr-day.flatpickr-disabled,.flatpickr-day.flatpickr-disabled:hover,.flatpickr-day.nextMonthDay,.flatpickr-day.notAllowed,.flatpickr-day.notAllowed.nextMonthDay,.flatpickr-day.notAllowed.prevMonthDay,.flatpickr-day.prevMonthDay {
    color: var(--bs-tertiary-color);
    background: 0;
    border-color: transparent
}

.flatpickr-day.flatpickr-disabled,.flatpickr-day.flatpickr-disabled:hover {
    color: var(--bs-gray-300);
    cursor: not-allowed;
    text-decoration: line-through
}

.flatpickr-day.hidden {
    visibility: hidden
}

.rangeMode .flatpickr-day {
    margin-top: 1px
}

.flatpickr-weekwrapper {
    float: left
}

.flatpickr-weekwrapper .flatpickr-weeks {
    padding: 0 12px;
    -webkit-box-shadow: var(--bs-box-shadow);
    box-shadow: var(--bs-box-shadow)
}

.flatpickr-weekwrapper .flatpickr-weekday {
    float: none;
    width: 100%;
    line-height: 28px
}

.flatpickr-weekwrapper span.flatpickr-day,.flatpickr-weekwrapper span.flatpickr-day:hover {
    display: block;
    width: 100%;
    max-width: none;
    color: rgba(57,57,57,.5);
    background: 0;
    border: 0
}

.flatpickr-innerContainer {
    display: block;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden
}

.flatpickr-rContainer {
    display: inline-block;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.flatpickr-time {
    text-align: center;
    outline: 0;
    display: block;
    height: 0;
    line-height: 48px;
    max-height: 48px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

.flatpickr-time:after {
    content: "";
    display: table;
    clear: both
}

.flatpickr-time .numInputWrapper {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 40%;
    height: 48px;
    float: left
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
    border-bottom-color: var(--bs-gray-700)
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
    border-top-color: var(--bs-gray-700)
}

.flatpickr-time.hasSeconds .numInputWrapper {
    width: 26%
}

.flatpickr-time.time24hr .numInputWrapper {
    width: 49%
}

.flatpickr-time input {
    background: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    border-radius: var(--bs-border-radius);
    text-align: center;
    margin: 0;
    padding: 0;
    height: inherit;
    line-height: inherit;
    color: var(--bs-dark);
    font-size: 1rem;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield
}

.flatpickr-time input:focus {
    outline: 0;
    border: 0
}

.flatpickr-time .flatpickr-am-pm,.flatpickr-time .flatpickr-time-separator {
    height: inherit;
    float: left;
    line-height: inherit;
    color: var(--bs-gray-400);
    font-weight: 700;
    width: 2%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center
}

.flatpickr-time .flatpickr-am-pm {
    outline: 0;
    width: 18%;
    text-align: center;
    font-weight: 400
}

.flatpickr-time .flatpickr-am-pm:focus,.flatpickr-time .flatpickr-am-pm:hover,.flatpickr-time input:focus,.flatpickr-time input:hover {
    background: #eee
}

.flatpickr-input[readonly] {
    cursor: pointer
}

.glightbox-container,.glightbox-container .gslider {
    height: 100%;
    top: 0;
    overflow: hidden;
    width: 100%;
    left: 0
}

.desc-bottom .gslide-media,.desc-top .gslide-media,.gclose,.glightbox-container .ginner-container,.gnext,.gprev {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.glightbox-container {
    position: fixed;
    z-index: 99997!important;
    -ms-touch-action: none;
    touch-action: none;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    outline: 0
}

.glightbox-button-hidden,.glightbox-container.inactive,.gslide-video .plyr__poster-enabled.plyr--loading .plyr__poster,.gslide-video.playing::before,.zoomed .gslide-description {
    display: none
}

.glightbox-container .gcontainer {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 9999;
    overflow: hidden
}

.glightbox-container .gslider {
    -webkit-transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s,-webkit-transform .4s;
    position: relative;
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0)
}

.glightbox-container .gslide {
    width: 100%;
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    opacity: 0
}

.glightbox-container .gslide.current {
    opacity: 1;
    z-index: 99999;
    position: relative
}

.glightbox-container .gslide.prev {
    opacity: 1;
    z-index: 9999
}

.glightbox-container .ginner-container.gvideo-container,.glightbox-container .gslide-inner-content {
    width: 100%
}

.glightbox-container .ginner-container {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 100%;
    margin: auto;
    height: 100vh
}

.glightbox-container .ginner-container.desc-bottom,.glightbox-container .ginner-container.desc-top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.glightbox-container .ginner-container.desc-left,.glightbox-container .ginner-container.desc-right {
    max-width: 100%!important
}

.gslide iframe,.gslide video {
    outline: 0!important;
    border: none;
    min-height: 165px;
    -webkit-overflow-scrolling: touch;
    -ms-touch-action: auto;
    touch-action: auto
}

.gslide:not(.current) {
    pointer-events: none
}

.gslide-image {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.gslide-image img {
    max-height: 100vh;
    display: block;
    padding: 0;
    float: none;
    outline: 0;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    max-width: 100vw;
    width: auto;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    -ms-touch-action: none;
    touch-action: none;
    margin: auto;
    min-width: 200px;
    border-radius: var(--bs-border-radius)
}

.desc-bottom .gslide-image img,.desc-top .gslide-image img {
    width: auto
}

.desc-left .gslide-image img,.desc-right .gslide-image img {
    width: auto;
    max-width: 100%
}

.grelative,.gslide-image img.zoomable {
    position: relative
}

.gslide-image img.dragging,.gslide-inline .dragging {
    cursor: -webkit-grabbing!important;
    cursor: grabbing!important;
    -webkit-transition: none;
    transition: none
}

.gslide-video {
    position: relative;
    max-width: 100vh;
    width: 100%!important
}

.gslide-video .gvideo-wrapper {
    width: 100%;
    margin: auto
}

.gslide-video::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255,0,0,.34);
    display: none
}

.gslide-video.fullscreen {
    max-width: 100%!important;
    min-width: 100%;
    height: 75vh
}

.gslide-description.description-left,.gslide-description.description-right,.gslide-inline {
    max-width: 100%
}

.gslide-video.fullscreen video {
    max-width: 100%!important;
    width: 100%!important
}

.gslide-inline {
    background: #fff;
    text-align: left;
    max-height: calc(100vh - 40px);
    overflow: auto;
    margin: auto
}

.gslide-inline .ginlined-content {
    padding: 20px;
    width: 100%
}

.ginlined-content {
    overflow: auto;
    display: block!important;
    opacity: 1
}

.gslide-external {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    min-width: 100%;
    background: #fff;
    padding: 0;
    overflow: auto;
    max-height: 75vh;
    height: 100%
}

.glightbox-clean .gslide-video,.glightbox-mobile .goverlay,.goverlay {
    background: #000
}

.gslide-media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto
}

.zoomed .gslide-media {
    -webkit-box-shadow: none!important;
    box-shadow: none!important
}

.desc-bottom .gslide-media,.desc-top .gslide-media {
    margin: 0 auto;
    -ms-flex-direction: column;
    flex-direction: column
}

.gslide-description {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%
}

.gslide-description.description-bottom,.gslide-description.description-top {
    margin: 0 auto;
    width: 100%
}

.glightbox-clean .gnext,.glightbox-clean .gprev {
    top: -100%;
    width: 40px;
    height: 50px;
    position: absolute
}

.gslide-description p {
    margin-bottom: 12px
}

.gslide-description p:last-child {
    margin-bottom: 0
}

.glightbox-mobile .glightbox-container .gslide-description {
    height: auto!important;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 19px 11px 50px;
    max-width: 100vw!important;
    -webkit-box-ordinal-group: 3!important;
    -ms-flex-order: 2!important;
    order: 2!important;
    max-height: 78vh;
    overflow: auto!important;
    background: -webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,0)),to(rgba(0,0,0,.75)));
    background: linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,.75) 100%);
    -webkit-transition: opacity .3s linear;
    transition: opacity .3s linear
}

.glightbox-mobile .glightbox-container .gslide-title {
    color: #fff;
    font-size: 1em
}

.glightbox-mobile .glightbox-container .gslide-desc {
    color: var(--bs-light)
}

.glightbox-mobile .glightbox-container .gslide-desc a {
    color: var(--bs-white);
    font-weight: 700
}

.glightbox-mobile .glightbox-container .gslide-desc * {
    color: inherit
}

.glightbox-mobile .glightbox-container .gslide-desc .desc-more {
    color: #fff;
    opacity: .4
}

.gdesc-open .gslide-media {
    -webkit-transition: opacity .5s;
    transition: opacity .5s;
    opacity: .4
}

.gdesc-open .gdesc-inner {
    padding-bottom: 30px
}

.gdesc-closed .gslide-media {
    -webkit-transition: opacity .5s;
    transition: opacity .5s;
    opacity: 1
}

.greset {
    -webkit-transition: .3s;
    transition: .3s
}

.gabsolute {
    position: absolute
}

.glightbox-desc {
    display: none!important
}

.glightbox-open {
    overflow: hidden
}

.gloader {
    height: 25px;
    width: 25px;
    -webkit-animation: .8s linear infinite lightboxLoader;
    animation: .8s linear infinite lightboxLoader;
    border: 2px solid #fff;
    border-right-color: transparent;
    border-radius: 50%;
    position: absolute;
    display: block;
    z-index: 9999;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 47%
}

.goverlay {
    width: 100%;
    height: calc(100vh + 1px);
    position: fixed;
    top: -1px;
    left: 0;
    will-change: opacity
}

.gclose,.gnext,.gprev {
    z-index: 99999;
    cursor: pointer;
    width: 26px;
    height: 44px;
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column
}

.gclose svg,.gnext svg,.gprev svg {
    display: block;
    width: 25px;
    height: auto;
    margin: 0;
    padding: 0
}

.gclose.disabled,.gnext.disabled,.gprev.disabled {
    opacity: .1
}

.gclose .garrow,.gnext .garrow,.gprev .garrow {
    stroke: #fff
}

.gbtn.focused {
    outline: #0f3d81 solid 2px
}

iframe.wait-autoplay {
    opacity: 0
}

.glightbox-closing .gclose,.glightbox-closing .gnext,.glightbox-closing .gprev {
    opacity: 0!important
}

.glightbox-clean .gdesc-inner {
    padding: 20px 20px;
    text-align: center
}

.glightbox-clean .gslide-title {
    font-size: 1em;
    font-weight: 400;
    font-family: arial;
    color: #fff;
    margin-bottom: 19px;
    line-height: 1.4em
}

.glightbox-clean .gslide-desc {
    font-size: .86em;
    margin-bottom: 0;
    font-family: arial;
    line-height: 1.4em
}

.glightbox-clean .gclose,.glightbox-clean .gnext,.glightbox-clean .gprev {
    background-color: rgba(var(--bs-black),.75);
    border-radius: var(--bs-border-radius)
}

.glightbox-clean .gclose path,.glightbox-clean .gnext path,.glightbox-clean .gprev path {
    fill: #fff
}

.glightbox-clean .gprev {
    left: 30px
}

.glightbox-clean .gnext {
    right: 30px
}

.glightbox-clean .gclose {
    width: 35px;
    height: 35px;
    top: 15px;
    right: 10px;
    position: absolute
}

.glightbox-clean .gclose svg {
    width: 18px;
    height: auto
}

.glightbox-clean .gclose:hover {
    opacity: 1
}

.gfadeIn {
    -webkit-animation: .5s gfadeIn;
    animation: .5s gfadeIn
}

.gfadeOut {
    -webkit-animation: .5s gfadeOut;
    animation: .5s gfadeOut
}

.gslideOutLeft {
    -webkit-animation: .3s gslideOutLeft;
    animation: .3s gslideOutLeft
}

.gslideInLeft {
    -webkit-animation: .3s gslideInLeft;
    animation: .3s gslideInLeft
}

.gslideOutRight {
    -webkit-animation: .3s gslideOutRight;
    animation: .3s gslideOutRight
}

.gslideInRight {
    -webkit-animation: .3s gslideInRight;
    animation: .3s gslideInRight
}

.gzoomIn {
    -webkit-animation: .5s gzoomIn;
    animation: .5s gzoomIn
}

.gzoomOut {
    -webkit-animation: .5s gzoomOut;
    animation: .5s gzoomOut
}

@-webkit-keyframes lightboxLoader {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes lightboxLoader {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes gfadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes gfadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@-webkit-keyframes gfadeOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes gfadeOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@-webkit-keyframes gslideInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-60%,0,0);
        transform: translate3d(-60%,0,0)
    }

    to {
        visibility: visible;
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@keyframes gslideInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-60%,0,0);
        transform: translate3d(-60%,0,0)
    }

    to {
        visibility: visible;
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes gslideOutLeft {
    from {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }

    to {
        -webkit-transform: translate3d(-60%,0,0);
        transform: translate3d(-60%,0,0);
        opacity: 0;
        visibility: hidden
    }
}

@keyframes gslideOutLeft {
    from {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }

    to {
        -webkit-transform: translate3d(-60%,0,0);
        transform: translate3d(-60%,0,0);
        opacity: 0;
        visibility: hidden
    }
}

@-webkit-keyframes gslideInRight {
    from {
        opacity: 0;
        visibility: visible;
        -webkit-transform: translate3d(60%,0,0);
        transform: translate3d(60%,0,0)
    }

    to {
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@keyframes gslideInRight {
    from {
        opacity: 0;
        visibility: visible;
        -webkit-transform: translate3d(60%,0,0);
        transform: translate3d(60%,0,0)
    }

    to {
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes gslideOutRight {
    from {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }

    to {
        -webkit-transform: translate3d(60%,0,0);
        transform: translate3d(60%,0,0);
        opacity: 0
    }
}

@keyframes gslideOutRight {
    from {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }

    to {
        -webkit-transform: translate3d(60%,0,0);
        transform: translate3d(60%,0,0);
        opacity: 0
    }
}

@-webkit-keyframes gzoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.3,.3,.3);
        transform: scale3d(.3,.3,.3)
    }

    to {
        opacity: 1
    }
}

@keyframes gzoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.3,.3,.3);
        transform: scale3d(.3,.3,.3)
    }

    to {
        opacity: 1
    }
}

@-webkit-keyframes gzoomOut {
    from {
        opacity: 1
    }

    50% {
        opacity: 0;
        -webkit-transform: scale3d(.3,.3,.3);
        transform: scale3d(.3,.3,.3)
    }

    to {
        opacity: 0
    }
}

@keyframes gzoomOut {
    from {
        opacity: 1
    }

    50% {
        opacity: 0;
        -webkit-transform: scale3d(.3,.3,.3);
        transform: scale3d(.3,.3,.3)
    }

    to {
        opacity: 0
    }
}

@media (min-width: 769px) {
    .glightbox-container .ginner-container {
        width:auto;
        height: auto;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .glightbox-container .ginner-container.desc-left .gslide-description,.glightbox-container .ginner-container.desc-top .gslide-description {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0
    }

    .glightbox-container .ginner-container.desc-left .gslide-image,.glightbox-container .ginner-container.desc-top .gslide-image,.glightbox-container .ginner-container.desc-top .gslide-image img {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .gslide-image img {
        max-height: 97vh;
        max-width: 100%
    }

    .gslide-image img.zoomable {
        cursor: -webkit-zoom-in;
        cursor: zoom-in
    }

    .zoomed .gslide-image img.zoomable {
        cursor: -webkit-grab;
        cursor: grab
    }

    .gslide-inline {
        max-height: 95vh
    }

    .gslide-external {
        max-height: 100vh
    }

    .gslide-description.description-left,.gslide-description.description-right {
        max-width: 275px
    }

    .glightbox-open {
        height: auto
    }

    .goverlay {
        background: rgba(0,0,0,.92)
    }

    .glightbox-clean .gslide-media {
        -webkit-box-shadow: 1px 2px 9px 0 rgba(0,0,0,.65);
        box-shadow: 1px 2px 9px 0 rgba(0,0,0,.65);
        border-radius: .55rem
    }

    .glightbox-clean .description-left .gdesc-inner,.glightbox-clean .description-right .gdesc-inner {
        position: absolute;
        height: 100%;
        overflow-y: auto
    }

    .glightbox-clean .gclose,.glightbox-clean .gnext,.glightbox-clean .gprev {
        background-color: rgba(var(--bs-black),.32)
    }

    .glightbox-clean .gclose:hover,.glightbox-clean .gnext:hover,.glightbox-clean .gprev:hover {
        background-color: rgba(var(--bs-black),.7)
    }

    .glightbox-clean .gnext,.glightbox-clean .gprev {
        top: 45%
    }
}

@media (min-width: 992px) {
    .glightbox-clean .gclose {
        opacity:.7;
        right: 20px
    }
}

@media screen and (max-height: 420px) {
    .goverlay {
        background:#000
    }
}

pre code.hljs {
    display: block;
    overflow-x: auto;
    padding: 1.5em
}

code.hljs {
    padding: 3px 5px
}

.hljs {
    color: #abb2bf;
    background: #282c34
}

.hljs-comment,.hljs-quote {
    color: #5c6370;
    font-style: italic
}

.hljs-doctag,.hljs-formula,.hljs-keyword {
    color: #c678dd
}

.hljs-deletion,.hljs-name,.hljs-section,.hljs-selector-tag,.hljs-subst {
    color: #e06c75
}

.hljs-literal {
    color: #56b6c2
}

.hljs-addition,.hljs-attribute,.hljs-meta .hljs-string,.hljs-regexp,.hljs-string {
    color: #98c379
}

.hljs-attr,.hljs-number,.hljs-selector-attr,.hljs-selector-class,.hljs-selector-pseudo,.hljs-template-variable,.hljs-type,.hljs-variable {
    color: #d19a66
}

.hljs-bullet,.hljs-link,.hljs-meta,.hljs-selector-id,.hljs-symbol,.hljs-title {
    color: #61aeee
}

.hljs-built_in,.hljs-class .hljs-title,.hljs-title.class_ {
    color: #e6c07b
}

.hljs-emphasis {
    font-style: italic
}

.hljs-strong {
    font-weight: 700
}

.hljs-link {
    text-decoration: underline
}

.datepicker__month-caption,.datepicker__week-days {
    height: 2em;
    vertical-align: middle
}

.datepicker__clear-button,.datepicker__close-button,.datepicker__month-button,.datepicker__month-day,.datepicker__submit-button {
    transition-duration: .2s;
    transition-property: color,background-color,border-color
}

.datepicker {
    box-sizing: border-box;
    overflow: hidden;
    position: absolute;
    z-index: 10;
    margin-top: .125rem;
    background-color: var(--bs-white);
    border-radius: var(--bs-border-radius);
    box-shadow: var(--bs-box-shadow);
    color: var(--bs-body-color);
    font-family: 'Roboto', sans-serif !important;
  
    font-size: var(--bs-body-font-size);
    line-height: 1
}

.datepicker-right+.datepicker {
    right: 0
}

.datepicker--inline {
    position: relative
}

.datepicker__inner {
    overflow: hidden;
    padding: 1.5rem 1.25rem
}

/* .datepicker__month {
    border-collapse: collapse;
    text-align: center;
    width: 100%;
    font-size: 92.5%
} */

.datepicker__info--feedback,.datepicker__month--month2 {
    display: none
}

.datepicker__month-button,.datepicker__month-day--valid {
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none
}

.datepicker__month-day--lastMonth,.datepicker__month-day--nextMonth {
    visibility: hidden
}

.datepicker__info--error,.datepicker__info--help {
    display: block
}

datepicker__buttons {
    -webkit-user-select: none;
    user-select: none
}

.datepicker__clear-button,.datepicker__close-button {
    cursor: pointer
}

.datepicker__tooltip {
    position: absolute
}

.datepicker__month-day--disabled,.datepicker__month-day--no-checkin,.datepicker__month-day--no-checkout {
    position: relative
}

.datepicker__month-caption {
    border-bottom: 1px solid var(--bs-border-color)
}

.datepicker__month-name {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 95%
}

.datepicker__week-name {
    font-size: 75%;
    font-weight: 500;
    text-transform: uppercase
}

.datepicker__month-day {
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    color: var(--bs-dark);
    padding: 8px;
    border: 1px solid transparent
}

.datepicker__month-day--disabled,.datepicker__month-day--invalid {
    color: var(--bs-gray-300);
    text-decoration: line-through
}

.datepicker__month-day:focus {
    outline: 0;
    background-color: var(--bs-gray-600)
}

.datepicker__month-day--no-checkin:before {
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    border-bottom: 8px solid var(--bs-danger);
    border-right: 8px solid transparent
}

.datepicker__month-day--no-checkout:before {
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    border-top: 8px solid var(--bs-danger);
    border-left: 8px solid transparent
}

.datepicker__month-day--no-checkin.datepicker__month-day--no-checkout:before {
    content: none
}

.datepicker__month-day--disabled:after {
    content: "\00d7";
    left: 50%;
    position: absolute;
    color: var(--bs-danger);
    font-size: 92.5%;
    top: 50%;
    transform: translate(-50%,-50%)
}

.datepicker__month-day--day-of-week-disabled,.datepicker__month-day--no-checkin,.datepicker__month-day--no-checkout {
    background-color: var(--gray-100)
}

.datepicker__month-day--hovering,.datepicker__month-day--selected {
    background-color: rgba(var(--bs-primary-rgb),.075);
    color: var(--bs-primary-text);
    font-weight: 400
}

.datepicker__month-day--selected:after,.datepicker__month-day--selected:before {
    display: none
}

.datepicker__month-day--today {
    background-color: var(--bs-gray-dark);
    color: var(--bs-white)
}

.datepicker__month-day--first-day-selected,.datepicker__month-day--last-day-selected {
    background-color: var(--bs-primary);
    color: var(--bs-white)
}

.datepicker__month-day--last-day-selected:after {
    content: none
}

.datepicker__month-button {
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    text-indent: -9999px;
    padding: 6px;
    align-items: center;
    justify-content: center;
    display: inline-flex
}

.datepicker__month-button:after {
    background-repeat: no-repeat;
    background-position: center;
    float: left;
    text-indent: 0;
    content: "";
    width: 1rem;
    height: 1rem
}

.datepicker__month-button--prev:after {
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 17 17'%3E%3Cg%3E%3C/g%3E%3Cpath d='M5.207 8.471l7.146 7.147-0.707 0.707-7.853-7.854 7.854-7.853 0.707 0.707-7.147 7.146z'%3E%3C/path%3E%3C/svg%3E")
}

.datepicker__month-button--next:after {
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 17 17'%3E%3Cg%3E%3C/g%3E%3Cpath d='M13.207 8.472l-7.854 7.854-0.707-0.707 7.146-7.146-7.146-7.148 0.707-0.707 7.854 7.854z'%3E%3C/path%3E%3C/svg%3E")
}

.datepicker__topbar {
    margin-bottom: 1rem;
    position: relative
}

.datepicker--topbar-bottom .datepicker__topbar {
    margin-bottom: 0;
    padding-top: 1rem;
    border-top: 1px solid #dcdcdc
}

.datepicker__info--selected {
    font-size: 75%;
    margin-bottom: .75rem
}

.datepicker__info--selected strong {
    color: var(--bs-primary);
    font-weight: 600
}

.datepicker__info--selected-label {
    color: var(--bs-dark)
}

.datepicker__info-text--selected-days {
    font-style: normal
}

.datepicker__info--error {
    color: var(--bs-danger);
    font-size: 75%;
    font-style: italic
}

.datepicker__info--help {
    color: var(--bs-dark);
    font-style: italic
}

.datepicker__clear-button,.datepicker__close-button,.datepicker__submit-button {
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    background-color: var(--bs-primary);
    border-radius: var(--bs-border-radius);
    border: none;
    box-shadow: none;
    font-size: 10px;
    color: var(--bs-white);
    margin-top: 20px;
    padding: 7px 13px;
    text-decoration: none;
    text-shadow: none;
    text-transform: uppercase
}

.datepicker--topbar-top .datepicker__clear-button,.datepicker--topbar-top .datepicker__close-button,.datepicker--topbar-top .datepicker__submit-button {
    margin-top: 0
}

.datepicker__clear-button:hover,.datepicker__close-button:hover,.datepicker__submit-button:hover {
    background-color: var(--bs-link-hover-color);
    color: var(--bs-white)
}

.datepicker__clear-button:disabled,.datepicker__clear-button[disabled],.datepicker__close-button:disabled,.datepicker__close-button[disabled],.datepicker__submit-button:disabled,.datepicker__submit-button[disabled] {
    background-color: var(--bs-gray-500);
    color: var(--bs-white);
    cursor: not-allowed
}

.datepicker__clear-button+.datepicker__close-button,.datepicker__clear-button+.datepicker__submit-button {
    margin-left: 10px
}

.datepicker__submit-button {
    width: auto
}

.datepicker__tooltip {
    background-color: var(--bs-gray-500);
    border-radius: var(--bs-border-radius);
    font-size: 65%;
    margin-top: -5px;
    padding: 5px 10px;
    color: var(--bs-white)
}

.datepicker__tooltip:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid var(--bs-gray-500);
    bottom: -4px;
    content: "";
    left: 50%;
    margin-left: -4px;
    position: absolute
}

@media (min-width: 320px) {
    .datepicker {
        width:100%
    }
}

@media (min-width: 577px) {
    .datepicker {
        width:100%
    }

    .datepicker__months {
        overflow: hidden;
        position: relative
    }

    .datepicker__month {
        width: 200px
    }

    .datepicker__month--month1 {
        float: left
    }

    .datepicker__month--month2 {
        display: table;
        float: right
    }

    .datepicker__month-button--disabled {
        visibility: hidden
    }

    .datepicker__months:before {
        background: var(--bs-border-color);
        bottom: 0;
        content: "";
        display: block;
        left: 50%;
        position: absolute;
        top: 0;
        width: 1px
    }
}

@media (min-width: 768px) {
    .datepicker {
        width:598px
    }

    .datepicker__month {
        width: 260px
    }

    .datepicker--topbar-has-clear-button .datepicker__info,.datepicker--topbar-has-close-button .datepicker__info,.datepicker--topbar-has-submit-button .datepicker__info {
        max-width: 325px
    }

    .datepicker__buttons {
        margin-top: 0;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%)
    }
}

.plyr--full-ui input[type=range]:focus,.plyr:focus,.plyr__control:focus {
    outline: 0
}

@keyframes plyr-progress {
    to {
        background-position: 25px 0;
        background-position: var(--plyr-progress-loading-size,25px) 0
    }
}

@keyframes plyr-popup {
    0% {
        opacity: .5;
        transform: translateY(10px)
    }

    to {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes plyr-fade-in {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.plyr {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    align-items: center;
    direction: ltr;
    display: flex;
    flex-direction: column;
    font-family: inherit;
    font-family: var(--plyr-font-family,inherit);
    font-variant-numeric: tabular-nums;
    font-weight: 400;
    font-weight: var(--plyr-font-weight-regular,400);
    line-height: 1.7;
    line-height: var(--plyr-line-height,1.7);
    max-width: 100%;
    min-width: 200px;
    position: relative;
    text-shadow: none;
    transition: box-shadow .3s;
    z-index: 0;
    border-radius: var(--bs-border-radius)
}

.plyr audio,.plyr iframe,.plyr video {
    display: block;
    height: 100%;
    width: 100%
}

.plyr button {
    font: inherit;
    line-height: inherit
}

.plyr--full-ui {
    box-sizing: border-box
}

.plyr--full-ui *,.plyr--full-ui :after,.plyr--full-ui :before {
    box-sizing: inherit
}

.plyr--full-ui a,.plyr--full-ui button,.plyr--full-ui input,.plyr--full-ui label {
    touch-action: manipulation
}

.plyr__badge {
    background: var(--bs-gray-600);
    background: var(--bs-dark,var(--bs-gray-600));
    border-radius: 2px;
    border-radius: var(--plyr-badge-border-radius,2px);
    color: #fff;
    color: var(--plyr-badge-text-color,#fff);
    font-size: 9px;
    font-size: var(--plyr-font-size-badge,9px);
    line-height: 1;
    padding: 3px 4px
}

.plyr--full-ui ::-webkit-media-text-track-container {
    display: none
}

.plyr__captions {
    animation: .3s plyr-fade-in;
    bottom: 0;
    display: none;
    font-size: 13px;
    font-size: var(--plyr-font-size-small,13px);
    left: 0;
    padding: 10px;
    padding: var(--plyr-control-spacing,10px);
    position: absolute;
    text-align: center;
    transition: transform .4s ease-in-out;
    width: 100%
}

.plyr__menu__container,.plyr__tooltip {
    bottom: 100%;
    box-shadow: 0 1px 2px rgba(0,0,0,.15);
    margin-bottom: 10px
}

.plyr [data-plyr=airplay],.plyr [data-plyr=captions],.plyr [data-plyr=fullscreen],.plyr [data-plyr=pip],.plyr--youtube.plyr--paused.plyr__poster-enabled:not(.plyr--stopped) .plyr__poster,.plyr__captions span:empty,.plyr__control.plyr__control--pressed .icon--not-pressed,.plyr__control.plyr__control--pressed .label--not-pressed,.plyr__control:not(.plyr__control--pressed) .icon--pressed,.plyr__control:not(.plyr__control--pressed) .label--pressed,.plyr__controls:empty,.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip,a.plyr__control:after,a.plyr__control:before {
    display: none
}

@media(min-width: 480px) {
    .plyr__captions {
        font-size:15px;
        font-size: var(--plyr-font-size-base,15px);
        padding: 20px;
        padding: calc(var(--plyr-control-spacing,10px)*2)
    }
}

@media(min-width: 768px) {
    .plyr__captions {
        font-size:18px;
        font-size: var(--plyr-font-size-large,18px)
    }
}

.plyr--captions-active .plyr__captions {
    display: block
}

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty)~.plyr__captions {
    transform: translateY(-40px);
    transform: translateY(calc(var(--plyr-control-spacing,10px)*-4))
}

.plyr__caption {
    background: rgba(0,0,0,.8);
    background: var(--plyr-captions-background,rgba(0,0,0,.8));
    border-radius: 2px;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    color: #fff;
    color: var(--plyr-captions-text-color,#fff);
    line-height: 185%;
    padding: .2em .5em;
    white-space: pre-wrap
}

.plyr__caption div {
    display: inline
}

.plyr__control {
    background: 0 0;
    border: 0;
    border-radius: 3px;
    border-radius: var(--plyr-control-radius,3px);
    color: inherit;
    cursor: pointer;
    flex-shrink: 0;
    overflow: visible;
    padding: 7px;
    padding: calc(var(--plyr-control-spacing,10px)*.7);
    position: relative;
    transition: .3s
}

.plyr__control svg {
    fill: currentColor;
    display: block;
    height: 18px;
    height: var(--plyr-control-icon-size,18px);
    pointer-events: none;
    width: 18px;
    width: var(--plyr-control-icon-size,18px)
}

.plyr__control.plyr__tab-focus {
    outline: var(--bs-primary-500) dotted 3px;
    outline: var(--plyr-tab-focus-color,var(--ht-color-primary,var(--ht-color-primary,var(--bs-primary-500)))) dotted 3px;
    outline-offset: 2px
}

a.plyr__control {
    text-decoration: none
}

.plyr--full-ui ::-webkit-media-controls {
    display: none
}

.plyr__controls {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    text-align: center
}

.plyr__controls .plyr__progress__container {
    flex: 1;
    min-width: 0
}

.plyr__controls .plyr__controls__item {
    margin-left: 2.5px;
    margin-left: calc(var(--plyr-control-spacing,10px)/4)
}

.plyr__controls .plyr__controls__item:first-child {
    margin-left: 0;
    margin-right: auto
}

.plyr__controls .plyr__controls__item.plyr__progress__container {
    padding-left: 2.5px;
    padding-left: calc(var(--plyr-control-spacing,10px)/4)
}

.plyr__controls .plyr__controls__item.plyr__time {
    padding: 0 5px;
    padding: 0 calc(var(--plyr-control-spacing,10px)/2)
}

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child,.plyr__controls .plyr__controls__item.plyr__time+.plyr__time,.plyr__controls .plyr__controls__item.plyr__time:first-child {
    padding-left: 0
}

.plyr--airplay-supported [data-plyr=airplay],.plyr--captions-enabled [data-plyr=captions],.plyr--fullscreen-enabled [data-plyr=fullscreen],.plyr--pip-supported [data-plyr=pip] {
    display: inline-block
}

.plyr__menu {
    display: flex;
    position: relative
}

.plyr__menu .plyr__control svg {
    transition: transform .3s
}

.plyr__menu .plyr__control[aria-expanded=true] svg {
    transform: rotate(90deg)
}

.plyr__menu__container {
    animation: .2s plyr-popup;
    background: hsla(0,0%,100%,.9);
    background: var(--plyr-menu-background,hsla(0,0%,100%,.9));
    border-radius: 4px;
    box-shadow: var(--plyr-menu-shadow,0 1px 2px rgba(0,0,0,.15));
    color: var(--bs-gray-600);
    color: var(--plyr-menu-color,var(--bs-gray-600));
    font-size: 15px;
    font-size: var(--plyr-font-size-base,15px);
    position: absolute;
    right: -3px;
    text-align: left;
    white-space: nowrap;
    z-index: 3
}

.plyr__menu__container>div {
    overflow: hidden;
    transition: height .35s cubic-bezier(.4,0,.2,1),width .35s cubic-bezier(.4,0,.2,1)
}

.plyr__menu__container:after {
    border: 4px solid transparent;
    border-top-color: hsla(0,0%,100%,.9);
    border: var(--plyr-menu-arrow-size,4px) solid transparent;
    border-top-color: var(--plyr-menu-background,hsla(0,0%,100%,.9));
    content: "";
    height: 0;
    position: absolute;
    right: 14px;
    right: calc(var(--plyr-control-icon-size,18px)/ 2 + var(--plyr-control-spacing,10px)*.7 - var(--plyr-menu-arrow-size,4px)/ 2);
    top: 100%;
    width: 0
}

.plyr__menu__container [role=menu] {
    padding: 7px;
    padding: calc(var(--plyr-control-spacing,10px)*.7)
}

.plyr__menu__container [role=menuitem],.plyr__menu__container [role=menuitemradio] {
    margin-top: 2px
}

.plyr__menu__container [role=menuitem]:first-child,.plyr__menu__container [role=menuitemradio]:first-child {
    margin-top: 0
}

.plyr__menu__container .plyr__control {
    align-items: center;
    color: var(--bs-gray-600);
    color: var(--plyr-menu-color,var(--bs-gray-600));
    display: flex;
    font-size: 13px;
    font-size: var(--plyr-font-size-menu,var(--plyr-font-size-small,13px));
    padding: 4.66667px 10.5px;
    padding: calc(var(--plyr-control-spacing,10px)*.7/1.5) calc(var(--plyr-control-spacing,10px)*.7*1.5);
    -webkit-user-select: none;
    user-select: none;
    width: 100%
}

.plyr__menu__container .plyr__control>span {
    align-items: inherit;
    display: flex;
    width: 100%
}

.plyr__menu__container .plyr__control:after {
    border: 4px solid transparent;
    border: var(--plyr-menu-item-arrow-size,4px) solid transparent;
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
}

.plyr__menu__container .plyr__control--forward {
    padding-right: 28px;
    padding-right: calc(var(--plyr-control-spacing,10px)*.7*4)
}

.plyr__menu__container .plyr__control--forward:after {
    border-left-color: var(--bs-gray-500);
    border-left-color: var(--plyr-menu-arrow-color,var(--bs-gray-500));
    right: 6.5px;
    right: calc(var(--plyr-control-spacing,10px)*.7*1.5 - var(--plyr-menu-item-arrow-size,4px))
}

.plyr__menu__container .plyr__control--forward.plyr__tab-focus:after,.plyr__menu__container .plyr__control--forward:hover:after {
    border-left-color: currentColor
}

.plyr__menu__container .plyr__control--back {
    font-weight: 400;
    font-weight: var(--plyr-font-weight-regular,400);
    margin: 7px;
    margin: calc(var(--plyr-control-spacing,10px)*.7);
    margin-bottom: 3.5px;
    margin-bottom: calc(var(--plyr-control-spacing,10px)*.7/2);
    padding-left: 28px;
    padding-left: calc(var(--plyr-control-spacing,10px)*.7*4);
    position: relative;
    width: calc(100% - 14px);
    width: calc(100% - var(--plyr-control-spacing,10px)*.7*2)
}

.plyr__menu__container .plyr__control--back:after {
    border-right-color: var(--bs-gray-500);
    border-right-color: var(--plyr-menu-arrow-color,var(--bs-gray-500));
    left: 6.5px;
    left: calc(var(--plyr-control-spacing,10px)*.7*1.5 - var(--plyr-menu-item-arrow-size,4px))
}

.plyr__menu__container .plyr__control--back:before {
    background: var(--bs-gray-300);
    background: var(--plyr-menu-back-border-color,var(--bs-gray-300));
    box-shadow: 0 1px 0 #fff;
    box-shadow: 0 1px 0 var(--plyr-menu-back-border-shadow-color,#fff);
    content: "";
    height: 1px;
    left: 0;
    margin-top: 3.5px;
    margin-top: calc(var(--plyr-control-spacing,10px)*.7/2);
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 100%
}

.plyr__menu__container .plyr__control--back.plyr__tab-focus:after,.plyr__menu__container .plyr__control--back:hover:after {
    border-right-color: currentColor
}

.plyr__menu__container .plyr__control[role=menuitemradio] {
    padding-left: 7px;
    padding-left: calc(var(--plyr-control-spacing,10px)*.7)
}

.plyr__menu__container .plyr__control[role=menuitemradio]:after,.plyr__menu__container .plyr__control[role=menuitemradio]:before {
    border-radius: 100%
}

.plyr__menu__container .plyr__control[role=menuitemradio]:before {
    background: rgba(0,0,0,.1);
    content: "";
    display: block;
    flex-shrink: 0;
    height: 16px;
    margin-right: 10px;
    margin-right: var(--plyr-control-spacing,10px);
    transition: .3s;
    width: 16px
}

.plyr__menu__container .plyr__control[role=menuitemradio]:after {
    background: #fff;
    border: 0;
    height: 6px;
    left: 12px;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%) scale(0);
    transition: transform .3s,opacity .3s;
    width: 6px
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]:before {
    background: var(--bs-primary-500);
    background: var(--plyr-control-toggle-checked-background,var(--ht-color-primary,var(--ht-color-primary,var(--bs-primary-500))))
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]:after {
    opacity: 1;
    transform: translateY(-50%) scale(1)
}

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus:before,.plyr__menu__container .plyr__control[role=menuitemradio]:hover:before {
    background: rgba(35,40,47,.1)
}

.plyr__menu__container .plyr__menu__value {
    align-items: center;
    display: flex;
    margin-left: auto;
    margin-right: calc(-7px - -2);
    margin-right: calc(var(--plyr-control-spacing,10px)*.7*-1 - -2);
    overflow: hidden;
    padding-left: 24.5px;
    padding-left: calc(var(--plyr-control-spacing,10px)*.7*3.5);
    pointer-events: none
}

.plyr--full-ui input[type=range] {
    -webkit-appearance: none;
    appearance: none;
    background: 0 0;
    border: 0;
    border-radius: 26px;
    border-radius: calc(var(--plyr-range-thumb-height,13px)*2);
    color: var(--bs-primary-500);
    color: var(--plyr-range-fill-background,var(--ht-color-primary,var(--ht-color-primary,var(--bs-primary-500))));
    display: block;
    height: 19px;
    height: calc(var(--plyr-range-thumb-active-shadow-width,3px)*2 + var(--plyr-range-thumb-height,13px));
    margin: 0;
    min-width: 0;
    padding: 0;
    transition: box-shadow .3s;
    width: 100%
}

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
    background: 0 0;
    background-image: linear-gradient(90deg,currentColor 0,transparent 0);
    background-image: linear-gradient(to right,currentColor var(--value,0),transparent var(--value,0));
    border: 0;
    border-radius: 2.5px;
    border-radius: calc(var(--plyr-range-track-height,5px)/2);
    height: 5px;
    height: var(--plyr-range-track-height,5px);
    -webkit-transition: box-shadow .3s;
    transition: box-shadow .3s;
    -webkit-user-select: none;
    user-select: none
}

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: #fff;
    background: var(--plyr-range-thumb-background,#fff);
    border: 0;
    border-radius: 100%;
    box-shadow: 0 1px 1px 0 0 0 1px rgba(35,40,47,.15) rgba(35,40,47,.2);
    box-shadow: var(--plyr-range-thumb-shadow,0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2));
    height: 13px;
    height: var(--plyr-range-thumb-height,13px);
    margin-top: -4px;
    margin-top: calc(var(--plyr-range-thumb-height,13px)/ 2*-1 - var(--plyr-range-track-height,5px)/ 2*-1);
    position: relative;
    -webkit-transition: .2s;
    transition: .2s;
    width: 13px;
    width: var(--plyr-range-thumb-height,13px)
}

.plyr--full-ui input[type=range]::-moz-range-track {
    background: 0 0;
    border: 0;
    border-radius: 2.5px;
    border-radius: calc(var(--plyr-range-track-height,5px)/2);
    height: 5px;
    height: var(--plyr-range-track-height,5px);
    -moz-transition: box-shadow .3s;
    transition: box-shadow .3s;
    user-select: none
}

.plyr--full-ui input[type=range]::-moz-range-thumb {
    background: #fff;
    background: var(--plyr-range-thumb-background,#fff);
    border: 0;
    border-radius: 100%;
    box-shadow: 0 1px 1px 0 0 0 1px rgba(35,40,47,.15) rgba(35,40,47,.2);
    box-shadow: var(--plyr-range-thumb-shadow,0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2));
    height: 13px;
    height: var(--plyr-range-thumb-height,13px);
    position: relative;
    -moz-transition: .2s;
    transition: .2s;
    width: 13px;
    width: var(--plyr-range-thumb-height,13px)
}

.plyr--full-ui input[type=range]::-moz-range-progress {
    background: currentColor;
    border-radius: 2.5px;
    border-radius: calc(var(--plyr-range-track-height,5px)/2);
    height: 5px;
    height: var(--plyr-range-track-height,5px)
}

.plyr--full-ui input[type=range]::-ms-track {
    color: transparent
}

.plyr--full-ui input[type=range]::-ms-fill-upper,.plyr--full-ui input[type=range]::-ms-track {
    background: 0 0;
    border: 0;
    border-radius: 2.5px;
    border-radius: calc(var(--plyr-range-track-height,5px)/2);
    height: 5px;
    height: var(--plyr-range-track-height,5px);
    -ms-transition: box-shadow .3s;
    transition: box-shadow .3s;
    user-select: none
}

.plyr--full-ui input[type=range]::-ms-fill-lower {
    background: currentColor;
    border: 0;
    border-radius: 2.5px;
    border-radius: calc(var(--plyr-range-track-height,5px)/2);
    height: 5px;
    height: var(--plyr-range-track-height,5px);
    -ms-transition: box-shadow .3s;
    transition: box-shadow .3s;
    user-select: none
}

.plyr--full-ui input[type=range]::-ms-thumb {
    background: #fff;
    background: var(--plyr-range-thumb-background,#fff);
    border: 0;
    border-radius: 100%;
    box-shadow: 0 1px 1px 0 0 0 1px rgba(35,40,47,.15) rgba(35,40,47,.2);
    box-shadow: var(--plyr-range-thumb-shadow,0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2));
    height: 13px;
    height: var(--plyr-range-thumb-height,13px);
    margin-top: 0;
    position: relative;
    -ms-transition: .2s;
    transition: .2s;
    width: 13px;
    width: var(--plyr-range-thumb-height,13px)
}

.plyr--full-ui input[type=range]::-ms-tooltip {
    display: none
}

.plyr--full-ui input[type=range]::-moz-focus-outer {
    border: 0
}

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
    outline: var(--bs-primary-500) dotted 3px;
    outline: var(--plyr-tab-focus-color,var(--ht-color-primary,var(--ht-color-primary,var(--bs-primary-500)))) dotted 3px;
    outline-offset: 2px
}

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
    outline: var(--bs-primary-500) dotted 3px;
    outline: var(--plyr-tab-focus-color,var(--ht-color-primary,var(--ht-color-primary,var(--bs-primary-500)))) dotted 3px;
    outline-offset: 2px
}

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
    outline: var(--bs-primary-500) dotted 3px;
    outline: var(--plyr-tab-focus-color,var(--ht-color-primary,var(--ht-color-primary,var(--bs-primary-500)))) dotted 3px;
    outline-offset: 2px
}

.plyr__poster {
    background-color: #000;
    background-color: var(--plyr-video-background,var(--plyr-video-background,#000));
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity .2s;
    width: 100%;
    z-index: 1
}

.plyr--stopped.plyr__poster-enabled .plyr__poster {
    opacity: 1
}

.plyr__time {
    font-size: 13px;
    font-size: var(--plyr-font-size-time,var(--plyr-font-size-small,13px))
}

.plyr__time+.plyr__time:before {
    content: "⁄";
    margin-right: 10px;
    margin-right: var(--plyr-control-spacing,10px)
}

@media(max-width: 767px) {
    .plyr__time+.plyr__time {
        display:none
    }
}

.plyr__tooltip {
    background: hsla(0,0%,100%,.9);
    background: var(--plyr-tooltip-background,hsla(0,0%,100%,.9));
    border-radius: 3px;
    border-radius: var(--plyr-tooltip-radius,3px);
    box-shadow: var(--plyr-tooltip-shadow,0 1px 2px rgba(0,0,0,.15));
    color: var(--bs-gray-600);
    color: var(--plyr-tooltip-color,var(--bs-gray-600));
    font-size: 13px;
    font-size: var(--plyr-font-size-small,13px);
    font-weight: 400;
    font-weight: var(--plyr-font-weight-regular,400);
    left: 50%;
    line-height: 1.3;
    margin-bottom: calc(var(--plyr-control-spacing,10px)/2*2);
    opacity: 0;
    padding: 5px 7.5px;
    padding: calc(var(--plyr-control-spacing,10px)/2) calc(var(--plyr-control-spacing,10px)/2*1.5);
    pointer-events: none;
    position: absolute;
    transform: translate(-50%,10px) scale(.8);
    transform-origin: 50% 100%;
    transition: transform .2s .1s,opacity .2s .1s;
    white-space: nowrap;
    z-index: 2
}

.plyr__tooltip:before {
    border-left: 4px solid transparent;
    border-left: var(--plyr-tooltip-arrow-size,4px) solid transparent;
    border-right: 4px solid transparent;
    border-right: var(--plyr-tooltip-arrow-size,4px) solid transparent;
    border-top: 4px solid hsla(0,0%,100%,.9);
    border-top: var(--plyr-tooltip-arrow-size,4px) solid var(--plyr-tooltip-background,hsla(0,0%,100%,.9));
    bottom: -4px;
    bottom: calc(var(--plyr-tooltip-arrow-size,4px)*-1);
    content: "";
    height: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 0;
    z-index: 2
}

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip,.plyr .plyr__control:hover .plyr__tooltip,.plyr__tooltip--visible {
    opacity: 1;
    transform: translate(-50%) scale(1)
}

.plyr .plyr__control:hover .plyr__tooltip {
    z-index: 3
}

.plyr__controls>.plyr__control:first-child .plyr__tooltip,.plyr__controls>.plyr__control:first-child+.plyr__control .plyr__tooltip {
    left: 0;
    transform: translateY(10px) scale(.8);
    transform-origin: 0 100%
}

.plyr__controls>.plyr__control:first-child .plyr__tooltip:before,.plyr__controls>.plyr__control:first-child+.plyr__control .plyr__tooltip:before {
    left: 16px;
    left: calc(var(--plyr-control-icon-size,18px)/ 2 + var(--plyr-control-spacing,10px)*.7)
}

.plyr__controls>.plyr__control:last-child .plyr__tooltip {
    left: auto;
    right: 0;
    transform: translateY(10px) scale(.8);
    transform-origin: 100% 100%
}

.plyr__controls>.plyr__control:last-child .plyr__tooltip:before {
    left: auto;
    right: 16px;
    right: calc(var(--plyr-control-icon-size,18px)/ 2 + var(--plyr-control-spacing,10px)*.7);
    transform: translateX(50%)
}

.plyr__controls>.plyr__control:first-child .plyr__tooltip--visible,.plyr__controls>.plyr__control:first-child+.plyr__control .plyr__tooltip--visible,.plyr__controls>.plyr__control:first-child+.plyr__control.plyr__tab-focus .plyr__tooltip,.plyr__controls>.plyr__control:first-child+.plyr__control:hover .plyr__tooltip,.plyr__controls>.plyr__control:first-child.plyr__tab-focus .plyr__tooltip,.plyr__controls>.plyr__control:first-child:hover .plyr__tooltip,.plyr__controls>.plyr__control:last-child .plyr__tooltip--visible,.plyr__controls>.plyr__control:last-child.plyr__tab-focus .plyr__tooltip,.plyr__controls>.plyr__control:last-child:hover .plyr__tooltip {
    transform: translate(0) scale(1)
}

.plyr__progress {
    left: 6.5px;
    left: calc(var(--plyr-range-thumb-height,13px)*.5);
    margin-right: 13px;
    margin-right: var(--plyr-range-thumb-height,13px);
    position: relative
}

.plyr__progress input[type=range],.plyr__progress__buffer {
    margin-left: -6.5px;
    margin-left: calc(var(--plyr-range-thumb-height,13px)*-.5);
    margin-right: -6.5px;
    margin-right: calc(var(--plyr-range-thumb-height,13px)*-.5);
    width: calc(100% + 13px);
    width: calc(100% + var(--plyr-range-thumb-height,13px))
}

.plyr__progress input[type=range] {
    position: relative;
    z-index: 2
}

.plyr__progress .plyr__tooltip {
    font-size: 13px;
    font-size: var(--plyr-font-size-time,var(--plyr-font-size-small,13px));
    left: 0
}

.plyr__progress__buffer {
    -webkit-appearance: none;
    background: 0 0;
    border: 0;
    border-radius: 100px;
    height: 5px;
    height: var(--plyr-range-track-height,5px);
    left: 0;
    margin-top: -2.5px;
    margin-top: calc(var(--plyr-range-track-height,5px)/2*-1);
    padding: 0;
    position: absolute;
    top: 50%
}

.plyr__progress__buffer::-webkit-progress-bar {
    background: 0 0
}

.plyr__progress__buffer::-webkit-progress-value {
    background: currentColor;
    border-radius: 100px;
    min-width: 5px;
    min-width: var(--plyr-range-track-height,5px);
    -webkit-transition: width .2s;
    transition: width .2s
}

.plyr__progress__buffer::-moz-progress-bar {
    background: currentColor;
    border-radius: 100px;
    min-width: 5px;
    min-width: var(--plyr-range-track-height,5px);
    -moz-transition: width .2s;
    transition: width .2s
}

.plyr__progress__buffer::-ms-fill {
    border-radius: 100px;
    -ms-transition: width .2s;
    transition: width .2s
}

.plyr--loading .plyr__progress__buffer {
    animation: 1s linear infinite plyr-progress;
    background-image: linear-gradient(-45deg,rgba(35,40,47,.6) 25%,transparent 0,transparent 50%,rgba(35,40,47,.6) 0,rgba(35,40,47,.6) 75%,transparent 0,transparent);
    background-image: linear-gradient(-45deg,var(--plyr-progress-loading-background,rgba(35,40,47,.6)) 25%,transparent 25%,transparent 50%,var(--plyr-progress-loading-background,rgba(35,40,47,.6)) 50%,var(--plyr-progress-loading-background,rgba(35,40,47,.6)) 75%,transparent 75%,transparent);
    background-repeat: repeat-x;
    background-size: 25px 25px;
    background-size: var(--plyr-progress-loading-size,25px) var(--plyr-progress-loading-size,25px);
    color: transparent
}

.plyr--video.plyr--loading .plyr__progress__buffer {
    background-color: hsla(0,0%,100%,.25);
    background-color: var(--plyr-video-progress-buffered-background,hsla(0,0%,100%,.25))
}

.plyr--audio.plyr--loading .plyr__progress__buffer {
    background-color: rgba(193,200,209,.6);
    background-color: var(--plyr-audio-progress-buffered-background,rgba(193,200,209,.6))
}

.plyr__volume {
    align-items: center;
    display: flex;
    max-width: 110px;
    min-width: 80px;
    position: relative;
    width: 20%
}

.plyr__volume input[type=range] {
    margin-left: 5px;
    margin-left: calc(var(--plyr-control-spacing,10px)/2);
    margin-right: 5px;
    margin-right: calc(var(--plyr-control-spacing,10px)/2);
    position: relative;
    z-index: 2
}

.plyr--is-ios .plyr__volume {
    min-width: 0;
    width: auto
}

.plyr--audio {
    display: block
}

.plyr--audio .plyr__controls {
    background: #fff;
    background: var(--plyr-audio-controls-background,#fff);
    border-radius: inherit;
    color: var(--bs-gray-600);
    color: var(--plyr-audio-control-color,var(--bs-gray-600));
    padding: 10px;
    padding: var(--plyr-control-spacing,10px)
}

.plyr--audio .plyr__control.plyr__tab-focus,.plyr--audio .plyr__control:hover,.plyr--audio .plyr__control[aria-expanded=true] {
    background: var(--bs-primary-500);
    background: var(--plyr-audio-control-background-hover,var(--ht-color-primary,var(--ht-color-primary,var(--bs-primary-500))));
    color: #fff;
    color: var(--plyr-audio-control-color-hover,#fff)
}

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
    background-color: rgba(193,200,209,.6);
    background-color: var(--plyr-audio-range-track-background,var(--plyr-audio-progress-buffered-background,rgba(193,200,209,.6)))
}

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
    background-color: rgba(193,200,209,.6);
    background-color: var(--plyr-audio-range-track-background,var(--plyr-audio-progress-buffered-background,rgba(193,200,209,.6)))
}

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
    background-color: rgba(193,200,209,.6);
    background-color: var(--plyr-audio-range-track-background,var(--plyr-audio-progress-buffered-background,rgba(193,200,209,.6)))
}

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
    box-shadow: 0 1px 1px 0 0 0 1px rgba(35,40,47,.15) rgba(35,40,47,.2),0 0 0 3px rgba(35,40,47,.1);
    box-shadow: var(--plyr-range-thumb-shadow,0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2)),0 0 0 var(--plyr-range-thumb-active-shadow-width,3px) var(--plyr-audio-range-thumb-active-shadow-color,rgba(35,40,47,.1))
}

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
    box-shadow: 0 1px 1px 0 0 0 1px rgba(35,40,47,.15) rgba(35,40,47,.2),0 0 0 3px rgba(35,40,47,.1);
    box-shadow: var(--plyr-range-thumb-shadow,0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2)),0 0 0 var(--plyr-range-thumb-active-shadow-width,3px) var(--plyr-audio-range-thumb-active-shadow-color,rgba(35,40,47,.1))
}

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
    box-shadow: 0 1px 1px 0 0 0 1px rgba(35,40,47,.15) rgba(35,40,47,.2),0 0 0 3px rgba(35,40,47,.1);
    box-shadow: var(--plyr-range-thumb-shadow,0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2)),0 0 0 var(--plyr-range-thumb-active-shadow-width,3px) var(--plyr-audio-range-thumb-active-shadow-color,rgba(35,40,47,.1))
}

.plyr--audio .plyr__progress__buffer {
    color: rgba(193,200,209,.6);
    color: var(--plyr-audio-progress-buffered-background,rgba(193,200,209,.6))
}

.plyr--video {
    background: #000;
    background: var(--plyr-video-background,var(--plyr-video-background,#000));
    overflow: hidden
}

.plyr--video.plyr--menu-open {
    overflow: visible
}

.plyr__video-wrapper {
    border-radius: var(--bs-border-radius);
    background: #000;
    background: var(--plyr-video-background,var(--plyr-video-background,#000));
    height: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%
}

.plyr__video-embed,.plyr__video-wrapper--fixed-ratio {
    aspect-ratio: 16/9
}

@supports not (aspect-ratio: 16/9) {
    .plyr__video-embed,.plyr__video-wrapper--fixed-ratio {
        height:0;
        padding-bottom: 56.25%;
        position: relative
    }
}

.plyr__video-embed iframe,.plyr__video-wrapper--fixed-ratio video {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.plyr--full-ui .plyr__video-embed>.plyr__video-embed__container {
    padding-bottom: 240%;
    position: relative;
    transform: translateY(-38.28125%)
}

.plyr--video .plyr__controls {
    background: linear-gradient(transparent,rgba(0,0,0,.75));
    background: var(--plyr-video-controls-background,linear-gradient(transparent,rgba(0,0,0,.75)));
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    bottom: 0;
    color: #fff;
    color: var(--plyr-video-control-color,#fff);
    left: 0;
    padding: 5px;
    padding: calc(var(--plyr-control-spacing,10px)/2);
    padding-top: 20px;
    padding-top: calc(var(--plyr-control-spacing,10px)*2);
    position: absolute;
    right: 0;
    transition: opacity .4s ease-in-out,transform .4s ease-in-out;
    z-index: 3
}

@media(min-width: 480px) {
    .plyr--video .plyr__controls {
        padding:10px;
        padding: var(--plyr-control-spacing,10px);
        padding-top: 35px;
        padding-top: calc(var(--plyr-control-spacing,10px)*3.5)
    }
}

.plyr--video.plyr--hide-controls .plyr__controls {
    opacity: 0;
    pointer-events: none;
    transform: translateY(100%)
}

.plyr--video .plyr__control.plyr__tab-focus,.plyr--video .plyr__control:hover,.plyr--video .plyr__control[aria-expanded=true] {
    background: var(--bs-primary-500);
    background: var(--plyr-video-control-background-hover,var(--ht-color-primary,var(--ht-color-primary,var(--bs-primary-500))));
    color: #fff;
    color: var(--plyr-video-control-color-hover,#fff)
}

.plyr__control--overlaid {
    background: var(--bs-primary-500);
    background: var(--plyr-video-control-background-hover,var(--ht-color-primary,var(--ht-color-primary,var(--bs-primary-500))));
    border: 0;
    border-radius: 100%;
    color: #fff;
    color: var(--plyr-video-control-color,#fff);
    display: none;
    left: 50%;
    opacity: .9;
    padding: 15px;
    padding: calc(var(--plyr-control-spacing,10px)*2.8);
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    transition: .3s;
    z-index: 2;
    max-width: 80px;
    max-height: 80px
}

.plyr--full-ui.plyr--video .plyr__control--overlaid,.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen,.plyr__cues {
    display: block
}

.plyr__control--overlaid svg {
    left: 3px;
    position: relative
}

.plyr__control--overlaid:focus,.plyr__control--overlaid:hover {
    opacity: 1
}

.plyr--playing .plyr__control--overlaid {
    opacity: 0;
    visibility: hidden
}

.plyr--full-ui.plyr--video .plyr__control--overlaid svg {
    height: var(--plyr-control-icon-size,24px);
    width: var(--plyr-control-icon-size,24px)
}

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
    background-color: hsla(0,0%,100%,.25);
    background-color: var(--plyr-video-range-track-background,var(--plyr-video-progress-buffered-background,hsla(0,0%,100%,.25)))
}

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
    background-color: hsla(0,0%,100%,.25);
    background-color: var(--plyr-video-range-track-background,var(--plyr-video-progress-buffered-background,hsla(0,0%,100%,.25)))
}

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
    background-color: hsla(0,0%,100%,.25);
    background-color: var(--plyr-video-range-track-background,var(--plyr-video-progress-buffered-background,hsla(0,0%,100%,.25)))
}

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
    box-shadow: 0 1px 1px 0 0 0 1px rgba(35,40,47,.15) rgba(35,40,47,.2),0 0 0 3px rgba(255,255,255,.5);
    box-shadow: var(--plyr-range-thumb-shadow,0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2)),0 0 0 var(--plyr-range-thumb-active-shadow-width,3px) var(--plyr-audio-range-thumb-active-shadow-color,hsla(0,0%,100%,.5))
}

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
    box-shadow: 0 1px 1px 0 0 0 1px rgba(35,40,47,.15) rgba(35,40,47,.2),0 0 0 3px rgba(255,255,255,.5);
    box-shadow: var(--plyr-range-thumb-shadow,0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2)),0 0 0 var(--plyr-range-thumb-active-shadow-width,3px) var(--plyr-audio-range-thumb-active-shadow-color,hsla(0,0%,100%,.5))
}

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
    box-shadow: 0 1px 1px 0 0 0 1px rgba(35,40,47,.15) rgba(35,40,47,.2),0 0 0 3px rgba(255,255,255,.5);
    box-shadow: var(--plyr-range-thumb-shadow,0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2)),0 0 0 var(--plyr-range-thumb-active-shadow-width,3px) var(--plyr-audio-range-thumb-active-shadow-color,hsla(0,0%,100%,.5))
}

.plyr--video .plyr__progress__buffer {
    color: hsla(0,0%,100%,.25);
    color: var(--plyr-video-progress-buffered-background,hsla(0,0%,100%,.25))
}

.plyr:-webkit-full-screen {
    background: #000;
    border-radius: 0!important;
    height: 100%;
    margin: 0;
    width: 100%
}

.plyr:fullscreen {
    background: #000;
    border-radius: 0!important;
    height: 100%;
    margin: 0;
    width: 100%
}

.plyr:-webkit-full-screen video {
    height: 100%
}

.plyr:fullscreen video {
    height: 100%
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
    display: block
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
    display: block
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen+svg {
    display: none
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen+svg {
    display: none
}

.plyr:-webkit-full-screen.plyr--hide-controls {
    cursor: none
}

.plyr:fullscreen.plyr--hide-controls {
    cursor: none
}

.plyr--fullscreen-fallback {
    background: #000;
    border-radius: 0!important;
    bottom: 0;
    display: block;
    height: 100%;
    left: 0;
    margin: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10000000
}

.plyr--fullscreen-fallback video {
    height: 100%
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen+svg,.plyr__ads:empty:after {
    display: none
}

.plyr--fullscreen-fallback.plyr--hide-controls {
    cursor: none
}

@media(min-width: 1024px) {
    .plyr:-webkit-full-screen .plyr__captions {
        font-size:21px;
        font-size: var(--plyr-font-size-xlarge,21px)
    }

    .plyr:fullscreen .plyr__captions {
        font-size: 21px;
        font-size: var(--plyr-font-size-xlarge,21px)
    }

    .plyr--fullscreen-fallback .plyr__captions {
        font-size: 21px;
        font-size: var(--plyr-font-size-xlarge,21px)
    }
}

.plyr__ads {
    border-radius: inherit;
    bottom: 0;
    cursor: pointer;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1
}

.plyr__ads>div,.plyr__ads>div iframe {
    height: 100%;
    position: absolute;
    width: 100%
}

.plyr__ads:after {
    background: #23282f;
    border-radius: 2px;
    bottom: 10px;
    bottom: var(--plyr-control-spacing,10px);
    color: #fff;
    content: attr(data-badge-text);
    font-size: 11px;
    padding: 2px 6px;
    pointer-events: none;
    position: absolute;
    right: 10px;
    right: var(--plyr-control-spacing,10px);
    z-index: 3
}

.plyr__cues {
    background: currentColor;
    height: 5px;
    height: var(--plyr-range-track-height,5px);
    left: 0;
    opacity: .8;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    z-index: 3
}

.plyr__preview-thumb {
    background-color: hsla(0,0%,100%,.9);
    background-color: var(--plyr-tooltip-background,hsla(0,0%,100%,.9));
    border-radius: 3px;
    bottom: 100%;
    box-shadow: 0 1px 2px rgba(0,0,0,.15);
    box-shadow: var(--plyr-tooltip-shadow,0 1px 2px rgba(0,0,0,.15));
    margin-bottom: 10px;
    margin-bottom: calc(var(--plyr-control-spacing,10px)/2*2);
    opacity: 0;
    padding: 3px;
    padding: var(--plyr-tooltip-radius,3px);
    pointer-events: none;
    position: absolute;
    transform: translateY(10px) scale(.8);
    transform-origin: 50% 100%;
    transition: transform .2s .1s,opacity .2s .1s;
    z-index: 2
}

.plyr__preview-thumb--is-shown {
    opacity: 1;
    transform: translate(0) scale(1)
}

.plyr__preview-thumb:before {
    border-left: 4px solid transparent;
    border-left: var(--plyr-tooltip-arrow-size,4px) solid transparent;
    border-right: 4px solid transparent;
    border-right: var(--plyr-tooltip-arrow-size,4px) solid transparent;
    border-top: 4px solid hsla(0,0%,100%,.9);
    border-top: var(--plyr-tooltip-arrow-size,4px) solid var(--plyr-tooltip-background,hsla(0,0%,100%,.9));
    bottom: -4px;
    bottom: calc(var(--plyr-tooltip-arrow-size,4px)*-1);
    content: "";
    height: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 0;
    z-index: 2
}

.plyr__preview-thumb__image-container {
    background: #c1c8d1;
    border-radius: 2px;
    border-radius: calc(var(--plyr-tooltip-radius,3px) - 1px);
    overflow: hidden;
    position: relative;
    z-index: 0
}

.plyr__preview-thumb__image-container img {
    height: 100%;
    left: 0;
    max-height: none;
    max-width: none;
    position: absolute;
    top: 0;
    width: 100%
}

.plyr__preview-thumb__time-container {
    bottom: 6px;
    left: 0;
    position: absolute;
    right: 0;
    white-space: nowrap;
    z-index: 3
}

.plyr__preview-thumb__time-container span {
    background-color: rgba(0,0,0,.55);
    border-radius: 2px;
    border-radius: calc(var(--plyr-tooltip-radius,3px) - 1px);
    color: #fff;
    font-size: 13px;
    font-size: var(--plyr-font-size-time,var(--plyr-font-size-small,13px));
    padding: 3px 6px
}

.plyr__preview-scrubbing {
    bottom: 0;
    filter: blur(1px);
    height: 100%;
    left: 0;
    margin: auto;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity .3s;
    width: 100%;
    z-index: 1
}

.plyr__preview-scrubbing--is-shown {
    opacity: 1
}

.plyr__preview-scrubbing img {
    height: 100%;
    left: 0;
    max-height: none;
    max-width: none;
    -o-object-fit: contain;
    object-fit: contain;
    position: absolute;
    top: 0;
    width: 100%
}

.plyr--no-transition {
    transition: none!important
}

.plyr__sr-only {
    clip: rect(1px,1px,1px,1px);
    border: 0!important;
    height: 1px!important;
    overflow: hidden;
    padding: 0!important;
    position: absolute!important;
    width: 1px!important
}

.plyr [hidden] {
    display: none!important
}

[data-cue=fadeIn],[data-cues=fadeIn]>* {
    opacity: 0
}

@keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

[data-cue=slideInLeft],[data-cues=slideInLeft]>* {
    opacity: 0
}

@keyframes slideInLeft {
    from {
        opacity: 0;
        transform: translateX(-30px)
    }

    to {
        opacity: 1;
        transform: translateX(0)
    }
}

[data-cue=slideInRight],[data-cues=slideInRight]>* {
    opacity: 0
}

@keyframes slideInRight {
    from {
        opacity: 0;
        transform: translateX(30px)
    }

    to {
        opacity: 1;
        transform: translateX(0)
    }
}

[data-cue=slideInDown],[data-cues=slideInDown]>* {
    opacity: 0
}

@keyframes slideInDown {
    from {
        opacity: 0;
        transform: translateY(-30px)
    }

    to {
        opacity: 1;
        transform: translateY(0)
    }
}

[data-cue=slideInUp],[data-cues=slideInUp]>* {
    opacity: 0
}

@keyframes slideInUp {
    from {
        opacity: 0;
        transform: translateY(30px)
    }

    to {
        opacity: 1;
        transform: translateY(0)
    }
}

[data-cue=zoomIn],[data-cues=zoomIn]>* {
    opacity: 0
}

@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale(.8)
    }

    to {
        opacity: 1;
        transform: scale(1)
    }
}

[data-cue=zoomOut],[data-cues=zoomOut]>* {
    opacity: 0
}

@keyframes zoomOut {
    from {
        opacity: 0;
        transform: scale(1.2)
    }

    to {
        opacity: 1;
        transform: scale(1)
    }
}

[data-cue=rotateIn],[data-cues=rotateIn]>* {
    opacity: 0
}

@keyframes rotateIn {
    from {
        opacity: 0;
        transform: rotateZ(-15deg)
    }

    to {
        opacity: 1;
        transform: rotateZ(0)
    }
}

[data-cue=bounceIn],[data-cues=bounceIn]>* {
    opacity: 0
}

@keyframes bounceIn {
    0% {
        transform: scale3d(.3,.3,.3)
    }

    20% {
        transform: scale3d(1.1,1.1,1.1)
    }

    40% {
        transform: scale3d(.9,.9,.9)
    }

    60% {
        opacity: 1;
        transform: scale3d(1.03,1.03,1.03)
    }

    80% {
        transform: scale3d(.97,.97,.97)
    }

    100% {
        opacity: 1;
        transform: scale3d(1,1,1)
    }
}

[data-cue=bounceInLeft],[data-cues=bounceInLeft]>* {
    opacity: 0
}

@keyframes bounceInLeft {
    60%,75%,90%,from,to {
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        opacity: 0;
        transform: translate3d(-3000px,0,0)
    }

    60% {
        opacity: 1;
        transform: translate3d(25px,0,0)
    }

    75% {
        transform: translate3d(-10px,0,0)
    }

    90% {
        transform: translate3d(5px,0,0)
    }

    to {
        opacity: 1;
        transform: translate3d(0,0,0)
    }
}

[data-cue=bounceInRight],[data-cues=bounceInRight]>* {
    opacity: 0
}

@keyframes bounceInRight {
    60%,75%,90%,from,to {
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    from {
        opacity: 0;
        transform: translate3d(3000px,0,0)
    }

    60% {
        opacity: 1;
        transform: translate3d(-25px,0,0)
    }

    75% {
        transform: translate3d(10px,0,0)
    }

    90% {
        transform: translate3d(-5px,0,0)
    }

    to {
        opacity: 1;
        transform: translate3d(0,0,0)
    }
}

[data-cue=bounceInDown],[data-cues=bounceInDown]>* {
    opacity: 0
}

@keyframes bounceInDown {
    60%,75%,90%,from,to {
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        opacity: 0;
        transform: translate3d(0,-3000px,0)
    }

    60% {
        opacity: 1;
        transform: translate3d(0,25px,0)
    }

    75% {
        transform: translate3d(0,-10px,0)
    }

    90% {
        transform: translate3d(0,5px,0)
    }

    to {
        opacity: 1;
        transform: translate3d(0,0,0)
    }
}

[data-cue=bounceInUp],[data-cues=bounceInUp]>* {
    opacity: 0
}

@keyframes bounceInUp {
    60%,75%,90%,from,to {
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    from {
        opacity: 0;
        transform: translate3d(0,3000px,0)
    }

    60% {
        opacity: 1;
        transform: translate3d(0,-20px,0)
    }

    75% {
        transform: translate3d(0,10px,0)
    }

    90% {
        transform: translate3d(0,-5px,0)
    }

    to {
        opacity: 1;
        transform: translate3d(0,0,0)
    }
}

[data-cue=flipInX],[data-cues=flipInX]>* {
    opacity: 0;
    backface-visibility: visible
}

@keyframes flipInX {
    from {
        transform: perspective(400px) rotate3d(1,0,0,90deg);
        animation-timing-function: ease-in;
        opacity: 0
    }

    40% {
        transform: perspective(400px) rotate3d(1,0,0,-20deg);
        animation-timing-function: ease-in
    }

    60% {
        transform: perspective(400px) rotate3d(1,0,0,10deg);
        opacity: 1
    }

    80% {
        transform: perspective(400px) rotate3d(1,0,0,-5deg)
    }

    to {
        opacity: 1;
        transform: perspective(400px)
    }
}

[data-cue=flipInY],[data-cues=flipInY]>* {
    opacity: 0;
    backface-visibility: visible
}

@keyframes flipInY {
    from {
        transform: perspective(400px) rotate3d(0,1,0,90deg);
        animation-timing-function: ease-in;
        opacity: 0
    }

    40% {
        transform: perspective(400px) rotate3d(0,1,0,-20deg);
        animation-timing-function: ease-in
    }

    60% {
        transform: perspective(400px) rotate3d(0,1,0,10deg);
        opacity: 1
    }

    80% {
        transform: perspective(400px) rotate3d(0,1,0,-5deg)
    }

    to {
        opacity: 1;
        transform: perspective(400px)
    }
}

.tns-outer {
    padding: 0!important
}

.tns-outer [hidden] {
    display: none!important
}

.tns-outer [aria-controls],.tns-outer [data-action] {
    cursor: pointer
}

.tns-slider {
    -webkit-transition: all 0s;
    -moz-transition: all 0s;
    transition: all 0s
}

.tns-slider>.tns-item {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.tns-horizontal.tns-subpixel {
    white-space: nowrap
}

.tns-horizontal.tns-subpixel>.tns-item {
    display: inline-block;
    vertical-align: top;
    white-space: normal
}

.tns-horizontal.tns-no-subpixel:after {
    content: '';
    display: table;
    clear: both
}

.tns-horizontal.tns-no-subpixel>.tns-item {
    float: left
}

.tns-horizontal.tns-carousel.tns-no-subpixel>.tns-item {
    margin-right: -100%
}

.tns-no-calc {
    position: relative;
    left: 0
}

.tns-gallery {
    position: relative;
    left: 0;
    min-height: 1px
}

.tns-gallery>.tns-item {
    position: absolute;
    left: -100%;
    -webkit-transition: transform 0s,opacity 0s;
    -moz-transition: transform 0s,opacity 0s;
    transition: transform 0s,opacity 0s
}

.tns-gallery>.tns-slide-active {
    position: relative;
    left: auto!important
}

.tns-gallery>.tns-moving {
    -webkit-transition: all .25s;
    -moz-transition: all .25s;
    transition: all .25s
}

.tns-autowidth {
    display: inline-block
}

.tns-lazy-img {
    -webkit-transition: opacity .6s;
    -moz-transition: opacity .6s;
    transition: opacity .6s;
    opacity: .6
}

.tns-lazy-img.tns-complete {
    opacity: 1
}

.tns-ah {
    -webkit-transition: height 0s;
    -moz-transition: height 0s;
    transition: height 0s
}

.tns-ovh {
    overflow: hidden
}

.tns-visually-hidden {
    position: absolute;
    left: -10000em
}

.tns-transparent {
    opacity: 0;
    visibility: hidden
}

.tns-fadeIn {
    opacity: 1;
    z-index: 0
}

.tns-fadeOut,.tns-normal {
    opacity: 0;
    z-index: -1
}

.tns-vpfix {
    white-space: nowrap
}

.tns-vpfix>div,.tns-vpfix>li {
    display: inline-block
}

.tns-t-subp2 {
    margin: 0 auto;
    width: 310px;
    position: relative;
    height: 10px;
    overflow: hidden
}

.tns-t-ct {
    width: 2333.3333333%;
    width: -webkit-calc(100% * 70 / 3);
    width: -moz-calc(100% * 70 / 3);
    width: calc(100% * 70 / 3);
    position: absolute;
    right: 0
}

.tns-t-ct:after {
    content: '';
    display: table;
    clear: both
}

.tns-t-ct>div {
    width: 1.4285714%;
    width: -webkit-calc(100% / 70);
    width: -moz-calc(100% / 70);
    width: calc(100% / 70);
    height: 10px;
    float: left
}

.tns-outer {
    position: relative
}

.tns-outer .tns-controls [data-controls=next],.tns-outer .tns-controls [data-controls=prev] {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    width: 2.6rem;
    height: 2.6rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(var(--bs-black-rgb),.85);
    color: var(--bs-white);
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-border-radius);
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

.tns-outer .tns-controls [data-controls=prev] {
    left: 1.25rem
}

.tns-outer .tns-controls [data-controls=next] {
    right: 1.25rem
}

.tns-outer .tns-controls [data-controls=next]:hover,.tns-outer .tns-controls [data-controls=prev]:hover {
    background-color: rgba(var(--bs-white-rgb),.85);
    color: var(--bs-dark)
}

.tns-outer .tns-controls [data-controls=next]:disabled,.tns-outer .tns-controls [data-controls=prev]:disabled {
    background-color: rgba(var(--bs-white-rgb),.25);
    color: rgba(var(--bs-dark-rgb),.55);
    cursor: not-allowed
}

.tns-outer .tns-nav {
    text-align: center
}

.tns-outer .tns-nav [data-nav] {
    width: 24px;
    height: 6px;
    margin: 0 3px;
    display: inline-block;
    background-color: rgba(var(--bs-dark-rgb),.15);
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

[data-bs-theme=dark] .tns-outer .tns-nav [data-nav] {
    background-color: rgba(var(--bs-white-rgb),.15)
}

.tns-outer .tns-nav [data-nav].tns-nav-active,.tns-outer .tns-nav [data-nav]:hover {
    background-color: rgba(var(--bs-primary-rgb),1)
}

:root,[data-bs-theme=light] {
    --bs-blue: #3f78e0;
    --bs-indigo: #605dba;
    --bs-purple: #747ed1;
    --bs-pink: #e668b3;
    --bs-red: #e35d5d;
    --bs-orange: #e58767;
    --bs-yellow: #eeb461;
    --bs-green: #5ab77b;
    --bs-teal: #4fc0c0;
    --bs-cyan: #5eb9f0;
    --bs-black: #111;
    --bs-white: #fff;
    --bs-gray: #6c6c6c;
    --bs-gray-dark: #343434;
    --bs-gray-100: #f8f8f8;
    --bs-gray-200: #e9e9e9;
    --bs-gray-300: #dedede;
    --bs-gray-400: #cecece;
    --bs-gray-500: #adadad;
    --bs-gray-600: #6c6c6c;
    --bs-gray-700: #494949;
    --bs-gray-800: #343434;
    --bs-gray-900: #212121;
    --bs-primary: #a7856a;
    --bs-secondary: #6c6c6c;
    --bs-success: #5ab77b;
    --bs-info: #5eb9f0;
    --bs-warning: #eeb461;
    --bs-danger: #e35d5d;
    --bs-light: #f8f8f8;
    --bs-dark: #212121;
    --bs-primary-rgb: 167,133,106;
    --bs-secondary-rgb: 108,108,108;
    --bs-success-rgb: 90,183,123;
    --bs-info-rgb: 94,185,240;
    --bs-warning-rgb: 238,180,97;
    --bs-danger-rgb: 227,93,93;
    --bs-light-rgb: 248,248,248;
    --bs-dark-rgb: 33,33,33;
    --bs-primary-text: #866a55;
    --bs-secondary-text: #6c6c6c;
    --bs-success-text: #489262;
    --bs-info-text: #386f90;
    --bs-warning-text: #8f6c3a;
    --bs-danger-text: #b64a4a;
    --bs-light-text: #6c6c6c;
    --bs-dark-text: #494949;
    --bs-primary-bg-subtle: #ede7e1;
    --bs-secondary-bg-subtle: #f8f8f8;
    --bs-success-bg-subtle: #def1e5;
    --bs-info-bg-subtle: #dff1fc;
    --bs-warning-bg-subtle: #fcf0df;
    --bs-danger-bg-subtle: #f9dfdf;
    --bs-light-bg-subtle: #fcfcfc;
    --bs-dark-bg-subtle: #cecece;
    --bs-primary-border-subtle: #dccec3;
    --bs-secondary-border-subtle: #e9e9e9;
    --bs-success-border-subtle: #bde2ca;
    --bs-info-border-subtle: #bfe3f9;
    --bs-warning-border-subtle: #f8e1c0;
    --bs-danger-border-subtle: #f4bebe;
    --bs-light-border-subtle: #e9e9e9;
    --bs-dark-border-subtle: #adadad;
    --bs-white-rgb: 255,255,255;
    --bs-black-rgb: 17,17,17;
    --bs-body-color-rgb: 33,33,33;
    --bs-body-bg-rgb: 255,255,255;
    --bs-font-sans-serif: "Jost",sans-serif;
    --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1.125rem;
    --bs-body-font-weight: 300;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212121;
    --bs-emphasis-color: #111;
    --bs-emphasis-color-rgb: 17,17,17;
    --bs-secondary-color: rgba(33, 33, 33, 0.75);
    --bs-secondary-color-rgb: 33,33,33;
    --bs-secondary-bg: #e9e9e9;
    --bs-secondary-bg-rgb: 233,233,233;
    --bs-tertiary-color: rgba(33, 33, 33, 0.5);
    --bs-tertiary-color-rgb: 33,33,33;
    --bs-tertiary-bg: #f8f8f8;
    --bs-tertiary-bg-rgb: 248,248,248;
    --bs-body-bg: #fff;
    --bs-body-bg-rgb: 255,255,255;
    --bs-link-color: #a7856a;
    --bs-link-color-rgb: 167,133,106;
    --bs-link-decoration: none;
    --bs-link-hover-color: #866a55;
    --bs-link-hover-color-rgb: 134,106,85;
    --bs-code-color: #e668b3;
    --bs-highlight-bg: #fcf0df;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #dedede;
    --bs-border-color-translucent: rgba(17, 17, 17, 0.175);
    --bs-border-radius: 0.135rem;
    --bs-border-radius-sm: 0.115rem;
    --bs-border-radius-lg: 0.15rem;
    --bs-border-radius-xl: 0.175rem;
    --bs-border-radius-2xl: 0.2rem;
    --bs-border-radius-pill: 0.25rem;
    --bs-box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15);
    --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(var(--bs-body-color-rgb), 0.075);
    --bs-box-shadow-lg: 0 1rem 3rem rgba(var(--bs-body-color-rgb), 0.175);
    --bs-box-shadow-inset: inset 0 1px 2px rgba(var(--bs-body-color-rgb), 0.075);
    --bs-emphasis-color: #111;
    --bs-form-control-bg: var(--bs-body-bg);
    --bs-form-control-disabled-bg: var(--bs-secondary-bg);
    --bs-highlight-bg: #fcf0df;
    --bs-breakpoint-xs: 0;
    --bs-breakpoint-sm: 576px;
    --bs-breakpoint-md: 768px;
    --bs-breakpoint-lg: 992px;
    --bs-breakpoint-xl: 1260px;
    --bs-breakpoint-xxl: 1400px
}

[data-bs-theme=dark] {
    --bs-body-color: #dedede;
    --bs-body-color-rgb: 222,222,222;
    --bs-body-bg: #212121;
    --bs-body-bg-rgb: 33,33,33;
    --bs-emphasis-color: #f8f8f8;
    --bs-emphasis-color-rgb: 248,248,248;
    --bs-secondary-color: rgba(222, 222, 222, 0.75);
    --bs-secondary-color-rgb: 222,222,222;
    --bs-secondary-bg: #343434;
    --bs-secondary-bg-rgb: 52,52,52;
    --bs-tertiary-color: rgba(222, 222, 222, 0.5);
    --bs-tertiary-color-rgb: 222,222,222;
    --bs-tertiary-bg: #2b2b2b;
    --bs-tertiary-bg-rgb: 43,43,43;
    --bs-emphasis-color: #fff;
    --bs-primary-text: #8caeec;
    --bs-secondary-text: #dedede;
    --bs-success-text: #9cd4b0;
    --bs-info-text: #9ed5f6;
    --bs-warning-text: #f5d2a0;
    --bs-danger-text: #ee9e9e;
    --bs-light-text: #f8f8f8;
    --bs-dark-text: #dedede;
    --bs-primary-bg-subtle: #0d182d;
    --bs-secondary-bg-subtle: #212121;
    --bs-success-bg-subtle: #122519;
    --bs-info-bg-subtle: #132530;
    --bs-warning-bg-subtle: #302413;
    --bs-danger-bg-subtle: #2d1313;
    --bs-light-bg-subtle: #343434;
    --bs-dark-bg-subtle: #232323;
    --bs-primary-border-subtle: #264886;
    --bs-secondary-border-subtle: #494949;
    --bs-success-border-subtle: #366e4a;
    --bs-info-border-subtle: #264a60;
    --bs-warning-border-subtle: #5f4827;
    --bs-danger-border-subtle: #883838;
    --bs-light-border-subtle: #494949;
    --bs-dark-border-subtle: #343434;
    --bs-heading-color: #fff;
    --bs-link-color: #a7856a;
    --bs-link-hover-color: #dccec3;
    --bs-link-color-rgb: 167,133,106;
    --bs-link-hover-color-rgb: 220,206,195;
    --bs-code-color: #f0a4d1;
    --bs-border-color: #494949;
    --bs-border-color-translucent: rgba(255, 255, 255, 0.15)
}

:root,[data-bs-theme=light] {
    --bs-primary-50: #f4f0ec;
    --bs-primary-100: #ede7e1;
    --bs-primary-150: #e5dad2;
    --bs-primary-200: #dccec3;
    --bs-primary-250: #d3c2b5;
    --bs-primary-300: #cab6a6;
    --bs-primary-350: #c1aa97;
    --bs-primary-400: #b99d88;
    --bs-primary-450: #b09179;
    --bs-primary-500: #a7856a;
    --bs-primary-550: #96785f;
    --bs-primary-600: #866a55;
    --bs-primary-650: #755d4a;
    --bs-primary-700: #645040;
    --bs-primary-750: #544335;
    --bs-primary-800: #43352a;
    --bs-primary-850: #43352a;
    --bs-primary-900: #211b15;
    --bs-primary-950: #15110d;
    --bs--emphasis-color-rgb: #111
}

*,::after,::before {
    box-sizing: border-box
}

@media (prefers-reduced-motion:no-preference) {
    :root {
        scroll-behavior: smooth
    }
}

body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(17,17,17,0)
}

hr {
    margin: 1.5rem 0;
    color: #adadad;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: .25
}

.h2,.h3,.h4,.h5,.h6,h2,h3,h4,h5,h6 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 200;
    line-height: 1.35;
    color: var(--bs-heading-color,inherit)
}
.h1,h1{
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 45px !important;
    line-height: 1.35;
    color: var(--bs-heading-color,inherit)
}

.h1,h1 {
    font-size: calc(1.378125rem + 1.5375vw)
}

@media (min-width: 1200px) {
    .h1,h1 {
        font-size:3.53125rem
    }
}

.h2,h2 {
    font-size: calc(1.333125rem + .9975vw)
}

@media (min-width: 1200px) {
    .h2,h2 {
        font-size:2.08125rem
    }
}







@media (min-width: 1200px) {
    .h4,h4 {
        font-size:1.35rem
    }
}

.h5,h5 {
    font-size: 1.18125rem
}

.h6,h6 {
    font-size:14px;
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

ol,ul {
    padding-left: 2rem
}

dl,ol,ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,ol ul,ul ol,ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 600
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b,strong {
    font-weight: 700
}

.small,small {
    font-size: .875em
}

.mark,mark {
    padding: .1875em;
    background-color: var(--bs-highlight-bg)
}

sub,sup {
    position: relative;
    font-size: .75em;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
  
    text-decoration: none
}

a:hover {
    --bs-link-color-rgb: var(--bs-link-hover-color-rgb)
}

a:not([href]):not([class]),a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none
}

code,kbd,pre,samp {
    font-family: var(--bs-font-monospace);
    font-size: 1em
}

pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: .875rem
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

code {
    font-size: .875rem;
    color: var(--bs-code-color);
    word-wrap: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .1875rem .375rem;
    font-size: .875rem;
    color: var(--bs-body-bg);
    background-color: var(--bs-body-color);
    border-radius: .115rem
}

kbd kbd {
    padding: 0;
    font-size: 1em
}

figure {
    margin: 0 0 1rem
}

img,svg {
    vertical-align: middle
}

table {
    caption-side: bottom;
    /* border-collapse: collapse */
}

caption {
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: var(--bs-secondary-color);
    text-align: left
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent
}

tbody,td,tfoot,th,thead,tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0
}

label {
    display: inline-block
}

button {
    border-radius: 0
}

button:focus:not(:focus-visible) {
    outline: 0
}

button,input,optgroup,select,textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,select {
    text-transform: none
}

[role=button] {
    cursor: pointer
}

select {
    word-wrap: normal
}

select:disabled {
    opacity: 1
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
    display: none!important
}

[type=button],[type=reset],[type=submit],button {
    -webkit-appearance: button
}

[type=button]:not(:disabled),[type=reset]:not(:disabled),[type=submit]:not(:disabled),button:not(:disabled) {
    cursor: pointer
}

::-moz-focus-inner {
    padding: 0;
    border-style: none
}

textarea {
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: calc(1.275rem + .3vw);
    line-height: inherit
}

@media (min-width: 1200px) {
    legend {
        font-size:1.5rem
    }
}

legend+* {
    clear: left
}

::-webkit-datetime-edit-day-field,::-webkit-datetime-edit-fields-wrapper,::-webkit-datetime-edit-hour-field,::-webkit-datetime-edit-minute,::-webkit-datetime-edit-month-field,::-webkit-datetime-edit-text,::-webkit-datetime-edit-year-field {
    padding: 0
}

::-webkit-inner-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: textfield
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
    padding: 0
}

::file-selector-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

iframe {
    border: 0
}

summary {
    display: list-item;
    cursor: pointer
}

progress {
    vertical-align: baseline
}

[hidden] {
    display: none!important
}

b,strong {
    font-weight: 500
}

a:hover {
    transition: all .35s ease-in-out
}

/* .h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
    font-family: Jost,sans-serif
} */

.mark,mark {
    font-family: var(--bs-font-monospace);
    font-size: .875rem;
    font-weight: var(--bs-body-font-weight);
    background-color: rgba(var(--bs-dark-rgb),.065);
    border-radius: var(--bs-border-radius)
}

::-moz-selection {
    background: var(--bs-primary);
    color: var(--bs-white)
}

::selection {
    background: var(--bs-primary);
    color: var(--bs-white)
}

::-moz-selection {
    background: var(--bs-primary);
    color: var(--bs-white)
}

::-webkit-selection {
    background: var(--bs-primary);
    color: var(--bs-white)
}

::scrollbar {
    width: 12px
}

::scrollbar-track {
    background-color: var(--bs-gray-100)
}

::scrollbar-thumb {
    border: 1px solid transparent;
    background-clip: content-box;
    background-color: var(--bs-gray-500)
}

::-moz-scrollbar {
    width: 12px
}

::-moz-scrollbar-track {
    background-color: var(--bs-gray-100)
}

::-moz-scrollbar-thumb {
    border: 1px solid transparent;
    background-clip: content-box;
    background-color: var(--bs-gray-500)
}

::-webkit-scrollbar {
    width: 12px
}

::-webkit-scrollbar-track {
    background-color: var(--bs-gray-100)
}

::-webkit-scrollbar-thumb {
    border: 1px solid transparent;
    background-clip: content-box;
    background-color: var(--bs-gray-500)
}

.lead {
    font-size: calc(1.265625rem + .1875vw);
    font-weight: 300
}

@media (min-width: 1200px) {
    .lead {
        font-size:1.40625rem
    }
}

.display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 300;
    line-height: 1.35
}

@media (min-width: 1200px) {
    .display-1 {
        font-size:5rem
    }
}

.display-2 {
    font-size: calc(1.575rem + 3.9vw);
    font-weight: 300;
    line-height: 1.35
}

@media (min-width: 1200px) {
    .display-2 {
        font-size:4.5rem
    }
}

.display-3 {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 300;
    line-height: 1.35
}

@media (min-width: 1200px) {
    .display-3 {
        font-size:4rem
    }
}

.display-4 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 300;
    line-height: 1.35
}

@media (min-width: 1200px) {
    .display-4 {
        font-size:3.5rem
    }
}

.display-5 {
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 300;
    line-height: 1.35
}

@media (min-width: 1200px) {
    .display-5 {
        font-size:3rem
    }
}

.display-6 {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 300;
    line-height: 1.35
}

@media (min-width: 1200px) {
    .display-6 {
        font-size:2.5rem
    }
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: .875em;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.125rem
}

.blockquote>:last-child {
    margin-bottom: 0
}

.blockquote-footer {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: .875em;
    color: #6c6c6c
}

.blockquote-footer::before {
    content: "\2014\a0"
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: var(--bs-body-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    max-width: 100%;
    height: auto
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: .875em;
    color: var(--bs-secondary-color)
}

figure {
    --bs-figure-image-transition: all 0.35s ease-in-out;
    --bs-figure-image-bg: var(--bs-black-rgb);
    --bs-figure-image-bg-opacity: 0.35;
    --bs-figure-image-bg-static-opacity: 0.55;
    --bs-figure-image-scale: 1.055
}

figure.image-hover-overlay-static {
    width: 100%;
    max-width: 100%;
    position: relative;
    overflow: hidden
}

figure.image-hover-overlay-static:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transition: var(--bs-figure-image-transition);
    background: rgba(var(--bs-figure-image-bg),var(--bs-figure-image-bg-static-opacity))
}

figure.image-hover-overlay {
    width: 100%;
    max-width: 100%;
    position: relative;
    overflow: hidden
}

figure.image-hover-overlay:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transition: var(--bs-figure-image-transition);
    background: rgba(var(--bs-figure-image-bg),var(--bs-figure-image-bg-opacity));
    opacity: 0
}

figure.image-hover-overlay:hover::after {
    opacity: 1
}

figure.image-hover-scale {
    width: 100%;
    max-width: 100%;
    position: relative;
    overflow: hidden
}

figure.image-hover-scale img {
    transition: var(--bs-figure-image-transition)
}

figure.image-hover-scale:hover img {
    transform: scale(var(--bs-figure-image-scale))
}

figure .image-hover-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    opacity: 0;
    transition: var(--bs-figure-image-transition);
    z-index: 2;
    color: #fff
}

figure:hover .image-hover-icon {
    opacity: 1
}






.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x))
}

.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y)
}

.col {
    flex: 1 0 0%
}

.row-cols-auto>* {
    flex: 0 0 auto;
    width: auto
}

.row-cols-1>* {
    flex: 0 0 auto;
    width: 100%
}

.row-cols-2>* {
    flex: 0 0 auto;
    width: 50%
}

.row-cols-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
}

.row-cols-4>* {
    flex: 0 0 auto;
    width: 25%
}

.row-cols-5>* {
    flex: 0 0 auto;
    width: 20%
}

.row-cols-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
}

.col-auto {
    flex: 0 0 auto;
    width: auto
}

.col-1 {
    flex: 0 0 auto;
    width: 8.33333333%
}

.col-2 {
    flex: 0 0 auto;
    width: 16.66666667%
}

.col-3 {
    flex: 0 0 auto;
    width: 25%
}

.col-4 {
    flex: 0 0 auto;
    width: 33.33333333%
}

.col-5 {
    flex: 0 0 auto;
    width: 41.66666667%
}

.col-6 {
    flex: 0 0 auto;
    width: 50%
}

.col-7 {
    flex: 0 0 auto;
    width: 58.33333333%
}

.col-8 {
    flex: 0 0 auto;
    width: 66.66666667%
}

.col-9 {
    flex: 0 0 auto;
    width: 75%
}

.col-10 {
    flex: 0 0 auto;
    width: 83.33333333%
}

.col-11 {
    flex: 0 0 auto;
    width: 91.66666667%
}

.col-12 {
    flex: 0 0 auto;
    width: 100%
}

.offset-1 {
    margin-left: 8.33333333%
}

.offset-2 {
    margin-left: 16.66666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333333%
}

.offset-5 {
    margin-left: 41.66666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333333%
}

.offset-8 {
    margin-left: 66.66666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333333%
}

.offset-11 {
    margin-left: 91.66666667%
}

.g-0,.gx-0 {
    --bs-gutter-x: 0
}

.g-0,.gy-0 {
    --bs-gutter-y: 0
}

.g-1,.gx-1 {
    --bs-gutter-x: 0.25rem
}

.g-1,.gy-1 {
    --bs-gutter-y: 0.25rem
}

.g-2,.gx-2 {
    --bs-gutter-x: 0.5rem
}

.g-2,.gy-2 {
    --bs-gutter-y: 0.5rem
}

.g-3,.gx-3 {
    --bs-gutter-x: 0.75rem
}

.g-3,.gy-3 {
    --bs-gutter-y: 0.75rem
}

.g-4,.gx-4 {
    --bs-gutter-x: 1rem
}

.g-4,.gy-4 {
    --bs-gutter-y: 1rem
}

.g-5,.gx-5 {
    --bs-gutter-x: 1.25rem
}

.g-5,.gy-5 {
    --bs-gutter-y: 1.25rem
}

.g-6,.gx-6 {
    --bs-gutter-x: 1.5rem
}

.g-6,.gy-6 {
    --bs-gutter-y: 1.5rem
}

.g-7,.gx-7 {
    --bs-gutter-x: 1.75rem
}

.g-7,.gy-7 {
    --bs-gutter-y: 1.75rem
}

.g-8,.gx-8 {
    --bs-gutter-x: 2rem
}

.g-8,.gy-8 {
    --bs-gutter-y: 2rem
}

.g-9,.gx-9 {
    --bs-gutter-x: 2.5rem
}

.g-9,.gy-9 {
    --bs-gutter-y: 2.5rem
}

.g-10,.gx-10 {
    --bs-gutter-x: 3rem
}

.g-10,.gy-10 {
    --bs-gutter-y: 3rem
}

.g-11,.gx-11 {
    --bs-gutter-x: 3.5rem
}

.g-11,.gy-11 {
    --bs-gutter-y: 3.5rem
}

.g-12,.gx-12 {
    --bs-gutter-x: 4rem
}

.g-12,.gy-12 {
    --bs-gutter-y: 4rem
}

.g-13,.gx-13 {
    --bs-gutter-x: 4.5rem
}

.g-13,.gy-13 {
    --bs-gutter-y: 4.5rem
}

.g-14,.gx-14 {
    --bs-gutter-x: 5rem
}

.g-14,.gy-14 {
    --bs-gutter-y: 5rem
}

.g-15,.gx-15 {
    --bs-gutter-x: 5.5rem
}

.g-15,.gy-15 {
    --bs-gutter-y: 5.5rem
}

.g-16,.gx-16 {
    --bs-gutter-x: 6rem
}

.g-16,.gy-16 {
    --bs-gutter-y: 6rem
}

.g-17,.gx-17 {
    --bs-gutter-x: 6.5rem
}

.g-17,.gy-17 {
    --bs-gutter-y: 6.5rem
}

.g-18,.gx-18 {
    --bs-gutter-x: 7rem
}

.g-18,.gy-18 {
    --bs-gutter-y: 7rem
}

.g-19,.gx-19 {
    --bs-gutter-x: 7.5rem
}

.g-19,.gy-19 {
    --bs-gutter-y: 7.5rem
}

.g-20,.gx-20 {
    --bs-gutter-x: 8rem
}

.g-20,.gy-20 {
    --bs-gutter-y: 8rem
}

.g-21,.gx-21 {
    --bs-gutter-x: 8.5rem
}

.g-21,.gy-21 {
    --bs-gutter-y: 8.5rem
}

.g-22,.gx-22 {
    --bs-gutter-x: 9rem
}

.g-22,.gy-22 {
    --bs-gutter-y: 9rem
}

.g-23,.gx-23 {
    --bs-gutter-x: 9.5rem
}

.g-23,.gy-23 {
    --bs-gutter-y: 9.5rem
}

.g-24,.gx-24 {
    --bs-gutter-x: 10rem
}

.g-24,.gy-24 {
    --bs-gutter-y: 10rem
}

.g-25,.gx-25 {
    --bs-gutter-x: 10.5rem
}

.g-25,.gy-25 {
    --bs-gutter-y: 10.5rem
}

.g-26,.gx-26 {
    --bs-gutter-x: 11rem
}

.g-26,.gy-26 {
    --bs-gutter-y: 11rem
}

.g-27,.gx-27 {
    --bs-gutter-x: 12rem
}

.g-27,.gy-27 {
    --bs-gutter-y: 12rem
}

.g-28,.gx-28 {
    --bs-gutter-x: 13rem
}

.g-28,.gy-28 {
    --bs-gutter-y: 13rem
}

.g-29,.gx-29 {
    --bs-gutter-x: 14rem
}

.g-29,.gy-29 {
    --bs-gutter-y: 14rem
}

.g-30,.gx-30 {
    --bs-gutter-x: 15rem
}

.g-30,.gy-30 {
    --bs-gutter-y: 15rem
}

.g-31,.gx-31 {
    --bs-gutter-x: 16rem
}

.g-31,.gy-31 {
    --bs-gutter-y: 16rem
}

.g-32,.gx-32 {
    --bs-gutter-x: 17rem
}

.g-32,.gy-32 {
    --bs-gutter-y: 17rem
}

.g-33,.gx-33 {
    --bs-gutter-x: 18rem
}

.g-33,.gy-33 {
    --bs-gutter-y: 18rem
}

.g-34,.gx-34 {
    --bs-gutter-x: 19rem
}

.g-34,.gy-34 {
    --bs-gutter-y: 19rem
}

.g-35,.gx-35 {
    --bs-gutter-x: 20rem
}

.g-35,.gy-35 {
    --bs-gutter-y: 20rem
}

@media (min-width: 576px) {
    .col-sm {
        flex:1 0 0%
    }

    .row-cols-sm-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-sm-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-sm-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-sm-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-sm-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-sm-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-sm-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-sm-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-sm-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-sm-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-sm-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-sm-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-sm-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-sm-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-sm-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-sm-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333333%
    }

    .offset-sm-2 {
        margin-left: 16.66666667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333333%
    }

    .offset-sm-5 {
        margin-left: 41.66666667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333333%
    }

    .offset-sm-8 {
        margin-left: 66.66666667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333333%
    }

    .offset-sm-11 {
        margin-left: 91.66666667%
    }

    .g-sm-0,.gx-sm-0 {
        --bs-gutter-x: 0
    }

    .g-sm-0,.gy-sm-0 {
        --bs-gutter-y: 0
    }

    .g-sm-1,.gx-sm-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-sm-1,.gy-sm-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-sm-2,.gx-sm-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-sm-2,.gy-sm-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-sm-3,.gx-sm-3 {
        --bs-gutter-x: 0.75rem
    }

    .g-sm-3,.gy-sm-3 {
        --bs-gutter-y: 0.75rem
    }

    .g-sm-4,.gx-sm-4 {
        --bs-gutter-x: 1rem
    }

    .g-sm-4,.gy-sm-4 {
        --bs-gutter-y: 1rem
    }

    .g-sm-5,.gx-sm-5 {
        --bs-gutter-x: 1.25rem
    }

    .g-sm-5,.gy-sm-5 {
        --bs-gutter-y: 1.25rem
    }

    .g-sm-6,.gx-sm-6 {
        --bs-gutter-x: 1.5rem
    }

    .g-sm-6,.gy-sm-6 {
        --bs-gutter-y: 1.5rem
    }

    .g-sm-7,.gx-sm-7 {
        --bs-gutter-x: 1.75rem
    }

    .g-sm-7,.gy-sm-7 {
        --bs-gutter-y: 1.75rem
    }

    .g-sm-8,.gx-sm-8 {
        --bs-gutter-x: 2rem
    }

    .g-sm-8,.gy-sm-8 {
        --bs-gutter-y: 2rem
    }

    .g-sm-9,.gx-sm-9 {
        --bs-gutter-x: 2.5rem
    }

    .g-sm-9,.gy-sm-9 {
        --bs-gutter-y: 2.5rem
    }

    .g-sm-10,.gx-sm-10 {
        --bs-gutter-x: 3rem
    }

    .g-sm-10,.gy-sm-10 {
        --bs-gutter-y: 3rem
    }

    .g-sm-11,.gx-sm-11 {
        --bs-gutter-x: 3.5rem
    }

    .g-sm-11,.gy-sm-11 {
        --bs-gutter-y: 3.5rem
    }

    .g-sm-12,.gx-sm-12 {
        --bs-gutter-x: 4rem
    }

    .g-sm-12,.gy-sm-12 {
        --bs-gutter-y: 4rem
    }

    .g-sm-13,.gx-sm-13 {
        --bs-gutter-x: 4.5rem
    }

    .g-sm-13,.gy-sm-13 {
        --bs-gutter-y: 4.5rem
    }

    .g-sm-14,.gx-sm-14 {
        --bs-gutter-x: 5rem
    }

    .g-sm-14,.gy-sm-14 {
        --bs-gutter-y: 5rem
    }

    .g-sm-15,.gx-sm-15 {
        --bs-gutter-x: 5.5rem
    }

    .g-sm-15,.gy-sm-15 {
        --bs-gutter-y: 5.5rem
    }

    .g-sm-16,.gx-sm-16 {
        --bs-gutter-x: 6rem
    }

    .g-sm-16,.gy-sm-16 {
        --bs-gutter-y: 6rem
    }

    .g-sm-17,.gx-sm-17 {
        --bs-gutter-x: 6.5rem
    }

    .g-sm-17,.gy-sm-17 {
        --bs-gutter-y: 6.5rem
    }

    .g-sm-18,.gx-sm-18 {
        --bs-gutter-x: 7rem
    }

    .g-sm-18,.gy-sm-18 {
        --bs-gutter-y: 7rem
    }

    .g-sm-19,.gx-sm-19 {
        --bs-gutter-x: 7.5rem
    }

    .g-sm-19,.gy-sm-19 {
        --bs-gutter-y: 7.5rem
    }

    .g-sm-20,.gx-sm-20 {
        --bs-gutter-x: 8rem
    }

    .g-sm-20,.gy-sm-20 {
        --bs-gutter-y: 8rem
    }

    .g-sm-21,.gx-sm-21 {
        --bs-gutter-x: 8.5rem
    }

    .g-sm-21,.gy-sm-21 {
        --bs-gutter-y: 8.5rem
    }

    .g-sm-22,.gx-sm-22 {
        --bs-gutter-x: 9rem
    }

    .g-sm-22,.gy-sm-22 {
        --bs-gutter-y: 9rem
    }

    .g-sm-23,.gx-sm-23 {
        --bs-gutter-x: 9.5rem
    }

    .g-sm-23,.gy-sm-23 {
        --bs-gutter-y: 9.5rem
    }

    .g-sm-24,.gx-sm-24 {
        --bs-gutter-x: 10rem
    }

    .g-sm-24,.gy-sm-24 {
        --bs-gutter-y: 10rem
    }

    .g-sm-25,.gx-sm-25 {
        --bs-gutter-x: 10.5rem
    }

    .g-sm-25,.gy-sm-25 {
        --bs-gutter-y: 10.5rem
    }

    .g-sm-26,.gx-sm-26 {
        --bs-gutter-x: 11rem
    }

    .g-sm-26,.gy-sm-26 {
        --bs-gutter-y: 11rem
    }

    .g-sm-27,.gx-sm-27 {
        --bs-gutter-x: 12rem
    }

    .g-sm-27,.gy-sm-27 {
        --bs-gutter-y: 12rem
    }

    .g-sm-28,.gx-sm-28 {
        --bs-gutter-x: 13rem
    }

    .g-sm-28,.gy-sm-28 {
        --bs-gutter-y: 13rem
    }

    .g-sm-29,.gx-sm-29 {
        --bs-gutter-x: 14rem
    }

    .g-sm-29,.gy-sm-29 {
        --bs-gutter-y: 14rem
    }

    .g-sm-30,.gx-sm-30 {
        --bs-gutter-x: 15rem
    }

    .g-sm-30,.gy-sm-30 {
        --bs-gutter-y: 15rem
    }

    .g-sm-31,.gx-sm-31 {
        --bs-gutter-x: 16rem
    }

    .g-sm-31,.gy-sm-31 {
        --bs-gutter-y: 16rem
    }

    .g-sm-32,.gx-sm-32 {
        --bs-gutter-x: 17rem
    }

    .g-sm-32,.gy-sm-32 {
        --bs-gutter-y: 17rem
    }

    .g-sm-33,.gx-sm-33 {
        --bs-gutter-x: 18rem
    }

    .g-sm-33,.gy-sm-33 {
        --bs-gutter-y: 18rem
    }

    .g-sm-34,.gx-sm-34 {
        --bs-gutter-x: 19rem
    }

    .g-sm-34,.gy-sm-34 {
        --bs-gutter-y: 19rem
    }

    .g-sm-35,.gx-sm-35 {
        --bs-gutter-x: 20rem
    }

    .g-sm-35,.gy-sm-35 {
        --bs-gutter-y: 20rem
    }
}

@media (min-width: 768px) {
    .col-md {
        flex:1 0 0%
    }

    .row-cols-md-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-md-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-md-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-md-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-md-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-md-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-md-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-md-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-md-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-md-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-md-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-md-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-md-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-md-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-md-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-md-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-md-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-md-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333333%
    }

    .offset-md-2 {
        margin-left: 16.66666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333333%
    }

    .offset-md-5 {
        margin-left: 41.66666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333333%
    }

    .offset-md-8 {
        margin-left: 66.66666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333333%
    }

    .offset-md-11 {
        margin-left: 91.66666667%
    }

    .g-md-0,.gx-md-0 {
        --bs-gutter-x: 0
    }

    .g-md-0,.gy-md-0 {
        --bs-gutter-y: 0
    }

    .g-md-1,.gx-md-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-md-1,.gy-md-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-md-2,.gx-md-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-md-2,.gy-md-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-md-3,.gx-md-3 {
        --bs-gutter-x: 0.75rem
    }

    .g-md-3,.gy-md-3 {
        --bs-gutter-y: 0.75rem
    }

    .g-md-4,.gx-md-4 {
        --bs-gutter-x: 1rem
    }

    .g-md-4,.gy-md-4 {
        --bs-gutter-y: 1rem
    }

    .g-md-5,.gx-md-5 {
        --bs-gutter-x: 1.25rem
    }

    .g-md-5,.gy-md-5 {
        --bs-gutter-y: 1.25rem
    }

    .g-md-6,.gx-md-6 {
        --bs-gutter-x: 1.5rem
    }

    .g-md-6,.gy-md-6 {
        --bs-gutter-y: 1.5rem
    }

    .g-md-7,.gx-md-7 {
        --bs-gutter-x: 1.75rem
    }

    .g-md-7,.gy-md-7 {
        --bs-gutter-y: 1.75rem
    }

    .g-md-8,.gx-md-8 {
        --bs-gutter-x: 2rem
    }

    .g-md-8,.gy-md-8 {
        --bs-gutter-y: 2rem
    }

    .g-md-9,.gx-md-9 {
        --bs-gutter-x: 2.5rem
    }

    .g-md-9,.gy-md-9 {
        --bs-gutter-y: 2.5rem
    }

    .g-md-10,.gx-md-10 {
        --bs-gutter-x: 3rem
    }

    .g-md-10,.gy-md-10 {
        --bs-gutter-y: 3rem
    }

    .g-md-11,.gx-md-11 {
        --bs-gutter-x: 3.5rem
    }

    .g-md-11,.gy-md-11 {
        --bs-gutter-y: 3.5rem
    }

    .g-md-12,.gx-md-12 {
        --bs-gutter-x: 4rem
    }

    .g-md-12,.gy-md-12 {
        --bs-gutter-y: 4rem
    }

    .g-md-13,.gx-md-13 {
        --bs-gutter-x: 4.5rem
    }

    .g-md-13,.gy-md-13 {
        --bs-gutter-y: 4.5rem
    }

    .g-md-14,.gx-md-14 {
        --bs-gutter-x: 5rem
    }

    .g-md-14,.gy-md-14 {
        --bs-gutter-y: 5rem
    }

    .g-md-15,.gx-md-15 {
        --bs-gutter-x: 5.5rem
    }

    .g-md-15,.gy-md-15 {
        --bs-gutter-y: 5.5rem
    }

    .g-md-16,.gx-md-16 {
        --bs-gutter-x: 6rem
    }

    .g-md-16,.gy-md-16 {
        --bs-gutter-y: 6rem
    }

    .g-md-17,.gx-md-17 {
        --bs-gutter-x: 6.5rem
    }

    .g-md-17,.gy-md-17 {
        --bs-gutter-y: 6.5rem
    }

    .g-md-18,.gx-md-18 {
        --bs-gutter-x: 7rem
    }

    .g-md-18,.gy-md-18 {
        --bs-gutter-y: 7rem
    }

    .g-md-19,.gx-md-19 {
        --bs-gutter-x: 7.5rem
    }

    .g-md-19,.gy-md-19 {
        --bs-gutter-y: 7.5rem
    }

    .g-md-20,.gx-md-20 {
        --bs-gutter-x: 8rem
    }

    .g-md-20,.gy-md-20 {
        --bs-gutter-y: 8rem
    }

    .g-md-21,.gx-md-21 {
        --bs-gutter-x: 8.5rem
    }

    .g-md-21,.gy-md-21 {
        --bs-gutter-y: 8.5rem
    }

    .g-md-22,.gx-md-22 {
        --bs-gutter-x: 9rem
    }

    .g-md-22,.gy-md-22 {
        --bs-gutter-y: 9rem
    }

    .g-md-23,.gx-md-23 {
        --bs-gutter-x: 9.5rem
    }

    .g-md-23,.gy-md-23 {
        --bs-gutter-y: 9.5rem
    }

    .g-md-24,.gx-md-24 {
        --bs-gutter-x: 10rem
    }

    .g-md-24,.gy-md-24 {
        --bs-gutter-y: 10rem
    }

    .g-md-25,.gx-md-25 {
        --bs-gutter-x: 10.5rem
    }

    .g-md-25,.gy-md-25 {
        --bs-gutter-y: 10.5rem
    }

    .g-md-26,.gx-md-26 {
        --bs-gutter-x: 11rem
    }

    .g-md-26,.gy-md-26 {
        --bs-gutter-y: 11rem
    }

    .g-md-27,.gx-md-27 {
        --bs-gutter-x: 12rem
    }

    .g-md-27,.gy-md-27 {
        --bs-gutter-y: 12rem
    }

    .g-md-28,.gx-md-28 {
        --bs-gutter-x: 13rem
    }

    .g-md-28,.gy-md-28 {
        --bs-gutter-y: 13rem
    }

    .g-md-29,.gx-md-29 {
        --bs-gutter-x: 14rem
    }

    .g-md-29,.gy-md-29 {
        --bs-gutter-y: 14rem
    }

    .g-md-30,.gx-md-30 {
        --bs-gutter-x: 15rem
    }

    .g-md-30,.gy-md-30 {
        --bs-gutter-y: 15rem
    }

    .g-md-31,.gx-md-31 {
        --bs-gutter-x: 16rem
    }

    .g-md-31,.gy-md-31 {
        --bs-gutter-y: 16rem
    }

    .g-md-32,.gx-md-32 {
        --bs-gutter-x: 17rem
    }

    .g-md-32,.gy-md-32 {
        --bs-gutter-y: 17rem
    }

    .g-md-33,.gx-md-33 {
        --bs-gutter-x: 18rem
    }

    .g-md-33,.gy-md-33 {
        --bs-gutter-y: 18rem
    }

    .g-md-34,.gx-md-34 {
        --bs-gutter-x: 19rem
    }

    .g-md-34,.gy-md-34 {
        --bs-gutter-y: 19rem
    }

    .g-md-35,.gx-md-35 {
        --bs-gutter-x: 20rem
    }

    .g-md-35,.gy-md-35 {
        --bs-gutter-y: 20rem
    }
}

@media (min-width: 992px) {
    .col-lg {
        flex:1 0 0%
    }

    .row-cols-lg-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-lg-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-lg-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-lg-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-lg-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-lg-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-lg-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-lg-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-lg-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-lg-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-lg-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-lg-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-lg-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-lg-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-lg-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-lg-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333333%
    }

    .offset-lg-2 {
        margin-left: 16.66666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333333%
    }

    .offset-lg-5 {
        margin-left: 41.66666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333333%
    }

    .offset-lg-8 {
        margin-left: 66.66666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333333%
    }

    .offset-lg-11 {
        margin-left: 91.66666667%
    }

    .g-lg-0,.gx-lg-0 {
        --bs-gutter-x: 0
    }

    .g-lg-0,.gy-lg-0 {
        --bs-gutter-y: 0
    }

    .g-lg-1,.gx-lg-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-lg-1,.gy-lg-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-lg-2,.gx-lg-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-lg-2,.gy-lg-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-lg-3,.gx-lg-3 {
        --bs-gutter-x: 0.75rem
    }

    .g-lg-3,.gy-lg-3 {
        --bs-gutter-y: 0.75rem
    }

    .g-lg-4,.gx-lg-4 {
        --bs-gutter-x: 1rem
    }

    .g-lg-4,.gy-lg-4 {
        --bs-gutter-y: 1rem
    }

    .g-lg-5,.gx-lg-5 {
        --bs-gutter-x: 1.25rem
    }

    .g-lg-5,.gy-lg-5 {
        --bs-gutter-y: 1.25rem
    }

    .g-lg-6,.gx-lg-6 {
        --bs-gutter-x: 1.5rem
    }

    .g-lg-6,.gy-lg-6 {
        --bs-gutter-y: 1.5rem
    }

    .g-lg-7,.gx-lg-7 {
        --bs-gutter-x: 1.75rem
    }

    .g-lg-7,.gy-lg-7 {
        --bs-gutter-y: 1.75rem
    }

    .g-lg-8,.gx-lg-8 {
        --bs-gutter-x: 2rem
    }

    .g-lg-8,.gy-lg-8 {
        --bs-gutter-y: 2rem
    }

    .g-lg-9,.gx-lg-9 {
        --bs-gutter-x: 2.5rem
    }

    .g-lg-9,.gy-lg-9 {
        --bs-gutter-y: 2.5rem
    }

    .g-lg-10,.gx-lg-10 {
        --bs-gutter-x: 3rem
    }

    .g-lg-10,.gy-lg-10 {
        --bs-gutter-y: 3rem
    }

    .g-lg-11,.gx-lg-11 {
        --bs-gutter-x: 3.5rem
    }

    .g-lg-11,.gy-lg-11 {
        --bs-gutter-y: 3.5rem
    }

    .g-lg-12,.gx-lg-12 {
        --bs-gutter-x: 4rem
    }

    .g-lg-12,.gy-lg-12 {
        --bs-gutter-y: 4rem
    }

    .g-lg-13,.gx-lg-13 {
        --bs-gutter-x: 4.5rem
    }

    .g-lg-13,.gy-lg-13 {
        --bs-gutter-y: 4.5rem
    }

    .g-lg-14,.gx-lg-14 {
        --bs-gutter-x: 5rem
    }

    .g-lg-14,.gy-lg-14 {
        --bs-gutter-y: 5rem
    }

    .g-lg-15,.gx-lg-15 {
        --bs-gutter-x: 5.5rem
    }

    .g-lg-15,.gy-lg-15 {
        --bs-gutter-y: 5.5rem
    }

    .g-lg-16,.gx-lg-16 {
        --bs-gutter-x: 6rem
    }

    .g-lg-16,.gy-lg-16 {
        --bs-gutter-y: 6rem
    }

    .g-lg-17,.gx-lg-17 {
        --bs-gutter-x: 6.5rem
    }

    .g-lg-17,.gy-lg-17 {
        --bs-gutter-y: 6.5rem
    }

    .g-lg-18,.gx-lg-18 {
        --bs-gutter-x: 7rem
    }

    .g-lg-18,.gy-lg-18 {
        --bs-gutter-y: 7rem
    }

    .g-lg-19,.gx-lg-19 {
        --bs-gutter-x: 7.5rem
    }

    .g-lg-19,.gy-lg-19 {
        --bs-gutter-y: 7.5rem
    }

    .g-lg-20,.gx-lg-20 {
        --bs-gutter-x: 8rem
    }

    .g-lg-20,.gy-lg-20 {
        --bs-gutter-y: 8rem
    }

    .g-lg-21,.gx-lg-21 {
        --bs-gutter-x: 8.5rem
    }

    .g-lg-21,.gy-lg-21 {
        --bs-gutter-y: 8.5rem
    }

    .g-lg-22,.gx-lg-22 {
        --bs-gutter-x: 9rem
    }

    .g-lg-22,.gy-lg-22 {
        --bs-gutter-y: 9rem
    }

    .g-lg-23,.gx-lg-23 {
        --bs-gutter-x: 9.5rem
    }

    .g-lg-23,.gy-lg-23 {
        --bs-gutter-y: 9.5rem
    }

    .g-lg-24,.gx-lg-24 {
        --bs-gutter-x: 10rem
    }

    .g-lg-24,.gy-lg-24 {
        --bs-gutter-y: 10rem
    }

    .g-lg-25,.gx-lg-25 {
        --bs-gutter-x: 10.5rem
    }

    .g-lg-25,.gy-lg-25 {
        --bs-gutter-y: 10.5rem
    }

    .g-lg-26,.gx-lg-26 {
        --bs-gutter-x: 11rem
    }

    .g-lg-26,.gy-lg-26 {
        --bs-gutter-y: 11rem
    }

    .g-lg-27,.gx-lg-27 {
        --bs-gutter-x: 12rem
    }

    .g-lg-27,.gy-lg-27 {
        --bs-gutter-y: 12rem
    }

    .g-lg-28,.gx-lg-28 {
        --bs-gutter-x: 13rem
    }

    .g-lg-28,.gy-lg-28 {
        --bs-gutter-y: 13rem
    }

    .g-lg-29,.gx-lg-29 {
        --bs-gutter-x: 14rem
    }

    .g-lg-29,.gy-lg-29 {
        --bs-gutter-y: 14rem
    }

    .g-lg-30,.gx-lg-30 {
        --bs-gutter-x: 15rem
    }

    .g-lg-30,.gy-lg-30 {
        --bs-gutter-y: 15rem
    }

    .g-lg-31,.gx-lg-31 {
        --bs-gutter-x: 16rem
    }

    .g-lg-31,.gy-lg-31 {
        --bs-gutter-y: 16rem
    }

    .g-lg-32,.gx-lg-32 {
        --bs-gutter-x: 17rem
    }

    .g-lg-32,.gy-lg-32 {
        --bs-gutter-y: 17rem
    }

    .g-lg-33,.gx-lg-33 {
        --bs-gutter-x: 18rem
    }

    .g-lg-33,.gy-lg-33 {
        --bs-gutter-y: 18rem
    }

    .g-lg-34,.gx-lg-34 {
        --bs-gutter-x: 19rem
    }

    .g-lg-34,.gy-lg-34 {
        --bs-gutter-y: 19rem
    }

    .g-lg-35,.gx-lg-35 {
        --bs-gutter-x: 20rem
    }

    .g-lg-35,.gy-lg-35 {
        --bs-gutter-y: 20rem
    }
}

@media (min-width: 1260px) {
    .col-xl {
        flex:1 0 0%
    }

    .row-cols-xl-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xl-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xl-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xl-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xl-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xl-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xl-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xl-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333333%
    }

    .offset-xl-2 {
        margin-left: 16.66666667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333333%
    }

    .offset-xl-5 {
        margin-left: 41.66666667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333333%
    }

    .offset-xl-8 {
        margin-left: 66.66666667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333333%
    }

    .offset-xl-11 {
        margin-left: 91.66666667%
    }

    .g-xl-0,.gx-xl-0 {
        --bs-gutter-x: 0
    }

    .g-xl-0,.gy-xl-0 {
        --bs-gutter-y: 0
    }

    .g-xl-1,.gx-xl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xl-1,.gy-xl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xl-2,.gx-xl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xl-2,.gy-xl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xl-3,.gx-xl-3 {
        --bs-gutter-x: 0.75rem
    }

    .g-xl-3,.gy-xl-3 {
        --bs-gutter-y: 0.75rem
    }

    .g-xl-4,.gx-xl-4 {
        --bs-gutter-x: 1rem
    }

    .g-xl-4,.gy-xl-4 {
        --bs-gutter-y: 1rem
    }

    .g-xl-5,.gx-xl-5 {
        --bs-gutter-x: 1.25rem
    }

    .g-xl-5,.gy-xl-5 {
        --bs-gutter-y: 1.25rem
    }

    .g-xl-6,.gx-xl-6 {
        --bs-gutter-x: 1.5rem
    }

    .g-xl-6,.gy-xl-6 {
        --bs-gutter-y: 1.5rem
    }

    .g-xl-7,.gx-xl-7 {
        --bs-gutter-x: 1.75rem
    }

    .g-xl-7,.gy-xl-7 {
        --bs-gutter-y: 1.75rem
    }

    .g-xl-8,.gx-xl-8 {
        --bs-gutter-x: 2rem
    }

    .g-xl-8,.gy-xl-8 {
        --bs-gutter-y: 2rem
    }

    .g-xl-9,.gx-xl-9 {
        --bs-gutter-x: 2.5rem
    }

    .g-xl-9,.gy-xl-9 {
        --bs-gutter-y: 2.5rem
    }

    .g-xl-10,.gx-xl-10 {
        --bs-gutter-x: 3rem
    }

    .g-xl-10,.gy-xl-10 {
        --bs-gutter-y: 3rem
    }

    .g-xl-11,.gx-xl-11 {
        --bs-gutter-x: 3.5rem
    }

    .g-xl-11,.gy-xl-11 {
        --bs-gutter-y: 3.5rem
    }

    .g-xl-12,.gx-xl-12 {
        --bs-gutter-x: 4rem
    }

    .g-xl-12,.gy-xl-12 {
        --bs-gutter-y: 4rem
    }

    .g-xl-13,.gx-xl-13 {
        --bs-gutter-x: 4.5rem
    }

    .g-xl-13,.gy-xl-13 {
        --bs-gutter-y: 4.5rem
    }

    .g-xl-14,.gx-xl-14 {
        --bs-gutter-x: 5rem
    }

    .g-xl-14,.gy-xl-14 {
        --bs-gutter-y: 5rem
    }

    .g-xl-15,.gx-xl-15 {
        --bs-gutter-x: 5.5rem
    }

    .g-xl-15,.gy-xl-15 {
        --bs-gutter-y: 5.5rem
    }

    .g-xl-16,.gx-xl-16 {
        --bs-gutter-x: 6rem
    }

    .g-xl-16,.gy-xl-16 {
        --bs-gutter-y: 6rem
    }

    .g-xl-17,.gx-xl-17 {
        --bs-gutter-x: 6.5rem
    }

    .g-xl-17,.gy-xl-17 {
        --bs-gutter-y: 6.5rem
    }

    .g-xl-18,.gx-xl-18 {
        --bs-gutter-x: 7rem
    }

    .g-xl-18,.gy-xl-18 {
        --bs-gutter-y: 7rem
    }

    .g-xl-19,.gx-xl-19 {
        --bs-gutter-x: 7.5rem
    }

    .g-xl-19,.gy-xl-19 {
        --bs-gutter-y: 7.5rem
    }

    .g-xl-20,.gx-xl-20 {
        --bs-gutter-x: 8rem
    }

    .g-xl-20,.gy-xl-20 {
        --bs-gutter-y: 8rem
    }

    .g-xl-21,.gx-xl-21 {
        --bs-gutter-x: 8.5rem
    }

    .g-xl-21,.gy-xl-21 {
        --bs-gutter-y: 8.5rem
    }

    .g-xl-22,.gx-xl-22 {
        --bs-gutter-x: 9rem
    }

    .g-xl-22,.gy-xl-22 {
        --bs-gutter-y: 9rem
    }

    .g-xl-23,.gx-xl-23 {
        --bs-gutter-x: 9.5rem
    }

    .g-xl-23,.gy-xl-23 {
        --bs-gutter-y: 9.5rem
    }

    .g-xl-24,.gx-xl-24 {
        --bs-gutter-x: 10rem
    }

    .g-xl-24,.gy-xl-24 {
        --bs-gutter-y: 10rem
    }

    .g-xl-25,.gx-xl-25 {
        --bs-gutter-x: 10.5rem
    }

    .g-xl-25,.gy-xl-25 {
        --bs-gutter-y: 10.5rem
    }

    .g-xl-26,.gx-xl-26 {
        --bs-gutter-x: 11rem
    }

    .g-xl-26,.gy-xl-26 {
        --bs-gutter-y: 11rem
    }

    .g-xl-27,.gx-xl-27 {
        --bs-gutter-x: 12rem
    }

    .g-xl-27,.gy-xl-27 {
        --bs-gutter-y: 12rem
    }

    .g-xl-28,.gx-xl-28 {
        --bs-gutter-x: 13rem
    }

    .g-xl-28,.gy-xl-28 {
        --bs-gutter-y: 13rem
    }

    .g-xl-29,.gx-xl-29 {
        --bs-gutter-x: 14rem
    }

    .g-xl-29,.gy-xl-29 {
        --bs-gutter-y: 14rem
    }

    .g-xl-30,.gx-xl-30 {
        --bs-gutter-x: 15rem
    }

    .g-xl-30,.gy-xl-30 {
        --bs-gutter-y: 15rem
    }

    .g-xl-31,.gx-xl-31 {
        --bs-gutter-x: 16rem
    }

    .g-xl-31,.gy-xl-31 {
        --bs-gutter-y: 16rem
    }

    .g-xl-32,.gx-xl-32 {
        --bs-gutter-x: 17rem
    }

    .g-xl-32,.gy-xl-32 {
        --bs-gutter-y: 17rem
    }

    .g-xl-33,.gx-xl-33 {
        --bs-gutter-x: 18rem
    }

    .g-xl-33,.gy-xl-33 {
        --bs-gutter-y: 18rem
    }

    .g-xl-34,.gx-xl-34 {
        --bs-gutter-x: 19rem
    }

    .g-xl-34,.gy-xl-34 {
        --bs-gutter-y: 19rem
    }

    .g-xl-35,.gx-xl-35 {
        --bs-gutter-x: 20rem
    }

    .g-xl-35,.gy-xl-35 {
        --bs-gutter-y: 20rem
    }
}

@media (min-width: 1400px) {
    .col-xxl {
        flex:1 0 0%
    }

    .row-cols-xxl-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xxl-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xxl-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xxl-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xxl-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xxl-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xxl-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xxl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xxl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xxl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xxl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xxl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xxl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xxl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xxl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xxl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xxl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xxl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xxl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xxl-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xxl-0 {
        margin-left: 0
    }

    .offset-xxl-1 {
        margin-left: 8.33333333%
    }

    .offset-xxl-2 {
        margin-left: 16.66666667%
    }

    .offset-xxl-3 {
        margin-left: 25%
    }

    .offset-xxl-4 {
        margin-left: 33.33333333%
    }

    .offset-xxl-5 {
        margin-left: 41.66666667%
    }

    .offset-xxl-6 {
        margin-left: 50%
    }

    .offset-xxl-7 {
        margin-left: 58.33333333%
    }

    .offset-xxl-8 {
        margin-left: 66.66666667%
    }

    .offset-xxl-9 {
        margin-left: 75%
    }

    .offset-xxl-10 {
        margin-left: 83.33333333%
    }

    .offset-xxl-11 {
        margin-left: 91.66666667%
    }

    .g-xxl-0,.gx-xxl-0 {
        --bs-gutter-x: 0
    }

    .g-xxl-0,.gy-xxl-0 {
        --bs-gutter-y: 0
    }

    .g-xxl-1,.gx-xxl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xxl-1,.gy-xxl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xxl-2,.gx-xxl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xxl-2,.gy-xxl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xxl-3,.gx-xxl-3 {
        --bs-gutter-x: 0.75rem
    }

    .g-xxl-3,.gy-xxl-3 {
        --bs-gutter-y: 0.75rem
    }

    .g-xxl-4,.gx-xxl-4 {
        --bs-gutter-x: 1rem
    }

    .g-xxl-4,.gy-xxl-4 {
        --bs-gutter-y: 1rem
    }

    .g-xxl-5,.gx-xxl-5 {
        --bs-gutter-x: 1.25rem
    }

    .g-xxl-5,.gy-xxl-5 {
        --bs-gutter-y: 1.25rem
    }

    .g-xxl-6,.gx-xxl-6 {
        --bs-gutter-x: 1.5rem
    }

    .g-xxl-6,.gy-xxl-6 {
        --bs-gutter-y: 1.5rem
    }

    .g-xxl-7,.gx-xxl-7 {
        --bs-gutter-x: 1.75rem
    }

    .g-xxl-7,.gy-xxl-7 {
        --bs-gutter-y: 1.75rem
    }

    .g-xxl-8,.gx-xxl-8 {
        --bs-gutter-x: 2rem
    }

    .g-xxl-8,.gy-xxl-8 {
        --bs-gutter-y: 2rem
    }

    .g-xxl-9,.gx-xxl-9 {
        --bs-gutter-x: 2.5rem
    }

    .g-xxl-9,.gy-xxl-9 {
        --bs-gutter-y: 2.5rem
    }

    .g-xxl-10,.gx-xxl-10 {
        --bs-gutter-x: 3rem
    }

    .g-xxl-10,.gy-xxl-10 {
        --bs-gutter-y: 3rem
    }

    .g-xxl-11,.gx-xxl-11 {
        --bs-gutter-x: 3.5rem
    }

    .g-xxl-11,.gy-xxl-11 {
        --bs-gutter-y: 3.5rem
    }

    .g-xxl-12,.gx-xxl-12 {
        --bs-gutter-x: 4rem
    }

    .g-xxl-12,.gy-xxl-12 {
        --bs-gutter-y: 4rem
    }

    .g-xxl-13,.gx-xxl-13 {
        --bs-gutter-x: 4.5rem
    }

    .g-xxl-13,.gy-xxl-13 {
        --bs-gutter-y: 4.5rem
    }

    .g-xxl-14,.gx-xxl-14 {
        --bs-gutter-x: 5rem
    }

    .g-xxl-14,.gy-xxl-14 {
        --bs-gutter-y: 5rem
    }

    .g-xxl-15,.gx-xxl-15 {
        --bs-gutter-x: 5.5rem
    }

    .g-xxl-15,.gy-xxl-15 {
        --bs-gutter-y: 5.5rem
    }

    .g-xxl-16,.gx-xxl-16 {
        --bs-gutter-x: 6rem
    }

    .g-xxl-16,.gy-xxl-16 {
        --bs-gutter-y: 6rem
    }

    .g-xxl-17,.gx-xxl-17 {
        --bs-gutter-x: 6.5rem
    }

    .g-xxl-17,.gy-xxl-17 {
        --bs-gutter-y: 6.5rem
    }

    .g-xxl-18,.gx-xxl-18 {
        --bs-gutter-x: 7rem
    }

    .g-xxl-18,.gy-xxl-18 {
        --bs-gutter-y: 7rem
    }

    .g-xxl-19,.gx-xxl-19 {
        --bs-gutter-x: 7.5rem
    }

    .g-xxl-19,.gy-xxl-19 {
        --bs-gutter-y: 7.5rem
    }

    .g-xxl-20,.gx-xxl-20 {
        --bs-gutter-x: 8rem
    }

    .g-xxl-20,.gy-xxl-20 {
        --bs-gutter-y: 8rem
    }

    .g-xxl-21,.gx-xxl-21 {
        --bs-gutter-x: 8.5rem
    }

    .g-xxl-21,.gy-xxl-21 {
        --bs-gutter-y: 8.5rem
    }

    .g-xxl-22,.gx-xxl-22 {
        --bs-gutter-x: 9rem
    }

    .g-xxl-22,.gy-xxl-22 {
        --bs-gutter-y: 9rem
    }

    .g-xxl-23,.gx-xxl-23 {
        --bs-gutter-x: 9.5rem
    }

    .g-xxl-23,.gy-xxl-23 {
        --bs-gutter-y: 9.5rem
    }

    .g-xxl-24,.gx-xxl-24 {
        --bs-gutter-x: 10rem
    }

    .g-xxl-24,.gy-xxl-24 {
        --bs-gutter-y: 10rem
    }

    .g-xxl-25,.gx-xxl-25 {
        --bs-gutter-x: 10.5rem
    }

    .g-xxl-25,.gy-xxl-25 {
        --bs-gutter-y: 10.5rem
    }

    .g-xxl-26,.gx-xxl-26 {
        --bs-gutter-x: 11rem
    }

    .g-xxl-26,.gy-xxl-26 {
        --bs-gutter-y: 11rem
    }

    .g-xxl-27,.gx-xxl-27 {
        --bs-gutter-x: 12rem
    }

    .g-xxl-27,.gy-xxl-27 {
        --bs-gutter-y: 12rem
    }

    .g-xxl-28,.gx-xxl-28 {
        --bs-gutter-x: 13rem
    }

    .g-xxl-28,.gy-xxl-28 {
        --bs-gutter-y: 13rem
    }

    .g-xxl-29,.gx-xxl-29 {
        --bs-gutter-x: 14rem
    }

    .g-xxl-29,.gy-xxl-29 {
        --bs-gutter-y: 14rem
    }

    .g-xxl-30,.gx-xxl-30 {
        --bs-gutter-x: 15rem
    }

    .g-xxl-30,.gy-xxl-30 {
        --bs-gutter-y: 15rem
    }

    .g-xxl-31,.gx-xxl-31 {
        --bs-gutter-x: 16rem
    }

    .g-xxl-31,.gy-xxl-31 {
        --bs-gutter-y: 16rem
    }

    .g-xxl-32,.gx-xxl-32 {
        --bs-gutter-x: 17rem
    }

    .g-xxl-32,.gy-xxl-32 {
        --bs-gutter-y: 17rem
    }

    .g-xxl-33,.gx-xxl-33 {
        --bs-gutter-x: 18rem
    }

    .g-xxl-33,.gy-xxl-33 {
        --bs-gutter-y: 18rem
    }

    .g-xxl-34,.gx-xxl-34 {
        --bs-gutter-x: 19rem
    }

    .g-xxl-34,.gy-xxl-34 {
        --bs-gutter-y: 19rem
    }

    .g-xxl-35,.gx-xxl-35 {
        --bs-gutter-x: 20rem
    }

    .g-xxl-35,.gy-xxl-35 {
        --bs-gutter-y: 20rem
    }
}

.table {
    --bs-table-color: var(--bs-body-color);
    --bs-table-bg: transparent;
    --bs-table-border-color: var(--bs-border-color);
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: var(--bs-body-color);
    --bs-table-striped-bg: rgba(17, 17, 17, 0.05);
    --bs-table-active-color: var(--bs-body-color);
    --bs-table-active-bg: rgba(17, 17, 17, 0.1);
    --bs-table-hover-color: var(--bs-body-color);
    --bs-table-hover-bg: rgba(17, 17, 17, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: var(--bs-table-color);
    vertical-align: top;
    border-color: var(--bs-table-border-color)
}

.table>:not(caption)>*>* {
    padding: 1rem 1.15rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: var(--bs-border-width);
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)
}

.table>tbody {
    vertical-align: inherit
}

.table>thead {
    vertical-align: bottom
}

.table-group-divider {
    border-top: calc(var(--bs-border-width) * 2) solid currentcolor
}

.caption-top {
    caption-side: top
}

.table-sm>:not(caption)>*>* {
    padding: .25rem .25rem
}

.table-bordered>:not(caption)>* {
    border-width: var(--bs-border-width) 0
}

.table-bordered>:not(caption)>*>* {
    border-width: 0 var(--bs-border-width)
}

.table-borderless>:not(caption)>*>* {
    border-bottom-width: 0
}

.table-borderless>:not(:first-child) {
    border-top-width: 0
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color)
}

.table-striped-columns>:not(caption)>tr>:nth-child(even) {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color)
}

.table-active {
    --bs-table-accent-bg: var(--bs-table-active-bg);
    color: var(--bs-table-active-color)
}

.table-hover>tbody>tr:hover>* {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color)
}

.table-primary {
    --bs-table-color: #111;
    --bs-table-bg: #ede7e1;
    --bs-table-border-color: #d7d2cc;
    --bs-table-striped-bg: #e2dcd7;
    --bs-table-striped-color: #111;
    --bs-table-active-bg: #d7d2cc;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #ddd7d1;
    --bs-table-hover-color: #111;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-secondary {
    --bs-table-color: #111;
    --bs-table-bg: #e2e2e2;
    --bs-table-border-color: #cdcdcd;
    --bs-table-striped-bg: #d8d8d8;
    --bs-table-striped-color: #111;
    --bs-table-active-bg: #cdcdcd;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #d2d2d2;
    --bs-table-hover-color: #fff;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-success {
    --bs-table-color: #111;
    --bs-table-bg: #def1e5;
    --bs-table-border-color: #cadbd0;
    --bs-table-striped-bg: #d4e6da;
    --bs-table-striped-color: #111;
    --bs-table-active-bg: #cadbd0;
    --bs-table-active-color: #111;
    --bs-table-hover-bg: #cfe0d5;
    --bs-table-hover-color: #111;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-info {
    --bs-table-color: #111;
    --bs-table-bg: #dff1fc;
    --bs-table-border-color: #cadbe5;
    --bs-table-striped-bg: #d5e6f0;
    --bs-table-striped-color: #111;
    --bs-table-active-bg: #cadbe5;
    --bs-table-active-color: #111;
    --bs-table-hover-bg: #d0e0ea;
    --bs-table-hover-color: #111;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-warning {
    --bs-table-color: #111;
    --bs-table-bg: #fcf0df;
    --bs-table-border-color: #e5daca;
    --bs-table-striped-bg: #f0e5d5;
    --bs-table-striped-color: #111;
    --bs-table-active-bg: #e5daca;
    --bs-table-active-color: #111;
    --bs-table-hover-bg: #eadfd0;
    --bs-table-hover-color: #111;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-danger {
    --bs-table-color: #111;
    --bs-table-bg: #f9dfdf;
    --bs-table-border-color: #e2caca;
    --bs-table-striped-bg: #edd5d5;
    --bs-table-striped-color: #111;
    --bs-table-active-bg: #e2caca;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #e8d0d0;
    --bs-table-hover-color: #111;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-light {
    --bs-table-color: #111;
    --bs-table-bg: #f8f8f8;
    --bs-table-border-color: #e1e1e1;
    --bs-table-striped-bg: #ececec;
    --bs-table-striped-color: #111;
    --bs-table-active-bg: #e1e1e1;
    --bs-table-active-color: #111;
    --bs-table-hover-bg: #e7e7e7;
    --bs-table-hover-color: #111;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-dark {
    --bs-table-color: #fff;
    --bs-table-bg: #212121;
    --bs-table-border-color: #373737;
    --bs-table-striped-bg: #2c2c2c;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #373737;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #323232;
    --bs-table-hover-color: #fff;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width: 1259.98px) {
    .table-responsive-xl {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width: 1399.98px) {
    .table-responsive-xxl {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

.table th {
    font-weight: 500
}

.form-label {
    margin-bottom: .5rem
}

.col-form-label {
    padding-top: calc(.375rem + var(--bs-border-width));
    padding-bottom: calc(.375rem + var(--bs-border-width));
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}

.col-form-label-lg {
    padding-top: calc(.5rem + var(--bs-border-width));
    padding-bottom: calc(.5rem + var(--bs-border-width));
    font-size: calc(1.265625rem + .1875vw)
}

@media (min-width: 1200px) {
    .col-form-label-lg {
        font-size:1.40625rem
    }
}

.col-form-label-sm {
    padding-top: calc(.25rem + var(--bs-border-width));
    padding-bottom: calc(.25rem + var(--bs-border-width));
    font-size: .984375rem
}

.form-text {
    margin-top: .25rem;
    font-size: .875em;
    color: var(--bs-secondary-color)
}

.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-form-control-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .135rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control {
        transition: none
    }
}

.form-control[type=file] {
    overflow: hidden
}

.form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-form-control-bg);
    border-color: #d3c2b5;
    outline: 0;
    box-shadow: 0 0 0 1px rgba(167,133,106,.25)
}

.form-control::-webkit-date-and-time-value {
    height: 1.5em
}

.form-control::-webkit-datetime-edit {
    display: block;
    padding: 0
}

.form-control::-moz-placeholder {
    color: var(--bs-secondary-color);
    opacity: 1
}

.form-control::placeholder {
    color: var(--bs-secondary-color);
    opacity: 1
}

.form-control:disabled {
    background-color: var(--bs-form-control-disabled-bg);
    opacity: 1
}

.form-control::file-selector-button {
    padding: .375rem .75rem;
    margin: -.375rem -.75rem;
    -webkit-margin-end: .75rem;
    margin-inline-end:.75rem;color: var(--bs-body-color);
    background-color: var(--bs-tertiary-bg);
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width:var(--bs-border-width);border-radius: 0;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control::file-selector-button {
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: var(--bs-secondary-bg)
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: .375rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: transparent;
    border: solid transparent;
    border-width: var(--bs-border-width) 0
}

.form-control-plaintext:focus {
    outline: 0
}

.form-control-plaintext.form-control-lg,.form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    min-height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2));
    padding: .25rem .5rem;
    font-size: .984375rem;
    border-radius: .115rem
}

.form-control-sm::file-selector-button {
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    -webkit-margin-end: .5rem;
    margin-inline-end:.5rem}

.form-control-lg {
    min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
    padding: .5rem 1rem;
    font-size: calc(1.265625rem + .1875vw);
    border-radius: .15rem
}

@media (min-width: 1200px) {
    .form-control-lg {
        font-size:1.40625rem
    }
}

.form-control-lg::file-selector-button {
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end:1rem}

textarea.form-control {
    min-height: calc(1.5em + .75rem + calc(var(--bs-border-width) * 2))
}

textarea.form-control-sm {
    min-height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2))
}

textarea.form-control-lg {
    min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2))
}

.form-control-color {
    width: 3rem;
    height: calc(1.5em + .75rem + calc(var(--bs-border-width) * 2));
    padding: .375rem
}

.form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control-color::-moz-color-swatch {
    border: 0!important;
    border-radius: .135rem
}

.form-control-color::-webkit-color-swatch {
    border-radius: .135rem
}

.form-control-color.form-control-sm {
    height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2))
}

.form-control-color.form-control-lg {
    height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2))
}

.form-select {
    --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343434' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    -moz-padding-start: calc(.75rem - 3px);
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-form-control-bg);
    background-image: var(--bs-form-select-bg-img),var(--bs-form-select-bg-icon,none);
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: .135rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-select {
        transition: none
    }
}

.form-select:focus {
    border-color: #d3c2b5;
    outline: 0;
    box-shadow: 0 0 0 1px rgba(167,133,106,.25)
}

.form-select[multiple],.form-select[size]:not([size="1"]) {
    padding-right: .75rem;
    background-image: none
}

.form-select:disabled {
    background-color: var(--bs-form-control-disabled-bg)
}

.form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 var(--bs-body-color)
}

.form-select-sm {
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: .5rem;
    font-size: .984375rem;
    border-radius: .115rem
}

.form-select-lg {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    font-size: calc(1.265625rem + .1875vw);
    border-radius: .15rem
}

@media (min-width: 1200px) {
    .form-select-lg {
        font-size:1.40625rem
    }
}

[data-bs-theme=dark] .form-select {
    --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dedede' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e")
}

.form-check {
    display: block;
    min-height: 1.6875rem;
    padding-left: 1.5em;
    margin-bottom: .125rem
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.5em
}

.form-check-reverse {
    padding-right: 1.5em;
    padding-left: 0;
    text-align: right
}

.form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.5em;
    margin-left: 0
}

.form-check-input {
    --bs-form-check-bg: var(--bs-form-control-bg);
    width: 1em;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    background-color: var(--bs-form-check-bg);
    background-image: var(--bs-form-check-bg-image);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: var(--bs-border-width) solid var(--bs-border-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact
}

.form-check-input[type=checkbox] {
    border-radius: .25em
}

.form-check-input[type=radio] {
    border-radius: 50%
}

.form-check-input:active {
    filter: brightness(90%)
}

.form-check-input:focus {
    border-color: #d3c2b5;
    outline: 0;
    box-shadow: 0 0 0 1px rgba(167,133,106,.25)
}

.form-check-input:checked {
    background-color: #a7856a;
    border-color: #a7856a
}

.form-check-input:checked[type=checkbox] {
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e")
}

.form-check-input:checked[type=radio] {
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")
}

.form-check-input[type=checkbox]:indeterminate {
    background-color: #a7856a;
    border-color: #a7856a;
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
}

.form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: .5
}

.form-check-input:disabled~.form-check-label,.form-check-input[disabled]~.form-check-label {
    cursor: default;
    opacity: .5
}

.form-switch {
    padding-left: 2.5em
}

.form-switch .form-check-input {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%2817, 17, 17, 0.25%29'/%3e%3c/svg%3e");
    width: 2em;
    margin-left: -2.5em;
    background-image: var(--bs-form-switch-bg);
    background-position: left center;
    border-radius: 2em;
    transition: background-position .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-switch .form-check-input {
        transition: none
    }
}

.form-switch .form-check-input:focus {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23d3c2b5'/%3e%3c/svg%3e")
}

.form-switch .form-check-input:checked {
    background-position: right center;
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.form-switch.form-check-reverse {
    padding-right: 2.5em;
    padding-left: 0
}

.form-switch.form-check-reverse .form-check-input {
    margin-right: -2.5em;
    margin-left: 0
}

.form-check-inline {
    display: inline-block;
    margin-right: 1rem
}

.btn-check {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none
}

.btn-check:disabled+.btn,.btn-check[disabled]+.btn {
    pointer-events: none;
    filter: none;
    opacity: .65
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e")
}

.form-range {
    width: 100%;
    height: calc(1rem + 2px);
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.form-range:focus {
    outline: 0
}

.form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff,0 0 0 1px rgba(167,133,106,.25)
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff,0 0 0 1px rgba(167,133,106,.25)
}

.form-range::-moz-focus-outer {
    border: 0
}

.form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: #a7856a;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none
    }
}

.form-range::-webkit-slider-thumb:active {
    background-color: #e5dad2
}

.form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--bs-tertiary-bg);
    border-color: transparent;
    border-radius: 1rem
}

.form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #a7856a;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -moz-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none
    }
}

.form-range::-moz-range-thumb:active {
    background-color: #e5dad2
}

.form-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--bs-tertiary-bg);
    border-color: transparent;
    border-radius: 1rem
}

.form-range:disabled {
    pointer-events: none
}

.form-range:disabled::-webkit-slider-thumb {
    background-color: var(--bs-secondary-color)
}

.form-range:disabled::-moz-range-thumb {
    background-color: var(--bs-secondary-color)
}

.form-floating {
    position: relative
}

.form-floating::before:not(.form-control:disabled) {
    position: absolute;
    top: var(--bs-border-width);
    left: var(--bs-border-width);
    width: calc(100% - (calc(calc(.375em + .1875rem) + calc(.75em + .375rem))));
    height: 1.875em;
    content: "";
    background-color: var(--bs-form-control-bg);
    border-radius: .135rem
}

.form-floating>.form-control,.form-floating>.form-control-plaintext,.form-floating>.form-select {
    height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    line-height: 1.25
}

.form-floating>label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem .75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: var(--bs-border-width) solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out,transform .1s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-floating>label {
        transition: none
    }
}

.form-floating>.form-control,.form-floating>.form-control-plaintext {
    padding: 1rem .75rem
}

.form-floating>.form-control-plaintext::-moz-placeholder,.form-floating>.form-control::-moz-placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext::placeholder,.form-floating>.form-control::placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext:not(:-moz-placeholder-shown),.form-floating>.form-control:not(:-moz-placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control-plaintext:focus,.form-floating>.form-control-plaintext:not(:placeholder-shown),.form-floating>.form-control:focus,.form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control-plaintext:-webkit-autofill,.form-floating>.form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-select {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control-plaintext~label,.form-floating>.form-control:focus~label,.form-floating>.form-control:not(:placeholder-shown)~label,.form-floating>.form-select~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control:-webkit-autofill~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control-plaintext~label {
    border-width: var(--bs-border-width) 0
}

.form-floating>.form-control:disabled~label {
    color: #6c6c6c
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
}

.input-group>.form-control,.input-group>.form-floating,.input-group>.form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0
}

.input-group>.form-control:focus,.input-group>.form-floating:focus-within,.input-group>.form-select:focus {
    z-index: 5
}

.input-group .btn {
    position: relative;
    z-index: 2
}

.input-group .btn:focus {
    z-index: 5
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.5;
    color: var(--bs-body-color);
    text-align: center;
    white-space: nowrap;
    background-color: var(--bs-tertiary-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: .135rem
}

.input-group-lg>.btn,.input-group-lg>.form-control,.input-group-lg>.form-select,.input-group-lg>.input-group-text {
    padding: .5rem 1rem;
    font-size: calc(1.265625rem + .1875vw);
    border-radius: .15rem
}

@media (min-width: 1200px) {
    .input-group-lg>.btn,.input-group-lg>.form-control,.input-group-lg>.form-select,.input-group-lg>.input-group-text {
        font-size:1.40625rem
    }
}

.input-group-sm>.btn,.input-group-sm>.form-control,.input-group-sm>.form-select,.input-group-sm>.input-group-text {
    padding: .25rem .5rem;
    font-size: .984375rem;
    border-radius: .115rem
}

.input-group-lg>.form-select,.input-group-sm>.form-select {
    padding-right: 3rem
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select,.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4),.input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-control,.input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-select,.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: calc(var(--bs-border-width) * -1);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group>.form-floating:not(:first-child)>.form-control,.input-group>.form-floating:not(:first-child)>.form-select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: var(--bs-success-text)
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .984375rem;
    color: #fff;
    background-color: var(--bs-success);
    border-radius: var(--bs-border-radius)
}

.is-valid~.valid-feedback,.is-valid~.valid-tooltip,.was-validated :valid~.valid-feedback,.was-validated :valid~.valid-tooltip {
    display: block
}

.form-control.is-valid,.was-validated .form-control:valid {
    border-color: var(--bs-success)
}

.form-control.is-valid:focus,.was-validated .form-control:valid:focus {
    border-color: var(--bs-success);
    box-shadow: 0 0 0 1px rgba(var(--bs-success-rgb),.25)
}

.form-select.is-valid,.was-validated .form-select:valid {
    border-color: var(--bs-success)
}

.form-select.is-valid:focus,.was-validated .form-select:valid:focus {
    border-color: var(--bs-success);
    box-shadow: 0 0 0 1px rgba(var(--bs-success-rgb),.25)
}

.form-check-input.is-valid,.was-validated .form-check-input:valid {
    border-color: var(--bs-success)
}

.form-check-input.is-valid:checked,.was-validated .form-check-input:valid:checked {
    background-color: var(--bs-success-text)
}

.form-check-input.is-valid:focus,.was-validated .form-check-input:valid:focus {
    box-shadow: 0 0 0 1px rgba(var(--bs-success-rgb),.25)
}

.form-check-input.is-valid~.form-check-label,.was-validated .form-check-input:valid~.form-check-label {
    color: var(--bs-success-text)
}

.form-check-inline .form-check-input~.valid-feedback {
    margin-left: .5em
}

.input-group>.form-control:not(:focus).is-valid,.input-group>.form-floating:not(:focus-within).is-valid,.input-group>.form-select:not(:focus).is-valid,.was-validated .input-group>.form-control:not(:focus):valid,.was-validated .input-group>.form-floating:not(:focus-within):valid,.was-validated .input-group>.form-select:not(:focus):valid {
    z-index: 3
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: var(--bs-danger-text)
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .984375rem;
    color: #fff;
    background-color: var(--bs-danger);
    border-radius: var(--bs-border-radius)
}

.is-invalid~.invalid-feedback,.is-invalid~.invalid-tooltip,.was-validated :invalid~.invalid-feedback,.was-validated :invalid~.invalid-tooltip {
    display: block
}

.form-control.is-invalid,.was-validated .form-control:invalid {
    border-color: var(--bs-danger)
}

.form-control.is-invalid:focus,.was-validated .form-control:invalid:focus {
    border-color: var(--bs-danger);
    box-shadow: 0 0 0 1px rgba(var(--bs-danger-rgb),.25)
}

.form-select.is-invalid,.was-validated .form-select:invalid {
    border-color: var(--bs-danger)
}

.form-select.is-invalid:focus,.was-validated .form-select:invalid:focus {
    border-color: var(--bs-danger);
    box-shadow: 0 0 0 1px rgba(var(--bs-danger-rgb),.25)
}

.form-check-input.is-invalid,.was-validated .form-check-input:invalid {
    border-color: var(--bs-danger)
}

.form-check-input.is-invalid:checked,.was-validated .form-check-input:invalid:checked {
    background-color: var(--bs-danger-text)
}

.form-check-input.is-invalid:focus,.was-validated .form-check-input:invalid:focus {
    box-shadow: 0 0 0 1px rgba(var(--bs-danger-rgb),.25)
}

.form-check-input.is-invalid~.form-check-label,.was-validated .form-check-input:invalid~.form-check-label {
    color: var(--bs-danger-text)
}

.form-check-inline .form-check-input~.invalid-feedback {
    margin-left: .5em
}

.input-group>.form-control:not(:focus).is-invalid,.input-group>.form-floating:not(:focus-within).is-invalid,.input-group>.form-select:not(:focus).is-invalid,.was-validated .input-group>.form-control:not(:focus):invalid,.was-validated .input-group>.form-floating:not(:focus-within):invalid,.was-validated .input-group>.form-select:not(:focus):invalid {
    z-index: 4
}

.control-icon {
    position: relative
}

.control-icon button.form-select,.control-icon>.form-control,.control-icon>.form-select {
    padding-left: 3rem
}

.control-icon button.form-select.form-control-lg,.control-icon button.form-select.form-select-lg,.control-icon>.form-control.form-control-lg,.control-icon>.form-control.form-select-lg,.control-icon>.form-select.form-control-lg,.control-icon>.form-select.form-select-lg {
    padding-left: 2.75rem
}

.control-icon>.hicon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1rem;
    z-index: 3!important
}

.control-icon-lg .control-icon>.hicon {
    left: .7rem;
    font-size: 130%
}

.control-icon-sm .control-icon>.hicon {
    left: 1rem;
    font-size: 120%
}

.form-select.form-select-indicator-light {
    --bs-form-select-bg-img-light: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23f8f8f8' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    background-image: var(--bs-form-select-bg-img-light),var(--bs-form-select-bg-icon,none)
}

.btn {
    --bs-btn-padding-x: 0.75rem;
    --bs-btn-padding-y: 0.375rem;
    --bs-btn-font-size: 1.125rem;
    --bs-btn-font-weight: 300;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: #212121;
    --bs-btn-bg: transparent;
    --bs-btn-border-width: var(--bs-border-width);
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: 0.135rem;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),0 1px 1px rgba(17, 17, 17, 0.075);
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 1px rgba(var(--bs-btn-focus-shadow-rgb), .5);
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: var(--bs-btn-bg);
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .btn {
        transition: none
    }
}

/* .btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color)
} */

.btn-check+.btn:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color)
}

.btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow)
}

.btn-check:focus-visible+.btn {
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow)
}

/* .btn-check:checked+.btn,.btn.active,.btn.show,.btn:first-child:active,:not(.btn-check)+.btn:active {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color)
} */

.btn-check:checked+.btn:focus-visible,.btn.active:focus-visible,.btn.show:focus-visible,.btn:first-child:active:focus-visible,:not(.btn-check)+.btn:active:focus-visible {
    box-shadow: var(--bs-btn-focus-box-shadow)
}

.btn.disabled,.btn:disabled,fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity)
}



.btn-secondary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #6c6c6c;
    --bs-btn-border-color: #6c6c6c;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5c5c5c;
    --bs-btn-hover-border-color: #565656;
    --bs-btn-focus-shadow-rgb: 130,130,130;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #565656;
    --bs-btn-active-border-color: #515151;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 17, 17, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c6c6c;
    --bs-btn-disabled-border-color: #6c6c6c
}

.btn-success {
    --bs-btn-color: #fff;
    --bs-btn-bg: #5ab77b;
    --bs-btn-border-color: #5ab77b;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #4d9c69;
    --bs-btn-hover-border-color: #489262;
    --bs-btn-focus-shadow-rgb: 115,194,143;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #489262;
    --bs-btn-active-border-color: #44895c;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 17, 17, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #5ab77b;
    --bs-btn-disabled-border-color: #5ab77b
}

.btn-info {
    --bs-btn-color: #fff;
    --bs-btn-bg: #5eb9f0;
    --bs-btn-border-color: #5eb9f0;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #509dcc;
    --bs-btn-hover-border-color: #4b94c0;
    --bs-btn-focus-shadow-rgb: 118,196,242;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #4b94c0;
    --bs-btn-active-border-color: #478bb4;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 17, 17, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #5eb9f0;
    --bs-btn-disabled-border-color: #5eb9f0
}

.btn-warning {
    --bs-btn-color: #fff;
    --bs-btn-bg: #eeb461;
    --bs-btn-border-color: #eeb461;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #ca9952;
    --bs-btn-hover-border-color: #be904e;
    --bs-btn-focus-shadow-rgb: 241,191,121;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #be904e;
    --bs-btn-active-border-color: #b38749;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 17, 17, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #eeb461;
    --bs-btn-disabled-border-color: #eeb461
}

.btn-danger {
    --bs-btn-color: #fff;
    --bs-btn-bg: #e35d5d;
    --bs-btn-border-color: #e35d5d;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #c14f4f;
    --bs-btn-hover-border-color: #b64a4a;
    --bs-btn-focus-shadow-rgb: 231,117,117;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #b64a4a;
    --bs-btn-active-border-color: #aa4646;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 17, 17, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #e35d5d;
    --bs-btn-disabled-border-color: #e35d5d
}

.btn-light {
    --bs-btn-color: #111;
    --bs-btn-bg: #f8f8f8;
    --bs-btn-border-color: #f8f8f8;
    --bs-btn-hover-color: #111;
    --bs-btn-hover-bg: lightgray;
    --bs-btn-hover-border-color: #c6c6c6;
    --bs-btn-focus-shadow-rgb: 213,213,213;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #c6c6c6;
    --bs-btn-active-border-color: #bababa;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 17, 17, 0.125);
    --bs-btn-disabled-color: #111;
    --bs-btn-disabled-bg: #f8f8f8;
    --bs-btn-disabled-border-color: #f8f8f8
}

.btn-dark {
    --bs-btn-color: #fff;
    --bs-btn-bg: #212121;
    --bs-btn-border-color: #212121;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #424242;
    --bs-btn-hover-border-color: #373737;
    --bs-btn-focus-shadow-rgb: 66,66,66;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #4d4d4d;
    --bs-btn-active-border-color: #373737;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 17, 17, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #212121;
    --bs-btn-disabled-border-color: #212121
}

.btn-outline-primary {
    --bs-btn-color: #a7856a;
    --bs-btn-border-color: #a7856a;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #a7856a;
    --bs-btn-hover-border-color: #a7856a;
    --bs-btn-focus-shadow-rgb: 167,133,106;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #a7856a;
    --bs-btn-active-border-color: #a7856a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 17, 17, 0.125);
    --bs-btn-disabled-color: #a7856a;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #a7856a;
    --bs-gradient: none
}

.btn-outline-secondary {
    --bs-btn-color: #6c6c6c;
    --bs-btn-border-color: #6c6c6c;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #6c6c6c;
    --bs-btn-hover-border-color: #6c6c6c;
    --bs-btn-focus-shadow-rgb: 108,108,108;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #6c6c6c;
    --bs-btn-active-border-color: #6c6c6c;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 17, 17, 0.125);
    --bs-btn-disabled-color: #6c6c6c;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #6c6c6c;
    --bs-gradient: none
}

.btn-outline-success {
    --bs-btn-color: #5ab77b;
    --bs-btn-border-color: #5ab77b;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5ab77b;
    --bs-btn-hover-border-color: #5ab77b;
    --bs-btn-focus-shadow-rgb: 90,183,123;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #5ab77b;
    --bs-btn-active-border-color: #5ab77b;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 17, 17, 0.125);
    --bs-btn-disabled-color: #5ab77b;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #5ab77b;
    --bs-gradient: none
}

.btn-outline-info {
    --bs-btn-color: #5eb9f0;
    --bs-btn-border-color: #5eb9f0;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5eb9f0;
    --bs-btn-hover-border-color: #5eb9f0;
    --bs-btn-focus-shadow-rgb: 94,185,240;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #5eb9f0;
    --bs-btn-active-border-color: #5eb9f0;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 17, 17, 0.125);
    --bs-btn-disabled-color: #5eb9f0;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #5eb9f0;
    --bs-gradient: none
}

.btn-outline-warning {
    --bs-btn-color: #eeb461;
    --bs-btn-border-color: #eeb461;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #eeb461;
    --bs-btn-hover-border-color: #eeb461;
    --bs-btn-focus-shadow-rgb: 238,180,97;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #eeb461;
    --bs-btn-active-border-color: #eeb461;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 17, 17, 0.125);
    --bs-btn-disabled-color: #eeb461;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #eeb461;
    --bs-gradient: none
}

.btn-outline-danger {
    --bs-btn-color: #e35d5d;
    --bs-btn-border-color: #e35d5d;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #e35d5d;
    --bs-btn-hover-border-color: #e35d5d;
    --bs-btn-focus-shadow-rgb: 227,93,93;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #e35d5d;
    --bs-btn-active-border-color: #e35d5d;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 17, 17, 0.125);
    --bs-btn-disabled-color: #e35d5d;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #e35d5d;
    --bs-gradient: none
}

.btn-outline-light {
    --bs-btn-color: #f8f8f8;
    --bs-btn-border-color: #f8f8f8;
    --bs-btn-hover-color: #111;
    --bs-btn-hover-bg: #f8f8f8;
    --bs-btn-hover-border-color: #f8f8f8;
    --bs-btn-focus-shadow-rgb: 248,248,248;
    --bs-btn-active-color: #111;
    --bs-btn-active-bg: #f8f8f8;
    --bs-btn-active-border-color: #f8f8f8;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 17, 17, 0.125);
    --bs-btn-disabled-color: #f8f8f8;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #f8f8f8;
    --bs-gradient: none
}

.btn-outline-dark {
    --bs-btn-color: #212121;
    --bs-btn-border-color: #212121;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #212121;
    --bs-btn-hover-border-color: #212121;
    --bs-btn-focus-shadow-rgb: 33,33,33;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #212121;
    --bs-btn-active-border-color: #212121;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 17, 17, 0.125);
    --bs-btn-disabled-color: #212121;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #212121;
    --bs-gradient: none
}

.btn-link {
    --bs-btn-font-weight: 300;
    --bs-btn-color: var(--bs-link-color);
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: var(--bs-link-hover-color);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-color: var(--bs-link-hover-color);
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-color: #6c6c6c;
    --bs-btn-disabled-border-color: transparent;
    --bs-btn-box-shadow: none;
    --bs-btn-focus-shadow-rgb: 180,151,128;
    text-decoration: none
}

.btn-link:focus-visible {
    color: var(--bs-btn-color)
}

.btn-link:hover {
    color: var(--bs-btn-hover-color)
}

.btn-group-lg>.btn,.btn-lg {
    --bs-btn-padding-y: 0.5rem;
    --bs-btn-padding-x: 1rem;
    --bs-btn-font-size: calc(1.265625rem + 0.1875vw);
    --bs-btn-border-radius: 0.135rem
}

@media (min-width: 1200px) {
    .btn-group-lg>.btn,.btn-lg {
        --bs-btn-font-size:1.40625rem
    }
}

.btn-group-sm>.btn,.btn-sm {
    --bs-btn-padding-y: 0.25rem;
    --bs-btn-padding-x: 0.5rem;
    --bs-btn-font-size: 0.984375rem;
    --bs-btn-border-radius: 0.135rem
}

.btn-outline-light {
    --bs-btn-color: #212121;
    --bs-btn-border-color: #dedede;
    --bs-btn-hover-border-color: #dedede;
    border-color: var(--bs-btn-border-color)
}

.btn-outline-light:hover {
    border-color: var(--bs-btn-hover-border-color)
}

.btn-square {
    width: 2.5995rem;
    height: 2.5995rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0
}

.btn-square.btn-xs {
    width: 1.6345rem;
    height: 1.6345rem
}

.btn-group-sm>.btn-square.btn,.btn-square.btn-sm {
    width: 2.1345rem;
    height: 2.1345rem
}

.btn-group-lg>.btn-square.btn,.btn-square.btn-lg {
    width: 3.2815rem;
    height: 3.2815rem
}

.btn-square.btn-xs {
    font-size: .85rem
}

.btn-video-play {
    --bs-btn-video-play-color: var(--bs-primary);
    --bs-btn-video-play-size: 64px;
    --bs-btn-video-play-size-md: 64px;
    --bs-btn-video-play-size-sm: 48px;
    --bs-btn-video-play-size-lg: 80px;
    --bs-btn-video-play-border-size: 16px;
    --bs-btn-video-play-border-size-md: 16px;
    --bs-btn-video-play-border-size-sm: 12px;
    --bs-btn-video-play-border-size-lg: 20px;
    --bs-btn-video-play-animation: pulse-border 1500ms ease-out infinite;
    --bs-btn-video-play-transition: all 0.35s ease-in-out;
    padding-left: 5px;
    position: relative;
    box-sizing: content-box;
    display: inline-flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    width: calc(var(--bs-btn-video-play-size) + 10px);
    height: calc(var(--bs-btn-video-play-size) + 10px)
}

.btn-video-play:after,.btn-video-play:before {
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    content: "";
    display: inline-block;
    width: var(--bs-btn-video-play-size);
    height: var(--bs-btn-video-play-size);
    border-radius: 50%;
    transition: var(--bs-btn-video-play-transition);
    background: var(--bs-btn-video-play-color)
}

.btn-video-play:before {
    animation: var(--bs-btn-video-play-animation)
}

.btn-video-play:hover:after,.btn-video-play:hover:before {
    opacity: .55
}

.btn-video-play span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    border-left: var(--bs-btn-video-play-border-size) solid #fff;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent
}

.btn-video-play.btn-video-play-sm:after,.btn-video-play.btn-video-play-sm:before {
    width: var(--bs-btn-video-play-size-sm);
    height: var(--bs-btn-video-play-size-sm)
}

.btn-video-play.btn-video-play-sm {
    width: calc(var(--bs-btn-video-play-size-sm) + 4px + 4px);
    height: calc(var(--bs-btn-video-play-size-sm) + 4px + 4px)
}

.btn-video-play.btn-video-play-sm span {
    border-left: var(--bs-btn-video-play-border-size-sm) solid #fff;
    border-top: calc(var(--bs-btn-video-play-border-size-sm) - 4px) solid transparent;
    border-bottom: calc(var(--bs-btn-video-play-border-size-sm) - 4px) solid transparent
}

.btn-video-play.btn-video-play-md:after,.btn-video-play.btn-video-play-md:before {
    width: var(--bs-btn-video-play-size-md);
    height: var(--bs-btn-video-play-size-md)
}

.btn-video-play.btn-video-play-md {
    width: calc(var(--bs-btn-video-play-size-md) + 6px + 4px);
    height: calc(var(--bs-btn-video-play-size-md) + 6px + 4px)
}

.btn-video-play.btn-video-play-md span {
    border-left: var(--bs-btn-video-play-border-size-md) solid #fff;
    border-top: calc(var(--bs-btn-video-play-border-size-md) - 6px) solid transparent;
    border-bottom: calc(var(--bs-btn-video-play-border-size-md) - 6px) solid transparent
}

.btn-video-play.btn-video-play-lg:after,.btn-video-play.btn-video-play-lg:before {
    width: var(--bs-btn-video-play-size-lg);
    height: var(--bs-btn-video-play-size-lg)
}

.btn-video-play.btn-video-play-lg {
    width: calc(var(--bs-btn-video-play-size-lg) + 8px + 4px);
    height: calc(var(--bs-btn-video-play-size-lg) + 8px + 4px)
}

.btn-video-play.btn-video-play-lg span {
    border-left: var(--bs-btn-video-play-border-size-lg) solid #fff;
    border-top: calc(var(--bs-btn-video-play-border-size-lg) - 8px) solid transparent;
    border-bottom: calc(var(--bs-btn-video-play-border-size-lg) - 8px) solid transparent
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0
    }
}

.fade {
    transition: opacity .15s linear
}

@media (prefers-reduced-motion:reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}
/* 
.collapse:not(.show) {
    display: none
} */

.collapsing {
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing {
        transition: none
    }
}

/* .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width .35s ease
} */

/* @media (prefers-reduced-motion:reduce) {
    .collapsing.collapse-horizontal {
        transition: none
    }
} */

.transition-base {
    --bs-header-transition: 0.35s all ease-in-out;
    transition: var(--bs-header-transition)
}

.dropdown,.dropdown-center,.dropend,.dropstart,.dropup,.dropup-center {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .1875rem;
    vertical-align: .0875rem;
    content: "";
    border-top: .25rem solid;
    border-right: .25rem solid transparent;
    border-bottom: 0;
    border-left: .25rem solid transparent
}

.dropdown-toggle:empty::after {
    margin-left: 0
}

.dropdown-menu {
    --bs-dropdown-zindex: 1000;
    --bs-dropdown-min-width: 11.5rem;
    --bs-dropdown-padding-x: 0;
    --bs-dropdown-padding-y: 0.75rem;
    --bs-dropdown-spacer: 0.125rem;
    --bs-dropdown-font-size: 1.125rem;
    --bs-dropdown-color: var(--bs-body-color);
    --bs-dropdown-bg: var(--bs-body-bg);
    --bs-dropdown-border-color: var(--bs-body-bg);
    --bs-dropdown-border-radius: 0.135rem;
    --bs-dropdown-border-width: var(--bs-border-width);
    --bs-dropdown-inner-border-radius: calc(0.135rem - var(--bs-border-width));
    --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
    --bs-dropdown-divider-margin-y: 0.5rem;
    --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15);
    --bs-dropdown-link-color: var(--bs-body-color);
    --bs-dropdown-link-hover-color: var(--bs-body-color);
    --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
    --bs-dropdown-link-active-color: #fff;
    --bs-dropdown-link-active-bg: #a7856a;
    --bs-dropdown-link-disabled-color: #adadad;
    --bs-dropdown-item-padding-x: 1.25rem;
    --bs-dropdown-item-padding-y: 0.25rem;
    --bs-dropdown-header-color: #6c6c6c;
    --bs-dropdown-header-padding-x: 1.25rem;
    --bs-dropdown-header-padding-y: 0.75rem;
    position: absolute;
    z-index: var(--bs-dropdown-zindex);
    display: none;
    min-width: var(--bs-dropdown-min-width);
    padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
    margin: 0;
    font-size: var(--bs-dropdown-font-size);
    color: var(--bs-dropdown-color);
    text-align: left;
    list-style: none;
    background-color: var(--bs-dropdown-bg);
    background-clip: padding-box;
    border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
    border-radius: var(--bs-dropdown-border-radius)
}

.dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer)
}

.dropdown-menu-start {
    --bs-position: start
}

.dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0
}

.dropdown-menu-end {
    --bs-position: end
}

.dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto
}

@media (min-width: 576px) {
    .dropdown-menu-sm-start {
        --bs-position:start
    }

    .dropdown-menu-sm-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-sm-end {
        --bs-position: end
    }

    .dropdown-menu-sm-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width: 768px) {
    .dropdown-menu-md-start {
        --bs-position:start
    }

    .dropdown-menu-md-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-md-end {
        --bs-position: end
    }

    .dropdown-menu-md-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width: 992px) {
    .dropdown-menu-lg-start {
        --bs-position:start
    }

    .dropdown-menu-lg-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-lg-end {
        --bs-position: end
    }

    .dropdown-menu-lg-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width: 1260px) {
    .dropdown-menu-xl-start {
        --bs-position:start
    }

    .dropdown-menu-xl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xl-end {
        --bs-position: end
    }

    .dropdown-menu-xl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width: 1400px) {
    .dropdown-menu-xxl-start {
        --bs-position:start
    }

    .dropdown-menu-xxl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xxl-end {
        --bs-position: end
    }

    .dropdown-menu-xxl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: var(--bs-dropdown-spacer)
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: .1875rem;
    vertical-align: .0875rem;
    content: "";
    border-top: 0;
    border-right: .25rem solid transparent;
    border-bottom: .25rem solid;
    border-left: .25rem solid transparent
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: var(--bs-dropdown-spacer)
}

.dropend .dropdown-toggle::after {
    display: inline-block;
    margin-left: .1875rem;
    vertical-align: .0875rem;
    content: "";
    border-top: .25rem solid transparent;
    border-right: 0;
    border-bottom: .25rem solid transparent;
    border-left: .25rem solid
}

.dropend .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropend .dropdown-toggle::after {
    vertical-align: 0
}

.dropstart .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: var(--bs-dropdown-spacer)
}

.dropstart .dropdown-toggle::after {
    display: inline-block;
    margin-left: .1875rem;
    vertical-align: .0875rem;
    content: ""
}

.dropstart .dropdown-toggle::after {
    display: none
}

.dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-right: .1875rem;
    vertical-align: .0875rem;
    content: "";
    border-top: .25rem solid transparent;
    border-right: .25rem solid;
    border-bottom: .25rem solid transparent
}

.dropstart .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropstart .dropdown-toggle::before {
    vertical-align: 0
}

.dropdown-divider {
    height: 0;
    margin: var(--bs-dropdown-divider-margin-y) 0;
    overflow: hidden;
    border-top: 1px solid var(--bs-dropdown-divider-bg);
    opacity: 1
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    clear: both;
    font-weight: 300;
    color: var(--bs-dropdown-link-color);
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    border-radius: var(--bs-dropdown-item-border-radius,0)
}

.dropdown-item:focus,.dropdown-item:hover {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg)
}

.dropdown-item.active,.dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg)
}

.dropdown-item.disabled,.dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent
}

.dropdown-menu.show {
    display: block;
    background-color: white;
    border: white;

}

.dropdown-header {
    display: block;
    padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
    margin-bottom: 0;
    font-size: .984375rem;
    color: var(--bs-dropdown-header-color);
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    color: var(--bs-dropdown-link-color)
}

.dropdown-menu-dark {
    --bs-dropdown-color: #dedede;
    --bs-dropdown-bg: #343434;
    --bs-dropdown-border-color: var(--bs-body-bg);
    --bs-dropdown-link-color: #dedede;
    --bs-dropdown-link-hover-color: #fff;
    --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
    --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
    --bs-dropdown-link-active-color: #fff;
    --bs-dropdown-link-active-bg: #a7856a;
    --bs-dropdown-link-disabled-color: #adadad;
    --bs-dropdown-header-color: #adadad
}

.dropdown-menu {
    --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15);
    box-shadow: var(--bs-dropdown-box-shadow)
}

.dropdown-menu-dark,[data-bs-theme=dark] .dropdown-menu {
    box-shadow: none
}

.dropdown-toggle::after,.dropdown-toggle::before {
    font-family: font-awesome;
    display: inline-block;
    line-height: 1;
    /* font-weight: 400; */
    /* font-style: normal; */
    /* font-size: .75rem; */
    /* speak: none; */
    color :white;
    text-decoration: inherit;
    /* text-transform: none; */
    /* text-rendering: optimizeLegibility; */
    /* -webkit-font-smoothing: antialiased; */
    -moz-osx-font-smoothing: grayscale;
    /* display: inline-block; */
    vertical-align: 0.0875rem;
    content: "";
    /* border: 0;*/
    
}



.dropdown-toggle::before {
    margin-right: .1875rem
}



.dropdown-toggle:empty::after {
    margin-left: 0
}

.dropup .dropdown-toggle::after,.dropup .dropdown-toggle::before {
    font-family: icons;
    display: inline-block;
    line-height: 1;
    font-weight: 400;
    font-style: normal;
    font-size: .75rem;
    speak: none;
    text-decoration: inherit;
    text-transform: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    vertical-align: .0875rem;
    content: "";
    border: 0
}

.dropup .dropdown-toggle::after {
    margin-left: .1875rem
}

.dropup .dropdown-toggle::before {
    margin-right: .1875rem
}

.dropup .dropdown-toggle::after {
    border: 0;
    content: "\f5ad"
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropend .dropdown-toggle::after,.dropend .dropdown-toggle::before {
    font-family: icons;
    display: inline-block;
    line-height: 1;
    font-weight: 400;
    font-style: normal;
    font-size: .75rem;
    speak: none;
    text-decoration: inherit;
    text-transform: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    vertical-align: .0875rem;
    content: "";
    border: 0
}

.dropend .dropdown-toggle::after {
    margin-left: .1875rem
}

.dropend .dropdown-toggle::before {
    margin-right: .1875rem
}

.dropend .dropdown-toggle::after {
    border: 0;
    content: "\f5ab"
}

.dropend .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropstart .dropdown-toggle::after,.dropstart .dropdown-toggle::before {
    font-family: icons;
    display: inline-block;
    line-height: 1;
    font-weight: 400;
    font-style: normal;
    font-size: .75rem;
    speak: none;
    text-decoration: inherit;
    text-transform: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    vertical-align: .0875rem;
    content: "";
    border: 0
}

.dropstart .dropdown-toggle::after {
    margin-left: .1875rem
}

.dropstart .dropdown-toggle::before {
    margin-right: .1875rem
}

.dropstart .dropdown-toggle::before {
    content: "\f5ac"
}

.dropstart .dropdown-toggle:empty::after {
    margin-left: 0
}

.btn-group,.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle
}

.btn-group-vertical>.btn,.btn-group>.btn {
    position: relative;
    flex: 1 1 auto
}

.btn-group-vertical>.btn-check:checked+.btn,.btn-group-vertical>.btn-check:focus+.btn,.btn-group-vertical>.btn.active,.btn-group-vertical>.btn:active,.btn-group-vertical>.btn:focus,.btn-group-vertical>.btn:hover,.btn-group>.btn-check:checked+.btn,.btn-group>.btn-check:focus+.btn,.btn-group>.btn.active,.btn-group>.btn:active,.btn-group>.btn:focus,.btn-group>.btn:hover {
    z-index: 1
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group {
    border-radius: .135rem
}

.btn-group>.btn-group:not(:first-child),.btn-group>:not(.btn-check:first-child)+.btn {
    margin-left: calc(var(--bs-border-width) * -1)
}

.btn-group>.btn-group:not(:last-child)>.btn,.btn-group>.btn.dropdown-toggle-split:first-child,.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,.btn-group>.btn:nth-child(n+3),.btn-group>:not(.btn-check)+.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: .5625rem;
    padding-left: .5625rem
}

.dropdown-toggle-split::after,.dropend .dropdown-toggle-split::after,.dropup .dropdown-toggle-split::after {
    margin-left: 0
}

.dropstart .dropdown-toggle-split::before {
    margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,.btn-sm+.dropdown-toggle-split {
    padding-right: .375rem;
    padding-left: .375rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,.btn-lg+.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
}

.btn-group-vertical>.btn,.btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn-group:not(:first-child),.btn-group-vertical>.btn:not(:first-child) {
    margin-top: calc(var(--bs-border-width) * -1)
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,.btn-group-vertical>.btn~.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav {
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-color: var(--bs-link-color);
    --bs-nav-link-hover-color: var(--bs-primary);
    --bs-nav-link-disabled-color: var(--bs-secondary-color);
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: var(--bs-nav-link-color);
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .nav-link {
        transition: none
    }
}

.nav-link:focus,.nav-link:hover {
    color: var(--bs-nav-link-hover-color)
}

.nav-link.disabled {
    color: var(--bs-nav-link-disabled-color);
    pointer-events: none;
    cursor: default
}

.nav-tabs {
    --bs-nav-tabs-border-width: var(--bs-border-width);
    --bs-nav-tabs-border-color: var(--bs-border-color);
    --bs-nav-tabs-border-radius: var(--bs-border-radius);
    --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
    --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
    --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
    --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
    border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color)
}

.nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    background: 0 0;
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius)
}

.nav-tabs .nav-link:focus,.nav-tabs .nav-link:hover {
    isolation: isolate;
    border-color: var(--bs-nav-tabs-link-hover-border-color)
}

.nav-tabs .nav-link.disabled,.nav-tabs .nav-link:disabled {
    color: var(--bs-nav-link-disabled-color);
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-item.show .nav-link,.nav-tabs .nav-link.active {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color)
}

.nav-tabs .dropdown-menu {
    margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills {
    --bs-nav-pills-border-radius: 0.135rem;
    --bs-nav-pills-link-active-color: #fff;
    --bs-nav-pills-link-active-bg: #a7856a
}

.nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: var(--bs-nav-pills-border-radius)
}

.nav-pills .nav-link:disabled {
    color: var(--bs-nav-link-disabled-color);
    background-color: transparent;
    border-color: transparent
}

.nav-pills .nav-link.active,.nav-pills .show>.nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg)
}

.nav-fill .nav-item,.nav-fill>.nav-link {
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item,.nav-justified>.nav-link {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center
}

.nav-fill .nav-item .nav-link,.nav-justified .nav-item .nav-link {
    width: 100%
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.nav-link {
    --bs-nav-link-hover-color: var(--bs-primary)
}

.nav-link.active {
    color: var(--bs-nav-link-hover-color)
}





.card {
    --bs-card-spacer-y: 1.5rem;
    --bs-card-spacer-x: 1.5rem;
    --bs-card-title-spacer-y: 1rem;
    --bs-card-border-width: var(--bs-border-width);
    --bs-card-border-color: var(--bs-border-color-translucent);
    --bs-card-border-radius: var(--bs-border-radius);
    --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
    --bs-card-cap-padding-y: 0.75rem;
    --bs-card-cap-padding-x: 1.5rem;
    --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
    --bs-card-bg: var(--bs-body-bg);
    --bs-card-img-overlay-padding: 1.5rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius)
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group {
    border-top: inherit;
    border-bottom: inherit
}

.card>.list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: var(--bs-card-inner-border-radius);
    border-top-right-radius: var(--bs-card-inner-border-radius)
}

.card>.list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: var(--bs-card-inner-border-radius);
    border-bottom-left-radius: var(--bs-card-inner-border-radius)
}

.card>.card-header+.list-group,.card>.list-group+.card-footer {
    border-top: 0
}

.card-body {
    flex: 1 1 auto;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    color: var(--bs-card-color)
}

.card-title {
    margin-bottom: var(--bs-card-title-spacer-y);
    color: var(--bs-card-title-color)
}

.card-subtitle {
    margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
    margin-bottom: 0;
    color: var(--bs-card-subtitle-color)
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link+.card-link {
    margin-left: var(--bs-card-spacer-x)
}

.card-header {
    padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
    margin-bottom: 0;
    color: var(--bs-card-cap-color);
    background-color: var(--bs-card-cap-bg);
    border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color)
}

.card-header:first-child {
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0
}

.card-footer {
    padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
    color: var(--bs-card-cap-color);
    background-color: var(--bs-card-cap-bg);
    border-top: var(--bs-card-border-width) solid var(--bs-card-border-color)
}

.card-footer:last-child {
    border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius)
}

.card-header-tabs {
    margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
    margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
    margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
    border-bottom: 0
}

.card-header-tabs .nav-link.active {
    background-color: var(--bs-card-bg);
    border-bottom-color: var(--bs-card-bg)
}

.card-header-pills {
    margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
    margin-left: calc(-.5 * var(--bs-card-cap-padding-x))
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: var(--bs-card-img-overlay-padding);
    border-radius: var(--bs-card-inner-border-radius)
}

.card-img,.card-img-bottom,.card-img-top {
    width: 100%
}

.card-img,.card-img-top {
    border-top-left-radius: var(--bs-card-inner-border-radius);
    border-top-right-radius: var(--bs-card-inner-border-radius)
}

.card-img,.card-img-bottom {
    border-bottom-right-radius: var(--bs-card-inner-border-radius);
    border-bottom-left-radius: var(--bs-card-inner-border-radius)
}

.card-group>.card {
    margin-bottom: var(--bs-card-group-margin)
}

@media (min-width: 576px) {
    .card-group {
        display:flex;
        flex-flow: row wrap
    }

    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-header,.card-group>.card:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-footer,.card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-header,.card-group>.card:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-footer,.card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }
}

.card.card-overlay-slide {
    --bs-card-overlay-slide-bg: var(--bs-black-rgb);
    --bs-card-overlay-bg-opacity: 0.45;
    --bs-card-overlay-slide-bg-opacity: 0.85;
    --bs-card-overlay-slide-from-bottom: 7.55rem;
    --bs-card-overlay-slide-transition: all 0.35s ease-in-out;
    overflow: hidden
}

.card.card-overlay-slide .card-img-overlay {
    top: unset;
    transform: translateY(calc(100% - var(--bs-card-overlay-slide-from-bottom)));
    transition: var(--bs-card-overlay-slide-transition);
    background: rgba(var(--bs-card-overlay-slide-bg),var(--bs-card-overlay-bg-opacity));
    z-index: 1
}

.card.card-overlay-slide .card-img-overlay .card-link,.card.card-overlay-slide .card-img-overlay .card-text {
    opacity: 0;
    transition: var(--bs-card-overlay-slide-transition)
}

.card.card-overlay-slide:hover::after {
    background: rgba(var(--bs-card-overlay-slide-bg),0)
}

.card.card-overlay-slide:hover .card-img-overlay {
    transform: translateY(0);
    background: rgba(var(--bs-card-overlay-slide-bg),var(--bs-card-overlay-slide-bg-opacity))
}

.card.card-overlay-slide:hover .card-img-overlay .card-link,.card.card-overlay-slide:hover .card-img-overlay .card-text {
    opacity: 1
}

.card.card-content-float {
    --bs-card-content-float-image-padding-xxl: 30%;
    --bs-card-content-float-image-padding-xl: 20%;
    --bs-card-content-float-body-width-xxl: 45%;
    --bs-card-content-float-body-width-xl: 55%;
    --bs-card-content-float-body-padding: 2.5rem;
    --bs-card-content-float-body-bottom: 2.5rem;
    --bs-card-content-float-body-top: 2.5rem
}

@media (min-width: 1260px) {
    .card.card-content-float {
        position:relative
    }

    .card.card-content-float .card-img-bottom,.card.card-content-float .card-img-top {
        padding-left: var(--bs-card-content-float-image-padding-xl);
        border-radius: .135rem;
        overflow: hidden
    }

    .card.card-content-float .card-img-bottom figure,.card.card-content-float .card-img-bottom img,.card.card-content-float .card-img-top figure,.card.card-content-float .card-img-top img {
        border-radius: .135rem;
        overflow: hidden
    }

    .card.card-content-float.card-content-float-right .card-img-bottom,.card.card-content-float.card-content-float-right .card-img-top {
        padding-left: unset;
        padding-right: var(--bs-card-content-float-image-padding-xl)
    }

    .card.card-content-float .card-body {
        position: absolute;
        padding: var(--bs-card-content-float-body-padding);
        top: var(--bs-card-content-float-body-top);
        bottom: var(--bs-card-content-float-body-bottom);
        width: var(--bs-card-content-float-body-width-xl);
        z-index: 3;
        border-radius: .135rem
    }

    .card.card-content-float.card-content-float-right .card-body {
        right: 0
    }
}

@media (min-width: 1400px) {
    .card.card-content-float .card-img-bottom,.card.card-content-float .card-img-top {
        padding-left:var(--bs-card-content-float-image-padding-xxl)
    }

    .card.card-content-float.card-content-float-right .card-img-bottom,.card.card-content-float.card-content-float-right .card-img-top {
        padding-right: var(--bs-card-content-float-image-padding-xxl)
    }

    .card.card-content-float .card-body {
        width: var(--bs-card-content-float-body-width-xxl)
    }
}

.card.card-selected {
    border-color: #a7856a
}

.card.card-selected:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 40px 40px 0;
    border-color: transparent #a7856a transparent transparent
}

.card.card-selected:after {
    content: "\f1c1";
    top: 3px;
    right: 5px;
    position: absolute;
    color: #fff;
    font-family: icons;
    font-weight: 900;
    font-size: 70%
}

.accordion {
    --bs-accordion-color: var(--bs-body-color);
    --bs-accordion-bg: var(--bs-body-bg);
    --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
    --bs-accordion-border-color: var(--bs-border-color);
    --bs-accordion-border-width: var(--bs-border-width);
    --bs-accordion-border-radius: var(--bs-border-radius);
    --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
    --bs-accordion-btn-padding-x: 1.25rem;
    --bs-accordion-btn-padding-y: 1rem;
    --bs-accordion-btn-color: var(--bs-body-color);
    --bs-accordion-btn-bg: var(--bs-accordion-bg);
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212121'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-icon-width: 1.25rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23866a55'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-focus-border-color: #d3c2b5;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 1px rgba(167, 133, 106, 0.25);
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    --bs-accordion-active-color: var(--bs-primary-text);
    --bs-accordion-active-bg: var(--bs-gray-100)
}

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: 1.125rem;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition)
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button {
        transition: none
    }
}

/* .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color)
} */


.accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition)
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button::after {
        transition: none
    }
}

.accordion-button:hover {
    z-index: 2
}

.accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow)
}

.accordion-header {
    margin-bottom: 0
}

.accordion-item {
    color: var(--bs-accordion-color);
    background-color: var(--bs-accordion-bg);
    border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color)
}

.accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius)
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: var(--bs-accordion-inner-border-radius);
    border-top-right-radius: var(--bs-accordion-inner-border-radius)
}

.accordion-item:not(:first-of-type) {
    border-top: 0
}

.accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius)
}

.accordion-body {
    padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x)
}

.accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.accordion-flush .accordion-item:first-child {
    border-top: 0
}

.accordion-flush .accordion-item:last-child {
    border-bottom: 0
}

[data-bs-theme=dark] .accordion-button::after {
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238caeec'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238caeec'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.breadcrumb {
    --bs-breadcrumb-padding-x: 0;
    --bs-breadcrumb-padding-y: 0;
    --bs-breadcrumb-margin-bottom: 1rem;
    --bs-breadcrumb-font-size: 85%;
    --bs-breadcrumb-divider-color: var(--bs-secondary-color);
    --bs-breadcrumb-item-padding-x: 0.5rem;
    --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
    display: flex;
    flex-wrap: wrap;
    padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
    margin-bottom: var(--bs-breadcrumb-margin-bottom);
    font-size: var(--bs-breadcrumb-font-size);
    list-style: none;
    background-color: var(--bs-breadcrumb-bg);
    border-radius: var(--bs-breadcrumb-border-radius)
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: var(--bs-breadcrumb-item-padding-x)
}

.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, "/")
}

.breadcrumb-item.active {
    color: var(--bs-breadcrumb-item-active-color)
}

[data-bs-theme=dark] .breadcrumb .breadcrumb-item a {
    color: var(--bs-text-light)
}

[data-bs-theme=dark] .breadcrumb .breadcrumb-item a:hover {
    color: var(--bs-primary)
}

.pagination {
    --bs-pagination-padding-x: 0.75rem;
    --bs-pagination-padding-y: 0.375rem;
    --bs-pagination-font-size: 1.125rem;
    --bs-pagination-color: var(--bs-link-color);
    --bs-pagination-bg: var(--bs-body-bg);
    --bs-pagination-border-width: var(--bs-border-width);
    --bs-pagination-border-color: var(--bs-border-color);
    --bs-pagination-border-radius: var(--bs-border-radius);
    --bs-pagination-hover-color: var(--bs-link-hover-color);
    --bs-pagination-hover-bg: var(--bs-tertiary-bg);
    --bs-pagination-hover-border-color: var(--bs-border-color);
    --bs-pagination-focus-color: var(--bs-link-hover-color);
    --bs-pagination-focus-bg: var(--bs-secondary-bg);
    --bs-pagination-focus-box-shadow: 0 0 0 1px rgba(167, 133, 106, 0.25);
    --bs-pagination-active-color: #fff;
    --bs-pagination-active-bg: #a7856a;
    --bs-pagination-active-border-color: #a7856a;
    --bs-pagination-disabled-color: var(--bs-secondary-color);
    --bs-pagination-disabled-bg: var(--bs-secondary-bg);
    --bs-pagination-disabled-border-color: var(--bs-border-color);
    display: flex;
    padding-left: 0;
    list-style: none
}

.page-link {
    position: relative;
    display: block;
    padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
    font-size: var(--bs-pagination-font-size);
    color: var(--bs-pagination-color);
    background-color: var(--bs-pagination-bg);
    border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .page-link {
        transition: none
    }
}

.page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color)
}

.page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow)
}

.active>.page-link,.page-link.active {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color)
}

.disabled>.page-link,.page-link.disabled {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color)
}

.page-item:not(:first-child) .page-link {
    margin-left: calc(var(--bs-border-width) * -1)
}

.page-item:first-child .page-link {
    border-top-left-radius: var(--bs-pagination-border-radius);
    border-bottom-left-radius: var(--bs-pagination-border-radius)
}

.page-item:last-child .page-link {
    border-top-right-radius: var(--bs-pagination-border-radius);
    border-bottom-right-radius: var(--bs-pagination-border-radius)
}

.pagination-lg {
    --bs-pagination-padding-x: 1.5rem;
    --bs-pagination-padding-y: 0.75rem;
    --bs-pagination-font-size: calc(1.265625rem + 0.1875vw);
    --bs-pagination-border-radius: 0.15rem
}

@media (min-width: 1200px) {
    .pagination-lg {
        --bs-pagination-font-size:1.40625rem
    }
}

.pagination-sm {
    --bs-pagination-padding-x: 0.5rem;
    --bs-pagination-padding-y: 0.25rem;
    --bs-pagination-font-size: 0.984375rem;
    --bs-pagination-border-radius: 0.115rem
}

.badge {
    --bs-badge-padding-x: 0.65em;
    --bs-badge-padding-y: 0.35em;
    --bs-badge-font-size: 0.75em;
    --bs-badge-font-weight: 400;
    --bs-badge-color: #fff;
    --bs-badge-border-radius: 0.135rem;
    display: inline-block;
    padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
    font-size: var(--bs-badge-font-size);
    font-weight: var(--bs-badge-font-weight);
    line-height: 1;
    color: var(--bs-badge-color);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: var(--bs-badge-border-radius)
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.alert-dismissible .btn-close {
    padding: 1.45rem 1.25rem!important;
    font-size: .85rem
}

.alert {
    --bs-alert-bg: transparent;
    --bs-alert-padding-x: 1rem;
    --bs-alert-padding-y: 1rem;
    --bs-alert-margin-bottom: 1rem;
    --bs-alert-color: inherit;
    --bs-alert-border-color: transparent;
    --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
    --bs-alert-border-radius: 0.135rem;
    --bs-alert-link-color: inherit;
    position: relative;
    padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
    margin-bottom: var(--bs-alert-margin-bottom);
    color: var(--bs-alert-color);
    background-color: var(--bs-alert-bg);
    border: var(--bs-alert-border);
    border-radius: var(--bs-alert-border-radius)
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 600;
    color: var(--bs-alert-link-color)
}

.alert-dismissible {
    padding-right: 3rem
}

.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem
}

.alert-primary {
    --bs-alert-color: var(--bs-primary-text);
    --bs-alert-bg: var(--bs-primary-bg-subtle);
    --bs-alert-border-color: var(--bs-primary-border-subtle);
    --bs-alert-link-color: var(--bs-primary-text)
}

.alert-secondary {
    --bs-alert-color: var(--bs-secondary-text);
    --bs-alert-bg: var(--bs-secondary-bg-subtle);
    --bs-alert-border-color: var(--bs-secondary-border-subtle);
    --bs-alert-link-color: var(--bs-secondary-text)
}

.alert-success {
    --bs-alert-color: var(--bs-success-text);
    --bs-alert-bg: var(--bs-success-bg-subtle);
    --bs-alert-border-color: var(--bs-success-border-subtle);
    --bs-alert-link-color: var(--bs-success-text)
}

.alert-info {
    --bs-alert-color: var(--bs-info-text);
    --bs-alert-bg: var(--bs-info-bg-subtle);
    --bs-alert-border-color: var(--bs-info-border-subtle);
    --bs-alert-link-color: var(--bs-info-text)
}

.alert-warning {
    --bs-alert-color: var(--bs-warning-text);
    --bs-alert-bg: var(--bs-warning-bg-subtle);
    --bs-alert-border-color: var(--bs-warning-border-subtle);
    --bs-alert-link-color: var(--bs-warning-text)
}

.alert-danger {
    --bs-alert-color: var(--bs-danger-text);
    --bs-alert-bg: var(--bs-danger-bg-subtle);
    --bs-alert-border-color: var(--bs-danger-border-subtle);
    --bs-alert-link-color: var(--bs-danger-text)
}

.alert-light {
    --bs-alert-color: var(--bs-light-text);
    --bs-alert-bg: var(--bs-light-bg-subtle);
    --bs-alert-border-color: var(--bs-light-border-subtle);
    --bs-alert-link-color: var(--bs-light-text)
}

.alert-dark {
    --bs-alert-color: var(--bs-dark-text);
    --bs-alert-bg: var(--bs-dark-bg-subtle);
    --bs-alert-border-color: var(--bs-dark-border-subtle);
    --bs-alert-link-color: var(--bs-dark-text)
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem
    }
}

.progress,.progress-stacked {
    --bs-progress-height: 1rem;
    --bs-progress-font-size: 0.84375rem;
    --bs-progress-bg: var(--bs-secondary-bg);
    --bs-progress-border-radius: var(--bs-border-radius);
    --bs-progress-box-shadow: var(--bs-box-shadow-inset);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #a7856a;
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius)
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: var(--bs-progress-bar-color);
    text-align: center;
    white-space: nowrap;
    background-color: var(--bs-progress-bar-bg);
    transition: var(--bs-progress-bar-transition)
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar {
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-size: var(--bs-progress-height) var(--bs-progress-height)
}

.progress-stacked>.progress {
    overflow: visible
}

.progress-stacked>.progress>.progress-bar {
    width: 100%
}

.progress-bar-animated {
    animation: 1s linear infinite progress-bar-stripes
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar-animated {
        animation: none
    }
}

.list-group {
    --bs-list-group-color: var(--bs-body-color);
    --bs-list-group-bg: var(--bs-body-bg);
    --bs-list-group-border-color: var(--bs-border-color);
    --bs-list-group-border-width: var(--bs-border-width);
    --bs-list-group-border-radius: var(--bs-border-radius);
    --bs-list-group-item-padding-x: 1rem;
    --bs-list-group-item-padding-y: 0.5rem;
    --bs-list-group-action-color: var(--bs-secondary-color);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
    --bs-list-group-action-active-color: var(--bs-body-color);
    --bs-list-group-action-active-bg: var(--bs-secondary-bg);
    --bs-list-group-disabled-color: var(--bs-secondary-color);
    --bs-list-group-disabled-bg: var(--bs-body-bg);
    --bs-list-group-active-color: #fff;
    --bs-list-group-active-bg: #a7856a;
    --bs-list-group-active-border-color: #a7856a;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: var(--bs-list-group-border-radius)
}

.list-group-numbered {
    list-style-type: none;
    counter-reset: section
}

.list-group-numbered>.list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section
}

.list-group-item-action {
    width: 100%;
    color: var(--bs-list-group-action-color);
    text-align: inherit
}

.list-group-item-action:focus,.list-group-item-action:hover {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg)
}

.list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg)
}

.list-group-item {
    position: relative;
    display: block;
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color)
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit
}

.list-group-item.disabled,.list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg)
}

.list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color)
}

.list-group-item+.list-group-item {
    border-top-width: 0
}

.list-group-item+.list-group-item.active {
    margin-top: calc(-1 * var(--bs-list-group-border-width));
    border-top-width: var(--bs-list-group-border-width)
}

.list-group-horizontal {
    flex-direction: row
}

.list-group-horizontal>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0
}

.list-group-horizontal>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0
}

.list-group-horizontal>.list-group-item.active {
    margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width)
}

@media (min-width: 576px) {
    .list-group-horizontal-sm {
        flex-direction:row
    }

    .list-group-horizontal-sm>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width)
    }
}

@media (min-width: 768px) {
    .list-group-horizontal-md {
        flex-direction:row
    }

    .list-group-horizontal-md>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-md>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-md>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width)
    }
}

@media (min-width: 992px) {
    .list-group-horizontal-lg {
        flex-direction:row
    }

    .list-group-horizontal-lg>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width)
    }
}

@media (min-width: 1260px) {
    .list-group-horizontal-xl {
        flex-direction:row
    }

    .list-group-horizontal-xl>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width)
    }
}

@media (min-width: 1400px) {
    .list-group-horizontal-xxl {
        flex-direction:row
    }

    .list-group-horizontal-xxl>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width)
    }
}

.list-group-flush {
    border-radius: 0
}

.list-group-flush>.list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width)
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0
}

.list-group-item-primary {
    --bs-list-group-color: var(--bs-primary-text);
    --bs-list-group-bg: var(--bs-primary-bg-subtle);
    --bs-list-group-border-color: var(--bs-primary-border-subtle)
}

.list-group-item-primary.list-group-item-action:focus,.list-group-item-primary.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle)
}

.list-group-item-primary.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-primary-text);
    --bs-list-group-active-border-color: var(--bs-primary-text)
}

.list-group-item-secondary {
    --bs-list-group-color: var(--bs-secondary-text);
    --bs-list-group-bg: var(--bs-secondary-bg-subtle);
    --bs-list-group-border-color: var(--bs-secondary-border-subtle)
}

.list-group-item-secondary.list-group-item-action:focus,.list-group-item-secondary.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle)
}

.list-group-item-secondary.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-secondary-text);
    --bs-list-group-active-border-color: var(--bs-secondary-text)
}

.list-group-item-success {
    --bs-list-group-color: var(--bs-success-text);
    --bs-list-group-bg: var(--bs-success-bg-subtle);
    --bs-list-group-border-color: var(--bs-success-border-subtle)
}

.list-group-item-success.list-group-item-action:focus,.list-group-item-success.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-success-border-subtle)
}

.list-group-item-success.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-success-text);
    --bs-list-group-active-border-color: var(--bs-success-text)
}

.list-group-item-info {
    --bs-list-group-color: var(--bs-info-text);
    --bs-list-group-bg: var(--bs-info-bg-subtle);
    --bs-list-group-border-color: var(--bs-info-border-subtle)
}

.list-group-item-info.list-group-item-action:focus,.list-group-item-info.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-info-border-subtle)
}

.list-group-item-info.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-info-text);
    --bs-list-group-active-border-color: var(--bs-info-text)
}

.list-group-item-warning {
    --bs-list-group-color: var(--bs-warning-text);
    --bs-list-group-bg: var(--bs-warning-bg-subtle);
    --bs-list-group-border-color: var(--bs-warning-border-subtle)
}

.list-group-item-warning.list-group-item-action:focus,.list-group-item-warning.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle)
}

.list-group-item-warning.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-warning-text);
    --bs-list-group-active-border-color: var(--bs-warning-text)
}

.list-group-item-danger {
    --bs-list-group-color: var(--bs-danger-text);
    --bs-list-group-bg: var(--bs-danger-bg-subtle);
    --bs-list-group-border-color: var(--bs-danger-border-subtle)
}

.list-group-item-danger.list-group-item-action:focus,.list-group-item-danger.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle)
}

.list-group-item-danger.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-danger-text);
    --bs-list-group-active-border-color: var(--bs-danger-text)
}

.list-group-item-light {
    --bs-list-group-color: var(--bs-light-text);
    --bs-list-group-bg: var(--bs-light-bg-subtle);
    --bs-list-group-border-color: var(--bs-light-border-subtle)
}

.list-group-item-light.list-group-item-action:focus,.list-group-item-light.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-light-border-subtle)
}

.list-group-item-light.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-light-text);
    --bs-list-group-active-border-color: var(--bs-light-text)
}

.list-group-item-dark {
    --bs-list-group-color: var(--bs-dark-text);
    --bs-list-group-bg: var(--bs-dark-bg-subtle);
    --bs-list-group-border-color: var(--bs-dark-border-subtle)
}

.list-group-item-dark.list-group-item-action:focus,.list-group-item-dark.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle)
}

.list-group-item-dark.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-dark-text);
    --bs-list-group-active-border-color: var(--bs-dark-text)
}

.btn-close {
    --bs-btn-close-color: #111;
    --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23111'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
    --bs-btn-close-opacity: 0.5;
    --bs-btn-close-hover-opacity: 0.75;
    --bs-btn-close-focus-shadow: 0 0 0 1px rgba(167, 133, 106, 0.25);
    --bs-btn-close-focus-opacity: 1;
    --bs-btn-close-disabled-opacity: 0.25;
    --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: var(--bs-btn-close-color);
    background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
    border: 0;
    border-radius: .135rem;
    opacity: var(--bs-btn-close-opacity)
}

.btn-close:hover {
    color: var(--bs-btn-close-color);
    text-decoration: none;
    opacity: var(--bs-btn-close-hover-opacity)
}

.btn-close:focus {
    outline: 0;
    box-shadow: var(--bs-btn-close-focus-shadow);
    opacity: var(--bs-btn-close-focus-opacity)
}

.btn-close.disabled,.btn-close:disabled {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    opacity: var(--bs-btn-close-disabled-opacity)
}

.btn-close-white {
    filter: var(--bs-btn-close-white-filter)
}

[data-bs-theme=dark] .btn-close {
    filter: var(--bs-btn-close-white-filter)
}

.modal {
    --bs-modal-zindex: 1055;
    --bs-modal-width: 500px;
    --bs-modal-padding: 1.5rem;
    --bs-modal-margin: 0.5rem;
    --bs-modal-bg: var(--bs-body-bg);
    --bs-modal-border-color: var(--bs-border-color-translucent);
    --bs-modal-border-width: var(--bs-border-width);
    --bs-modal-border-radius: var(--bs-border-radius);
    --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-body-color-rgb), 0.075);
    --bs-modal-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
    --bs-modal-header-padding-x: 1.5rem;
    --bs-modal-header-padding-y: 1.5rem;
    --bs-modal-header-padding: 1.5rem 1.5rem;
    --bs-modal-header-border-color: var(--bs-border-color);
    --bs-modal-header-border-width: var(--bs-border-width);
    --bs-modal-title-line-height: 1.5;
    --bs-modal-footer-gap: 0.5rem;
    --bs-modal-footer-border-color: var(--bs-border-color);
    --bs-modal-footer-border-width: var(--bs-border-width);
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-modal-zindex);
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: var(--bs-modal-margin);
    pointer-events: none
}

.modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0,-50px)
}

@media (prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
}

.modal.show .modal-dialog {
    transform: none
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    height: calc(100% - var(--bs-modal-margin) * 2)
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - var(--bs-modal-margin) * 2)
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0
}

.modal-backdrop {
    --bs-backdrop-zindex: 1050;
    --bs-backdrop-bg: #111;
    --bs-backdrop-opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-backdrop-zindex);
    width: 100vw;
    height: 100vh;
    background-color: var(--bs-backdrop-bg)
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity)
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: var(--bs-modal-header-padding);
    border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
    border-top-left-radius: var(--bs-modal-inner-border-radius);
    border-top-right-radius: var(--bs-modal-inner-border-radius)
}

.modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
    margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto
}

.modal-title {
    margin-bottom: 0;
    line-height: var(--bs-modal-title-line-height)
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: var(--bs-modal-padding)
}

.modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
    background-color: var(--bs-modal-footer-bg);
    border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
    border-bottom-right-radius: var(--bs-modal-inner-border-radius);
    border-bottom-left-radius: var(--bs-modal-inner-border-radius)
}

.modal-footer>* {
    margin: calc(var(--bs-modal-footer-gap) * .5)
}

@media (min-width: 576px) {
    .modal {
        --bs-modal-margin:1.75rem;
        --bs-modal-box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15)
    }

    .modal-dialog {
        max-width: var(--bs-modal-width);
        margin-right: auto;
        margin-left: auto
    }

    .modal-sm {
        --bs-modal-width: 300px
    }
}

@media (min-width: 992px) {
    .modal-lg,.modal-xl {
        --bs-modal-width:800px
    }
}

@media (min-width: 1260px) {
    .modal-xl {
        --bs-modal-width:1140px
    }
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
}

.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
}

.modal-fullscreen .modal-footer,.modal-fullscreen .modal-header {
    border-radius: 0
}

.modal-fullscreen .modal-body {
    overflow-y: auto
}

@media (max-width: 575.98px) {
    .modal-fullscreen-sm-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-sm-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-footer,.modal-fullscreen-sm-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width: 767.98px) {
    .modal-fullscreen-md-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-md-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-footer,.modal-fullscreen-md-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width: 991.98px) {
    .modal-fullscreen-lg-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-lg-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-footer,.modal-fullscreen-lg-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width: 1259.98px) {
    .modal-fullscreen-xl-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-footer,.modal-fullscreen-xl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width: 1399.98px) {
    .modal-fullscreen-xxl-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-footer,.modal-fullscreen-xxl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto
    }
}

.carousel {
    position: relative
}

.carousel.pointer-event {
    touch-action: pan-y
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item-next,.carousel-item-prev,.carousel-item.active {
    display: block
}

.active.carousel-item-end,.carousel-item-next:not(.carousel-item-start) {
    transform: translateX(100%)
}

.active.carousel-item-start,.carousel-item-prev:not(.carousel-item-end) {
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none
}

.carousel-fade .carousel-item-next.carousel-item-start,.carousel-fade .carousel-item-prev.carousel-item-end,.carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-end,.carousel-fade .active.carousel-item-start {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s .6s
}

@media (prefers-reduced-motion:reduce) {
    .carousel-fade .active.carousel-item-end,.carousel-fade .active.carousel-item-start {
        transition: none
    }
}

.carousel-control-next,.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease
}

@media (prefers-reduced-motion:reduce) {
    .carousel-control-next,.carousel-control-prev {
        transition: none
    }
}

.carousel-control-next:focus,.carousel-control-next:hover,.carousel-control-prev:focus,.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon,.carousel-control-prev-icon {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 24px;
    height: 6px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 2rem solid transparent;
    border-bottom: 2rem solid transparent;
    opacity: .35;
    transition: opacity .6s ease
}

@media (prefers-reduced-motion:reduce) {
    .carousel-indicators [data-bs-target] {
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: unset;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center
}

.carousel-dark .carousel-control-next-icon,.carousel-dark .carousel-control-prev-icon {
    filter: invert(1) grayscale(100)
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #111
}

.carousel-dark .carousel-caption {
    color: #111
}

[data-bs-theme=dark] .carousel .carousel-control-next-icon,[data-bs-theme=dark] .carousel .carousel-control-prev-icon {
    filter: invert(1) grayscale(100)
}

[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target] {
    background-color: #111
}

[data-bs-theme=dark] .carousel .carousel-caption {
    color: #111
}

.spinner-border,.spinner-grow {
    display: inline-block;
    width: var(--bs-spinner-width);
    height: var(--bs-spinner-height);
    vertical-align: var(--bs-spinner-vertical-align);
    border-radius: 50%;
    animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name)
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg)
    }
}

.spinner-border {
    --bs-spinner-width: 2rem;
    --bs-spinner-height: 2rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-border-width: 0.25em;
    --bs-spinner-animation-speed: 1s;
    --bs-spinner-animation-name: spinner-border;
    border: var(--bs-spinner-border-width) solid currentcolor;
    border-right-color: transparent
}

.spinner-border-sm {
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem;
    --bs-spinner-border-width: 0.2em
}

@keyframes spinner-grow {
    0% {
        transform: scale(0)
    }

    50% {
        opacity: 1;
        transform: none
    }
}

.spinner-grow {
    --bs-spinner-width: 2rem;
    --bs-spinner-height: 2rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-animation-speed: 1s;
    --bs-spinner-animation-name: spinner-grow;
    background-color: currentcolor;
    opacity: 0
}

.spinner-grow-sm {
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem
}

@media (prefers-reduced-motion:reduce) {
    .spinner-border,.spinner-grow {
        --bs-spinner-animation-speed: 2s
    }
}

.offcanvas,.offcanvas-lg,.offcanvas-md,.offcanvas-sm,.offcanvas-xl,.offcanvas-xxl {
    --bs-offcanvas-zindex: 1045;
    --bs-offcanvas-width: 400px;
    --bs-offcanvas-height: 30vh;
    --bs-offcanvas-padding-x: 1.5rem;
    --bs-offcanvas-padding-y: 1.5rem;
    --bs-offcanvas-color: var(--bs-body-color);
    --bs-offcanvas-bg: var(--bs-body-bg);
    --bs-offcanvas-border-width: var(--bs-border-width);
    --bs-offcanvas-border-color: var(--bs-border-color-translucent);
    --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-body-color-rgb), 0.075);
    --bs-offcanvas-transition: transform 0.3s ease-in-out;
    --bs-offcanvas-title-line-height: 1.5
}

@media (max-width: 575.98px) {
    .offcanvas-sm {
        position:fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: var(--bs-offcanvas-transition)
    }
}

@media (max-width: 575.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-sm {
        transition:none
    }
}

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-start {
        top:0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(-100%)
    }
}

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-end {
        top:0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(100%)
    }
}

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-top {
        top:0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(-100%)
    }
}

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-bottom {
        right:0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }
}

@media (max-width: 575.98px) {
    .offcanvas-sm.show:not(.hiding),.offcanvas-sm.showing {
        transform:none
    }
}

@media (max-width: 575.98px) {
    .offcanvas-sm.hiding,.offcanvas-sm.show,.offcanvas-sm.showing {
        visibility:visible
    }
}

@media (min-width: 576px) {
    .offcanvas-sm {
        --bs-offcanvas-height:auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent!important
    }

    .offcanvas-sm .offcanvas-header {
        display: none
    }

    .offcanvas-sm .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent!important
    }
}

@media (max-width: 767.98px) {
    .offcanvas-md {
        position:fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: var(--bs-offcanvas-transition)
    }
}

@media (max-width: 767.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-md {
        transition:none
    }
}

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-start {
        top:0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(-100%)
    }
}

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-end {
        top:0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(100%)
    }
}

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-top {
        top:0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(-100%)
    }
}

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-bottom {
        right:0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }
}

@media (max-width: 767.98px) {
    .offcanvas-md.show:not(.hiding),.offcanvas-md.showing {
        transform:none
    }
}

@media (max-width: 767.98px) {
    .offcanvas-md.hiding,.offcanvas-md.show,.offcanvas-md.showing {
        visibility:visible
    }
}

@media (min-width: 768px) {
    .offcanvas-md {
        --bs-offcanvas-height:auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent!important
    }

    .offcanvas-md .offcanvas-header {
        display: none
    }

    .offcanvas-md .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent!important
    }
}

@media (max-width: 991.98px) {
    .offcanvas-lg {
        position:fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: var(--bs-offcanvas-transition)
    }
}

@media (max-width: 991.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-lg {
        transition:none
    }
}

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-start {
        top:0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(-100%)
    }
}

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-end {
        top:0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(100%)
    }
}

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-top {
        top:0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(-100%)
    }
}

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-bottom {
        right:0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }
}

@media (max-width: 991.98px) {
    .offcanvas-lg.show:not(.hiding),.offcanvas-lg.showing {
        transform:none
    }
}

@media (max-width: 991.98px) {
    .offcanvas-lg.hiding,.offcanvas-lg.show,.offcanvas-lg.showing {
        visibility:visible
    }
}

@media (min-width: 992px) {
    .offcanvas-lg {
        --bs-offcanvas-height:auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent!important
    }

    .offcanvas-lg .offcanvas-header {
        display: none
    }

    .offcanvas-lg .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent!important
    }
}

@media (max-width: 1259.98px) {
    .offcanvas-xl {
        position:fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: var(--bs-offcanvas-transition)
    }
}

@media (max-width: 1259.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-xl {
        transition:none
    }
}

@media (max-width: 1259.98px) {
    .offcanvas-xl.offcanvas-start {
        top:0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(-100%)
    }
}

@media (max-width: 1259.98px) {
    .offcanvas-xl.offcanvas-end {
        top:0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(100%)
    }
}

@media (max-width: 1259.98px) {
    .offcanvas-xl.offcanvas-top {
        top:0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(-100%)
    }
}

@media (max-width: 1259.98px) {
    .offcanvas-xl.offcanvas-bottom {
        right:0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }
}

@media (max-width: 1259.98px) {
    .offcanvas-xl.show:not(.hiding),.offcanvas-xl.showing {
        transform:none
    }
}

@media (max-width: 1259.98px) {
    .offcanvas-xl.hiding,.offcanvas-xl.show,.offcanvas-xl.showing {
        visibility:visible
    }
}

@media (min-width: 1260px) {
    .offcanvas-xl {
        --bs-offcanvas-height:auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent!important
    }

    .offcanvas-xl .offcanvas-header {
        display: none
    }

    .offcanvas-xl .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent!important
    }
}

@media (max-width: 1399.98px) {
    .offcanvas-xxl {
        position:fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: var(--bs-offcanvas-transition)
    }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-xxl {
        transition:none
    }
}

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-start {
        top:0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(-100%)
    }
}

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-end {
        top:0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(100%)
    }
}

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-top {
        top:0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(-100%)
    }
}

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-bottom {
        right:0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }
}

@media (max-width: 1399.98px) {
    .offcanvas-xxl.show:not(.hiding),.offcanvas-xxl.showing {
        transform:none
    }
}

@media (max-width: 1399.98px) {
    .offcanvas-xxl.hiding,.offcanvas-xxl.show,.offcanvas-xxl.showing {
        visibility:visible
    }
}

@media (min-width: 1400px) {
    .offcanvas-xxl {
        --bs-offcanvas-height:auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent!important
    }

    .offcanvas-xxl .offcanvas-header {
        display: none
    }

    .offcanvas-xxl .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent!important
    }
}

.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition)
}

@media (prefers-reduced-motion:reduce) {
    .offcanvas {
        transition: none
    }
}

.offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%)
}

.offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%)
}

.offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%)
}

.offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%)
}

.offcanvas.show:not(.hiding),.offcanvas.showing {
    transform: none
}

.offcanvas.hiding,.offcanvas.show,.offcanvas.showing {
    visibility: visible
}

.offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #111
}

.offcanvas-backdrop.fade {
    opacity: 0
}

.offcanvas-backdrop.show {
    opacity: .5
}

.offcanvas-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x)
}

.offcanvas-header .btn-close {
    padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
    margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
    margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
    margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y))
}

.offcanvas-title {
    margin-bottom: 0;
    line-height: var(--bs-offcanvas-title-line-height)
}

.offcanvas-body {
    flex-grow: 1;
    padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
    overflow-y: auto
}

.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

.text-bg-primary {
    color: #fff!important;
    background-color: RGBA(167,133,106,var(--bs-bg-opacity,1))!important
}

.text-bg-secondary {
    color: #fff!important;
    background-color: RGBA(108,108,108,var(--bs-bg-opacity,1))!important
}

.text-bg-success {
    color: #fff!important;
    background-color: RGBA(90,183,123,var(--bs-bg-opacity,1))!important
}

.text-bg-info {
    color: #fff!important;
    background-color: RGBA(94,185,240,var(--bs-bg-opacity,1))!important
}

.text-bg-warning {
    color: #fff!important;
    background-color: RGBA(238,180,97,var(--bs-bg-opacity,1))!important
}

.text-bg-danger {
    color: #fff!important;
    background-color: RGBA(227,93,93,var(--bs-bg-opacity,1))!important
}

.text-bg-light {
    color: #111!important;
    background-color: RGBA(248,248,248,var(--bs-bg-opacity,1))!important
}

.text-bg-dark {
    color: #fff!important;
    background-color: RGBA(33,33,33,var(--bs-bg-opacity,1))!important
}

.link-primary {
    color: #a7856a!important
}

.link-primary:focus,.link-primary:hover {
    color: #866a55!important
}

.link-secondary {
    color: #6c6c6c!important
}

.link-secondary:focus,.link-secondary:hover {
    color: #565656!important
}

.link-success {
    color: #5ab77b!important
}

.link-success:focus,.link-success:hover {
    color: #489262!important
}

.link-info {
    color: #5eb9f0!important
}

.link-info:focus,.link-info:hover {
    color: #4b94c0!important
}

.link-warning {
    color: #eeb461!important
}

.link-warning:focus,.link-warning:hover {
    color: #be904e!important
}

.link-danger {
    color: #e35d5d!important
}

.link-danger:focus,.link-danger:hover {
    color: #b64a4a!important
}

.link-light {
    color: #f8f8f8!important
}

.link-light:focus,.link-light:hover {
    color: #f9f9f9!important
}

.link-dark {
    color: #212121!important
}

.link-dark:focus,.link-dark:hover {
    color: #1a1a1a!important
}

.ratio {
    position: relative;
    width: 100%
}

.ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""
}

.ratio>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.ratio-1x1 {
    --bs-aspect-ratio: 100%
}

.ratio-4x3 {
    --bs-aspect-ratio: 75%
}

.ratio-16x9 {
    --bs-aspect-ratio: 56.25%
}

.ratio-21x9 {
    --bs-aspect-ratio: 42.8571428571%
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020
}

.sticky-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020
}

@media (min-width: 576px) {
    .sticky-sm-top {
        position:sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-sm-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width: 768px) {
    .sticky-md-top {
        position:sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-md-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width: 992px) {
    .sticky-lg-top {
        position:sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-lg-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width: 1260px) {
    .sticky-xl-top {
        position:sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-xl-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width: 1400px) {
    .sticky-xxl-top {
        position:sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-xxl-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

.hstack {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch
}

.vstack {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-self: stretch
}

.visually-hidden,.visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute!important;
    width: 1px!important;
    height: 1px!important;
    padding: 0!important;
    margin: -1px!important;
    overflow: hidden!important;
    clip: rect(0,0,0,0)!important;
    white-space: nowrap!important;
    border: 0!important
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: ""
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.vr {
    display: inline-block;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentcolor;
    opacity: .25
}

.link-hover-primary {
    --bs-link-hover-primary: #a7856a
}

.link-hover-primary:focus,.link-hover-primary:hover {
    color: var(--bs-link-hover-primary)!important
}

.image-wrapper {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    z-index: 0
}

.image-wrapper:not(.mobile) {
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed!important
}

.image-wrapper.bg-auto {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto;
    background-attachment: scroll!important
}

.image-wrapper.bg-full {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: "100%";
    background-attachment: scroll!important
}

.image-wrapper.bg-cover {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: scroll!important
}

.image-wrapper.bg-overlay:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: rgba(17,17,17,.5)
}

.image-wrapper.bg-overlay-300:after {
    background: rgba(17,17,17,.3)
}

.image-wrapper.bg-overlay-300-light:after {
    background: rgba(255,255,255,.3)
}

.image-wrapper.bg-overlay-400:after {
    background: rgba(17,17,17,.4)
}

.image-wrapper.bg-overlay-400-light:after {
    background: rgba(255,255,255,.4)
}

.image-wrapper.bg-overlay-500:after {
    background: rgba(17,17,17,.5)
}

.image-wrapper.bg-overlay-500-light:after {
    background: rgba(255,255,255,.5)
}

.image-wrapper.bg-overlay-600:after {
    background: rgba(17,17,17,.6)
}

.image-wrapper.bg-overlay-600-light:after {
    background: rgba(255,255,255,.6)
}

.image-wrapper.bg-overlay-700:after {
    background: rgba(17,17,17,.7)
}

.image-wrapper.bg-overlay-700-light:after {
    background: rgba(255,255,255,.7)
}

.image-wrapper.bg-overlay-800:after {
    background: rgba(17,17,17,.8)
}

.image-wrapper.bg-overlay-800-light:after {
    background: rgba(255,255,255,.8)
}

.image-wrapper.bg-overlay-900:after {
    background: rgba(17,17,17,.9)
}

.image-wrapper.bg-overlay-900-light:after {
    background: rgba(255,255,255,.9)
}

.image-wrapper.bg-overlay .bg-content {
    position: relative;
    z-index: 2;
    height: 100%
}

.image-wrapper.card:before {
    border-radius: .135rem!important
}

@media (max-width: 991.98px) {
    .image-wrapper.bg-full {
        background-repeat:no-repeat;
        background-position: center center;
        background-size: cover
    }
}

.video-wrapper {
    position: relative;
    overflow: hidden
}

.video-wrapper.bg-overlay:after {
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    position: absolute;
    background-color: rgba(17,17,17,.35)
}

.video-wrapper.bg-overlay-300:after {
    background: rgba(17,17,17,.3)
}

.video-wrapper.bg-overlay-300-light:after {
    background: rgba(255,255,255,.3)
}

.video-wrapper.bg-overlay-400:after {
    background: rgba(17,17,17,.4)
}

.video-wrapper.bg-overlay-400-light:after {
    background: rgba(255,255,255,.4)
}

.video-wrapper.bg-overlay-500:after {
    background: rgba(17,17,17,.5)
}

.video-wrapper.bg-overlay-500-light:after {
    background: rgba(255,255,255,.5)
}

.video-wrapper.bg-overlay-600:after {
    background: rgba(17,17,17,.6)
}

.video-wrapper.bg-overlay-600-light:after {
    background: rgba(255,255,255,.6)
}

.video-wrapper.bg-overlay-700:after {
    background: rgba(17,17,17,.7)
}

.video-wrapper.bg-overlay-700-light:after {
    background: rgba(255,255,255,.7)
}

.video-wrapper.bg-overlay-800:after {
    background: rgba(17,17,17,.8)
}

.video-wrapper.bg-overlay-800-light:after {
    background: rgba(255,255,255,.8)
}

.video-wrapper.bg-overlay-900:after {
    background: rgba(17,17,17,.9)
}

.video-wrapper.bg-overlay-900-light:after {
    background: rgba(255,255,255,.9)
}

.video-wrapper.ratio::before {
    content: unset
}

.video-wrapper.bg-overlay .bg-content {
    position: relative;
    z-index: 2;
    height: 100%
}

@keyframes slideIn {
    0% {
        transform: translateY(-1rem);
        opacity: 0
    }

    100% {
        transform: translateY(0);
        opacity: 1
    }
}

.slideIn {
    -webkit-animation-name: slideIn;
    -webkit-animation-duration: .35s;
    -webkit-animation-delay: 0s;
    -webkit-animation-fill-mode: both;
    -moz-animation-name: slideIn;
    -moz-animation-duration: .35s;
    -moz-animation-delay: 0s;
    -moz-animation-fill-mode: both;
    -ms-animation-name: slideIn;
    -ms-animation-duration: .35s;
    -ms-animation-delay: 0s;
    -ms-animation-fill-mode: both;
    -o-animation-name: slideIn;
    -o-animation-duration: .35s;
    -o-animation-delay: 0s;
    -o-animation-fill-mode: both;
    animation-name: slideIn;
    animation-duration: .35s;
    animation-delay: 0s;
    animation-fill-mode: both
}

.upDown {
    -webkit-animation-name: mover;
    -webkit-animation-duration: .75s;
    -webkit-animation-delay: 0s;
    -webkit-animation-fill-mode: both;
    -moz-animation-name: mover;
    -moz-animation-duration: .75s;
    -moz-animation-delay: 0s;
    -moz-animation-fill-mode: both;
    -ms-animation-name: mover;
    -ms-animation-duration: .75s;
    -ms-animation-delay: 0s;
    -ms-animation-fill-mode: both;
    -o-animation-name: mover;
    -o-animation-duration: .75s;
    -o-animation-delay: 0s;
    -o-animation-fill-mode: both;
    animation-name: mover;
    animation-duration: .75s;
    animation-delay: 0s;
    animation-fill-mode: both
}

@keyframes mover {
    0% {
        transform: translateY(0)
    }

    100% {
        transform: translateY(-15px)
    }
}

.star-rate-view {
    position: relative;
    display: inline-block;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDguOSIgaGVpZ2h0PSIxMDMuNiIgdmlld0JveD0iMCAwIDEwOC45IDEwMy42Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2UzZTZlNjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPnN0YXJfMDwvdGl0bGU+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzEtMiIgZGF0YS1uYW1lPSJMYXllciAxIj48cG9seWdvbiBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMTA4LjkgMzkuNiA3MS4zIDM0LjEgNTQuNCAwIDM3LjYgMzQuMSAwIDM5LjYgMjcuMiA2Ni4xIDIwLjggMTAzLjYgNTQuNCA4NS45IDg4LjEgMTAzLjYgODEuNyA2Ni4xIDEwOC45IDM5LjYiLz48L2c+PC9nPjwvc3ZnPg0K);
    background-position: 0 0;
    background-repeat: repeat-x;
    text-align: left
}

.star-rate-view.star-rate-size-sm {
    width: 80px;
    height: 16px;
    background-size: 16px
}

.star-rate-view,.star-rate-view.star-rate-size-md {
    width: 120px;
    height: 24px;
    background-size: 24px
}

.star-rate-view.star-rate-size-lg {
    width: 160px;
    height: 32px;
    background-size: 32px
}

.star-rate-view .star-value {
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url(data:image/svg+xml;base64,PHN2Zw0KCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwOC45IiBoZWlnaHQ9IjEwMy42IiB2aWV3Qm94PSIwIDAgMTA4LjkgMTAzLjYiPg0KCTxkZWZzPg0KCQk8c3R5bGU+LmNscy0xe2ZpbGw6I2YxYzk0Nzt9PC9zdHlsZT4NCgk8L2RlZnM+DQoJPHRpdGxlPnN0YXIxPC90aXRsZT4NCgk8ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIj4NCgkJPGcgaWQ9IkxheWVyXzEtMiIgZGF0YS1uYW1lPSJMYXllciAxIj4NCgkJCTxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSI1NC40IDAgNzEuMyAzNC4xIDEwOC45IDM5LjYgODEuNyA2Ni4xIDg4LjEgMTAzLjYgNTQuNCA4NS45IDIwLjggMTAzLjYgMjcuMiA2Ni4xIDAgMzkuNiAzNy42IDM0LjEgNTQuNCAwIi8+DQoJCTwvZz4NCgk8L2c+DQo8L3N2Zz4NCg==);
    background-repeat: repeat-x
}

.star-rate-view .star-value.rate-0 {
    width: 0%
}

.star-rate-view .star-value.rate-5 {
    width: 10%
}

.star-rate-view .star-value.rate-10 {
    width: 20%
}

.star-rate-view .star-value.rate-15 {
    width: 30%
}

.star-rate-view .star-value.rate-20 {
    width: 40%
}

.star-rate-view .star-value.rate-25 {
    width: 50%
}

.star-rate-view .star-value.rate-30 {
    width: 60%
}

.star-rate-view .star-value.rate-35 {
    width: 70%
}

.star-rate-view .star-value.rate-40 {
    width: 80%
}

.star-rate-view .star-value.rate-45 {
    width: 90%
}

.star-rate-view .star-value.rate-50 {
    width: 100%
}

.star-rate-view.star-rate-size-sm .star-value {
    background-size: 16px
}

.star-rate-view .star-value,.star-rate-view.star-rate-size-md .star-value {
    background-size: 24px
}

.star-rate-view.star-rate-size-lg .star-value {
    background-size: 32px
}

.sticky-top-120 {
    top: 120px!important
}

.align-baseline {
    vertical-align: baseline!important
}

.align-top {
    vertical-align: top!important
}

.align-middle {
    vertical-align: middle!important
}

.align-bottom {
    vertical-align: bottom!important
}

.align-text-bottom {
    vertical-align: text-bottom!important
}

.align-text-top {
    vertical-align: text-top!important
}

.float-start {
    float: left!important
}

.float-end {
    float: right!important
}

.float-none {
    float: none!important
}

.object-fit-contain {
    -o-object-fit: contain!important;
    object-fit: contain!important
}

.object-fit-cover {
    -o-object-fit: cover!important;
    object-fit: cover!important
}

.object-fit-fill {
    -o-object-fit: fill!important;
    object-fit: fill!important
}

.object-fit-scale {
    -o-object-fit: scale-down!important;
    object-fit: scale-down!important
}

.object-fit-none {
    -o-object-fit: none!important;
    object-fit: none!important
}

.opacity-0 {
    opacity: 0!important
}

.opacity-25 {
    opacity: .25!important
}

.opacity-50 {
    opacity: .5!important
}

.opacity-75 {
    opacity: .75!important
}

.opacity-100 {
    opacity: 1!important
}

.overflow-auto {
    overflow: auto!important
}

.overflow-hidden {
    overflow: hidden!important
}

.overflow-visible {
    overflow: visible!important
}

.overflow-scroll {
    overflow: scroll!important
}

.overflow-x-auto {
    overflow-x: auto!important
}

.overflow-x-hidden {
    overflow-x: hidden!important
}

.overflow-x-visible {
    overflow-x: visible!important
}

.overflow-x-scroll {
    overflow-x: scroll!important
}

.overflow-y-auto {
    overflow-y: auto!important
}

.overflow-y-hidden {
    overflow-y: hidden!important
}

.overflow-y-visible {
    overflow-y: visible!important
}

.overflow-y-scroll {
    overflow-y: scroll!important
}

.d-inline {
    display: inline!important
}

.d-inline-block {
    display: inline-block!important
}

.d-block {
    display: block!important
}

.d-grid {
    display: grid!important
}

.d-table {
    display: table!important
}

.d-table-row {
    display: table-row!important
}

.d-table-cell {
    display: table-cell!important
}

.d-flex {
    display: flex!important
}

.d-inline-flex {
    display: inline-flex!important
}

.d-none {
    display: none!important
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(var(--bs-body-color-rgb),.15)!important
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(var(--bs-body-color-rgb),.075)!important
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(var(--bs-body-color-rgb),.175)!important
}

.shadow-none {
    box-shadow: none!important
}

.position-static {
    position: static!important
}

.position-relative {
    position: relative!important
}

.position-absolute {
    position: absolute!important
}

.position-fixed {
    position: fixed!important
}

.position-sticky {
    position: sticky!important
}

.top-0 {
    top: 0!important
}

.top-25 {
    top: 25%!important
}

.top-33 {
    top: 33.333333%!important
}

.top-50 {
    top: 50%!important
}

.top-66 {
    top: 66.666666%!important
}

.top-100 {
    top: 100%!important
}

.bottom-0 {
    bottom: 0!important
}

.bottom-25 {
    bottom: 25%!important
}

.bottom-33 {
    bottom: 33.333333%!important
}

.bottom-50 {
    bottom: 50%!important
}

.bottom-66 {
    bottom: 66.666666%!important
}

.bottom-100 {
    bottom: 100%!important
}

.start-0 {
    left: 0!important
}

.start-25 {
    left: 25%!important
}

.start-33 {
    left: 33.333333%!important
}

.start-50 {
    left: 50%!important
}

.start-66 {
    left: 66.666666%!important
}

.start-100 {
    left: 100%!important
}

.end-0 {
    right: 0!important
}

.end-25 {
    right: 25%!important
}

.end-33 {
    right: 33.333333%!important
}

.end-50 {
    right: 50%!important
}

.end-66 {
    right: 66.666666%!important
}

.end-100 {
    right: 100%!important
}

.translate-middle {
    transform: translate(-50%,-50%)!important
}

.translate-middle-x {
    transform: translateX(-50%)!important
}

.translate-middle-y {
    transform: translateY(-50%)!important
}

.border {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important
}

.border-0 {
    border: 0!important
}

.border-top {
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important
}

.border-top-0 {
    border-top: 0!important
}

.border-end {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important
}

.border-end-0 {
    border-right: 0!important
}

.border-bottom {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important
}

.border-bottom-0 {
    border-bottom: 0!important
}

.border-start {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important
}

.border-start-0 {
    border-left: 0!important
}

.border-primary {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-primary-rgb),var(--bs-border-opacity))!important
}

.border-secondary {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-secondary-rgb),var(--bs-border-opacity))!important
}

.border-success {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-success-rgb),var(--bs-border-opacity))!important
}

.border-info {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-info-rgb),var(--bs-border-opacity))!important
}

.border-warning {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-warning-rgb),var(--bs-border-opacity))!important
}

.border-danger {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-danger-rgb),var(--bs-border-opacity))!important
}

.border-light {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-light-rgb),var(--bs-border-opacity))!important
}

.border-dark {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-dark-rgb),var(--bs-border-opacity))!important
}

.border-white {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-white-rgb),var(--bs-border-opacity))!important
}

.border-primary-subtle {
    border-color: var(--bs-primary-border-subtle)!important
}

.border-secondary-subtle {
    border-color: var(--bs-secondary-border-subtle)!important
}

.border-success-subtle {
    border-color: var(--bs-success-border-subtle)!important
}

.border-info-subtle {
    border-color: var(--bs-info-border-subtle)!important
}

.border-warning-subtle {
    border-color: var(--bs-warning-border-subtle)!important
}

.border-danger-subtle {
    border-color: var(--bs-danger-border-subtle)!important
}

.border-light-subtle {
    border-color: var(--bs-light-border-subtle)!important
}

.border-dark-subtle {
    border-color: var(--bs-dark-border-subtle)!important
}

.border-1 {
    --bs-border-width: 1px
}

.border-2 {
    --bs-border-width: 2px
}

.border-3 {
    --bs-border-width: 3px
}

.border-4 {
    --bs-border-width: 4px
}

.border-5 {
    --bs-border-width: 5px
}

.border-opacity-10 {
    --bs-border-opacity: 0.1
}

.border-opacity-15 {
    --bs-border-opacity: 0.15
}

.border-opacity-20 {
    --bs-border-opacity: 0.2
}

.border-opacity-25 {
    --bs-border-opacity: 0.25
}

.border-opacity-30 {
    --bs-border-opacity: 0.3
}

.border-opacity-35 {
    --bs-border-opacity: 0.35
}

.border-opacity-40 {
    --bs-border-opacity: 0.4
}

.border-opacity-45 {
    --bs-border-opacity: 0.45
}

.border-opacity-50 {
    --bs-border-opacity: 0.5
}

.border-opacity-55 {
    --bs-border-opacity: 0.55
}

.border-opacity-60 {
    --bs-border-opacity: 0.6
}

.border-opacity-65 {
    --bs-border-opacity: 0.65
}

.border-opacity-70 {
    --bs-border-opacity: 0.7
}

.border-opacity-75 {
    --bs-border-opacity: 0.75
}

.border-opacity-80 {
    --bs-border-opacity: 0.8
}

.border-opacity-85 {
    --bs-border-opacity: 0.85
}

.border-opacity-90 {
    --bs-border-opacity: 0.9
}

.border-opacity-95 {
    --bs-border-opacity: 0.95
}

.border-opacity-100 {
    --bs-border-opacity: 1
}

.w-25 {
    width: 25%!important
}

.w-50 {
    width: 50%!important
}

.w-75 {
    width: 75%!important
}

.w-100 {
    width: 100%!important
}

.w-auto {
    width: auto!important
}

.mw-100 {
    max-width: 100%!important
}

.vw-100 {
    width: 100vw!important
}

.min-vw-100 {
    min-width: 100vw!important
}

.h-25 {
    height: 25%!important
}

.h-50 {
    height: 50%!important
}

.h-75 {
    height: 75%!important
}

.h-100 {
    height: 100%!important
}

.h-auto {
    height: auto!important
}

.mh-100 {
    max-height: 100%!important
}

.vh-100 {
    height: 100vh!important
}

.min-vh-100 {
    min-height: 100vh!important
}

.flex-fill {
    flex: 1 1 auto!important
}

.flex-row {
    flex-direction: row!important
}

.flex-column {
    flex-direction: column!important
}

.flex-row-reverse {
    flex-direction: row-reverse!important
}

.flex-column-reverse {
    flex-direction: column-reverse!important
}

.flex-grow-0 {
    flex-grow: 0!important
}

.flex-grow-1 {
    flex-grow: 1!important
}

.flex-shrink-0 {
    flex-shrink: 0!important
}

.flex-shrink-1 {
    flex-shrink: 1!important
}

.flex-wrap {
    flex-wrap: wrap!important
}

.flex-nowrap {
    flex-wrap: nowrap!important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse!important
}

.justify-content-start {
    justify-content: flex-start!important
}

.justify-content-end {
    justify-content: flex-end!important
}

.justify-content-center {
    justify-content: center!important
}

.justify-content-between {
    justify-content: space-between!important
}

.justify-content-around {
    justify-content: space-around!important
}

.justify-content-evenly {
    justify-content: space-evenly!important
}

.align-items-start {
    align-items: flex-start!important
}

.align-items-end {
    align-items: flex-end!important
}

.align-items-center {
    align-items: center!important
}

.align-items-baseline {
    align-items: baseline!important
}

.align-items-stretch {
    align-items: stretch!important
}

.align-content-start {
    align-content: flex-start!important
}

.align-content-end {
    align-content: flex-end!important
}

.align-content-center {
    align-content: center!important
}

.align-content-between {
    align-content: space-between!important
}

.align-content-around {
    align-content: space-around!important
}

.align-content-stretch {
    align-content: stretch!important
}

.align-self-auto {
    align-self: auto!important
}

.align-self-start {
    align-self: flex-start!important
}

.align-self-end {
    align-self: flex-end!important
}

.align-self-center {
    align-self: center!important
}

.align-self-baseline {
    align-self: baseline!important
}

.align-self-stretch {
    align-self: stretch!important
}

.order-first {
    order: -1!important
}

.order-0 {
    order: 0!important
}

.order-1 {
    order: 1!important
}

.order-2 {
    order: 2!important
}

.order-3 {
    order: 3!important
}

.order-4 {
    order: 4!important
}

.order-5 {
    order: 5!important
}

.order-last {
    order: 6!important
}

.m-0 {
    margin: 0!important
}

.m-1 {
    margin: .25rem!important
}

.m-2 {
    margin: .5rem!important
}

.m-3 {
    margin: .75rem!important
}

.m-4 {
    margin: 1rem!important
}

.m-5 {
    margin: 1.25rem!important
}

.m-6 {
    margin: 1.5rem!important
}

.m-7 {
    margin: 1.75rem!important
}

.m-8 {
    margin: 2rem!important
}

.m-9 {
    margin: 2.5rem!important
}

.m-10 {
    margin: 3rem!important
}

.m-11 {
    margin: 3.5rem!important
}

.m-12 {
    margin: 4rem!important
}

.m-13 {
    margin: 4.5rem!important
}

.m-14 {
    margin: 5rem!important
}

.m-15 {
    margin: 5.5rem!important
}

.m-16 {
    margin: 6rem!important
}

.m-17 {
    margin: 6.5rem!important
}

.m-18 {
    margin: 7rem!important
}

.m-19 {
    margin: 7.5rem!important
}

.m-20 {
    margin: 8rem!important
}

.m-21 {
    margin: 8.5rem!important
}

.m-22 {
    margin: 9rem!important
}

.m-23 {
    margin: 9.5rem!important
}

.m-24 {
    margin: 10rem!important
}

.m-25 {
    margin: 10.5rem!important
}

.m-26 {
    margin: 11rem!important
}

.m-27 {
    margin: 12rem!important
}

.m-28 {
    margin: 13rem!important
}

.m-29 {
    margin: 14rem!important
}

.m-30 {
    margin: 15rem!important
}

.m-31 {
    margin: 16rem!important
}

.m-32 {
    margin: 17rem!important
}

.m-33 {
    margin: 18rem!important
}

.m-34 {
    margin: 19rem!important
}

.m-35 {
    margin: 20rem!important
}

.m-auto {
    margin: auto!important
}

.mx-0 {
    margin-right: 0!important;
    margin-left: 0!important
}

.mx-1 {
    margin-right: .25rem!important;
    margin-left: .25rem!important
}

.mx-2 {
    margin-right: .5rem!important;
    margin-left: .5rem!important
}

.mx-3 {
    margin-right: .75rem!important;
    margin-left: .75rem!important
}

.mx-4 {
    margin-right: 1rem!important;
    margin-left: 1rem!important
}

.mx-5 {
    margin-right: 1.25rem!important;
    margin-left: 1.25rem!important
}

.mx-6 {
    margin-right: 1.5rem!important;
    margin-left: 1.5rem!important
}

.mx-7 {
    margin-right: 1.75rem!important;
    margin-left: 1.75rem!important
}

.mx-8 {
    margin-right: 2rem!important;
    margin-left: 2rem!important
}

.mx-9 {
    margin-right: 2.5rem!important;
    margin-left: 2.5rem!important
}

.mx-10 {
    margin-right: 3rem!important;
    margin-left: 3rem!important
}

.mx-11 {
    margin-right: 3.5rem!important;
    margin-left: 3.5rem!important
}

.mx-12 {
    margin-right: 4rem!important;
    margin-left: 4rem!important
}

.mx-13 {
    margin-right: 4.5rem!important;
    margin-left: 4.5rem!important
}

.mx-14 {
    margin-right: 5rem!important;
    margin-left: 5rem!important
}

.mx-15 {
    margin-right: 5.5rem!important;
    margin-left: 5.5rem!important
}

.mx-16 {
    margin-right: 6rem!important;
    margin-left: 6rem!important
}

.mx-17 {
    margin-right: 6.5rem!important;
    margin-left: 6.5rem!important
}

.mx-18 {
    margin-right: 7rem!important;
    margin-left: 7rem!important
}

.mx-19 {
    margin-right: 7.5rem!important;
    margin-left: 7.5rem!important
}

.mx-20 {
    margin-right: 8rem!important;
    margin-left: 8rem!important
}

.mx-21 {
    margin-right: 8.5rem!important;
    margin-left: 8.5rem!important
}

.mx-22 {
    margin-right: 9rem!important;
    margin-left: 9rem!important
}

.mx-23 {
    margin-right: 9.5rem!important;
    margin-left: 9.5rem!important
}

.mx-24 {
    margin-right: 10rem!important;
    margin-left: 10rem!important
}

.mx-25 {
    margin-right: 10.5rem!important;
    margin-left: 10.5rem!important
}

.mx-26 {
    margin-right: 11rem!important;
    margin-left: 11rem!important
}

.mx-27 {
    margin-right: 12rem!important;
    margin-left: 12rem!important
}

.mx-28 {
    margin-right: 13rem!important;
    margin-left: 13rem!important
}

.mx-29 {
    margin-right: 14rem!important;
    margin-left: 14rem!important
}

.mx-30 {
    margin-right: 15rem!important;
    margin-left: 15rem!important
}

.mx-31 {
    margin-right: 16rem!important;
    margin-left: 16rem!important
}

.mx-32 {
    margin-right: 17rem!important;
    margin-left: 17rem!important
}

.mx-33 {
    margin-right: 18rem!important;
    margin-left: 18rem!important
}

.mx-34 {
    margin-right: 19rem!important;
    margin-left: 19rem!important
}

.mx-35 {
    margin-right: 20rem!important;
    margin-left: 20rem!important
}

.mx-auto {
    margin-right: auto!important;
    margin-left: auto!important
}

.my-0 {
    margin-top: 0!important;
    margin-bottom: 0!important
}

.my-1 {
    margin-top: .25rem!important;
    margin-bottom: .25rem!important
}

.my-2 {
    margin-top: .5rem!important;
    margin-bottom: .5rem!important
}

.my-3 {
    margin-top: .75rem!important;
    margin-bottom: .75rem!important
}

.my-4 {
    margin-top: 1rem!important;
    margin-bottom: 1rem!important
}

.my-5 {
    margin-top: 1.25rem!important;
    margin-bottom: 1.25rem!important
}

.my-6 {
    margin-top: 1.5rem!important;
    margin-bottom: 1.5rem!important
}

.my-7 {
    margin-top: 1.75rem!important;
    margin-bottom: 1.75rem!important
}

.my-8 {
    margin-top: 2rem!important;
    margin-bottom: 2rem!important
}

.my-9 {
    margin-top: 2.5rem!important;
    margin-bottom: 2.5rem!important
}

.my-10 {
    margin-top: 3rem!important;
    margin-bottom: 3rem!important
}

.my-11 {
    margin-top: 3.5rem!important;
    margin-bottom: 3.5rem!important
}

.my-12 {
    margin-top: 4rem!important;
    margin-bottom: 4rem!important
}

.my-13 {
    margin-top: 4.5rem!important;
    margin-bottom: 4.5rem!important
}

.my-14 {
    margin-top: 5rem!important;
    margin-bottom: 5rem!important
}

.my-15 {
    margin-top: 5.5rem!important;
    margin-bottom: 5.5rem!important
}

.my-16 {
    margin-top: 6rem!important;
    margin-bottom: 6rem!important
}

.my-17 {
    margin-top: 6.5rem!important;
    margin-bottom: 6.5rem!important
}

.my-18 {
    margin-top: 7rem!important;
    margin-bottom: 7rem!important
}

.my-19 {
    margin-top: 7.5rem!important;
    margin-bottom: 7.5rem!important
}

.my-20 {
    margin-top: 8rem!important;
    margin-bottom: 8rem!important
}

.my-21 {
    margin-top: 8.5rem!important;
    margin-bottom: 8.5rem!important
}

.my-22 {
    margin-top: 9rem!important;
    margin-bottom: 9rem!important
}

.my-23 {
    margin-top: 9.5rem!important;
    margin-bottom: 9.5rem!important
}

.my-24 {
    margin-top: 10rem!important;
    margin-bottom: 10rem!important
}

.my-25 {
    margin-top: 10.5rem!important;
    margin-bottom: 10.5rem!important
}

.my-26 {
    margin-top: 11rem!important;
    margin-bottom: 11rem!important
}

.my-27 {
    margin-top: 12rem!important;
    margin-bottom: 12rem!important
}

.my-28 {
    margin-top: 13rem!important;
    margin-bottom: 13rem!important
}

.my-29 {
    margin-top: 14rem!important;
    margin-bottom: 14rem!important
}

.my-30 {
    margin-top: 15rem!important;
    margin-bottom: 15rem!important
}

.my-31 {
    margin-top: 16rem!important;
    margin-bottom: 16rem!important
}

.my-32 {
    margin-top: 17rem!important;
    margin-bottom: 17rem!important
}

.my-33 {
    margin-top: 18rem!important;
    margin-bottom: 18rem!important
}

.my-34 {
    margin-top: 19rem!important;
    margin-bottom: 19rem!important
}

.my-35 {
    margin-top: 20rem!important;
    margin-bottom: 20rem!important
}

.my-auto {
    margin-top: auto!important;
    margin-bottom: auto!important
}

.mt-0 {
    margin-top: 0!important
}

.mt-1 {
    margin-top: .25rem!important
}

.mt-2 {
    margin-top: .5rem!important
}

.mt-3 {
    margin-top: .75rem!important
}

.mt-4 {
    margin-top: 1rem!important
}

.mt-5 {
    margin-top: 1.25rem!important
}

.mt-6 {
    margin-top: 1.5rem!important
}

.mt-7 {
    margin-top: 1.75rem!important
}

.mt-8 {
    margin-top: 2rem!important
}

.mt-9 {
    margin-top: 2.5rem!important
}

.mt-10 {
    margin-top: 3rem!important
}

.mt-11 {
    margin-top: 3.5rem!important
}

.mt-12 {
    margin-top: 4rem!important
}

.mt-13 {
    margin-top: 4.5rem!important
}

.mt-14 {
    margin-top: 5rem!important
}

.mt-15 {
    margin-top: 5.5rem!important
}

.mt-16 {
    margin-top: 6rem!important
}

.mt-17 {
    margin-top: 6.5rem!important
}

.mt-18 {
    margin-top: 7rem!important
}

.mt-19 {
    margin-top: 7.5rem!important
}

.mt-20 {
    margin-top: 8rem!important
}

.mt-21 {
    margin-top: 8.5rem!important
}

.mt-22 {
    margin-top: 9rem!important
}

.mt-23 {
    margin-top: 9.5rem!important
}

.mt-24 {
    margin-top: 10rem!important
}

.mt-25 {
    margin-top: 10.5rem!important
}

.mt-26 {
    margin-top: 11rem!important
}

.mt-27 {
    margin-top: 12rem!important
}

.mt-28 {
    margin-top: 13rem!important
}

.mt-29 {
    margin-top: 14rem!important
}

.mt-30 {
    margin-top: 15rem!important
}

.mt-31 {
    margin-top: 16rem!important
}

.mt-32 {
    margin-top: 17rem!important
}

.mt-33 {
    margin-top: 18rem!important
}

.mt-34 {
    margin-top: 19rem!important
}

.mt-35 {
    margin-top: 20rem!important
}

.mt-auto {
    margin-top: auto!important
}

.me-0 {
    margin-right: 0!important
}

.me-1 {
    margin-right: .25rem!important
}

.me-2 {
    margin-right: .5rem!important
}

.me-3 {
    margin-right: .75rem!important
}

.me-4 {
    margin-right: 1rem!important
}

.me-5 {
    margin-right: 1.25rem!important
}

.me-6 {
    margin-right: 1.5rem!important
}

.me-7 {
    margin-right: 1.75rem!important
}

.me-8 {
    margin-right: 2rem!important
}

.me-9 {
    margin-right: 2.5rem!important
}

.me-10 {
    margin-right: 3rem!important
}

.me-11 {
    margin-right: 3.5rem!important
}

.me-12 {
    margin-right: 4rem!important
}

.me-13 {
    margin-right: 4.5rem!important
}

.me-14 {
    margin-right: 5rem!important
}

.me-15 {
    margin-right: 5.5rem!important
}

.me-16 {
    margin-right: 6rem!important
}

.me-17 {
    margin-right: 6.5rem!important
}

.me-18 {
    margin-right: 7rem!important
}

.me-19 {
    margin-right: 7.5rem!important
}

.me-20 {
    margin-right: 8rem!important
}

.me-21 {
    margin-right: 8.5rem!important
}

.me-22 {
    margin-right: 9rem!important
}

.me-23 {
    margin-right: 9.5rem!important
}

.me-24 {
    margin-right: 10rem!important
}

.me-25 {
    margin-right: 10.5rem!important
}

.me-26 {
    margin-right: 11rem!important
}

.me-27 {
    margin-right: 12rem!important
}

.me-28 {
    margin-right: 13rem!important
}

.me-29 {
    margin-right: 14rem!important
}

.me-30 {
    margin-right: 15rem!important
}

.me-31 {
    margin-right: 16rem!important
}

.me-32 {
    margin-right: 17rem!important
}

.me-33 {
    margin-right: 18rem!important
}

.me-34 {
    margin-right: 19rem!important
}

.me-35 {
    margin-right: 20rem!important
}

.me-auto {
    margin-right: auto!important
}

.mb-0 {
    margin-bottom: 0!important
}

.mb-1 {
    margin-bottom: .25rem!important
}

.mb-2 {
    margin-bottom: .5rem!important
}

.mb-3 {
    margin-bottom: .75rem!important
}

.mb-4 {
    margin-bottom: 1rem!important
}

.mb-5 {
    margin-bottom: 1.25rem!important
}

.mb-6 {
    margin-bottom: 1.5rem!important
}

.mb-7 {
    margin-bottom: 1.75rem!important
}

.mb-8 {
    margin-bottom: 2rem!important
}

.mb-9 {
    margin-bottom: 2.5rem!important
}

.mb-10 {
    margin-bottom: 3rem!important
}

.mb-11 {
    margin-bottom: 3.5rem!important
}

.mb-12 {
    margin-bottom: 4rem!important
}

.mb-13 {
    margin-bottom: 4.5rem!important
}

.mb-14 {
    margin-bottom: 5rem!important
}

.mb-15 {
    margin-bottom: 5.5rem!important
}

.mb-16 {
    margin-bottom: 6rem!important
}

.mb-17 {
    margin-bottom: 6.5rem!important
}

.mb-18 {
    margin-bottom: 7rem!important
}

.mb-19 {
    margin-bottom: 7.5rem!important
}

.mb-20 {
    margin-bottom: 8rem!important
}

.mb-21 {
    margin-bottom: 8.5rem!important
}

.mb-22 {
    margin-bottom: 9rem!important
}

.mb-23 {
    margin-bottom: 9.5rem!important
}

.mb-24 {
    margin-bottom: 10rem!important
}

.mb-25 {
    margin-bottom: 10.5rem!important
}

.mb-26 {
    margin-bottom: 11rem!important
}

.mb-27 {
    margin-bottom: 12rem!important
}

.mb-28 {
    margin-bottom: 13rem!important
}

.mb-29 {
    margin-bottom: 14rem!important
}

.mb-30 {
 
}

.mb-31 {
    margin-bottom: 16rem!important
}

.mb-32 {
    margin-bottom: 17rem!important
}

.mb-33 {
    margin-bottom: 18rem!important
}

.mb-34 {
    margin-bottom: 19rem!important
}

.mb-35 {
    margin-bottom: 20rem!important
}

.mb-auto {
    margin-bottom: auto!important
}

.ms-0 {
    margin-left: 0!important
}

.ms-1 {
    margin-left: .25rem!important
}

.ms-2 {
    margin-left: .5rem!important
}

.ms-3 {
    margin-left: .75rem!important
}

.ms-4 {
    margin-left: 1rem!important
}

.ms-5 {
    margin-left: 1.25rem!important
}

.ms-6 {
    margin-left: 1.5rem!important
}

.ms-7 {
    margin-left: 1.75rem!important
}

.ms-8 {
    margin-left: 2rem!important
}

.ms-9 {
    margin-left: 2.5rem!important
}

.ms-10 {
    margin-left: 3rem!important
}

.ms-11 {
    margin-left: 3.5rem!important
}

.ms-12 {
    margin-left: 4rem!important
}

.ms-13 {
    margin-left: 4.5rem!important
}

.ms-14 {
    margin-left: 5rem!important
}

.ms-15 {
    margin-left: 5.5rem!important
}

.ms-16 {
    margin-left: 6rem!important
}

.ms-17 {
    margin-left: 6.5rem!important
}

.ms-18 {
    margin-left: 7rem!important
}

.ms-19 {
    margin-left: 7.5rem!important
}

.ms-20 {
    margin-left: 8rem!important
}

.ms-21 {
    margin-left: 8.5rem!important
}

.ms-22 {
    margin-left: 9rem!important
}

.ms-23 {
    margin-left: 9.5rem!important
}

.ms-24 {
    margin-left: 10rem!important
}

.ms-25 {
    margin-left: 10.5rem!important
}

.ms-26 {
    margin-left: 11rem!important
}

.ms-27 {
    margin-left: 12rem!important
}

.ms-28 {
    margin-left: 13rem!important
}

.ms-29 {
    margin-left: 14rem!important
}

.ms-30 {
    margin-left: 15rem!important
}

.ms-31 {
    margin-left: 16rem!important
}

.ms-32 {
    margin-left: 17rem!important
}

.ms-33 {
    margin-left: 18rem!important
}

.ms-34 {
    margin-left: 19rem!important
}

.ms-35 {
    margin-left: 20rem!important
}

.ms-auto {
    margin-left: auto!important
}

.p-0 {
    padding: 0!important
}

.p-1 {
    padding: .25rem!important
}

.p-2 {
    padding: .5rem!important
}

.p-3 {
    padding: .75rem!important
}

.p-4 {
    padding: 1rem!important
}

.p-5 {
    padding: 1.25rem!important
}

.p-6 {
    padding: 1.5rem!important
}

.p-7 {
    padding: 1.75rem!important
}

.p-8 {
    padding: 2rem!important
}

.p-9 {
    padding: 2.5rem!important
}

.p-10 {
    padding: 3rem!important
}

.p-11 {
    padding: 3.5rem!important
}

.p-12 {
    padding: 4rem!important
}

.p-13 {
    padding: 4.5rem!important
}

.p-14 {
    padding: 5rem!important
}

.p-15 {
    padding: 5.5rem!important
}

.p-16 {
    padding: 6rem!important
}

.p-17 {
    padding: 6.5rem!important
}

.p-18 {
    padding: 7rem!important
}

.p-19 {
    padding: 7.5rem!important
}

.p-20 {
    padding: 8rem!important
}

.p-21 {
    padding: 8.5rem!important
}

.p-22 {
    padding: 9rem!important
}

.p-23 {
    padding: 9.5rem!important
}

.p-24 {
    padding: 10rem!important
}

.p-25 {
    padding: 10.5rem!important
}

.p-26 {
    padding: 11rem!important
}

.p-27 {
    padding: 12rem!important
}

.p-28 {
    padding: 13rem!important
}

.p-29 {
    padding: 14rem!important
}

.p-30 {
    padding: 15rem!important
}

.p-31 {
    padding: 16rem!important
}

.p-32 {
    padding: 17rem!important
}

.p-33 {
    padding: 18rem!important
}

.p-34 {
    padding: 19rem!important
}

.p-35 {
    padding: 20rem!important
}

.px-0 {
    padding-right: 0!important;
    padding-left: 0!important
}

.px-1 {
    padding-right: .25rem!important;
    padding-left: .25rem!important
}

.px-2 {
    padding-right: .5rem!important;
    padding-left: .5rem!important
}

.px-3 {
    padding-right: .75rem!important;
    padding-left: .75rem!important
}

.px-4 {
    padding-right: 1rem!important;
    padding-left: 1rem!important
}

.px-5 {
    padding-right: 1.25rem!important;
    padding-left: 1.25rem!important
}

.px-6 {
    padding-right: 1.5rem!important;
    padding-left: 1.5rem!important
}

.px-7 {
    padding-right: 1.75rem!important;
    padding-left: 1.75rem!important
}

.px-8 {
    padding-right: 2rem!important;
    padding-left: 2rem!important
}

.px-9 {
    padding-right: 2.5rem!important;
    padding-left: 2.5rem!important
}

.px-10 {
    padding-right: 3rem!important;
    padding-left: 3rem!important
}

.px-11 {
    padding-right: 3.5rem!important;
    padding-left: 3.5rem!important
}

.px-12 {
    padding-right: 4rem!important;
    padding-left: 4rem!important
}

.px-13 {
    padding-right: 4.5rem!important;
    padding-left: 4.5rem!important
}

.px-14 {
    padding-right: 5rem!important;
    padding-left: 5rem!important
}

.px-15 {
    padding-right: 5.5rem!important;
    padding-left: 5.5rem!important
}

.px-16 {
    padding-right: 6rem!important;
    padding-left: 6rem!important
}

.px-17 {
    padding-right: 6.5rem!important;
    padding-left: 6.5rem!important
}

.px-18 {
    padding-right: 7rem!important;
    padding-left: 7rem!important
}

.px-19 {
    padding-right: 7.5rem!important;
    padding-left: 7.5rem!important
}

.px-20 {
    padding-right: 8rem!important;
    padding-left: 8rem!important
}

.px-21 {
    padding-right: 8.5rem!important;
    padding-left: 8.5rem!important
}

.px-22 {
    padding-right: 9rem!important;
    padding-left: 9rem!important
}

.px-23 {
    padding-right: 9.5rem!important;
    padding-left: 9.5rem!important
}

.px-24 {
    padding-right: 10rem!important;
    padding-left: 10rem!important
}

.px-25 {
    padding-right: 10.5rem!important;
    padding-left: 10.5rem!important
}

.px-26 {
    padding-right: 11rem!important;
    padding-left: 11rem!important
}

.px-27 {
    padding-right: 12rem!important;
    padding-left: 12rem!important
}

.px-28 {
    padding-right: 13rem!important;
    padding-left: 13rem!important
}

.px-29 {
    padding-right: 14rem!important;
    padding-left: 14rem!important
}

.px-30 {
    padding-right: 15rem!important;
    padding-left: 15rem!important
}

.px-31 {
    padding-right: 16rem!important;
    padding-left: 16rem!important
}

.px-32 {
    padding-right: 17rem!important;
    padding-left: 17rem!important
}

.px-33 {
    padding-right: 18rem!important;
    padding-left: 18rem!important
}

.px-34 {
    padding-right: 19rem!important;
    padding-left: 19rem!important
}

.px-35 {
    padding-right: 20rem!important;
    padding-left: 20rem!important
}

.py-0 {
    padding-top: 0!important;
    padding-bottom: 0!important
}

.py-1 {
    padding-top: .25rem!important;
    padding-bottom: .25rem!important
}

.py-2 {
    padding-top: .5rem!important;
    padding-bottom: .5rem!important
}

.py-3 {
    padding-top: .75rem!important;
    padding-bottom: .75rem!important
}

.py-4 {
    padding-top: 1rem!important;
    padding-bottom: 1rem!important
}

.py-5 {
    padding-top: 1.25rem!important;
    padding-bottom: 1.25rem!important
}

.py-6 {
    padding-top: 1.5rem!important;
    padding-bottom: 1.5rem!important
}

.py-7 {
    padding-top: 1.75rem!important;
    padding-bottom: 1.75rem!important
}

.py-8 {
    padding-top: 2rem!important;
    padding-bottom: 2rem!important
}

.py-9 {
    padding-top: 2.5rem!important;
    padding-bottom: 2.5rem!important
}

.py-10 {
    padding-top: 3rem!important;
    padding-bottom: 3rem!important
}

.py-11 {
    padding-top: 3.5rem!important;
    padding-bottom: 3.5rem!important
}

.py-12 {
    padding-top: 4rem!important;
    padding-bottom: 4rem!important
}

.py-13 {
    padding-top: 4.5rem!important;
    padding-bottom: 4.5rem!important
}

.py-14 {
    padding-top: 5rem!important;
    padding-bottom: 5rem!important
}

.py-15 {
    padding-top: 5.5rem!important;
    padding-bottom: 5.5rem!important
}

.py-16 {
    padding-top: 6rem!important;
    padding-bottom: 6rem!important
}

.py-17 {
    padding-top: 6.5rem!important;
    padding-bottom: 6.5rem!important
}

.py-18 {
    padding-top: 7rem!important;
    padding-bottom: 7rem!important
}

.py-19 {
    padding-top: 7.5rem!important;
    padding-bottom: 7.5rem!important
}

.py-20 {
    padding-top: 8rem!important;
    padding-bottom: 8rem!important
}

.py-21 {
    padding-top: 8.5rem!important;
    padding-bottom: 8.5rem!important
}

.py-22 {
    padding-top: 9rem!important;
    padding-bottom: 9rem!important
}

.py-23 {
    padding-top: 9.5rem!important;
    padding-bottom: 9.5rem!important
}

.py-24 {
    padding-top: 10rem!important;
    padding-bottom: 10rem!important
}

.py-25 {
    padding-top: 10.5rem!important;
    padding-bottom: 10.5rem!important
}

.py-26 {
    padding-top: 11rem!important;
    padding-bottom: 11rem!important
}

.py-27 {
    padding-top: 12rem!important;
    padding-bottom: 12rem!important
}

.py-28 {
    padding-top: 13rem!important;
    padding-bottom: 13rem!important
}

.py-29 {
    padding-top: 14rem!important;
    padding-bottom: 14rem!important
}

.py-30 {
    padding-top: 15rem!important;
    padding-bottom: 15rem!important
}

.py-31 {
    padding-top: 16rem!important;
    padding-bottom: 16rem!important
}

.py-32 {
    padding-top: 17rem!important;
    padding-bottom: 17rem!important
}

.py-33 {
    padding-top: 18rem!important;
    padding-bottom: 18rem!important
}

.py-34 {
    padding-top: 19rem!important;
    padding-bottom: 19rem!important
}

.py-35 {
    padding-top: 20rem!important;
    padding-bottom: 20rem!important
}

.pt-0 {
    padding-top: 0!important
}

.pt-1 {
    padding-top: .25rem!important
}

.pt-2 {
    padding-top: .5rem!important
}

.pt-3 {
    padding-top: .75rem!important
}

.pt-4 {
    padding-top: 1rem!important
}

.pt-5 {
    padding-top: 1.25rem!important
}

.pt-6 {
    padding-top: 1.5rem!important
}

.pt-7 {
    padding-top: 1.75rem!important
}

.pt-8 {
    padding-top: 2rem!important
}

.pt-9 {
    padding-top: 2.5rem!important
}

.pt-10 {
    padding-top: 3rem!important
}

.pt-11 {
    padding-top: 3.5rem!important
}

.pt-12 {
    padding-top: 4rem!important
}

.pt-13 {
    padding-top: 4.5rem!important
}

.pt-14 {
    padding-top: 5rem!important
}

.pt-15 {
    padding-top: 5.5rem!important
}

.pt-16 {
    padding-top: 6rem!important
}

.pt-17 {
    padding-top: 6.5rem!important
}

.pt-18 {
    padding-top: 7rem!important
}

.pt-19 {
    padding-top: 7.5rem!important
}

.pt-20 {
    padding-top: 8rem!important
}

.pt-21 {
    padding-top: 8.5rem!important
}

.pt-22 {
    padding-top: 9rem!important
}

.pt-23 {
    padding-top: 9.5rem!important
}

.pt-24 {
    padding-top: 10rem!important
}

.pt-25 {
    padding-top: 10.5rem!important
}

.pt-26 {
    padding-top: 11rem!important
}

.pt-27 {
    padding-top: 12rem!important
}

.pt-28 {
    padding-top: 13rem!important
}

.pt-29 {
    padding-top: 14rem!important
}

.pt-30 {
    padding-top: 15rem!important
}

.pt-31 {
    padding-top: 16rem!important
}

.pt-32 {
    padding-top: 17rem!important
}

.pt-33 {
    padding-top: 18rem!important
}

.pt-34 {
    padding-top: 19rem!important
}

.pt-35 {
    padding-top: 20rem!important
}

.pe-0 {
    padding-right: 0!important
}

.pe-1 {
    padding-right: .25rem!important
}

.pe-2 {
    padding-right: .5rem!important
}

.pe-3 {
    padding-right: .75rem!important
}

.pe-4 {
    padding-right: 1rem!important
}

.pe-5 {
    padding-right: 1.25rem!important
}

.pe-6 {
    padding-right: 1.5rem!important
}

.pe-7 {
    padding-right: 1.75rem!important
}

.pe-8 {
    padding-right: 2rem!important
}

.pe-9 {
    padding-right: 2.5rem!important
}

.pe-10 {
    padding-right: 3rem!important
}

.pe-11 {
    padding-right: 3.5rem!important
}

.pe-12 {
    padding-right: 4rem!important
}

.pe-13 {
    padding-right: 4.5rem!important
}

.pe-14 {
    padding-right: 5rem!important
}

.pe-15 {
    padding-right: 5.5rem!important
}

.pe-16 {
    padding-right: 6rem!important
}

.pe-17 {
    padding-right: 6.5rem!important
}

.pe-18 {
    padding-right: 7rem!important
}

.pe-19 {
    padding-right: 7.5rem!important
}

.pe-20 {
    padding-right: 8rem!important
}

.pe-21 {
    padding-right: 8.5rem!important
}

.pe-22 {
    padding-right: 9rem!important
}

.pe-23 {
    padding-right: 9.5rem!important
}

.pe-24 {
    padding-right: 10rem!important
}

.pe-25 {
    padding-right: 10.5rem!important
}

.pe-26 {
    padding-right: 11rem!important
}

.pe-27 {
    padding-right: 12rem!important
}

.pe-28 {
    padding-right: 13rem!important
}

.pe-29 {
    padding-right: 14rem!important
}

.pe-30 {
    padding-right: 15rem!important
}

.pe-31 {
    padding-right: 16rem!important
}

.pe-32 {
    padding-right: 17rem!important
}

.pe-33 {
    padding-right: 18rem!important
}

.pe-34 {
    padding-right: 19rem!important
}

.pe-35 {
    padding-right: 20rem!important
}

.pb-0 {
    padding-bottom: 0!important
}

.pb-1 {
    padding-bottom: .25rem!important
}

.pb-2 {
    padding-bottom: .5rem!important
}

.pb-3 {
    padding-bottom: .75rem!important
}

.pb-4 {
    padding-bottom: 1rem!important
}

.pb-5 {
    padding-bottom: 1.25rem!important
}

.pb-6 {
    padding-bottom: 1.5rem!important
}

.pb-7 {
    padding-bottom: 1.75rem!important
}

.pb-8 {
    padding-bottom: 2rem!important
}

.pb-9 {
    padding-bottom: 2.5rem!important
}

.pb-10 {
    padding-bottom: 3rem!important
}

.pb-11 {
    padding-bottom: 3.5rem!important
}

.pb-12 {
    padding-bottom: 4rem!important
}

.pb-13 {
    padding-bottom: 4.5rem!important
}

.pb-14 {
    padding-bottom: 5rem!important
}

.pb-15 {
    padding-bottom: 5.5rem!important
}

.pb-16 {
    padding-bottom: 6rem!important
}

.pb-17 {
    padding-bottom: 6.5rem!important
}

.pb-18 {
    padding-bottom: 7rem!important
}

.pb-19 {
    padding-bottom: 7.5rem!important
}

.pb-20 {
    padding-bottom: 8rem!important
}

.pb-21 {
    padding-bottom: 8.5rem!important
}

.pb-22 {
    padding-bottom: 9rem!important
}

.pb-23 {
    padding-bottom: 9.5rem!important
}

.pb-24 {
    padding-bottom: 10rem!important
}

.pb-25 {
    padding-bottom: 10.5rem!important
}

.pb-26 {
    padding-bottom: 11rem!important
}

.pb-27 {
    padding-bottom: 12rem!important
}

.pb-28 {
    padding-bottom: 13rem!important
}

.pb-29 {
    padding-bottom: 14rem!important
}

.pb-30 {
    padding-bottom: 15rem!important
}

.pb-31 {
    padding-bottom: 16rem!important
}

.pb-32 {
    padding-bottom: 17rem!important
}

.pb-33 {
    padding-bottom: 18rem!important
}

.pb-34 {
    padding-bottom: 19rem!important
}

.pb-35 {
    padding-bottom: 20rem!important
}

.ps-0 {
    padding-left: 0!important
}

.ps-1 {
    padding-left: .25rem!important
}

.ps-2 {
    padding-left: .5rem!important
}

.ps-3 {
    padding-left: .75rem!important
}

.ps-4 {
    padding-left: 1rem!important
}

.ps-5 {
    padding-left: 1.25rem!important
}

.ps-6 {
    padding-left: 1.5rem!important
}

.ps-7 {
    padding-left: 1.75rem!important
}

.ps-8 {
    padding-left: 2rem!important
}

.ps-9 {
    padding-left: 2.5rem!important
}

.ps-10 {
    padding-left: 3rem!important
}

.ps-11 {
    padding-left: 3.5rem!important
}

.ps-12 {
    padding-left: 4rem!important
}

.ps-13 {
    padding-left: 4.5rem!important
}

.ps-14 {
    padding-left: 5rem!important
}

.ps-15 {
    padding-left: 5.5rem!important
}

.ps-16 {
    padding-left: 6rem!important
}

.ps-17 {
    padding-left: 6.5rem!important
}

.ps-18 {
    padding-left: 7rem!important
}

.ps-19 {
    padding-left: 7.5rem!important
}

.ps-20 {
    padding-left: 8rem!important
}

.ps-21 {
    padding-left: 8.5rem!important
}

.ps-22 {
    padding-left: 9rem!important
}

.ps-23 {
    padding-left: 9.5rem!important
}

.ps-24 {
    padding-left: 10rem!important
}

.ps-25 {
    padding-left: 10.5rem!important
}

.ps-26 {
    padding-left: 11rem!important
}

.ps-27 {
    padding-left: 12rem!important
}

.ps-28 {
    padding-left: 13rem!important
}

.ps-29 {
    padding-left: 14rem!important
}

.ps-30 {
    padding-left: 15rem!important
}

.ps-31 {
    padding-left: 16rem!important
}

.ps-32 {
    padding-left: 17rem!important
}

.ps-33 {
    padding-left: 18rem!important
}

.ps-34 {
    padding-left: 19rem!important
}

.ps-35 {
    padding-left: 20rem!important
}

.gap-0 {
    gap: 0!important
}

.gap-1 {
    gap: .25rem!important
}

.gap-2 {
    gap: .5rem!important
}

.gap-3 {
    gap: .75rem!important
}

.gap-4 {
    gap: 1rem!important
}

.gap-5 {
    gap: 1.25rem!important
}

.gap-6 {
    gap: 1.5rem!important
}

.gap-7 {
    gap: 1.75rem!important
}

.gap-8 {
    gap: 2rem!important
}

.gap-9 {
    gap: 2.5rem!important
}

.gap-10 {
    gap: 3rem!important
}

.gap-11 {
    gap: 3.5rem!important
}

.gap-12 {
    gap: 4rem!important
}

.gap-13 {
    gap: 4.5rem!important
}

.gap-14 {
    gap: 5rem!important
}

.gap-15 {
    gap: 5.5rem!important
}

.gap-16 {
    gap: 6rem!important
}

.gap-17 {
    gap: 6.5rem!important
}

.gap-18 {
    gap: 7rem!important
}

.gap-19 {
    gap: 7.5rem!important
}

.gap-20 {
    gap: 8rem!important
}

.gap-21 {
    gap: 8.5rem!important
}

.gap-22 {
    gap: 9rem!important
}

.gap-23 {
    gap: 9.5rem!important
}

.gap-24 {
    gap: 10rem!important
}

.gap-25 {
    gap: 10.5rem!important
}

.gap-26 {
    gap: 11rem!important
}

.gap-27 {
    gap: 12rem!important
}

.gap-28 {
    gap: 13rem!important
}

.gap-29 {
    gap: 14rem!important
}

.gap-30 {
    gap: 15rem!important
}

.gap-31 {
    gap: 16rem!important
}

.gap-32 {
    gap: 17rem!important
}

.gap-33 {
    gap: 18rem!important
}

.gap-34 {
    gap: 19rem!important
}

.gap-35 {
    gap: 20rem!important
}

.row-gap-0 {
    row-gap: 0!important
}

.row-gap-1 {
    row-gap: .25rem!important
}

.row-gap-2 {
    row-gap: .5rem!important
}

.row-gap-3 {
    row-gap: .75rem!important
}

.row-gap-4 {
    row-gap: 1rem!important
}

.row-gap-5 {
    row-gap: 1.25rem!important
}

.row-gap-6 {
    row-gap: 1.5rem!important
}

.row-gap-7 {
    row-gap: 1.75rem!important
}

.row-gap-8 {
    row-gap: 2rem!important
}

.row-gap-9 {
    row-gap: 2.5rem!important
}

.row-gap-10 {
    row-gap: 3rem!important
}

.row-gap-11 {
    row-gap: 3.5rem!important
}

.row-gap-12 {
    row-gap: 4rem!important
}

.row-gap-13 {
    row-gap: 4.5rem!important
}

.row-gap-14 {
    row-gap: 5rem!important
}

.row-gap-15 {
    row-gap: 5.5rem!important
}

.row-gap-16 {
    row-gap: 6rem!important
}

.row-gap-17 {
    row-gap: 6.5rem!important
}

.row-gap-18 {
    row-gap: 7rem!important
}

.row-gap-19 {
    row-gap: 7.5rem!important
}

.row-gap-20 {
    row-gap: 8rem!important
}

.row-gap-21 {
    row-gap: 8.5rem!important
}

.row-gap-22 {
    row-gap: 9rem!important
}

.row-gap-23 {
    row-gap: 9.5rem!important
}

.row-gap-24 {
    row-gap: 10rem!important
}

.row-gap-25 {
    row-gap: 10.5rem!important
}

.row-gap-26 {
    row-gap: 11rem!important
}

.row-gap-27 {
    row-gap: 12rem!important
}

.row-gap-28 {
    row-gap: 13rem!important
}

.row-gap-29 {
    row-gap: 14rem!important
}

.row-gap-30 {
    row-gap: 15rem!important
}

.row-gap-31 {
    row-gap: 16rem!important
}

.row-gap-32 {
    row-gap: 17rem!important
}

.row-gap-33 {
    row-gap: 18rem!important
}

.row-gap-34 {
    row-gap: 19rem!important
}

.row-gap-35 {
    row-gap: 20rem!important
}

.column-gap-0 {
    -moz-column-gap: 0!important;
    column-gap: 0!important
}

.column-gap-1 {
    -moz-column-gap: .25rem!important;
    column-gap: .25rem!important
}

.column-gap-2 {
    -moz-column-gap: .5rem!important;
    column-gap: .5rem!important
}

.column-gap-3 {
    -moz-column-gap: .75rem!important;
    column-gap: .75rem!important
}

.column-gap-4 {
    -moz-column-gap: 1rem!important;
    column-gap: 1rem!important
}

.column-gap-5 {
    -moz-column-gap: 1.25rem!important;
    column-gap: 1.25rem!important
}

.column-gap-6 {
    -moz-column-gap: 1.5rem!important;
    column-gap: 1.5rem!important
}

.column-gap-7 {
    -moz-column-gap: 1.75rem!important;
    column-gap: 1.75rem!important
}

.column-gap-8 {
    -moz-column-gap: 2rem!important;
    column-gap: 2rem!important
}

.column-gap-9 {
    -moz-column-gap: 2.5rem!important;
    column-gap: 2.5rem!important
}

.column-gap-10 {
    -moz-column-gap: 3rem!important;
    column-gap: 3rem!important
}

.column-gap-11 {
    -moz-column-gap: 3.5rem!important;
    column-gap: 3.5rem!important
}

.column-gap-12 {
    -moz-column-gap: 4rem!important;
    column-gap: 4rem!important
}

.column-gap-13 {
    -moz-column-gap: 4.5rem!important;
    column-gap: 4.5rem!important
}

.column-gap-14 {
    -moz-column-gap: 5rem!important;
    column-gap: 5rem!important
}

.column-gap-15 {
    -moz-column-gap: 5.5rem!important;
    column-gap: 5.5rem!important
}

.column-gap-16 {
    -moz-column-gap: 6rem!important;
    column-gap: 6rem!important
}

.column-gap-17 {
    -moz-column-gap: 6.5rem!important;
    column-gap: 6.5rem!important
}

.column-gap-18 {
    -moz-column-gap: 7rem!important;
    column-gap: 7rem!important
}

.column-gap-19 {
    -moz-column-gap: 7.5rem!important;
    column-gap: 7.5rem!important
}

.column-gap-20 {
    -moz-column-gap: 8rem!important;
    column-gap: 8rem!important
}

.column-gap-21 {
    -moz-column-gap: 8.5rem!important;
    column-gap: 8.5rem!important
}

.column-gap-22 {
    -moz-column-gap: 9rem!important;
    column-gap: 9rem!important
}

.column-gap-23 {
    -moz-column-gap: 9.5rem!important;
    column-gap: 9.5rem!important
}

.column-gap-24 {
    -moz-column-gap: 10rem!important;
    column-gap: 10rem!important
}

.column-gap-25 {
    -moz-column-gap: 10.5rem!important;
    column-gap: 10.5rem!important
}

.column-gap-26 {
    -moz-column-gap: 11rem!important;
    column-gap: 11rem!important
}

.column-gap-27 {
    -moz-column-gap: 12rem!important;
    column-gap: 12rem!important
}

.column-gap-28 {
    -moz-column-gap: 13rem!important;
    column-gap: 13rem!important
}

.column-gap-29 {
    -moz-column-gap: 14rem!important;
    column-gap: 14rem!important
}

.column-gap-30 {
    -moz-column-gap: 15rem!important;
    column-gap: 15rem!important
}

.column-gap-31 {
    -moz-column-gap: 16rem!important;
    column-gap: 16rem!important
}

.column-gap-32 {
    -moz-column-gap: 17rem!important;
    column-gap: 17rem!important
}

.column-gap-33 {
    -moz-column-gap: 18rem!important;
    column-gap: 18rem!important
}

.column-gap-34 {
    -moz-column-gap: 19rem!important;
    column-gap: 19rem!important
}

.column-gap-35 {
    -moz-column-gap: 20rem!important;
    column-gap: 20rem!important
}

.font-monospace {
    font-family: 'Roboto', sans-serif !important;
 
}

.fs-1 {
    font-size: calc(1.378125rem + 1.5375vw)!important
}

.fs-2 {
    font-size: calc(1.333125rem + .9975vw)!important
}

.fs-3 {
    font-size: calc(1.2825rem + .39vw)!important
}

.fs-4 {
    font-size: calc(1.26rem + .12vw)!important
}

.fs-5 {
    font-size: 1.18125rem!important
}

.fs-6 {
    font-size: 1.06875rem!important
}

.fst-italic {
    font-style: italic!important
}

.fst-normal {
    font-style: normal!important
}

.fw-lighter {
    font-weight: lighter!important
}

.fw-light {
    font-weight: 200!important
}

.fw-normal {
    font-weight: 300!important
}

.fw-medium {
    font-weight: 400!important
}

.fw-semibold {
    font-weight: 500!important
}

.fw-bold {
    font-weight: 600!important
}

.fw-bolder {
    font-weight: 700!important
}

.lh-1 {
    line-height: 1!important
}

.lh-sm {
    line-height: 1.25!important
}

.lh-base {
    line-height: 1.5!important
}

.lh-lg {
    line-height: 2!important
}

.text-start {
    text-align: left!important
}

.text-end {
    text-align: right!important
}

.text-center {
    text-align: center!important
}

.text-decoration-none {
    text-decoration: none!important
}

.text-decoration-underline {
    text-decoration: underline!important
}

.text-decoration-line-through {
    text-decoration: line-through!important
}

.text-lowercase {
    text-transform: lowercase!important
}

.text-uppercase {
    text-transform: uppercase!important
}

.text-capitalize {
    text-transform: capitalize!important
}

.text-wrap {
    white-space: normal!important
}

.text-nowrap {
    white-space: nowrap!important
}

.text-break {
    word-wrap: break-word!important;
    word-break: break-word!important
}

.text-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb),var(--bs-text-opacity))!important
}

.text-secondary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb),var(--bs-text-opacity))!important
}

.text-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb),var(--bs-text-opacity))!important
}

.text-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb),var(--bs-text-opacity))!important
}

.text-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb),var(--bs-text-opacity))!important
}

.text-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb),var(--bs-text-opacity))!important
}

.text-light {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb),var(--bs-text-opacity))!important
}

.text-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb),var(--bs-text-opacity))!important
}

.text-black {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb),var(--bs-text-opacity))!important
}

.text-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb),var(--bs-text-opacity))!important
}

.text-body {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-color-rgb),var(--bs-text-opacity))!important
}

.text-muted {
    --bs-text-opacity: 1;
    color: var(--bs-secondary-color)!important
}

.text-black-50 {
    --bs-text-opacity: 1;
    color: rgba(17,17,17,.5)!important
}

.text-white-50 {
    --bs-text-opacity: 1;
    color: rgba(255,255,255,.5)!important
}

.text-body-secondary {
    --bs-text-opacity: 1;
    color: var(--bs-secondary-color)!important
}

.text-body-tertiary {
    --bs-text-opacity: 1;
    color: var(--bs-tertiary-color)!important
}

.text-body-emphasis {
    --bs-text-opacity: 1;
    color: var(--bs-emphasis-color)!important
}

.text-reset {
    --bs-text-opacity: 1;
    color: inherit!important
}

.text-opacity-25 {
    --bs-text-opacity: 0.25
}

.text-opacity-50 {
    --bs-text-opacity: 0.5
}

.text-opacity-75 {
    --bs-text-opacity: 0.75
}

.text-opacity-100 {
    --bs-text-opacity: 1
}

.text-primary-emphasis {
    color: var(--bs-primary-text)!important
}

.text-secondary-emphasis {
    color: var(--bs-secondary-text)!important
}

.text-success-emphasis {
    color: var(--bs-success-text)!important
}

.text-info-emphasis {
    color: var(--bs-info-text)!important
}

.text-warning-emphasis {
    color: var(--bs-warning-text)!important
}

.text-danger-emphasis {
    color: var(--bs-danger-text)!important
}

.text-light-emphasis {
    color: var(--bs-light-text)!important
}

.text-dark-emphasis {
    color: var(--bs-dark-text)!important
}

.bg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb),var(--bs-bg-opacity))!important
}

.bg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb),var(--bs-bg-opacity))!important
}

.bg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb),var(--bs-bg-opacity))!important
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb),var(--bs-bg-opacity))!important
}

.bg-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb),var(--bs-bg-opacity))!important
}

.bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb),var(--bs-bg-opacity))!important
}

.bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb),var(--bs-bg-opacity))!important
}

.bg-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity))!important
}

.bg-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb),var(--bs-bg-opacity))!important
}

.bg-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb),var(--bs-bg-opacity))!important
}

.bg-body {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb),var(--bs-bg-opacity))!important
}

.bg-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent!important
}

.bg-body-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-bg-rgb),var(--bs-bg-opacity))!important
}

.bg-body-tertiary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-tertiary-bg-rgb),var(--bs-bg-opacity))!important
}

.bg-body-emphasis {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-emphasis-bg-rgb),var(--bs-bg-opacity))!important
}

.bg-opacity-10 {
    --bs-bg-opacity: 0.1
}

.bg-opacity-15 {
    --bs-bg-opacity: 0.15
}

.bg-opacity-20 {
    --bs-bg-opacity: 0.2
}

.bg-opacity-25 {
    --bs-bg-opacity: 0.25
}

.bg-opacity-30 {
    --bs-bg-opacity: 0.3
}

.bg-opacity-35 {
    --bs-bg-opacity: 0.35
}

.bg-opacity-40 {
    --bs-bg-opacity: 0.4
}

.bg-opacity-45 {
    --bs-bg-opacity: 0.45
}

.bg-opacity-50 {
    --bs-bg-opacity: 0.5
}

.bg-opacity-55 {
    --bs-bg-opacity: 0.55
}

.bg-opacity-60 {
    --bs-bg-opacity: 0.6
}

.bg-opacity-65 {
    --bs-bg-opacity: 0.65
}

.bg-opacity-70 {
    --bs-bg-opacity: 0.7
}

.bg-opacity-75 {
    --bs-bg-opacity: 0.75
}

.bg-opacity-80 {
    --bs-bg-opacity: 0.8
}

.bg-opacity-85 {
    --bs-bg-opacity: 0.85
}

.bg-opacity-90 {
    --bs-bg-opacity: 0.9
}

.bg-opacity-95 {
    --bs-bg-opacity: 0.95
}

.bg-opacity-100 {
    --bs-bg-opacity: 1
}

.bg-primary-subtle {
    background-color: var(--bs-primary-bg-subtle)!important
}

.bg-secondary-subtle {
    background-color: var(--bs-secondary-bg-subtle)!important
}

.bg-success-subtle {
    background-color: var(--bs-success-bg-subtle)!important
}

.bg-info-subtle {
    background-color: var(--bs-info-bg-subtle)!important
}

.bg-warning-subtle {
    background-color: var(--bs-warning-bg-subtle)!important
}

.bg-danger-subtle {
    background-color: var(--bs-danger-bg-subtle)!important
}

.bg-light-subtle {
    background-color: var(--bs-light-bg-subtle)!important
}

.bg-dark-subtle {
    background-color: var(--bs-dark-bg-subtle)!important
}

.bg-gradient {
    background-image: var(--bs-gradient)!important
}

.user-select-all {
    -webkit-user-select: all!important;
    -moz-user-select: all!important;
    user-select: all!important
}

.user-select-auto {
    -webkit-user-select: auto!important;
    -moz-user-select: auto!important;
    user-select: auto!important
}

.user-select-none {
    -webkit-user-select: none!important;
    -moz-user-select: none!important;
    user-select: none!important
}

.pe-none {
    pointer-events: none!important
}

.pe-auto {
    pointer-events: auto!important
}

.rounded {
    border-radius: var(--bs-border-radius)!important
}

.rounded-0 {
    border-radius: 0!important
}

.rounded-1 {
    border-radius: var(--bs-border-radius-sm)!important
}

.rounded-2 {
    border-radius: var(--bs-border-radius)!important
}

.rounded-3 {
    border-radius: var(--bs-border-radius-lg)!important
}

.rounded-4 {
    border-radius: var(--bs-border-radius-xl)!important
}

.rounded-5 {
    border-radius: var(--bs-border-radius-2xl)!important
}

.rounded-circle {
    border-radius: 50%!important
}

.rounded-pill {
    border-radius: var(--bs-border-radius-pill)!important
}

.rounded-top {
    border-top-left-radius: var(--bs-border-radius)!important;
    border-top-right-radius: var(--bs-border-radius)!important
}

.rounded-top-0 {
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important
}

.rounded-top-1 {
    border-top-left-radius: var(--bs-border-radius-sm)!important;
    border-top-right-radius: var(--bs-border-radius-sm)!important
}

.rounded-top-2 {
    border-top-left-radius: var(--bs-border-radius)!important;
    border-top-right-radius: var(--bs-border-radius)!important
}

.rounded-top-3 {
    border-top-left-radius: var(--bs-border-radius-lg)!important;
    border-top-right-radius: var(--bs-border-radius-lg)!important
}

.rounded-top-4 {
    border-top-left-radius: var(--bs-border-radius-xl)!important;
    border-top-right-radius: var(--bs-border-radius-xl)!important
}

.rounded-top-5 {
    border-top-left-radius: var(--bs-border-radius-2xl)!important;
    border-top-right-radius: var(--bs-border-radius-2xl)!important
}

.rounded-top-circle {
    border-top-left-radius: 50%!important;
    border-top-right-radius: 50%!important
}

.rounded-top-pill {
    border-top-left-radius: var(--bs-border-radius-pill)!important;
    border-top-right-radius: var(--bs-border-radius-pill)!important
}

.rounded-end {
    border-top-right-radius: var(--bs-border-radius)!important;
    border-bottom-right-radius: var(--bs-border-radius)!important
}

.rounded-end-0 {
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important
}

.rounded-end-1 {
    border-top-right-radius: var(--bs-border-radius-sm)!important;
    border-bottom-right-radius: var(--bs-border-radius-sm)!important
}

.rounded-end-2 {
    border-top-right-radius: var(--bs-border-radius)!important;
    border-bottom-right-radius: var(--bs-border-radius)!important
}

.rounded-end-3 {
    border-top-right-radius: var(--bs-border-radius-lg)!important;
    border-bottom-right-radius: var(--bs-border-radius-lg)!important
}

.rounded-end-4 {
    border-top-right-radius: var(--bs-border-radius-xl)!important;
    border-bottom-right-radius: var(--bs-border-radius-xl)!important
}

.rounded-end-5 {
    border-top-right-radius: var(--bs-border-radius-2xl)!important;
    border-bottom-right-radius: var(--bs-border-radius-2xl)!important
}

.rounded-end-circle {
    border-top-right-radius: 50%!important;
    border-bottom-right-radius: 50%!important
}

.rounded-end-pill {
    border-top-right-radius: var(--bs-border-radius-pill)!important;
    border-bottom-right-radius: var(--bs-border-radius-pill)!important
}

.rounded-bottom {
    border-bottom-right-radius: var(--bs-border-radius)!important;
    border-bottom-left-radius: var(--bs-border-radius)!important
}

.rounded-bottom-0 {
    border-bottom-right-radius: 0!important;
    border-bottom-left-radius: 0!important
}

.rounded-bottom-1 {
    border-bottom-right-radius: var(--bs-border-radius-sm)!important;
    border-bottom-left-radius: var(--bs-border-radius-sm)!important
}

.rounded-bottom-2 {
    border-bottom-right-radius: var(--bs-border-radius)!important;
    border-bottom-left-radius: var(--bs-border-radius)!important
}

.rounded-bottom-3 {
    border-bottom-right-radius: var(--bs-border-radius-lg)!important;
    border-bottom-left-radius: var(--bs-border-radius-lg)!important
}

.rounded-bottom-4 {
    border-bottom-right-radius: var(--bs-border-radius-xl)!important;
    border-bottom-left-radius: var(--bs-border-radius-xl)!important
}

.rounded-bottom-5 {
    border-bottom-right-radius: var(--bs-border-radius-2xl)!important;
    border-bottom-left-radius: var(--bs-border-radius-2xl)!important
}

.rounded-bottom-circle {
    border-bottom-right-radius: 50%!important;
    border-bottom-left-radius: 50%!important
}

.rounded-bottom-pill {
    border-bottom-right-radius: var(--bs-border-radius-pill)!important;
    border-bottom-left-radius: var(--bs-border-radius-pill)!important
}

.rounded-start {
    border-bottom-left-radius: var(--bs-border-radius)!important;
    border-top-left-radius: var(--bs-border-radius)!important
}

.rounded-start-0 {
    border-bottom-left-radius: 0!important;
    border-top-left-radius: 0!important
}

.rounded-start-1 {
    border-bottom-left-radius: var(--bs-border-radius-sm)!important;
    border-top-left-radius: var(--bs-border-radius-sm)!important
}

.rounded-start-2 {
    border-bottom-left-radius: var(--bs-border-radius)!important;
    border-top-left-radius: var(--bs-border-radius)!important
}

.rounded-start-3 {
    border-bottom-left-radius: var(--bs-border-radius-lg)!important;
    border-top-left-radius: var(--bs-border-radius-lg)!important
}

.rounded-start-4 {
    border-bottom-left-radius: var(--bs-border-radius-xl)!important;
    border-top-left-radius: var(--bs-border-radius-xl)!important
}

.rounded-start-5 {
    border-bottom-left-radius: var(--bs-border-radius-2xl)!important;
    border-top-left-radius: var(--bs-border-radius-2xl)!important
}

.rounded-start-circle {
    border-bottom-left-radius: 50%!important;
    border-top-left-radius: 50%!important
}

.rounded-start-pill {
    border-bottom-left-radius: var(--bs-border-radius-pill)!important;
    border-top-left-radius: var(--bs-border-radius-pill)!important
}

.visible {
    visibility: visible!important
}

.invisible {
    visibility: hidden!important
}

.z-n1 {
    z-index: -1!important
}

.z-0 {
    z-index: 0!important
}

.z-1 {
    z-index: 1!important
}

.z-2 {
    z-index: 2!important
}

.z-3 {
    z-index: 3!important
}

.z-4 {
    z-index: 4!important
}

.z-5 {
    z-index: 5!important
}

.z-6 {
    z-index: 6!important
}

.z-7 {
    z-index: 7!important
}

.z-8 {
    z-index: 8!important
}

.z-9 {
    z-index: 9!important
}

.z-10 {
    z-index: 10!important
}

.z-100 {
    z-index: 100!important
}

.z-1000 {
    z-index: 1000!important
}

.z-1010 {
    z-index: 1010!important
}

.z-1020 {
    z-index: 1020!important
}

.z-1030 {
    z-index: 1030!important
}

.z-1040 {
    z-index: 1040!important
}

.z-1050 {
    z-index: 1050!important
}

.z-1060 {
    z-index: 1060!important
}

.z-1070 {
    z-index: 1070!important
}

.z-1080 {
    z-index: 1080!important
}

.z-1090 {
    z-index: 1090!important
}

.z-1100 {
    z-index: 1100!important
}

.cursor-alias {
    cursor: alias!important
}

.cursor-all-scroll {
    cursor: all-scroll!important
}

.cursor-auto {
    cursor: auto!important
}

.cursor-cell {
    cursor: cell!important
}

.cursor-col-resize {
    cursor: col-resize!important
}

.cursor-context-menu {
    cursor: context-menu!important
}

.cursor-copy {
    cursor: copy!important
}

.cursor-crosshair {
    cursor: crosshair!important
}

.cursor-default {
    cursor: default!important
}

.cursor-e-resize {
    cursor: e-resize!important
}

.cursor-ew-resize {
    cursor: ew-resize!important
}

.cursor-grab {
    cursor: grab!important
}

.cursor-grabbing {
    cursor: grabbing!important
}

.cursor-help {
    cursor: help!important
}

.cursor-move {
    cursor: move!important
}

.cursor-n-resize {
    cursor: n-resize!important
}

.cursor-ne-resize {
    cursor: ne-resize!important
}

.cursor-nesw-resize {
    cursor: nesw-resize!important
}

.cursor-ns-resize {
    cursor: ns-resize!important
}

.cursor-nw-resize {
    cursor: nw-resize!important
}

.cursor-nwse-resize {
    cursor: nwse-resize!important
}

.cursor-no-drop {
    cursor: no-drop!important
}

.cursor-none {
    cursor: none!important
}

.cursor-not-allowed {
    cursor: not-allowed!important
}

.cursor-pointer {
    cursor: pointer!important
}

.cursor-progress {
    cursor: progress!important
}

.cursor-row-resize {
    cursor: row-resize!important
}

.cursor-s-resize {
    cursor: s-resize!important
}

.cursor-se-resize {
    cursor: se-resize!important
}

.cursor-sw-resize {
    cursor: sw-resize!important
}

.cursor-text {
    cursor: text!important
}

.cursor-url {
    cursor: url!important
}

.cursor-w-resize {
    cursor: w-resize!important
}

.cursor-wait {
    cursor: wait!important
}

.cursor-zoom-in {
    cursor: zoom-in!important
}

.cursor-zoom-out {
    cursor: zoom-out!important
}

.bg-primary-50 {
    background-color: #f4f0ec!important
}

.bg-primary-100 {
    background-color: #ede7e1!important
}

.bg-primary-150 {
    background-color: #e5dad2!important
}

.bg-primary-200 {
    background-color: #dccec3!important
}

.bg-primary-250 {
    background-color: #d3c2b5!important
}

.bg-primary-300 {
    background-color: #cab6a6!important
}

.bg-primary-350 {
    background-color: #c1aa97!important
}

.bg-primary-400 {
    background-color: #b99d88!important
}

.bg-primary-450 {
    background-color: #b09179!important
}

.bg-primary-500 {
    background-color: #a7856a!important
}

.bg-primary-550 {
    background-color: #96785f!important
}

.bg-primary-600 {
    background-color: #866a55!important
}

.bg-primary-650 {
    background-color: #755d4a!important
}

.bg-primary-700 {
    background-color: #645040!important
}

.bg-primary-750 {
    background-color: #544335!important
}

.bg-primary-800 {
    background-color: #43352a!important
}

.bg-primary-850 {
    background-color: #43352a!important
}

.bg-primary-900 {
    background-color: #211b15!important
}

.bg-primary-950 {
    background-color: #15110d!important
}

@media (min-width: 576px) {
    .float-sm-start {
        float:left!important
    }

    .float-sm-end {
        float: right!important
    }

    .float-sm-none {
        float: none!important
    }

    .object-fit-sm-contain {
        -o-object-fit: contain!important;
        object-fit: contain!important
    }

    .object-fit-sm-cover {
        -o-object-fit: cover!important;
        object-fit: cover!important
    }

    .object-fit-sm-fill {
        -o-object-fit: fill!important;
        object-fit: fill!important
    }

    .object-fit-sm-scale {
        -o-object-fit: scale-down!important;
        object-fit: scale-down!important
    }

    .object-fit-sm-none {
        -o-object-fit: none!important;
        object-fit: none!important
    }

    .d-sm-inline {
        display: inline!important
    }

    .d-sm-inline-block {
        display: inline-block!important
    }

    .d-sm-block {
        display: block!important
    }

    .d-sm-grid {
        display: grid!important
    }

    .d-sm-table {
        display: table!important
    }

    .d-sm-table-row {
        display: table-row!important
    }

    .d-sm-table-cell {
        display: table-cell!important
    }

    .d-sm-flex {
        display: flex!important
    }

    .d-sm-inline-flex {
        display: inline-flex!important
    }

    .d-sm-none {
        display: none!important
    }

    .flex-sm-fill {
        flex: 1 1 auto!important
    }

    .flex-sm-row {
        flex-direction: row!important
    }

    .flex-sm-column {
        flex-direction: column!important
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse!important
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse!important
    }

    .flex-sm-grow-0 {
        flex-grow: 0!important
    }

    .flex-sm-grow-1 {
        flex-grow: 1!important
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0!important
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1!important
    }

    .flex-sm-wrap {
        flex-wrap: wrap!important
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap!important
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }

    .justify-content-sm-start {
        justify-content: flex-start!important
    }

    .justify-content-sm-end {
        justify-content: flex-end!important
    }

    .justify-content-sm-center {
        justify-content: center!important
    }

    .justify-content-sm-between {
        justify-content: space-between!important
    }

    .justify-content-sm-around {
        justify-content: space-around!important
    }

    .justify-content-sm-evenly {
        justify-content: space-evenly!important
    }

    .align-items-sm-start {
        align-items: flex-start!important
    }

    .align-items-sm-end {
        align-items: flex-end!important
    }

    .align-items-sm-center {
        align-items: center!important
    }

    .align-items-sm-baseline {
        align-items: baseline!important
    }

    .align-items-sm-stretch {
        align-items: stretch!important
    }

    .align-content-sm-start {
        align-content: flex-start!important
    }

    .align-content-sm-end {
        align-content: flex-end!important
    }

    .align-content-sm-center {
        align-content: center!important
    }

    .align-content-sm-between {
        align-content: space-between!important
    }

    .align-content-sm-around {
        align-content: space-around!important
    }

    .align-content-sm-stretch {
        align-content: stretch!important
    }

    .align-self-sm-auto {
        align-self: auto!important
    }

    .align-self-sm-start {
        align-self: flex-start!important
    }

    .align-self-sm-end {
        align-self: flex-end!important
    }

    .align-self-sm-center {
        align-self: center!important
    }

    .align-self-sm-baseline {
        align-self: baseline!important
    }

    .align-self-sm-stretch {
        align-self: stretch!important
    }

    .order-sm-first {
        order: -1!important
    }

    .order-sm-0 {
        order: 0!important
    }

    .order-sm-1 {
        order: 1!important
    }

    .order-sm-2 {
        order: 2!important
    }

    .order-sm-3 {
        order: 3!important
    }

    .order-sm-4 {
        order: 4!important
    }

    .order-sm-5 {
        order: 5!important
    }

    .order-sm-last {
        order: 6!important
    }

    .m-sm-0 {
        margin: 0!important
    }

    .m-sm-1 {
        margin: .25rem!important
    }

    .m-sm-2 {
        margin: .5rem!important
    }

    .m-sm-3 {
        margin: .75rem!important
    }

    .m-sm-4 {
        margin: 1rem!important
    }

    .m-sm-5 {
        margin: 1.25rem!important
    }

    .m-sm-6 {
        margin: 1.5rem!important
    }

    .m-sm-7 {
        margin: 1.75rem!important
    }

    .m-sm-8 {
        margin: 2rem!important
    }

    .m-sm-9 {
        margin: 2.5rem!important
    }

    .m-sm-10 {
        margin: 3rem!important
    }

    .m-sm-11 {
        margin: 3.5rem!important
    }

    .m-sm-12 {
        margin: 4rem!important
    }

    .m-sm-13 {
        margin: 4.5rem!important
    }

    .m-sm-14 {
        margin: 5rem!important
    }

    .m-sm-15 {
        margin: 5.5rem!important
    }

    .m-sm-16 {
        margin: 6rem!important
    }

    .m-sm-17 {
        margin: 6.5rem!important
    }

    .m-sm-18 {
        margin: 7rem!important
    }

    .m-sm-19 {
        margin: 7.5rem!important
    }

    .m-sm-20 {
        margin: 8rem!important
    }

    .m-sm-21 {
        margin: 8.5rem!important
    }

    .m-sm-22 {
        margin: 9rem!important
    }

    .m-sm-23 {
        margin: 9.5rem!important
    }

    .m-sm-24 {
        margin: 10rem!important
    }

    .m-sm-25 {
        margin: 10.5rem!important
    }

    .m-sm-26 {
        margin: 11rem!important
    }

    .m-sm-27 {
        margin: 12rem!important
    }

    .m-sm-28 {
        margin: 13rem!important
    }

    .m-sm-29 {
        margin: 14rem!important
    }

    .m-sm-30 {
        margin: 15rem!important
    }

    .m-sm-31 {
        margin: 16rem!important
    }

    .m-sm-32 {
        margin: 17rem!important
    }

    .m-sm-33 {
        margin: 18rem!important
    }

    .m-sm-34 {
        margin: 19rem!important
    }

    .m-sm-35 {
        margin: 20rem!important
    }

    .m-sm-auto {
        margin: auto!important
    }

    .mx-sm-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-sm-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }

    .mx-sm-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }

    .mx-sm-3 {
        margin-right: .75rem!important;
        margin-left: .75rem!important
    }

    .mx-sm-4 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-sm-5 {
        margin-right: 1.25rem!important;
        margin-left: 1.25rem!important
    }

    .mx-sm-6 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-sm-7 {
        margin-right: 1.75rem!important;
        margin-left: 1.75rem!important
    }

    .mx-sm-8 {
        margin-right: 2rem!important;
        margin-left: 2rem!important
    }

    .mx-sm-9 {
        margin-right: 2.5rem!important;
        margin-left: 2.5rem!important
    }

    .mx-sm-10 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-sm-11 {
        margin-right: 3.5rem!important;
        margin-left: 3.5rem!important
    }

    .mx-sm-12 {
        margin-right: 4rem!important;
        margin-left: 4rem!important
    }

    .mx-sm-13 {
        margin-right: 4.5rem!important;
        margin-left: 4.5rem!important
    }

    .mx-sm-14 {
        margin-right: 5rem!important;
        margin-left: 5rem!important
    }

    .mx-sm-15 {
        margin-right: 5.5rem!important;
        margin-left: 5.5rem!important
    }

    .mx-sm-16 {
        margin-right: 6rem!important;
        margin-left: 6rem!important
    }

    .mx-sm-17 {
        margin-right: 6.5rem!important;
        margin-left: 6.5rem!important
    }

    .mx-sm-18 {
        margin-right: 7rem!important;
        margin-left: 7rem!important
    }

    .mx-sm-19 {
        margin-right: 7.5rem!important;
        margin-left: 7.5rem!important
    }

    .mx-sm-20 {
        margin-right: 8rem!important;
        margin-left: 8rem!important
    }

    .mx-sm-21 {
        margin-right: 8.5rem!important;
        margin-left: 8.5rem!important
    }

    .mx-sm-22 {
        margin-right: 9rem!important;
        margin-left: 9rem!important
    }

    .mx-sm-23 {
        margin-right: 9.5rem!important;
        margin-left: 9.5rem!important
    }

    .mx-sm-24 {
        margin-right: 10rem!important;
        margin-left: 10rem!important
    }

    .mx-sm-25 {
        margin-right: 10.5rem!important;
        margin-left: 10.5rem!important
    }

    .mx-sm-26 {
        margin-right: 11rem!important;
        margin-left: 11rem!important
    }

    .mx-sm-27 {
        margin-right: 12rem!important;
        margin-left: 12rem!important
    }

    .mx-sm-28 {
        margin-right: 13rem!important;
        margin-left: 13rem!important
    }

    .mx-sm-29 {
        margin-right: 14rem!important;
        margin-left: 14rem!important
    }

    .mx-sm-30 {
        margin-right: 15rem!important;
        margin-left: 15rem!important
    }

    .mx-sm-31 {
        margin-right: 16rem!important;
        margin-left: 16rem!important
    }

    .mx-sm-32 {
        margin-right: 17rem!important;
        margin-left: 17rem!important
    }

    .mx-sm-33 {
        margin-right: 18rem!important;
        margin-left: 18rem!important
    }

    .mx-sm-34 {
        margin-right: 19rem!important;
        margin-left: 19rem!important
    }

    .mx-sm-35 {
        margin-right: 20rem!important;
        margin-left: 20rem!important
    }

    .mx-sm-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-sm-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-sm-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }

    .my-sm-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .my-sm-3 {
        margin-top: .75rem!important;
        margin-bottom: .75rem!important
    }

    .my-sm-4 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-sm-5 {
        margin-top: 1.25rem!important;
        margin-bottom: 1.25rem!important
    }

    .my-sm-6 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-sm-7 {
        margin-top: 1.75rem!important;
        margin-bottom: 1.75rem!important
    }

    .my-sm-8 {
        margin-top: 2rem!important;
        margin-bottom: 2rem!important
    }

    .my-sm-9 {
        margin-top: 2.5rem!important;
        margin-bottom: 2.5rem!important
    }

    .my-sm-10 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-sm-11 {
        margin-top: 3.5rem!important;
        margin-bottom: 3.5rem!important
    }

    .my-sm-12 {
        margin-top: 4rem!important;
        margin-bottom: 4rem!important
    }

    .my-sm-13 {
        margin-top: 4.5rem!important;
        margin-bottom: 4.5rem!important
    }

    .my-sm-14 {
        margin-top: 5rem!important;
        margin-bottom: 5rem!important
    }

    .my-sm-15 {
        margin-top: 5.5rem!important;
        margin-bottom: 5.5rem!important
    }

    .my-sm-16 {
        margin-top: 6rem!important;
        margin-bottom: 6rem!important
    }

    .my-sm-17 {
        margin-top: 6.5rem!important;
        margin-bottom: 6.5rem!important
    }

    .my-sm-18 {
        margin-top: 7rem!important;
        margin-bottom: 7rem!important
    }

    .my-sm-19 {
        margin-top: 7.5rem!important;
        margin-bottom: 7.5rem!important
    }

    .my-sm-20 {
        margin-top: 8rem!important;
        margin-bottom: 8rem!important
    }

    .my-sm-21 {
        margin-top: 8.5rem!important;
        margin-bottom: 8.5rem!important
    }

    .my-sm-22 {
        margin-top: 9rem!important;
        margin-bottom: 9rem!important
    }

    .my-sm-23 {
        margin-top: 9.5rem!important;
        margin-bottom: 9.5rem!important
    }

    .my-sm-24 {
        margin-top: 10rem!important;
        margin-bottom: 10rem!important
    }

    .my-sm-25 {
        margin-top: 10.5rem!important;
        margin-bottom: 10.5rem!important
    }

    .my-sm-26 {
        margin-top: 11rem!important;
        margin-bottom: 11rem!important
    }

    .my-sm-27 {
        margin-top: 12rem!important;
        margin-bottom: 12rem!important
    }

    .my-sm-28 {
        margin-top: 13rem!important;
        margin-bottom: 13rem!important
    }

    .my-sm-29 {
        margin-top: 14rem!important;
        margin-bottom: 14rem!important
    }

    .my-sm-30 {
        margin-top: 15rem!important;
        margin-bottom: 15rem!important
    }

    .my-sm-31 {
        margin-top: 16rem!important;
        margin-bottom: 16rem!important
    }

    .my-sm-32 {
        margin-top: 17rem!important;
        margin-bottom: 17rem!important
    }

    .my-sm-33 {
        margin-top: 18rem!important;
        margin-bottom: 18rem!important
    }

    .my-sm-34 {
        margin-top: 19rem!important;
        margin-bottom: 19rem!important
    }

    .my-sm-35 {
        margin-top: 20rem!important;
        margin-bottom: 20rem!important
    }

    .my-sm-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-sm-0 {
        margin-top: 0!important
    }

    .mt-sm-1 {
        margin-top: .25rem!important
    }

    .mt-sm-2 {
        margin-top: .5rem!important
    }

    .mt-sm-3 {
        margin-top: .75rem!important
    }

    .mt-sm-4 {
        margin-top: 1rem!important
    }

    .mt-sm-5 {
        margin-top: 1.25rem!important
    }

    .mt-sm-6 {
        margin-top: 1.5rem!important
    }

    .mt-sm-7 {
        margin-top: 1.75rem!important
    }

    .mt-sm-8 {
        margin-top: 2rem!important
    }

    .mt-sm-9 {
        margin-top: 2.5rem!important
    }

    .mt-sm-10 {
        margin-top: 3rem!important
    }

    .mt-sm-11 {
        margin-top: 3.5rem!important
    }

    .mt-sm-12 {
        margin-top: 4rem!important
    }

    .mt-sm-13 {
        margin-top: 4.5rem!important
    }

    .mt-sm-14 {
        margin-top: 5rem!important
    }

    .mt-sm-15 {
        margin-top: 5.5rem!important
    }

    .mt-sm-16 {
        margin-top: 6rem!important
    }

    .mt-sm-17 {
        margin-top: 6.5rem!important
    }

    .mt-sm-18 {
        margin-top: 7rem!important
    }

    .mt-sm-19 {
        margin-top: 7.5rem!important
    }

    .mt-sm-20 {
        margin-top: 8rem!important
    }

    .mt-sm-21 {
        margin-top: 8.5rem!important
    }

    .mt-sm-22 {
        margin-top: 9rem!important
    }

    .mt-sm-23 {
        margin-top: 9.5rem!important
    }

    .mt-sm-24 {
        margin-top: 10rem!important
    }

    .mt-sm-25 {
        margin-top: 10.5rem!important
    }

    .mt-sm-26 {
        margin-top: 11rem!important
    }

    .mt-sm-27 {
        margin-top: 12rem!important
    }

    .mt-sm-28 {
        margin-top: 13rem!important
    }

    .mt-sm-29 {
        margin-top: 14rem!important
    }

    .mt-sm-30 {
        margin-top: 15rem!important
    }

    .mt-sm-31 {
        margin-top: 16rem!important
    }

    .mt-sm-32 {
        margin-top: 17rem!important
    }

    .mt-sm-33 {
        margin-top: 18rem!important
    }

    .mt-sm-34 {
        margin-top: 19rem!important
    }

    .mt-sm-35 {
        margin-top: 20rem!important
    }

    .mt-sm-auto {
        margin-top: auto!important
    }

    .me-sm-0 {
        margin-right: 0!important
    }

    .me-sm-1 {
        margin-right: .25rem!important
    }

    .me-sm-2 {
        margin-right: .5rem!important
    }

    .me-sm-3 {
        margin-right: .75rem!important
    }

    .me-sm-4 {
        margin-right: 1rem!important
    }

    .me-sm-5 {
        margin-right: 1.25rem!important
    }

    .me-sm-6 {
        margin-right: 1.5rem!important
    }

    .me-sm-7 {
        margin-right: 1.75rem!important
    }

    .me-sm-8 {
        margin-right: 2rem!important
    }

    .me-sm-9 {
        margin-right: 2.5rem!important
    }

    .me-sm-10 {
        margin-right: 3rem!important
    }

    .me-sm-11 {
        margin-right: 3.5rem!important
    }

    .me-sm-12 {
        margin-right: 4rem!important
    }

    .me-sm-13 {
        margin-right: 4.5rem!important
    }

    .me-sm-14 {
        margin-right: 5rem!important
    }

    .me-sm-15 {
        margin-right: 5.5rem!important
    }

    .me-sm-16 {
        margin-right: 6rem!important
    }

    .me-sm-17 {
        margin-right: 6.5rem!important
    }

    .me-sm-18 {
        margin-right: 7rem!important
    }

    .me-sm-19 {
        margin-right: 7.5rem!important
    }

    .me-sm-20 {
        margin-right: 8rem!important
    }

    .me-sm-21 {
        margin-right: 8.5rem!important
    }

    .me-sm-22 {
        margin-right: 9rem!important
    }

    .me-sm-23 {
        margin-right: 9.5rem!important
    }

    .me-sm-24 {
        margin-right: 10rem!important
    }

    .me-sm-25 {
        margin-right: 10.5rem!important
    }

    .me-sm-26 {
        margin-right: 11rem!important
    }

    .me-sm-27 {
        margin-right: 12rem!important
    }

    .me-sm-28 {
        margin-right: 13rem!important
    }

    .me-sm-29 {
        margin-right: 14rem!important
    }

    .me-sm-30 {
        margin-right: 15rem!important
    }

    .me-sm-31 {
        margin-right: 16rem!important
    }

    .me-sm-32 {
        margin-right: 17rem!important
    }

    .me-sm-33 {
        margin-right: 18rem!important
    }

    .me-sm-34 {
        margin-right: 19rem!important
    }

    .me-sm-35 {
        margin-right: 20rem!important
    }

    .me-sm-auto {
        margin-right: auto!important
    }

    .mb-sm-0 {
        margin-bottom: 0!important
    }

    .mb-sm-1 {
        margin-bottom: .25rem!important
    }

    .mb-sm-2 {
        margin-bottom: .5rem!important
    }

    .mb-sm-3 {
        margin-bottom: .75rem!important
    }

    .mb-sm-4 {
        margin-bottom: 1rem!important
    }

    .mb-sm-5 {
        margin-bottom: 1.25rem!important
    }

    .mb-sm-6 {
        margin-bottom: 1.5rem!important
    }

    .mb-sm-7 {
        margin-bottom: 1.75rem!important
    }

    .mb-sm-8 {
        margin-bottom: 2rem!important
    }

    .mb-sm-9 {
        margin-bottom: 2.5rem!important
    }

    .mb-sm-10 {
        margin-bottom: 3rem!important
    }

    .mb-sm-11 {
        margin-bottom: 3.5rem!important
    }

    .mb-sm-12 {
        margin-bottom: 4rem!important
    }

    .mb-sm-13 {
        margin-bottom: 4.5rem!important
    }

    .mb-sm-14 {
        margin-bottom: 5rem!important
    }

    .mb-sm-15 {
        margin-bottom: 5.5rem!important
    }

    .mb-sm-16 {
        margin-bottom: 6rem!important
    }

    .mb-sm-17 {
        margin-bottom: 6.5rem!important
    }

    .mb-sm-18 {
        margin-bottom: 7rem!important
    }

    .mb-sm-19 {
        margin-bottom: 7.5rem!important
    }

    .mb-sm-20 {
        margin-bottom: 8rem!important
    }

    .mb-sm-21 {
        margin-bottom: 8.5rem!important
    }

    .mb-sm-22 {
        margin-bottom: 9rem!important
    }

    .mb-sm-23 {
        margin-bottom: 9.5rem!important
    }

    .mb-sm-24 {
        margin-bottom: 10rem!important
    }

    .mb-sm-25 {
        margin-bottom: 10.5rem!important
    }

    .mb-sm-26 {
        margin-bottom: 11rem!important
    }

    .mb-sm-27 {
        margin-bottom: 12rem!important
    }

    .mb-sm-28 {
        margin-bottom: 13rem!important
    }

    .mb-sm-29 {
        margin-bottom: 14rem!important
    }

    .mb-sm-30 {
        margin-bottom: 15rem!important
    }

    .mb-sm-31 {
        margin-bottom: 16rem!important
    }

    .mb-sm-32 {
        margin-bottom: 17rem!important
    }

    .mb-sm-33 {
        margin-bottom: 18rem!important
    }

    .mb-sm-34 {
        margin-bottom: 19rem!important
    }

    .mb-sm-35 {
        margin-bottom: 20rem!important
    }

    .mb-sm-auto {
        margin-bottom: auto!important
    }

    .ms-sm-0 {
        margin-left: 0!important
    }

    .ms-sm-1 {
        margin-left: .25rem!important
    }

    .ms-sm-2 {
        margin-left: .5rem!important
    }

    .ms-sm-3 {
        margin-left: .75rem!important
    }

    .ms-sm-4 {
        margin-left: 1rem!important
    }

    .ms-sm-5 {
        margin-left: 1.25rem!important
    }

    .ms-sm-6 {
        margin-left: 1.5rem!important
    }

    .ms-sm-7 {
        margin-left: 1.75rem!important
    }

    .ms-sm-8 {
        margin-left: 2rem!important
    }

    .ms-sm-9 {
        margin-left: 2.5rem!important
    }

    .ms-sm-10 {
        margin-left: 3rem!important
    }

    .ms-sm-11 {
        margin-left: 3.5rem!important
    }

    .ms-sm-12 {
        margin-left: 4rem!important
    }

    .ms-sm-13 {
        margin-left: 4.5rem!important
    }

    .ms-sm-14 {
        margin-left: 5rem!important
    }

    .ms-sm-15 {
        margin-left: 5.5rem!important
    }

    .ms-sm-16 {
        margin-left: 6rem!important
    }

    .ms-sm-17 {
        margin-left: 6.5rem!important
    }

    .ms-sm-18 {
        margin-left: 7rem!important
    }

    .ms-sm-19 {
        margin-left: 7.5rem!important
    }

    .ms-sm-20 {
        margin-left: 8rem!important
    }

    .ms-sm-21 {
        margin-left: 8.5rem!important
    }

    .ms-sm-22 {
        margin-left: 9rem!important
    }

    .ms-sm-23 {
        margin-left: 9.5rem!important
    }

    .ms-sm-24 {
        margin-left: 10rem!important
    }

    .ms-sm-25 {
        margin-left: 10.5rem!important
    }

    .ms-sm-26 {
        margin-left: 11rem!important
    }

    .ms-sm-27 {
        margin-left: 12rem!important
    }

    .ms-sm-28 {
        margin-left: 13rem!important
    }

    .ms-sm-29 {
        margin-left: 14rem!important
    }

    .ms-sm-30 {
        margin-left: 15rem!important
    }

    .ms-sm-31 {
        margin-left: 16rem!important
    }

    .ms-sm-32 {
        margin-left: 17rem!important
    }

    .ms-sm-33 {
        margin-left: 18rem!important
    }

    .ms-sm-34 {
        margin-left: 19rem!important
    }

    .ms-sm-35 {
        margin-left: 20rem!important
    }

    .ms-sm-auto {
        margin-left: auto!important
    }

    .p-sm-0 {
        padding: 0!important
    }

    .p-sm-1 {
        padding: .25rem!important
    }

    .p-sm-2 {
        padding: .5rem!important
    }

    .p-sm-3 {
        padding: .75rem!important
    }

    .p-sm-4 {
        padding: 1rem!important
    }

    .p-sm-5 {
        padding: 1.25rem!important
    }

    .p-sm-6 {
        padding: 1.5rem!important
    }

    .p-sm-7 {
        padding: 1.75rem!important
    }

    .p-sm-8 {
        padding: 2rem!important
    }

    .p-sm-9 {
        padding: 2.5rem!important
    }

    .p-sm-10 {
        padding: 3rem!important
    }

    .p-sm-11 {
        padding: 3.5rem!important
    }

    .p-sm-12 {
        padding: 4rem!important
    }

    .p-sm-13 {
        padding: 4.5rem!important
    }

    .p-sm-14 {
        padding: 5rem!important
    }

    .p-sm-15 {
        padding: 5.5rem!important
    }

    .p-sm-16 {
        padding: 6rem!important
    }

    .p-sm-17 {
        padding: 6.5rem!important
    }

    .p-sm-18 {
        padding: 7rem!important
    }

    .p-sm-19 {
        padding: 7.5rem!important
    }

    .p-sm-20 {
        padding: 8rem!important
    }

    .p-sm-21 {
        padding: 8.5rem!important
    }

    .p-sm-22 {
        padding: 9rem!important
    }

    .p-sm-23 {
        padding: 9.5rem!important
    }

    .p-sm-24 {
        padding: 10rem!important
    }

    .p-sm-25 {
        padding: 10.5rem!important
    }

    .p-sm-26 {
        padding: 11rem!important
    }

    .p-sm-27 {
        padding: 12rem!important
    }

    .p-sm-28 {
        padding: 13rem!important
    }

    .p-sm-29 {
        padding: 14rem!important
    }

    .p-sm-30 {
        padding: 15rem!important
    }

    .p-sm-31 {
        padding: 16rem!important
    }

    .p-sm-32 {
        padding: 17rem!important
    }

    .p-sm-33 {
        padding: 18rem!important
    }

    .p-sm-34 {
        padding: 19rem!important
    }

    .p-sm-35 {
        padding: 20rem!important
    }

    .px-sm-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-sm-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }

    .px-sm-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }

    .px-sm-3 {
        padding-right: .75rem!important;
        padding-left: .75rem!important
    }

    .px-sm-4 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-sm-5 {
        padding-right: 1.25rem!important;
        padding-left: 1.25rem!important
    }

    .px-sm-6 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-sm-7 {
        padding-right: 1.75rem!important;
        padding-left: 1.75rem!important
    }

    .px-sm-8 {
        padding-right: 2rem!important;
        padding-left: 2rem!important
    }

    .px-sm-9 {
        padding-right: 2.5rem!important;
        padding-left: 2.5rem!important
    }

    .px-sm-10 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .px-sm-11 {
        padding-right: 3.5rem!important;
        padding-left: 3.5rem!important
    }

    .px-sm-12 {
        padding-right: 4rem!important;
        padding-left: 4rem!important
    }

    .px-sm-13 {
        padding-right: 4.5rem!important;
        padding-left: 4.5rem!important
    }

    .px-sm-14 {
        padding-right: 5rem!important;
        padding-left: 5rem!important
    }

    .px-sm-15 {
        padding-right: 5.5rem!important;
        padding-left: 5.5rem!important
    }

    .px-sm-16 {
        padding-right: 6rem!important;
        padding-left: 6rem!important
    }

    .px-sm-17 {
        padding-right: 6.5rem!important;
        padding-left: 6.5rem!important
    }

    .px-sm-18 {
        padding-right: 7rem!important;
        padding-left: 7rem!important
    }

    .px-sm-19 {
        padding-right: 7.5rem!important;
        padding-left: 7.5rem!important
    }

    .px-sm-20 {
        padding-right: 8rem!important;
        padding-left: 8rem!important
    }

    .px-sm-21 {
        padding-right: 8.5rem!important;
        padding-left: 8.5rem!important
    }

    .px-sm-22 {
        padding-right: 9rem!important;
        padding-left: 9rem!important
    }

    .px-sm-23 {
        padding-right: 9.5rem!important;
        padding-left: 9.5rem!important
    }

    .px-sm-24 {
        padding-right: 10rem!important;
        padding-left: 10rem!important
    }

    .px-sm-25 {
        padding-right: 10.5rem!important;
        padding-left: 10.5rem!important
    }

    .px-sm-26 {
        padding-right: 11rem!important;
        padding-left: 11rem!important
    }

    .px-sm-27 {
        padding-right: 12rem!important;
        padding-left: 12rem!important
    }

    .px-sm-28 {
        padding-right: 13rem!important;
        padding-left: 13rem!important
    }

    .px-sm-29 {
        padding-right: 14rem!important;
        padding-left: 14rem!important
    }

    .px-sm-30 {
        padding-right: 15rem!important;
        padding-left: 15rem!important
    }

    .px-sm-31 {
        padding-right: 16rem!important;
        padding-left: 16rem!important
    }

    .px-sm-32 {
        padding-right: 17rem!important;
        padding-left: 17rem!important
    }

    .px-sm-33 {
        padding-right: 18rem!important;
        padding-left: 18rem!important
    }

    .px-sm-34 {
        padding-right: 19rem!important;
        padding-left: 19rem!important
    }

    .px-sm-35 {
        padding-right: 20rem!important;
        padding-left: 20rem!important
    }

    .py-sm-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-sm-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }

    .py-sm-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }

    .py-sm-3 {
        padding-top: .75rem!important;
        padding-bottom: .75rem!important
    }

    .py-sm-4 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-sm-5 {
        padding-top: 1.25rem!important;
        padding-bottom: 1.25rem!important
    }

    .py-sm-6 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-sm-7 {
        padding-top: 1.75rem!important;
        padding-bottom: 1.75rem!important
    }

    .py-sm-8 {
        padding-top: 2rem!important;
        padding-bottom: 2rem!important
    }

    .py-sm-9 {
        padding-top: 2.5rem!important;
        padding-bottom: 2.5rem!important
    }

    .py-sm-10 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .py-sm-11 {
        padding-top: 3.5rem!important;
        padding-bottom: 3.5rem!important
    }

    .py-sm-12 {
        padding-top: 4rem!important;
        padding-bottom: 4rem!important
    }

    .py-sm-13 {
        padding-top: 4.5rem!important;
        padding-bottom: 4.5rem!important
    }

    .py-sm-14 {
        padding-top: 5rem!important;
        padding-bottom: 5rem!important
    }

    .py-sm-15 {
        padding-top: 5.5rem!important;
        padding-bottom: 5.5rem!important
    }

    .py-sm-16 {
        padding-top: 6rem!important;
        padding-bottom: 6rem!important
    }

    .py-sm-17 {
        padding-top: 6.5rem!important;
        padding-bottom: 6.5rem!important
    }

    .py-sm-18 {
        padding-top: 7rem!important;
        padding-bottom: 7rem!important
    }

    .py-sm-19 {
        padding-top: 7.5rem!important;
        padding-bottom: 7.5rem!important
    }

    .py-sm-20 {
        padding-top: 8rem!important;
        padding-bottom: 8rem!important
    }

    .py-sm-21 {
        padding-top: 8.5rem!important;
        padding-bottom: 8.5rem!important
    }

    .py-sm-22 {
        padding-top: 9rem!important;
        padding-bottom: 9rem!important
    }

    .py-sm-23 {
        padding-top: 9.5rem!important;
        padding-bottom: 9.5rem!important
    }

    .py-sm-24 {
        padding-top: 10rem!important;
        padding-bottom: 10rem!important
    }

    .py-sm-25 {
        padding-top: 10.5rem!important;
        padding-bottom: 10.5rem!important
    }

    .py-sm-26 {
        padding-top: 11rem!important;
        padding-bottom: 11rem!important
    }

    .py-sm-27 {
        padding-top: 12rem!important;
        padding-bottom: 12rem!important
    }

    .py-sm-28 {
        padding-top: 13rem!important;
        padding-bottom: 13rem!important
    }

    .py-sm-29 {
        padding-top: 14rem!important;
        padding-bottom: 14rem!important
    }

    .py-sm-30 {
        padding-top: 15rem!important;
        padding-bottom: 15rem!important
    }

    .py-sm-31 {
        padding-top: 16rem!important;
        padding-bottom: 16rem!important
    }

    .py-sm-32 {
        padding-top: 17rem!important;
        padding-bottom: 17rem!important
    }

    .py-sm-33 {
        padding-top: 18rem!important;
        padding-bottom: 18rem!important
    }

    .py-sm-34 {
        padding-top: 19rem!important;
        padding-bottom: 19rem!important
    }

    .py-sm-35 {
        padding-top: 20rem!important;
        padding-bottom: 20rem!important
    }

    .pt-sm-0 {
        padding-top: 0!important
    }

    .pt-sm-1 {
        padding-top: .25rem!important
    }

    .pt-sm-2 {
        padding-top: .5rem!important
    }

    .pt-sm-3 {
        padding-top: .75rem!important
    }

    .pt-sm-4 {
        padding-top: 1rem!important
    }

    .pt-sm-5 {
        padding-top: 1.25rem!important
    }

    .pt-sm-6 {
        padding-top: 1.5rem!important
    }

    .pt-sm-7 {
        padding-top: 1.75rem!important
    }

    .pt-sm-8 {
        padding-top: 2rem!important
    }

    .pt-sm-9 {
        padding-top: 2.5rem!important
    }

    .pt-sm-10 {
        padding-top: 3rem!important
    }

    .pt-sm-11 {
        padding-top: 3.5rem!important
    }

    .pt-sm-12 {
        padding-top: 4rem!important
    }

    .pt-sm-13 {
        padding-top: 4.5rem!important
    }

    .pt-sm-14 {
        padding-top: 5rem!important
    }

    .pt-sm-15 {
        padding-top: 5.5rem!important
    }

    .pt-sm-16 {
        padding-top: 6rem!important
    }

    .pt-sm-17 {
        padding-top: 6.5rem!important
    }

    .pt-sm-18 {
        padding-top: 7rem!important
    }

    .pt-sm-19 {
        padding-top: 7.5rem!important
    }

    .pt-sm-20 {
        padding-top: 8rem!important
    }

    .pt-sm-21 {
        padding-top: 8.5rem!important
    }

    .pt-sm-22 {
        padding-top: 9rem!important
    }

    .pt-sm-23 {
        padding-top: 9.5rem!important
    }

    .pt-sm-24 {
        padding-top: 10rem!important
    }

    .pt-sm-25 {
        padding-top: 10.5rem!important
    }

    .pt-sm-26 {
        padding-top: 11rem!important
    }

    .pt-sm-27 {
        padding-top: 12rem!important
    }

    .pt-sm-28 {
        padding-top: 13rem!important
    }

    .pt-sm-29 {
        padding-top: 14rem!important
    }

    .pt-sm-30 {
        padding-top: 15rem!important
    }

    .pt-sm-31 {
        padding-top: 16rem!important
    }

    .pt-sm-32 {
        padding-top: 17rem!important
    }

    .pt-sm-33 {
        padding-top: 18rem!important
    }

    .pt-sm-34 {
        padding-top: 19rem!important
    }

    .pt-sm-35 {
        padding-top: 20rem!important
    }

    .pe-sm-0 {
        padding-right: 0!important
    }

    .pe-sm-1 {
        padding-right: .25rem!important
    }

    .pe-sm-2 {
        padding-right: .5rem!important
    }

    .pe-sm-3 {
        padding-right: .75rem!important
    }

    .pe-sm-4 {
        padding-right: 1rem!important
    }

    .pe-sm-5 {
        padding-right: 1.25rem!important
    }

    .pe-sm-6 {
        padding-right: 1.5rem!important
    }

    .pe-sm-7 {
        padding-right: 1.75rem!important
    }

    .pe-sm-8 {
        padding-right: 2rem!important
    }

    .pe-sm-9 {
        padding-right: 2.5rem!important
    }

    .pe-sm-10 {
        padding-right: 3rem!important
    }

    .pe-sm-11 {
        padding-right: 3.5rem!important
    }

    .pe-sm-12 {
        padding-right: 4rem!important
    }

    .pe-sm-13 {
        padding-right: 4.5rem!important
    }

    .pe-sm-14 {
        padding-right: 5rem!important
    }

    .pe-sm-15 {
        padding-right: 5.5rem!important
    }

    .pe-sm-16 {
        padding-right: 6rem!important
    }

    .pe-sm-17 {
        padding-right: 6.5rem!important
    }

    .pe-sm-18 {
        padding-right: 7rem!important
    }

    .pe-sm-19 {
        padding-right: 7.5rem!important
    }

    .pe-sm-20 {
        padding-right: 8rem!important
    }

    .pe-sm-21 {
        padding-right: 8.5rem!important
    }

    .pe-sm-22 {
        padding-right: 9rem!important
    }

    .pe-sm-23 {
        padding-right: 9.5rem!important
    }

    .pe-sm-24 {
        padding-right: 10rem!important
    }

    .pe-sm-25 {
        padding-right: 10.5rem!important
    }

    .pe-sm-26 {
        padding-right: 11rem!important
    }

    .pe-sm-27 {
        padding-right: 12rem!important
    }

    .pe-sm-28 {
        padding-right: 13rem!important
    }

    .pe-sm-29 {
        padding-right: 14rem!important
    }

    .pe-sm-30 {
        padding-right: 15rem!important
    }

    .pe-sm-31 {
        padding-right: 16rem!important
    }

    .pe-sm-32 {
        padding-right: 17rem!important
    }

    .pe-sm-33 {
        padding-right: 18rem!important
    }

    .pe-sm-34 {
        padding-right: 19rem!important
    }

    .pe-sm-35 {
        padding-right: 20rem!important
    }

    .pb-sm-0 {
        padding-bottom: 0!important
    }

    .pb-sm-1 {
        padding-bottom: .25rem!important
    }

    .pb-sm-2 {
        padding-bottom: .5rem!important
    }

    .pb-sm-3 {
        padding-bottom: .75rem!important
    }

    .pb-sm-4 {
        padding-bottom: 1rem!important
    }

    .pb-sm-5 {
        padding-bottom: 1.25rem!important
    }

    .pb-sm-6 {
        padding-bottom: 1.5rem!important
    }

    .pb-sm-7 {
        padding-bottom: 1.75rem!important
    }

    .pb-sm-8 {
        padding-bottom: 2rem!important
    }

    .pb-sm-9 {
        padding-bottom: 2.5rem!important
    }

    .pb-sm-10 {
        padding-bottom: 3rem!important
    }

    .pb-sm-11 {
        padding-bottom: 3.5rem!important
    }

    .pb-sm-12 {
        padding-bottom: 4rem!important
    }

    .pb-sm-13 {
        padding-bottom: 4.5rem!important
    }

    .pb-sm-14 {
        padding-bottom: 5rem!important
    }

    .pb-sm-15 {
        padding-bottom: 5.5rem!important
    }

    .pb-sm-16 {
        padding-bottom: 6rem!important
    }

    .pb-sm-17 {
        padding-bottom: 6.5rem!important
    }

    .pb-sm-18 {
        padding-bottom: 7rem!important
    }

    .pb-sm-19 {
        padding-bottom: 7.5rem!important
    }

    .pb-sm-20 {
        padding-bottom: 8rem!important
    }

    .pb-sm-21 {
        padding-bottom: 8.5rem!important
    }

    .pb-sm-22 {
        padding-bottom: 9rem!important
    }

    .pb-sm-23 {
        padding-bottom: 9.5rem!important
    }

    .pb-sm-24 {
        padding-bottom: 10rem!important
    }

    .pb-sm-25 {
        padding-bottom: 10.5rem!important
    }

    .pb-sm-26 {
        padding-bottom: 11rem!important
    }

    .pb-sm-27 {
        padding-bottom: 12rem!important
    }

    .pb-sm-28 {
        padding-bottom: 13rem!important
    }

    .pb-sm-29 {
        padding-bottom: 14rem!important
    }

    .pb-sm-30 {
        padding-bottom: 15rem!important
    }

    .pb-sm-31 {
        padding-bottom: 16rem!important
    }

    .pb-sm-32 {
        padding-bottom: 17rem!important
    }

    .pb-sm-33 {
        padding-bottom: 18rem!important
    }

    .pb-sm-34 {
        padding-bottom: 19rem!important
    }

    .pb-sm-35 {
        padding-bottom: 20rem!important
    }

    .ps-sm-0 {
        padding-left: 0!important
    }

    .ps-sm-1 {
        padding-left: .25rem!important
    }

    .ps-sm-2 {
        padding-left: .5rem!important
    }

    .ps-sm-3 {
        padding-left: .75rem!important
    }

    .ps-sm-4 {
        padding-left: 1rem!important
    }

    .ps-sm-5 {
        padding-left: 1.25rem!important
    }

    .ps-sm-6 {
        padding-left: 1.5rem!important
    }

    .ps-sm-7 {
        padding-left: 1.75rem!important
    }

    .ps-sm-8 {
        padding-left: 2rem!important
    }

    .ps-sm-9 {
        padding-left: 2.5rem!important
    }

    .ps-sm-10 {
        padding-left: 3rem!important
    }

    .ps-sm-11 {
        padding-left: 3.5rem!important
    }

    .ps-sm-12 {
        padding-left: 4rem!important
    }

    .ps-sm-13 {
        padding-left: 4.5rem!important
    }

    .ps-sm-14 {
        padding-left: 5rem!important
    }

    .ps-sm-15 {
        padding-left: 5.5rem!important
    }

    .ps-sm-16 {
        padding-left: 6rem!important
    }

    .ps-sm-17 {
        padding-left: 6.5rem!important
    }

    .ps-sm-18 {
        padding-left: 7rem!important
    }

    .ps-sm-19 {
        padding-left: 7.5rem!important
    }

    .ps-sm-20 {
        padding-left: 8rem!important
    }

    .ps-sm-21 {
        padding-left: 8.5rem!important
    }

    .ps-sm-22 {
        padding-left: 9rem!important
    }

    .ps-sm-23 {
        padding-left: 9.5rem!important
    }

    .ps-sm-24 {
        padding-left: 10rem!important
    }

    .ps-sm-25 {
        padding-left: 10.5rem!important
    }

    .ps-sm-26 {
        padding-left: 11rem!important
    }

    .ps-sm-27 {
        padding-left: 12rem!important
    }

    .ps-sm-28 {
        padding-left: 13rem!important
    }

    .ps-sm-29 {
        padding-left: 14rem!important
    }

    .ps-sm-30 {
        padding-left: 15rem!important
    }

    .ps-sm-31 {
        padding-left: 16rem!important
    }

    .ps-sm-32 {
        padding-left: 17rem!important
    }

    .ps-sm-33 {
        padding-left: 18rem!important
    }

    .ps-sm-34 {
        padding-left: 19rem!important
    }

    .ps-sm-35 {
        padding-left: 20rem!important
    }

    .gap-sm-0 {
        gap: 0!important
    }

    .gap-sm-1 {
        gap: .25rem!important
    }

    .gap-sm-2 {
        gap: .5rem!important
    }

    .gap-sm-3 {
        gap: .75rem!important
    }

    .gap-sm-4 {
        gap: 1rem!important
    }

    .gap-sm-5 {
        gap: 1.25rem!important
    }

    .gap-sm-6 {
        gap: 1.5rem!important
    }

    .gap-sm-7 {
        gap: 1.75rem!important
    }

    .gap-sm-8 {
        gap: 2rem!important
    }

    .gap-sm-9 {
        gap: 2.5rem!important
    }

    .gap-sm-10 {
        gap: 3rem!important
    }

    .gap-sm-11 {
        gap: 3.5rem!important
    }

    .gap-sm-12 {
        gap: 4rem!important
    }

    .gap-sm-13 {
        gap: 4.5rem!important
    }

    .gap-sm-14 {
        gap: 5rem!important
    }

    .gap-sm-15 {
        gap: 5.5rem!important
    }

    .gap-sm-16 {
        gap: 6rem!important
    }

    .gap-sm-17 {
        gap: 6.5rem!important
    }

    .gap-sm-18 {
        gap: 7rem!important
    }

    .gap-sm-19 {
        gap: 7.5rem!important
    }

    .gap-sm-20 {
        gap: 8rem!important
    }

    .gap-sm-21 {
        gap: 8.5rem!important
    }

    .gap-sm-22 {
        gap: 9rem!important
    }

    .gap-sm-23 {
        gap: 9.5rem!important
    }

    .gap-sm-24 {
        gap: 10rem!important
    }

    .gap-sm-25 {
        gap: 10.5rem!important
    }

    .gap-sm-26 {
        gap: 11rem!important
    }

    .gap-sm-27 {
        gap: 12rem!important
    }

    .gap-sm-28 {
        gap: 13rem!important
    }

    .gap-sm-29 {
        gap: 14rem!important
    }

    .gap-sm-30 {
        gap: 15rem!important
    }

    .gap-sm-31 {
        gap: 16rem!important
    }

    .gap-sm-32 {
        gap: 17rem!important
    }

    .gap-sm-33 {
        gap: 18rem!important
    }

    .gap-sm-34 {
        gap: 19rem!important
    }

    .gap-sm-35 {
        gap: 20rem!important
    }

    .row-gap-sm-0 {
        row-gap: 0!important
    }

    .row-gap-sm-1 {
        row-gap: .25rem!important
    }

    .row-gap-sm-2 {
        row-gap: .5rem!important
    }

    .row-gap-sm-3 {
        row-gap: .75rem!important
    }

    .row-gap-sm-4 {
        row-gap: 1rem!important
    }

    .row-gap-sm-5 {
        row-gap: 1.25rem!important
    }

    .row-gap-sm-6 {
        row-gap: 1.5rem!important
    }

    .row-gap-sm-7 {
        row-gap: 1.75rem!important
    }

    .row-gap-sm-8 {
        row-gap: 2rem!important
    }

    .row-gap-sm-9 {
        row-gap: 2.5rem!important
    }

    .row-gap-sm-10 {
        row-gap: 3rem!important
    }

    .row-gap-sm-11 {
        row-gap: 3.5rem!important
    }

    .row-gap-sm-12 {
        row-gap: 4rem!important
    }

    .row-gap-sm-13 {
        row-gap: 4.5rem!important
    }

    .row-gap-sm-14 {
        row-gap: 5rem!important
    }

    .row-gap-sm-15 {
        row-gap: 5.5rem!important
    }

    .row-gap-sm-16 {
        row-gap: 6rem!important
    }

    .row-gap-sm-17 {
        row-gap: 6.5rem!important
    }

    .row-gap-sm-18 {
        row-gap: 7rem!important
    }

    .row-gap-sm-19 {
        row-gap: 7.5rem!important
    }

    .row-gap-sm-20 {
        row-gap: 8rem!important
    }

    .row-gap-sm-21 {
        row-gap: 8.5rem!important
    }

    .row-gap-sm-22 {
        row-gap: 9rem!important
    }

    .row-gap-sm-23 {
        row-gap: 9.5rem!important
    }

    .row-gap-sm-24 {
        row-gap: 10rem!important
    }

    .row-gap-sm-25 {
        row-gap: 10.5rem!important
    }

    .row-gap-sm-26 {
        row-gap: 11rem!important
    }

    .row-gap-sm-27 {
        row-gap: 12rem!important
    }

    .row-gap-sm-28 {
        row-gap: 13rem!important
    }

    .row-gap-sm-29 {
        row-gap: 14rem!important
    }

    .row-gap-sm-30 {
        row-gap: 15rem!important
    }

    .row-gap-sm-31 {
        row-gap: 16rem!important
    }

    .row-gap-sm-32 {
        row-gap: 17rem!important
    }

    .row-gap-sm-33 {
        row-gap: 18rem!important
    }

    .row-gap-sm-34 {
        row-gap: 19rem!important
    }

    .row-gap-sm-35 {
        row-gap: 20rem!important
    }

    .column-gap-sm-0 {
        -moz-column-gap: 0!important;
        column-gap: 0!important
    }

    .column-gap-sm-1 {
        -moz-column-gap: .25rem!important;
        column-gap: .25rem!important
    }

    .column-gap-sm-2 {
        -moz-column-gap: .5rem!important;
        column-gap: .5rem!important
    }

    .column-gap-sm-3 {
        -moz-column-gap: .75rem!important;
        column-gap: .75rem!important
    }

    .column-gap-sm-4 {
        -moz-column-gap: 1rem!important;
        column-gap: 1rem!important
    }

    .column-gap-sm-5 {
        -moz-column-gap: 1.25rem!important;
        column-gap: 1.25rem!important
    }

    .column-gap-sm-6 {
        -moz-column-gap: 1.5rem!important;
        column-gap: 1.5rem!important
    }

    .column-gap-sm-7 {
        -moz-column-gap: 1.75rem!important;
        column-gap: 1.75rem!important
    }

    .column-gap-sm-8 {
        -moz-column-gap: 2rem!important;
        column-gap: 2rem!important
    }

    .column-gap-sm-9 {
        -moz-column-gap: 2.5rem!important;
        column-gap: 2.5rem!important
    }

    .column-gap-sm-10 {
        -moz-column-gap: 3rem!important;
        column-gap: 3rem!important
    }

    .column-gap-sm-11 {
        -moz-column-gap: 3.5rem!important;
        column-gap: 3.5rem!important
    }

    .column-gap-sm-12 {
        -moz-column-gap: 4rem!important;
        column-gap: 4rem!important
    }

    .column-gap-sm-13 {
        -moz-column-gap: 4.5rem!important;
        column-gap: 4.5rem!important
    }

    .column-gap-sm-14 {
        -moz-column-gap: 5rem!important;
        column-gap: 5rem!important
    }

    .column-gap-sm-15 {
        -moz-column-gap: 5.5rem!important;
        column-gap: 5.5rem!important
    }

    .column-gap-sm-16 {
        -moz-column-gap: 6rem!important;
        column-gap: 6rem!important
    }

    .column-gap-sm-17 {
        -moz-column-gap: 6.5rem!important;
        column-gap: 6.5rem!important
    }

    .column-gap-sm-18 {
        -moz-column-gap: 7rem!important;
        column-gap: 7rem!important
    }

    .column-gap-sm-19 {
        -moz-column-gap: 7.5rem!important;
        column-gap: 7.5rem!important
    }

    .column-gap-sm-20 {
        -moz-column-gap: 8rem!important;
        column-gap: 8rem!important
    }

    .column-gap-sm-21 {
        -moz-column-gap: 8.5rem!important;
        column-gap: 8.5rem!important
    }

    .column-gap-sm-22 {
        -moz-column-gap: 9rem!important;
        column-gap: 9rem!important
    }

    .column-gap-sm-23 {
        -moz-column-gap: 9.5rem!important;
        column-gap: 9.5rem!important
    }

    .column-gap-sm-24 {
        -moz-column-gap: 10rem!important;
        column-gap: 10rem!important
    }

    .column-gap-sm-25 {
        -moz-column-gap: 10.5rem!important;
        column-gap: 10.5rem!important
    }

    .column-gap-sm-26 {
        -moz-column-gap: 11rem!important;
        column-gap: 11rem!important
    }

    .column-gap-sm-27 {
        -moz-column-gap: 12rem!important;
        column-gap: 12rem!important
    }

    .column-gap-sm-28 {
        -moz-column-gap: 13rem!important;
        column-gap: 13rem!important
    }

    .column-gap-sm-29 {
        -moz-column-gap: 14rem!important;
        column-gap: 14rem!important
    }

    .column-gap-sm-30 {
        -moz-column-gap: 15rem!important;
        column-gap: 15rem!important
    }

    .column-gap-sm-31 {
        -moz-column-gap: 16rem!important;
        column-gap: 16rem!important
    }

    .column-gap-sm-32 {
        -moz-column-gap: 17rem!important;
        column-gap: 17rem!important
    }

    .column-gap-sm-33 {
        -moz-column-gap: 18rem!important;
        column-gap: 18rem!important
    }

    .column-gap-sm-34 {
        -moz-column-gap: 19rem!important;
        column-gap: 19rem!important
    }

    .column-gap-sm-35 {
        -moz-column-gap: 20rem!important;
        column-gap: 20rem!important
    }

    .text-sm-start {
        text-align: left!important
    }

    .text-sm-end {
        text-align: right!important
    }

    .text-sm-center {
        text-align: center!important
    }
}

@media (min-width: 768px) {
    .float-md-start {
        float:left!important
    }

    .float-md-end {
        float: right!important
    }

    .float-md-none {
        float: none!important
    }

    .object-fit-md-contain {
        -o-object-fit: contain!important;
        object-fit: contain!important
    }

    .object-fit-md-cover {
        -o-object-fit: cover!important;
        object-fit: cover!important
    }

    .object-fit-md-fill {
        -o-object-fit: fill!important;
        object-fit: fill!important
    }

    .object-fit-md-scale {
        -o-object-fit: scale-down!important;
        object-fit: scale-down!important
    }

    .object-fit-md-none {
        -o-object-fit: none!important;
        object-fit: none!important
    }

    .d-md-inline {
        display: inline!important
    }

    .d-md-inline-block {
        display: inline-block!important
    }

    .d-md-block {
        display: block!important
    }

    .d-md-grid {
        display: grid!important
    }

    .d-md-table {
        display: table!important
    }

    .d-md-table-row {
        display: table-row!important
    }

    .d-md-table-cell {
        display: table-cell!important
    }

    .d-md-flex {
        display: flex!important
    }

    .d-md-inline-flex {
        display: inline-flex!important
    }

    .d-md-none {
        display: none!important
    }

    .flex-md-fill {
        flex: 1 1 auto!important
    }

    .flex-md-row {
        flex-direction: row!important
    }

    .flex-md-column {
        flex-direction: column!important
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse!important
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse!important
    }

    .flex-md-grow-0 {
        flex-grow: 0!important
    }

    .flex-md-grow-1 {
        flex-grow: 1!important
    }

    .flex-md-shrink-0 {
        flex-shrink: 0!important
    }

    .flex-md-shrink-1 {
        flex-shrink: 1!important
    }

    .flex-md-wrap {
        flex-wrap: wrap!important
    }

    .flex-md-nowrap {
        flex-wrap: nowrap!important
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }

    .justify-content-md-start {
        justify-content: flex-start!important
    }

    .justify-content-md-end {
        justify-content: flex-end!important
    }

    .justify-content-md-center {
        justify-content: center!important
    }

    .justify-content-md-between {
        justify-content: space-between!important
    }

    .justify-content-md-around {
        justify-content: space-around!important
    }

    .justify-content-md-evenly {
        justify-content: space-evenly!important
    }

    .align-items-md-start {
        align-items: flex-start!important
    }

    .align-items-md-end {
        align-items: flex-end!important
    }

    .align-items-md-center {
        align-items: center!important
    }

    .align-items-md-baseline {
        align-items: baseline!important
    }

    .align-items-md-stretch {
        align-items: stretch!important
    }

    .align-content-md-start {
        align-content: flex-start!important
    }

    .align-content-md-end {
        align-content: flex-end!important
    }

    .align-content-md-center {
        align-content: center!important
    }

    .align-content-md-between {
        align-content: space-between!important
    }

    .align-content-md-around {
        align-content: space-around!important
    }

    .align-content-md-stretch {
        align-content: stretch!important
    }

    .align-self-md-auto {
        align-self: auto!important
    }

    .align-self-md-start {
        align-self: flex-start!important
    }

    .align-self-md-end {
        align-self: flex-end!important
    }

    .align-self-md-center {
        align-self: center!important
    }

    .align-self-md-baseline {
        align-self: baseline!important
    }

    .align-self-md-stretch {
        align-self: stretch!important
    }

    .order-md-first {
        order: -1!important
    }

    .order-md-0 {
        order: 0!important
    }

    .order-md-1 {
        order: 1!important
    }

    .order-md-2 {
        order: 2!important
    }

    .order-md-3 {
        order: 3!important
    }

    .order-md-4 {
        order: 4!important
    }

    .order-md-5 {
        order: 5!important
    }

    .order-md-last {
        order: 6!important
    }

    .m-md-0 {
        margin: 0!important
    }

    .m-md-1 {
        margin: .25rem!important
    }

    .m-md-2 {
        margin: .5rem!important
    }

    .m-md-3 {
        margin: .75rem!important
    }

    .m-md-4 {
        margin: 1rem!important
    }

    .m-md-5 {
        margin: 1.25rem!important
    }

    .m-md-6 {
        margin: 1.5rem!important
    }

    .m-md-7 {
        margin: 1.75rem!important
    }

    .m-md-8 {
        margin: 2rem!important
    }

    .m-md-9 {
        margin: 2.5rem!important
    }

    .m-md-10 {
        margin: 3rem!important
    }

    .m-md-11 {
        margin: 3.5rem!important
    }

    .m-md-12 {
        margin: 4rem!important
    }

    .m-md-13 {
        margin: 4.5rem!important
    }

    .m-md-14 {
        margin: 5rem!important
    }

    .m-md-15 {
        margin: 5.5rem!important
    }

    .m-md-16 {
        margin: 6rem!important
    }

    .m-md-17 {
        margin: 6.5rem!important
    }

    .m-md-18 {
        margin: 7rem!important
    }

    .m-md-19 {
        margin: 7.5rem!important
    }

    .m-md-20 {
        margin: 8rem!important
    }

    .m-md-21 {
        margin: 8.5rem!important
    }

    .m-md-22 {
        margin: 9rem!important
    }

    .m-md-23 {
        margin: 9.5rem!important
    }

    .m-md-24 {
        margin: 10rem!important
    }

    .m-md-25 {
        margin: 10.5rem!important
    }

    .m-md-26 {
        margin: 11rem!important
    }

    .m-md-27 {
        margin: 12rem!important
    }

    .m-md-28 {
        margin: 13rem!important
    }

    .m-md-29 {
        margin: 14rem!important
    }

    .m-md-30 {
        margin: 15rem!important
    }

    .m-md-31 {
        margin: 16rem!important
    }

    .m-md-32 {
        margin: 17rem!important
    }

    .m-md-33 {
        margin: 18rem!important
    }

    .m-md-34 {
        margin: 19rem!important
    }

    .m-md-35 {
        margin: 20rem!important
    }

    .m-md-auto {
        margin: auto!important
    }

    .mx-md-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-md-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }

    .mx-md-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }

    .mx-md-3 {
        margin-right: .75rem!important;
        margin-left: .75rem!important
    }

    .mx-md-4 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-md-5 {
        margin-right: 1.25rem!important;
        margin-left: 1.25rem!important
    }

    .mx-md-6 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-md-7 {
        margin-right: 1.75rem!important;
        margin-left: 1.75rem!important
    }

    .mx-md-8 {
        margin-right: 2rem!important;
        margin-left: 2rem!important
    }

    .mx-md-9 {
        margin-right: 2.5rem!important;
        margin-left: 2.5rem!important
    }

    .mx-md-10 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-md-11 {
        margin-right: 3.5rem!important;
        margin-left: 3.5rem!important
    }

    .mx-md-12 {
        margin-right: 4rem!important;
        margin-left: 4rem!important
    }

    .mx-md-13 {
        margin-right: 4.5rem!important;
        margin-left: 4.5rem!important
    }

    .mx-md-14 {
        margin-right: 5rem!important;
        margin-left: 5rem!important
    }

    .mx-md-15 {
        margin-right: 5.5rem!important;
        margin-left: 5.5rem!important
    }

    .mx-md-16 {
        margin-right: 6rem!important;
        margin-left: 6rem!important
    }

    .mx-md-17 {
        margin-right: 6.5rem!important;
        margin-left: 6.5rem!important
    }

    .mx-md-18 {
        margin-right: 7rem!important;
        margin-left: 7rem!important
    }

    .mx-md-19 {
        margin-right: 7.5rem!important;
        margin-left: 7.5rem!important
    }

    .mx-md-20 {
        margin-right: 8rem!important;
        margin-left: 8rem!important
    }

    .mx-md-21 {
        margin-right: 8.5rem!important;
        margin-left: 8.5rem!important
    }

    .mx-md-22 {
        margin-right: 9rem!important;
        margin-left: 9rem!important
    }

    .mx-md-23 {
        margin-right: 9.5rem!important;
        margin-left: 9.5rem!important
    }

    .mx-md-24 {
        margin-right: 10rem!important;
        margin-left: 10rem!important
    }

    .mx-md-25 {
        margin-right: 10.5rem!important;
        margin-left: 10.5rem!important
    }

    .mx-md-26 {
        margin-right: 11rem!important;
        margin-left: 11rem!important
    }

    .mx-md-27 {
        margin-right: 12rem!important;
        margin-left: 12rem!important
    }

    .mx-md-28 {
        margin-right: 13rem!important;
        margin-left: 13rem!important
    }

    .mx-md-29 {
        margin-right: 14rem!important;
        margin-left: 14rem!important
    }

    .mx-md-30 {
        margin-right: 15rem!important;
        margin-left: 15rem!important
    }

    .mx-md-31 {
        margin-right: 16rem!important;
        margin-left: 16rem!important
    }

    .mx-md-32 {
        margin-right: 17rem!important;
        margin-left: 17rem!important
    }

    .mx-md-33 {
        margin-right: 18rem!important;
        margin-left: 18rem!important
    }

    .mx-md-34 {
        margin-right: 19rem!important;
        margin-left: 19rem!important
    }

    .mx-md-35 {
        margin-right: 20rem!important;
        margin-left: 20rem!important
    }

    .mx-md-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-md-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-md-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }

    .my-md-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .my-md-3 {
        margin-top: .75rem!important;
        margin-bottom: .75rem!important
    }

    .my-md-4 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-md-5 {
        margin-top: 1.25rem!important;
        margin-bottom: 1.25rem!important
    }

    .my-md-6 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-md-7 {
        margin-top: 1.75rem!important;
        margin-bottom: 1.75rem!important
    }

    .my-md-8 {
        margin-top: 2rem!important;
        margin-bottom: 2rem!important
    }

    .my-md-9 {
        margin-top: 2.5rem!important;
        margin-bottom: 2.5rem!important
    }

    .my-md-10 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-md-11 {
        margin-top: 3.5rem!important;
        margin-bottom: 3.5rem!important
    }

    .my-md-12 {
        margin-top: 4rem!important;
        margin-bottom: 4rem!important
    }

    .my-md-13 {
        margin-top: 4.5rem!important;
        margin-bottom: 4.5rem!important
    }

    .my-md-14 {
        margin-top: 5rem!important;
        margin-bottom: 5rem!important
    }

    .my-md-15 {
        margin-top: 5.5rem!important;
        margin-bottom: 5.5rem!important
    }

    .my-md-16 {
        margin-top: 6rem!important;
        margin-bottom: 6rem!important
    }

    .my-md-17 {
        margin-top: 6.5rem!important;
        margin-bottom: 6.5rem!important
    }

    .my-md-18 {
        margin-top: 7rem!important;
        margin-bottom: 7rem!important
    }

    .my-md-19 {
        margin-top: 7.5rem!important;
        margin-bottom: 7.5rem!important
    }

    .my-md-20 {
        margin-top: 8rem!important;
        margin-bottom: 8rem!important
    }

    .my-md-21 {
        margin-top: 8.5rem!important;
        margin-bottom: 8.5rem!important
    }

    .my-md-22 {
        margin-top: 9rem!important;
        margin-bottom: 9rem!important
    }

    .my-md-23 {
        margin-top: 9.5rem!important;
        margin-bottom: 9.5rem!important
    }

    .my-md-24 {
        margin-top: 10rem!important;
        margin-bottom: 10rem!important
    }

    .my-md-25 {
        margin-top: 10.5rem!important;
        margin-bottom: 10.5rem!important
    }

    .my-md-26 {
        margin-top: 11rem!important;
        margin-bottom: 11rem!important
    }

    .my-md-27 {
        margin-top: 12rem!important;
        margin-bottom: 12rem!important
    }

    .my-md-28 {
        margin-top: 13rem!important;
        margin-bottom: 13rem!important
    }

    .my-md-29 {
        margin-top: 14rem!important;
        margin-bottom: 14rem!important
    }

    .my-md-30 {
        margin-top: 15rem!important;
        margin-bottom: 15rem!important
    }

    .my-md-31 {
        margin-top: 16rem!important;
        margin-bottom: 16rem!important
    }

    .my-md-32 {
        margin-top: 17rem!important;
        margin-bottom: 17rem!important
    }

    .my-md-33 {
        margin-top: 18rem!important;
        margin-bottom: 18rem!important
    }

    .my-md-34 {
        margin-top: 19rem!important;
        margin-bottom: 19rem!important
    }

    .my-md-35 {
        margin-top: 20rem!important;
        margin-bottom: 20rem!important
    }

    .my-md-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-md-0 {
        margin-top: 0!important
    }

    .mt-md-1 {
        margin-top: .25rem!important
    }

    .mt-md-2 {
        margin-top: .5rem!important
    }

    .mt-md-3 {
        margin-top: .75rem!important
    }

    .mt-md-4 {
        margin-top: 1rem!important
    }

    .mt-md-5 {
        margin-top: 1.25rem!important
    }

    .mt-md-6 {
        margin-top: 1.5rem!important
    }

    .mt-md-7 {
        margin-top: 1.75rem!important
    }

    .mt-md-8 {
        margin-top: 2rem!important
    }

    .mt-md-9 {
        margin-top: 2.5rem!important
    }

    .mt-md-10 {
        margin-top: 3rem!important
    }

    .mt-md-11 {
        margin-top: 3.5rem!important
    }

    .mt-md-12 {
        margin-top: 4rem!important
    }

    .mt-md-13 {
        margin-top: 4.5rem!important
    }

    .mt-md-14 {
        margin-top: 5rem!important
    }

    .mt-md-15 {
        margin-top: 5.5rem!important
    }

    .mt-md-16 {
        margin-top: 6rem!important
    }

    .mt-md-17 {
        margin-top: 6.5rem!important
    }

    .mt-md-18 {
        margin-top: 7rem!important
    }

    .mt-md-19 {
        margin-top: 7.5rem!important
    }

    .mt-md-20 {
        margin-top: 8rem!important
    }

    .mt-md-21 {
        margin-top: 8.5rem!important
    }

    .mt-md-22 {
        margin-top: 9rem!important
    }

    .mt-md-23 {
        margin-top: 9.5rem!important
    }

    .mt-md-24 {
        margin-top: 10rem!important
    }

    .mt-md-25 {
        margin-top: 10.5rem!important
    }

    .mt-md-26 {
        margin-top: 11rem!important
    }

    .mt-md-27 {
        margin-top: 12rem!important
    }

    .mt-md-28 {
        margin-top: 13rem!important
    }

    .mt-md-29 {
        margin-top: 14rem!important
    }

    .mt-md-30 {
        margin-top: 15rem!important
    }

    .mt-md-31 {
        margin-top: 16rem!important
    }

    .mt-md-32 {
        margin-top: 17rem!important
    }

    .mt-md-33 {
        margin-top: 18rem!important
    }

    .mt-md-34 {
        margin-top: 19rem!important
    }

    .mt-md-35 {
        margin-top: 20rem!important
    }

    .mt-md-auto {
        margin-top: auto!important
    }

    .me-md-0 {
        margin-right: 0!important
    }

    .me-md-1 {
        margin-right: .25rem!important
    }

    .me-md-2 {
        margin-right: .5rem!important
    }

    .me-md-3 {
        margin-right: .75rem!important
    }

    .me-md-4 {
        margin-right: 1rem!important
    }

    .me-md-5 {
        margin-right: 1.25rem!important
    }

    .me-md-6 {
        margin-right: 1.5rem!important
    }

    .me-md-7 {
        margin-right: 1.75rem!important
    }

    .me-md-8 {
        margin-right: 2rem!important
    }

    .me-md-9 {
        margin-right: 2.5rem!important
    }

    .me-md-10 {
        margin-right: 3rem!important
    }

    .me-md-11 {
        margin-right: 3.5rem!important
    }

    .me-md-12 {
        margin-right: 4rem!important
    }

    .me-md-13 {
        margin-right: 4.5rem!important
    }

    .me-md-14 {
        margin-right: 5rem!important
    }

    .me-md-15 {
        margin-right: 5.5rem!important
    }

    .me-md-16 {
        margin-right: 6rem!important
    }

    .me-md-17 {
        margin-right: 6.5rem!important
    }

    .me-md-18 {
        margin-right: 7rem!important
    }

    .me-md-19 {
        margin-right: 7.5rem!important
    }

    .me-md-20 {
        margin-right: 8rem!important
    }

    .me-md-21 {
        margin-right: 8.5rem!important
    }

    .me-md-22 {
        margin-right: 9rem!important
    }

    .me-md-23 {
        margin-right: 9.5rem!important
    }

    .me-md-24 {
        margin-right: 10rem!important
    }

    .me-md-25 {
        margin-right: 10.5rem!important
    }

    .me-md-26 {
        margin-right: 11rem!important
    }

    .me-md-27 {
        margin-right: 12rem!important
    }

    .me-md-28 {
        margin-right: 13rem!important
    }

    .me-md-29 {
        margin-right: 14rem!important
    }

    .me-md-30 {
        margin-right: 15rem!important
    }

    .me-md-31 {
        margin-right: 16rem!important
    }

    .me-md-32 {
        margin-right: 17rem!important
    }

    .me-md-33 {
        margin-right: 18rem!important
    }

    .me-md-34 {
        margin-right: 19rem!important
    }

    .me-md-35 {
        margin-right: 20rem!important
    }

    .me-md-auto {
        margin-right: auto!important
    }

    .mb-md-0 {
        margin-bottom: 0!important
    }

    .mb-md-1 {
        margin-bottom: .25rem!important
    }

    .mb-md-2 {
        margin-bottom: .5rem!important
    }

    .mb-md-3 {
        margin-bottom: .75rem!important
    }

    .mb-md-4 {
        margin-bottom: 1rem!important
    }

    .mb-md-5 {
        margin-bottom: 1.25rem!important
    }

    .mb-md-6 {
        margin-bottom: 1.5rem!important
    }

    .mb-md-7 {
        margin-bottom: 1.75rem!important
    }

    .mb-md-8 {
        margin-bottom: 2rem!important
    }

    .mb-md-9 {
        margin-bottom: 2.5rem!important
    }

    .mb-md-10 {
        margin-bottom: 3rem!important
    }

    .mb-md-11 {
        margin-bottom: 3.5rem!important
    }

    .mb-md-12 {
        margin-bottom: 4rem!important
    }

    .mb-md-13 {
        margin-bottom: 4.5rem!important
    }

    .mb-md-14 {
        margin-bottom: 5rem!important
    }

    .mb-md-15 {
        margin-bottom: 5.5rem!important
    }

    .mb-md-16 {
        margin-bottom: 6rem!important
    }

    .mb-md-17 {
        margin-bottom: 6.5rem!important
    }

    .mb-md-18 {
        margin-bottom: 7rem!important
    }

    .mb-md-19 {
        margin-bottom: 7.5rem!important
    }

    .mb-md-20 {
        margin-bottom: 8rem!important
    }

    .mb-md-21 {
        margin-bottom: 8.5rem!important
    }

    .mb-md-22 {
        margin-bottom: 9rem!important
    }

    .mb-md-23 {
        margin-bottom: 9.5rem!important
    }

    .mb-md-24 {
        margin-bottom: 10rem!important
    }

    .mb-md-25 {
        margin-bottom: 10.5rem!important
    }

    .mb-md-26 {
        margin-bottom: 11rem!important
    }

    .mb-md-27 {
        margin-bottom: 12rem!important
    }

    .mb-md-28 {
        margin-bottom: 13rem!important
    }

    .mb-md-29 {
        margin-bottom: 14rem!important
    }

    .mb-md-30 {
        margin-bottom: 15rem!important
    }

    .mb-md-31 {
        margin-bottom: 16rem!important
    }

    .mb-md-32 {
        margin-bottom: 17rem!important
    }

    .mb-md-33 {
        margin-bottom: 18rem!important
    }

    .mb-md-34 {
        margin-bottom: 19rem!important
    }

    .mb-md-35 {
        margin-bottom: 20rem!important
    }

    .mb-md-auto {
        margin-bottom: auto!important
    }

    .ms-md-0 {
        margin-left: 0!important
    }

    .ms-md-1 {
        margin-left: .25rem!important
    }

    .ms-md-2 {
        margin-left: .5rem!important
    }

    .ms-md-3 {
        margin-left: .75rem!important
    }

    .ms-md-4 {
        margin-left: 1rem!important
    }

    .ms-md-5 {
        margin-left: 1.25rem!important
    }

    .ms-md-6 {
        margin-left: 1.5rem!important
    }

    .ms-md-7 {
        margin-left: 1.75rem!important
    }

    .ms-md-8 {
        margin-left: 2rem!important
    }

    .ms-md-9 {
        margin-left: 2.5rem!important
    }

    .ms-md-10 {
        margin-left: 3rem!important
    }

    .ms-md-11 {
        margin-left: 3.5rem!important
    }

    .ms-md-12 {
        margin-left: 4rem!important
    }

    .ms-md-13 {
        margin-left: 4.5rem!important
    }

    .ms-md-14 {
        margin-left: 5rem!important
    }

    .ms-md-15 {
        margin-left: 5.5rem!important
    }

    .ms-md-16 {
        margin-left: 6rem!important
    }

    .ms-md-17 {
        margin-left: 6.5rem!important
    }

    .ms-md-18 {
        margin-left: 7rem!important
    }

    .ms-md-19 {
        margin-left: 7.5rem!important
    }

    .ms-md-20 {
        margin-left: 8rem!important
    }

    .ms-md-21 {
        margin-left: 8.5rem!important
    }

    .ms-md-22 {
        margin-left: 9rem!important
    }

    .ms-md-23 {
        margin-left: 9.5rem!important
    }

    .ms-md-24 {
        margin-left: 10rem!important
    }

    .ms-md-25 {
        margin-left: 10.5rem!important
    }

    .ms-md-26 {
        margin-left: 11rem!important
    }

    .ms-md-27 {
        margin-left: 12rem!important
    }

    .ms-md-28 {
        margin-left: 13rem!important
    }

    .ms-md-29 {
        margin-left: 14rem!important
    }

    .ms-md-30 {
        margin-left: 15rem!important
    }

    .ms-md-31 {
        margin-left: 16rem!important
    }

    .ms-md-32 {
        margin-left: 17rem!important
    }

    .ms-md-33 {
        margin-left: 18rem!important
    }

    .ms-md-34 {
        margin-left: 19rem!important
    }

    .ms-md-35 {
        margin-left: 20rem!important
    }

    .ms-md-auto {
        margin-left: auto!important
    }

    .p-md-0 {
        padding: 0!important
    }

    .p-md-1 {
        padding: .25rem!important
    }

    .p-md-2 {
        padding: .5rem!important
    }

    .p-md-3 {
        padding: .75rem!important
    }

    .p-md-4 {
        padding: 1rem!important
    }

    .p-md-5 {
        padding: 1.25rem!important
    }

    .p-md-6 {
        padding: 1.5rem!important
    }

    .p-md-7 {
        padding: 1.75rem!important
    }

    .p-md-8 {
        padding: 2rem!important
    }

    .p-md-9 {
        padding: 2.5rem!important
    }

    .p-md-10 {
        padding: 3rem!important
    }

    .p-md-11 {
        padding: 3.5rem!important
    }

    .p-md-12 {
        padding: 4rem!important
    }

    .p-md-13 {
        padding: 4.5rem!important
    }

    .p-md-14 {
        padding: 5rem!important
    }

    .p-md-15 {
        padding: 5.5rem!important
    }

    .p-md-16 {
        padding: 6rem!important
    }

    .p-md-17 {
        padding: 6.5rem!important
    }

    .p-md-18 {
        padding: 7rem!important
    }

    .p-md-19 {
        padding: 7.5rem!important
    }

    .p-md-20 {
        padding: 8rem!important
    }

    .p-md-21 {
        padding: 8.5rem!important
    }

    .p-md-22 {
        padding: 9rem!important
    }

    .p-md-23 {
        padding: 9.5rem!important
    }

    .p-md-24 {
        padding: 10rem!important
    }

    .p-md-25 {
        padding: 10.5rem!important
    }

    .p-md-26 {
        padding: 11rem!important
    }

    .p-md-27 {
        padding: 12rem!important
    }

    .p-md-28 {
        padding: 13rem!important
    }

    .p-md-29 {
        padding: 14rem!important
    }

    .p-md-30 {
        padding: 15rem!important
    }

    .p-md-31 {
        padding: 16rem!important
    }

    .p-md-32 {
        padding: 17rem!important
    }

    .p-md-33 {
        padding: 18rem!important
    }

    .p-md-34 {
        padding: 19rem!important
    }

    .p-md-35 {
        padding: 20rem!important
    }

    .px-md-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-md-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }

    .px-md-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }

    .px-md-3 {
        padding-right: .75rem!important;
        padding-left: .75rem!important
    }

    .px-md-4 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-md-5 {
        padding-right: 1.25rem!important;
        padding-left: 1.25rem!important
    }

    .px-md-6 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-md-7 {
        padding-right: 1.75rem!important;
        padding-left: 1.75rem!important
    }

    .px-md-8 {
        padding-right: 2rem!important;
        padding-left: 2rem!important
    }

    .px-md-9 {
        padding-right: 2.5rem!important;
        padding-left: 2.5rem!important
    }

    .px-md-10 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .px-md-11 {
        padding-right: 3.5rem!important;
        padding-left: 3.5rem!important
    }

    .px-md-12 {
        padding-right: 4rem!important;
        padding-left: 4rem!important
    }

    .px-md-13 {
        padding-right: 4.5rem!important;
        padding-left: 4.5rem!important
    }

    .px-md-14 {
        padding-right: 5rem!important;
        padding-left: 5rem!important
    }

    .px-md-15 {
        padding-right: 5.5rem!important;
        padding-left: 5.5rem!important
    }

    .px-md-16 {
        padding-right: 6rem!important;
        padding-left: 6rem!important
    }

    .px-md-17 {
        padding-right: 6.5rem!important;
        padding-left: 6.5rem!important
    }

    .px-md-18 {
        padding-right: 7rem!important;
        padding-left: 7rem!important
    }

    .px-md-19 {
        padding-right: 7.5rem!important;
        padding-left: 7.5rem!important
    }

    .px-md-20 {
        padding-right: 8rem!important;
        padding-left: 8rem!important
    }

    .px-md-21 {
        padding-right: 8.5rem!important;
        padding-left: 8.5rem!important
    }

    .px-md-22 {
        padding-right: 9rem!important;
        padding-left: 9rem!important
    }

    .px-md-23 {
        padding-right: 9.5rem!important;
        padding-left: 9.5rem!important
    }

    .px-md-24 {
        padding-right: 10rem!important;
        padding-left: 10rem!important
    }

    .px-md-25 {
        padding-right: 10.5rem!important;
        padding-left: 10.5rem!important
    }

    .px-md-26 {
        padding-right: 11rem!important;
        padding-left: 11rem!important
    }

    .px-md-27 {
        padding-right: 12rem!important;
        padding-left: 12rem!important
    }

    .px-md-28 {
        padding-right: 13rem!important;
        padding-left: 13rem!important
    }

    .px-md-29 {
        padding-right: 14rem!important;
        padding-left: 14rem!important
    }

    .px-md-30 {
        padding-right: 15rem!important;
        padding-left: 15rem!important
    }

    .px-md-31 {
        padding-right: 16rem!important;
        padding-left: 16rem!important
    }

    .px-md-32 {
        padding-right: 17rem!important;
        padding-left: 17rem!important
    }

    .px-md-33 {
        padding-right: 18rem!important;
        padding-left: 18rem!important
    }

    .px-md-34 {
        padding-right: 19rem!important;
        padding-left: 19rem!important
    }

    .px-md-35 {
        padding-right: 20rem!important;
        padding-left: 20rem!important
    }

    .py-md-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-md-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }

    .py-md-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }

    .py-md-3 {
        padding-top: .75rem!important;
        padding-bottom: .75rem!important
    }

    .py-md-4 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-md-5 {
        padding-top: 1.25rem!important;
        padding-bottom: 1.25rem!important
    }

    .py-md-6 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-md-7 {
        padding-top: 1.75rem!important;
        padding-bottom: 1.75rem!important
    }

    .py-md-8 {
        padding-top: 2rem!important;
        padding-bottom: 2rem!important
    }

    .py-md-9 {
        padding-top: 2.5rem!important;
        padding-bottom: 2.5rem!important
    }

    .py-md-10 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .py-md-11 {
        padding-top: 3.5rem!important;
        padding-bottom: 3.5rem!important
    }

    .py-md-12 {
        padding-top: 4rem!important;
        padding-bottom: 4rem!important
    }

    .py-md-13 {
        padding-top: 4.5rem!important;
        padding-bottom: 4.5rem!important
    }

    .py-md-14 {
        padding-top: 5rem!important;
        padding-bottom: 5rem!important
    }

    .py-md-15 {
        padding-top: 5.5rem!important;
        padding-bottom: 5.5rem!important
    }

    .py-md-16 {
        padding-top: 6rem!important;
        padding-bottom: 6rem!important
    }

    .py-md-17 {
        padding-top: 6.5rem!important;
        padding-bottom: 6.5rem!important
    }

    .py-md-18 {
        padding-top: 7rem!important;
        padding-bottom: 7rem!important
    }

    .py-md-19 {
        padding-top: 7.5rem!important;
        padding-bottom: 7.5rem!important
    }

    .py-md-20 {
        padding-top: 8rem!important;
        padding-bottom: 8rem!important
    }

    .py-md-21 {
        padding-top: 8.5rem!important;
        padding-bottom: 8.5rem!important
    }

    .py-md-22 {
        padding-top: 9rem!important;
        padding-bottom: 9rem!important
    }

    .py-md-23 {
        padding-top: 9.5rem!important;
        padding-bottom: 9.5rem!important
    }

    .py-md-24 {
        padding-top: 10rem!important;
        padding-bottom: 10rem!important
    }

    .py-md-25 {
        padding-top: 10.5rem!important;
        padding-bottom: 10.5rem!important
    }

    .py-md-26 {
        padding-top: 11rem!important;
        padding-bottom: 11rem!important
    }

    .py-md-27 {
        padding-top: 12rem!important;
        padding-bottom: 12rem!important
    }

    .py-md-28 {
        padding-top: 13rem!important;
        padding-bottom: 13rem!important
    }

    .py-md-29 {
        padding-top: 14rem!important;
        padding-bottom: 14rem!important
    }

    .py-md-30 {
        padding-top: 15rem!important;
        padding-bottom: 15rem!important
    }

    .py-md-31 {
        padding-top: 16rem!important;
        padding-bottom: 16rem!important
    }

    .py-md-32 {
        padding-top: 17rem!important;
        padding-bottom: 17rem!important
    }

    .py-md-33 {
        padding-top: 18rem!important;
        padding-bottom: 18rem!important
    }

    .py-md-34 {
        padding-top: 19rem!important;
        padding-bottom: 19rem!important
    }

    .py-md-35 {
        padding-top: 20rem!important;
        padding-bottom: 20rem!important
    }

    .pt-md-0 {
        padding-top: 0!important
    }

    .pt-md-1 {
        padding-top: .25rem!important
    }

    .pt-md-2 {
        padding-top: .5rem!important
    }

    .pt-md-3 {
        padding-top: .75rem!important
    }

    .pt-md-4 {
        padding-top: 1rem!important
    }

    .pt-md-5 {
        padding-top: 1.25rem!important
    }

    .pt-md-6 {
        padding-top: 1.5rem!important
    }

    .pt-md-7 {
        padding-top: 1.75rem!important
    }

    .pt-md-8 {
        padding-top: 2rem!important
    }

    .pt-md-9 {
        padding-top: 2.5rem!important
    }

    .pt-md-10 {
        padding-top: 3rem!important
    }

    .pt-md-11 {
        padding-top: 3.5rem!important
    }

    .pt-md-12 {
        padding-top: 4rem!important
    }

    .pt-md-13 {
        padding-top: 4.5rem!important
    }

    .pt-md-14 {
        padding-top: 5rem!important
    }

    .pt-md-15 {
        padding-top: 5.5rem!important
    }

    .pt-md-16 {
        padding-top: 6rem!important
    }

    .pt-md-17 {
        padding-top: 6.5rem!important
    }

    .pt-md-18 {
        padding-top: 7rem!important
    }

    .pt-md-19 {
        padding-top: 7.5rem!important
    }

    .pt-md-20 {
        padding-top: 8rem!important
    }

    .pt-md-21 {
        padding-top: 8.5rem!important
    }

    .pt-md-22 {
        padding-top: 9rem!important
    }

    .pt-md-23 {
        padding-top: 9.5rem!important
    }

    .pt-md-24 {
        padding-top: 10rem!important
    }

    .pt-md-25 {
        padding-top: 10.5rem!important
    }

    .pt-md-26 {
        padding-top: 11rem!important
    }

    .pt-md-27 {
        padding-top: 12rem!important
    }

    .pt-md-28 {
        padding-top: 13rem!important
    }

    .pt-md-29 {
        padding-top: 14rem!important
    }

    .pt-md-30 {
        padding-top: 15rem!important
    }

    .pt-md-31 {
        padding-top: 16rem!important
    }

    .pt-md-32 {
        padding-top: 17rem!important
    }

    .pt-md-33 {
        padding-top: 18rem!important
    }

    .pt-md-34 {
        padding-top: 19rem!important
    }

    .pt-md-35 {
        padding-top: 20rem!important
    }

    .pe-md-0 {
        padding-right: 0!important
    }

    .pe-md-1 {
        padding-right: .25rem!important
    }

    .pe-md-2 {
        padding-right: .5rem!important
    }

    .pe-md-3 {
        padding-right: .75rem!important
    }

    .pe-md-4 {
        padding-right: 1rem!important
    }

    .pe-md-5 {
        padding-right: 1.25rem!important
    }

    .pe-md-6 {
        padding-right: 1.5rem!important
    }

    .pe-md-7 {
        padding-right: 1.75rem!important
    }

    .pe-md-8 {
        padding-right: 2rem!important
    }

    .pe-md-9 {
        padding-right: 2.5rem!important
    }

    .pe-md-10 {
        padding-right: 3rem!important
    }

    .pe-md-11 {
        padding-right: 3.5rem!important
    }

    .pe-md-12 {
        padding-right: 4rem!important
    }

    .pe-md-13 {
        padding-right: 4.5rem!important
    }

    .pe-md-14 {
        padding-right: 5rem!important
    }

    .pe-md-15 {
        padding-right: 5.5rem!important
    }

    .pe-md-16 {
        padding-right: 6rem!important
    }

    .pe-md-17 {
        padding-right: 6.5rem!important
    }

    .pe-md-18 {
        padding-right: 7rem!important
    }

    .pe-md-19 {
        padding-right: 7.5rem!important
    }

    .pe-md-20 {
        padding-right: 8rem!important
    }

    .pe-md-21 {
        padding-right: 8.5rem!important
    }

    .pe-md-22 {
        padding-right: 9rem!important
    }

    .pe-md-23 {
        padding-right: 9.5rem!important
    }

    .pe-md-24 {
        padding-right: 10rem!important
    }

    .pe-md-25 {
        padding-right: 10.5rem!important
    }

    .pe-md-26 {
        padding-right: 11rem!important
    }

    .pe-md-27 {
        padding-right: 12rem!important
    }

    .pe-md-28 {
        padding-right: 13rem!important
    }

    .pe-md-29 {
        padding-right: 14rem!important
    }

    .pe-md-30 {
        padding-right: 15rem!important
    }

    .pe-md-31 {
        padding-right: 16rem!important
    }

    .pe-md-32 {
        padding-right: 17rem!important
    }

    .pe-md-33 {
        padding-right: 18rem!important
    }

    .pe-md-34 {
        padding-right: 19rem!important
    }

    .pe-md-35 {
        padding-right: 20rem!important
    }

    .pb-md-0 {
        padding-bottom: 0!important
    }

    .pb-md-1 {
        padding-bottom: .25rem!important
    }

    .pb-md-2 {
        padding-bottom: .5rem!important
    }

    .pb-md-3 {
        padding-bottom: .75rem!important
    }

    .pb-md-4 {
        padding-bottom: 1rem!important
    }

    .pb-md-5 {
        padding-bottom: 1.25rem!important
    }

    .pb-md-6 {
        padding-bottom: 1.5rem!important
    }

    .pb-md-7 {
        padding-bottom: 1.75rem!important
    }

    .pb-md-8 {
        padding-bottom: 2rem!important
    }

    .pb-md-9 {
        padding-bottom: 2.5rem!important
    }

    .pb-md-10 {
        padding-bottom: 3rem!important
    }

    .pb-md-11 {
        padding-bottom: 3.5rem!important
    }

    .pb-md-12 {
        padding-bottom: 4rem!important
    }

    .pb-md-13 {
        padding-bottom: 4.5rem!important
    }

    .pb-md-14 {
        padding-bottom: 5rem!important
    }

    .pb-md-15 {
        padding-bottom: 5.5rem!important
    }

    .pb-md-16 {
        padding-bottom: 6rem!important
    }

    .pb-md-17 {
        padding-bottom: 6.5rem!important
    }

    .pb-md-18 {
        padding-bottom: 7rem!important
    }

    .pb-md-19 {
        padding-bottom: 7.5rem!important
    }

    .pb-md-20 {
        padding-bottom: 8rem!important
    }

    .pb-md-21 {
        padding-bottom: 8.5rem!important
    }

    .pb-md-22 {
        padding-bottom: 9rem!important
    }

    .pb-md-23 {
        padding-bottom: 9.5rem!important
    }

    .pb-md-24 {
        padding-bottom: 10rem!important
    }

    .pb-md-25 {
        padding-bottom: 10.5rem!important
    }

    .pb-md-26 {
        padding-bottom: 11rem!important
    }

    .pb-md-27 {
        padding-bottom: 12rem!important
    }

    .pb-md-28 {
        padding-bottom: 13rem!important
    }

    .pb-md-29 {
        padding-bottom: 14rem!important
    }

    .pb-md-30 {
        padding-bottom: 15rem!important
    }

    .pb-md-31 {
        padding-bottom: 16rem!important
    }

    .pb-md-32 {
        padding-bottom: 17rem!important
    }

    .pb-md-33 {
        padding-bottom: 18rem!important
    }

    .pb-md-34 {
        padding-bottom: 19rem!important
    }

    .pb-md-35 {
        padding-bottom: 20rem!important
    }

    .ps-md-0 {
        padding-left: 0!important
    }

    .ps-md-1 {
        padding-left: .25rem!important
    }

    .ps-md-2 {
        padding-left: .5rem!important
    }

    .ps-md-3 {
        padding-left: .75rem!important
    }

    .ps-md-4 {
        padding-left: 1rem!important
    }

    .ps-md-5 {
        padding-left: 1.25rem!important
    }

    .ps-md-6 {
        padding-left: 1.5rem!important
    }

    .ps-md-7 {
        padding-left: 1.75rem!important
    }

    .ps-md-8 {
        padding-left: 2rem!important
    }

    .ps-md-9 {
        padding-left: 2.5rem!important
    }

    .ps-md-10 {
        padding-left: 3rem!important
    }

    .ps-md-11 {
        padding-left: 3.5rem!important
    }

    .ps-md-12 {
        padding-left: 4rem!important
    }

    .ps-md-13 {
        padding-left: 4.5rem!important
    }

    .ps-md-14 {
        padding-left: 5rem!important
    }

    .ps-md-15 {
        padding-left: 5.5rem!important
    }

    .ps-md-16 {
        padding-left: 6rem!important
    }

    .ps-md-17 {
        padding-left: 6.5rem!important
    }

    .ps-md-18 {
        padding-left: 7rem!important
    }

    .ps-md-19 {
        padding-left: 7.5rem!important
    }

    .ps-md-20 {
        padding-left: 8rem!important
    }

    .ps-md-21 {
        padding-left: 8.5rem!important
    }

    .ps-md-22 {
        padding-left: 9rem!important
    }

    .ps-md-23 {
        padding-left: 9.5rem!important
    }

    .ps-md-24 {
        padding-left: 10rem!important
    }

    .ps-md-25 {
        padding-left: 10.5rem!important
    }

    .ps-md-26 {
        padding-left: 11rem!important
    }

    .ps-md-27 {
        padding-left: 12rem!important
    }

    .ps-md-28 {
        padding-left: 13rem!important
    }

    .ps-md-29 {
        padding-left: 14rem!important
    }

    .ps-md-30 {
        padding-left: 15rem!important
    }

    .ps-md-31 {
        padding-left: 16rem!important
    }

    .ps-md-32 {
        padding-left: 17rem!important
    }

    .ps-md-33 {
        padding-left: 18rem!important
    }

    .ps-md-34 {
        padding-left: 19rem!important
    }

    .ps-md-35 {
        padding-left: 20rem!important
    }

    .gap-md-0 {
        gap: 0!important
    }

    .gap-md-1 {
        gap: .25rem!important
    }

    .gap-md-2 {
        gap: .5rem!important
    }

    .gap-md-3 {
        gap: .75rem!important
    }

    .gap-md-4 {
        gap: 1rem!important
    }

    .gap-md-5 {
        gap: 1.25rem!important
    }

    .gap-md-6 {
        gap: 1.5rem!important
    }

    .gap-md-7 {
        gap: 1.75rem!important
    }

    .gap-md-8 {
        gap: 2rem!important
    }

    .gap-md-9 {
        gap: 2.5rem!important
    }

    .gap-md-10 {
        gap: 3rem!important
    }

    .gap-md-11 {
        gap: 3.5rem!important
    }

    .gap-md-12 {
        gap: 4rem!important
    }

    .gap-md-13 {
        gap: 4.5rem!important
    }

    .gap-md-14 {
        gap: 5rem!important
    }

    .gap-md-15 {
        gap: 5.5rem!important
    }

    .gap-md-16 {
        gap: 6rem!important
    }

    .gap-md-17 {
        gap: 6.5rem!important
    }

    .gap-md-18 {
        gap: 7rem!important
    }

    .gap-md-19 {
        gap: 7.5rem!important
    }

    .gap-md-20 {
        gap: 8rem!important
    }

    .gap-md-21 {
        gap: 8.5rem!important
    }

    .gap-md-22 {
        gap: 9rem!important
    }

    .gap-md-23 {
        gap: 9.5rem!important
    }

    .gap-md-24 {
        gap: 10rem!important
    }

    .gap-md-25 {
        gap: 10.5rem!important
    }

    .gap-md-26 {
        gap: 11rem!important
    }

    .gap-md-27 {
        gap: 12rem!important
    }

    .gap-md-28 {
        gap: 13rem!important
    }

    .gap-md-29 {
        gap: 14rem!important
    }

    .gap-md-30 {
        gap: 15rem!important
    }

    .gap-md-31 {
        gap: 16rem!important
    }

    .gap-md-32 {
        gap: 17rem!important
    }

    .gap-md-33 {
        gap: 18rem!important
    }

    .gap-md-34 {
        gap: 19rem!important
    }

    .gap-md-35 {
        gap: 20rem!important
    }

    .row-gap-md-0 {
        row-gap: 0!important
    }

    .row-gap-md-1 {
        row-gap: .25rem!important
    }

    .row-gap-md-2 {
        row-gap: .5rem!important
    }

    .row-gap-md-3 {
        row-gap: .75rem!important
    }

    .row-gap-md-4 {
        row-gap: 1rem!important
    }

    .row-gap-md-5 {
        row-gap: 1.25rem!important
    }

    .row-gap-md-6 {
        row-gap: 1.5rem!important
    }

    .row-gap-md-7 {
        row-gap: 1.75rem!important
    }

    .row-gap-md-8 {
        row-gap: 2rem!important
    }

    .row-gap-md-9 {
        row-gap: 2.5rem!important
    }

    .row-gap-md-10 {
        row-gap: 3rem!important
    }

    .row-gap-md-11 {
        row-gap: 3.5rem!important
    }

    .row-gap-md-12 {
        row-gap: 4rem!important
    }

    .row-gap-md-13 {
        row-gap: 4.5rem!important
    }

    .row-gap-md-14 {
        row-gap: 5rem!important
    }

    .row-gap-md-15 {
        row-gap: 5.5rem!important
    }

    .row-gap-md-16 {
        row-gap: 6rem!important
    }

    .row-gap-md-17 {
        row-gap: 6.5rem!important
    }

    .row-gap-md-18 {
        row-gap: 7rem!important
    }

    .row-gap-md-19 {
        row-gap: 7.5rem!important
    }

    .row-gap-md-20 {
        row-gap: 8rem!important
    }

    .row-gap-md-21 {
        row-gap: 8.5rem!important
    }

    .row-gap-md-22 {
        row-gap: 9rem!important
    }

    .row-gap-md-23 {
        row-gap: 9.5rem!important
    }

    .row-gap-md-24 {
        row-gap: 10rem!important
    }

    .row-gap-md-25 {
        row-gap: 10.5rem!important
    }

    .row-gap-md-26 {
        row-gap: 11rem!important
    }

    .row-gap-md-27 {
        row-gap: 12rem!important
    }

    .row-gap-md-28 {
        row-gap: 13rem!important
    }

    .row-gap-md-29 {
        row-gap: 14rem!important
    }

    .row-gap-md-30 {
        row-gap: 15rem!important
    }

    .row-gap-md-31 {
        row-gap: 16rem!important
    }

    .row-gap-md-32 {
        row-gap: 17rem!important
    }

    .row-gap-md-33 {
        row-gap: 18rem!important
    }

    .row-gap-md-34 {
        row-gap: 19rem!important
    }

    .row-gap-md-35 {
        row-gap: 20rem!important
    }

    .column-gap-md-0 {
        -moz-column-gap: 0!important;
        column-gap: 0!important
    }

    .column-gap-md-1 {
        -moz-column-gap: .25rem!important;
        column-gap: .25rem!important
    }

    .column-gap-md-2 {
        -moz-column-gap: .5rem!important;
        column-gap: .5rem!important
    }

    .column-gap-md-3 {
        -moz-column-gap: .75rem!important;
        column-gap: .75rem!important
    }

    .column-gap-md-4 {
        -moz-column-gap: 1rem!important;
        column-gap: 1rem!important
    }

    .column-gap-md-5 {
        -moz-column-gap: 1.25rem!important;
        column-gap: 1.25rem!important
    }

    .column-gap-md-6 {
        -moz-column-gap: 1.5rem!important;
        column-gap: 1.5rem!important
    }

    .column-gap-md-7 {
        -moz-column-gap: 1.75rem!important;
        column-gap: 1.75rem!important
    }

    .column-gap-md-8 {
        -moz-column-gap: 2rem!important;
        column-gap: 2rem!important
    }

    .column-gap-md-9 {
        -moz-column-gap: 2.5rem!important;
        column-gap: 2.5rem!important
    }

    .column-gap-md-10 {
        -moz-column-gap: 3rem!important;
        column-gap: 3rem!important
    }

    .column-gap-md-11 {
        -moz-column-gap: 3.5rem!important;
        column-gap: 3.5rem!important
    }

    .column-gap-md-12 {
        -moz-column-gap: 4rem!important;
        column-gap: 4rem!important
    }

    .column-gap-md-13 {
        -moz-column-gap: 4.5rem!important;
        column-gap: 4.5rem!important
    }

    .column-gap-md-14 {
        -moz-column-gap: 5rem!important;
        column-gap: 5rem!important
    }

    .column-gap-md-15 {
        -moz-column-gap: 5.5rem!important;
        column-gap: 5.5rem!important
    }

    .column-gap-md-16 {
        -moz-column-gap: 6rem!important;
        column-gap: 6rem!important
    }

    .column-gap-md-17 {
        -moz-column-gap: 6.5rem!important;
        column-gap: 6.5rem!important
    }

    .column-gap-md-18 {
        -moz-column-gap: 7rem!important;
        column-gap: 7rem!important
    }

    .column-gap-md-19 {
        -moz-column-gap: 7.5rem!important;
        column-gap: 7.5rem!important
    }

    .column-gap-md-20 {
        -moz-column-gap: 8rem!important;
        column-gap: 8rem!important
    }

    .column-gap-md-21 {
        -moz-column-gap: 8.5rem!important;
        column-gap: 8.5rem!important
    }

    .column-gap-md-22 {
        -moz-column-gap: 9rem!important;
        column-gap: 9rem!important
    }

    .column-gap-md-23 {
        -moz-column-gap: 9.5rem!important;
        column-gap: 9.5rem!important
    }

    .column-gap-md-24 {
        -moz-column-gap: 10rem!important;
        column-gap: 10rem!important
    }

    .column-gap-md-25 {
        -moz-column-gap: 10.5rem!important;
        column-gap: 10.5rem!important
    }

    .column-gap-md-26 {
        -moz-column-gap: 11rem!important;
        column-gap: 11rem!important
    }

    .column-gap-md-27 {
        -moz-column-gap: 12rem!important;
        column-gap: 12rem!important
    }

    .column-gap-md-28 {
        -moz-column-gap: 13rem!important;
        column-gap: 13rem!important
    }

    .column-gap-md-29 {
        -moz-column-gap: 14rem!important;
        column-gap: 14rem!important
    }

    .column-gap-md-30 {
        -moz-column-gap: 15rem!important;
        column-gap: 15rem!important
    }

    .column-gap-md-31 {
        -moz-column-gap: 16rem!important;
        column-gap: 16rem!important
    }

    .column-gap-md-32 {
        -moz-column-gap: 17rem!important;
        column-gap: 17rem!important
    }

    .column-gap-md-33 {
        -moz-column-gap: 18rem!important;
        column-gap: 18rem!important
    }

    .column-gap-md-34 {
        -moz-column-gap: 19rem!important;
        column-gap: 19rem!important
    }

    .column-gap-md-35 {
        -moz-column-gap: 20rem!important;
        column-gap: 20rem!important
    }

    .text-md-start {
        text-align: left!important
    }

    .text-md-end {
        text-align: right!important
    }

    .text-md-center {
        text-align: center!important
    }
}

@media (min-width: 992px) {
    .float-lg-start {
        float:left!important
    }

    .float-lg-end {
        float: right!important
    }

    .float-lg-none {
        float: none!important
    }

    .object-fit-lg-contain {
        -o-object-fit: contain!important;
        object-fit: contain!important
    }

    .object-fit-lg-cover {
        -o-object-fit: cover!important;
        object-fit: cover!important
    }

    .object-fit-lg-fill {
        -o-object-fit: fill!important;
        object-fit: fill!important
    }

    .object-fit-lg-scale {
        -o-object-fit: scale-down!important;
        object-fit: scale-down!important
    }

    .object-fit-lg-none {
        -o-object-fit: none!important;
        object-fit: none!important
    }

    .d-lg-inline {
        display: inline!important
    }

    .d-lg-inline-block {
        display: inline-block!important
    }

    .d-lg-block {
        display: block!important
    }

    .d-lg-grid {
        display: grid!important
    }

    .d-lg-table {
        display: table!important
    }

    .d-lg-table-row {
        display: table-row!important
    }

    .d-lg-table-cell {
        display: table-cell!important
    }

    .d-lg-flex {
        display: flex!important
    }

    .d-lg-inline-flex {
        display: inline-flex!important
    }

    .d-lg-none {
        display: none!important
    }

    .flex-lg-fill {
        flex: 1 1 auto!important
    }

    .flex-lg-row {
        flex-direction: row!important
    }

    .flex-lg-column {
        flex-direction: column!important
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse!important
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse!important
    }

    .flex-lg-grow-0 {
        flex-grow: 0!important
    }

    .flex-lg-grow-1 {
        flex-grow: 1!important
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0!important
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1!important
    }

    .flex-lg-wrap {
        flex-wrap: wrap!important
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap!important
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }

    .justify-content-lg-start {
        justify-content: flex-start!important
    }

    .justify-content-lg-end {
        justify-content: flex-end!important
    }

    .justify-content-lg-center {
        justify-content: center!important
    }

    .justify-content-lg-between {
        justify-content: space-between!important
    }

    .justify-content-lg-around {
        justify-content: space-around!important
    }

    .justify-content-lg-evenly {
        justify-content: space-evenly!important
    }

    .align-items-lg-start {
        align-items: flex-start!important
    }

    .align-items-lg-end {
        align-items: flex-end!important
    }

    .align-items-lg-center {
        align-items: center!important
    }

    .align-items-lg-baseline {
        align-items: baseline!important
    }

    .align-items-lg-stretch {
        align-items: stretch!important
    }

    .align-content-lg-start {
        align-content: flex-start!important
    }

    .align-content-lg-end {
        align-content: flex-end!important
    }

    .align-content-lg-center {
        align-content: center!important
    }

    .align-content-lg-between {
        align-content: space-between!important
    }

    .align-content-lg-around {
        align-content: space-around!important
    }

    .align-content-lg-stretch {
        align-content: stretch!important
    }

    .align-self-lg-auto {
        align-self: auto!important
    }

    .align-self-lg-start {
        align-self: flex-start!important
    }

    .align-self-lg-end {
        align-self: flex-end!important
    }

    .align-self-lg-center {
        align-self: center!important
    }

    .align-self-lg-baseline {
        align-self: baseline!important
    }

    .align-self-lg-stretch {
        align-self: stretch!important
    }

    .order-lg-first {
        order: -1!important
    }

    .order-lg-0 {
        order: 0!important
    }

    .order-lg-1 {
        order: 1!important
    }

    .order-lg-2 {
        order: 2!important
    }

    .order-lg-3 {
        order: 3!important
    }

    .order-lg-4 {
        order: 4!important
    }

    .order-lg-5 {
        order: 5!important
    }

    .order-lg-last {
        order: 6!important
    }

    .m-lg-0 {
        margin: 0!important
    }

    .m-lg-1 {
        margin: .25rem!important
    }

    .m-lg-2 {
        margin: .5rem!important
    }

    .m-lg-3 {
        margin: .75rem!important
    }

    .m-lg-4 {
        margin: 1rem!important
    }

    .m-lg-5 {
        margin: 1.25rem!important
    }

    .m-lg-6 {
        margin: 1.5rem!important
    }

    .m-lg-7 {
        margin: 1.75rem!important
    }

    .m-lg-8 {
        margin: 2rem!important
    }

    .m-lg-9 {
        margin: 2.5rem!important
    }

    .m-lg-10 {
        margin: 3rem!important
    }

    .m-lg-11 {
        margin: 3.5rem!important
    }

    .m-lg-12 {
        margin: 4rem!important
    }

    .m-lg-13 {
        margin: 4.5rem!important
    }

    .m-lg-14 {
        margin: 5rem!important
    }

    .m-lg-15 {
        margin: 5.5rem!important
    }

    .m-lg-16 {
        margin: 6rem!important
    }

    .m-lg-17 {
        margin: 6.5rem!important
    }

    .m-lg-18 {
        margin: 7rem!important
    }

    .m-lg-19 {
        margin: 7.5rem!important
    }

    .m-lg-20 {
        margin: 8rem!important
    }

    .m-lg-21 {
        margin: 8.5rem!important
    }

    .m-lg-22 {
        margin: 9rem!important
    }

    .m-lg-23 {
        margin: 9.5rem!important
    }

    .m-lg-24 {
        margin: 10rem!important
    }

    .m-lg-25 {
        margin: 10.5rem!important
    }

    .m-lg-26 {
        margin: 11rem!important
    }

    .m-lg-27 {
        margin: 12rem!important
    }

    .m-lg-28 {
        margin: 13rem!important
    }

    .m-lg-29 {
        margin: 14rem!important
    }

    .m-lg-30 {
        margin: 15rem!important
    }

    .m-lg-31 {
        margin: 16rem!important
    }

    .m-lg-32 {
        margin: 17rem!important
    }

    .m-lg-33 {
        margin: 18rem!important
    }

    .m-lg-34 {
        margin: 19rem!important
    }

    .m-lg-35 {
        margin: 20rem!important
    }

    .m-lg-auto {
        margin: auto!important
    }

    .mx-lg-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-lg-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }

    .mx-lg-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }

    .mx-lg-3 {
        margin-right: .75rem!important;
        margin-left: .75rem!important
    }

    .mx-lg-4 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-lg-5 {
        margin-right: 1.25rem!important;
        margin-left: 1.25rem!important
    }

    .mx-lg-6 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-lg-7 {
        margin-right: 1.75rem!important;
        margin-left: 1.75rem!important
    }

    .mx-lg-8 {
        margin-right: 2rem!important;
        margin-left: 2rem!important
    }

    .mx-lg-9 {
        margin-right: 2.5rem!important;
        margin-left: 2.5rem!important
    }

    .mx-lg-10 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-lg-11 {
        margin-right: 3.5rem!important;
        margin-left: 3.5rem!important
    }

    .mx-lg-12 {
        margin-right: 4rem!important;
        margin-left: 4rem!important
    }

    .mx-lg-13 {
        margin-right: 4.5rem!important;
        margin-left: 4.5rem!important
    }

    .mx-lg-14 {
        margin-right: 5rem!important;
        margin-left: 5rem!important
    }

    .mx-lg-15 {
        margin-right: 5.5rem!important;
        margin-left: 5.5rem!important
    }

    .mx-lg-16 {
        margin-right: 6rem!important;
        margin-left: 6rem!important
    }

    .mx-lg-17 {
        margin-right: 6.5rem!important;
        margin-left: 6.5rem!important
    }

    .mx-lg-18 {
        margin-right: 7rem!important;
        margin-left: 7rem!important
    }

    .mx-lg-19 {
        margin-right: 7.5rem!important;
        margin-left: 7.5rem!important
    }

    .mx-lg-20 {
        margin-right: 8rem!important;
        margin-left: 8rem!important
    }

    .mx-lg-21 {
        margin-right: 8.5rem!important;
        margin-left: 8.5rem!important
    }

    .mx-lg-22 {
        margin-right: 9rem!important;
        margin-left: 9rem!important
    }

    .mx-lg-23 {
        margin-right: 9.5rem!important;
        margin-left: 9.5rem!important
    }

    .mx-lg-24 {
        margin-right: 10rem!important;
        margin-left: 10rem!important
    }

    .mx-lg-25 {
        margin-right: 10.5rem!important;
        margin-left: 10.5rem!important
    }

    .mx-lg-26 {
        margin-right: 11rem!important;
        margin-left: 11rem!important
    }

    .mx-lg-27 {
        margin-right: 12rem!important;
        margin-left: 12rem!important
    }

    .mx-lg-28 {
        margin-right: 13rem!important;
        margin-left: 13rem!important
    }

    .mx-lg-29 {
        margin-right: 14rem!important;
        margin-left: 14rem!important
    }

    .mx-lg-30 {
        margin-right: 15rem!important;
        margin-left: 15rem!important
    }

    .mx-lg-31 {
        margin-right: 16rem!important;
        margin-left: 16rem!important
    }

    .mx-lg-32 {
        margin-right: 17rem!important;
        margin-left: 17rem!important
    }

    .mx-lg-33 {
        margin-right: 18rem!important;
        margin-left: 18rem!important
    }

    .mx-lg-34 {
        margin-right: 19rem!important;
        margin-left: 19rem!important
    }

    .mx-lg-35 {
        margin-right: 20rem!important;
        margin-left: 20rem!important
    }

    .mx-lg-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-lg-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-lg-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }

    .my-lg-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .my-lg-3 {
        margin-top: .75rem!important;
        margin-bottom: .75rem!important
    }

    .my-lg-4 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-lg-5 {
        margin-top: 1.25rem!important;
        margin-bottom: 1.25rem!important
    }

    .my-lg-6 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-lg-7 {
        margin-top: 1.75rem!important;
        margin-bottom: 1.75rem!important
    }

    .my-lg-8 {
        margin-top: 2rem!important;
        margin-bottom: 2rem!important
    }

    .my-lg-9 {
        margin-top: 2.5rem!important;
        margin-bottom: 2.5rem!important
    }

    .my-lg-10 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-lg-11 {
        margin-top: 3.5rem!important;
        margin-bottom: 3.5rem!important
    }

    .my-lg-12 {
        margin-top: 4rem!important;
        margin-bottom: 4rem!important
    }

    .my-lg-13 {
        margin-top: 4.5rem!important;
        margin-bottom: 4.5rem!important
    }

    .my-lg-14 {
        margin-top: 5rem!important;
        margin-bottom: 5rem!important
    }

    .my-lg-15 {
        margin-top: 5.5rem!important;
        margin-bottom: 5.5rem!important
    }

    .my-lg-16 {
        margin-top: 6rem!important;
        margin-bottom: 6rem!important
    }

    .my-lg-17 {
        margin-top: 6.5rem!important;
        margin-bottom: 6.5rem!important
    }

    .my-lg-18 {
        margin-top: 7rem!important;
        margin-bottom: 7rem!important
    }

    .my-lg-19 {
        margin-top: 7.5rem!important;
        margin-bottom: 7.5rem!important
    }

    .my-lg-20 {
        margin-top: 8rem!important;
        margin-bottom: 8rem!important
    }

    .my-lg-21 {
        margin-top: 8.5rem!important;
        margin-bottom: 8.5rem!important
    }

    .my-lg-22 {
        margin-top: 9rem!important;
        margin-bottom: 9rem!important
    }

    .my-lg-23 {
        margin-top: 9.5rem!important;
        margin-bottom: 9.5rem!important
    }

    .my-lg-24 {
        margin-top: 10rem!important;
        margin-bottom: 10rem!important
    }

    .my-lg-25 {
        margin-top: 10.5rem!important;
        margin-bottom: 10.5rem!important
    }

    .my-lg-26 {
        margin-top: 11rem!important;
        margin-bottom: 11rem!important
    }

    .my-lg-27 {
        margin-top: 12rem!important;
        margin-bottom: 12rem!important
    }

    .my-lg-28 {
        margin-top: 13rem!important;
        margin-bottom: 13rem!important
    }

    .my-lg-29 {
        margin-top: 14rem!important;
        margin-bottom: 14rem!important
    }

    .my-lg-30 {
        margin-top: 15rem!important;
        margin-bottom: 15rem!important
    }

    .my-lg-31 {
        margin-top: 16rem!important;
        margin-bottom: 16rem!important
    }

    .my-lg-32 {
        margin-top: 17rem!important;
        margin-bottom: 17rem!important
    }

    .my-lg-33 {
        margin-top: 18rem!important;
        margin-bottom: 18rem!important
    }

    .my-lg-34 {
        margin-top: 19rem!important;
        margin-bottom: 19rem!important
    }

    .my-lg-35 {
        margin-top: 20rem!important;
        margin-bottom: 20rem!important
    }

    .my-lg-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-lg-0 {
        margin-top: 0!important
    }

    .mt-lg-1 {
        margin-top: .25rem!important
    }

    .mt-lg-2 {
        margin-top: .5rem!important
    }

    .mt-lg-3 {
        margin-top: .75rem!important
    }

    .mt-lg-4 {
        margin-top: 1rem!important
    }

    .mt-lg-5 {
        margin-top: 1.25rem!important
    }

    .mt-lg-6 {
        margin-top: 1.5rem!important
    }

    .mt-lg-7 {
        margin-top: 1.75rem!important
    }

    .mt-lg-8 {
        margin-top: 2rem!important
    }

    .mt-lg-9 {
        margin-top: 2.5rem!important
    }

    .mt-lg-10 {
        margin-top: 3rem!important
    }

    .mt-lg-11 {
        margin-top: 3.5rem!important
    }

    .mt-lg-12 {
        margin-top: 4rem!important
    }

    .mt-lg-13 {
        margin-top: 4.5rem!important
    }

    .mt-lg-14 {
        margin-top: 5rem!important
    }

    .mt-lg-15 {
        margin-top: 5.5rem!important
    }

    .mt-lg-16 {
        margin-top: 6rem!important
    }

    .mt-lg-17 {
        margin-top: 6.5rem!important
    }

    .mt-lg-18 {
        margin-top: 7rem!important
    }

    .mt-lg-19 {
        margin-top: 7.5rem!important
    }

    .mt-lg-20 {
        margin-top: 8rem!important
    }

    .mt-lg-21 {
        margin-top: 8.5rem!important
    }

    .mt-lg-22 {
        margin-top: 9rem!important
    }

    .mt-lg-23 {
        margin-top: 9.5rem!important
    }

    .mt-lg-24 {
        margin-top: 10rem!important
    }

    .mt-lg-25 {
        margin-top: 10.5rem!important
    }

    .mt-lg-26 {
        margin-top: 11rem!important
    }

    .mt-lg-27 {
        margin-top: 12rem!important
    }

    .mt-lg-28 {
        margin-top: 13rem!important
    }

    .mt-lg-29 {
        margin-top: 14rem!important
    }

    .mt-lg-30 {
        margin-top: 15rem!important
    }

    .mt-lg-31 {
        margin-top: 16rem!important
    }

    .mt-lg-32 {
        margin-top: 17rem!important
    }

    .mt-lg-33 {
        margin-top: 18rem!important
    }

    .mt-lg-34 {
        margin-top: 19rem!important
    }

    .mt-lg-35 {
        margin-top: 20rem!important
    }

    .mt-lg-auto {
        margin-top: auto!important
    }

    .me-lg-0 {
        margin-right: 0!important
    }

    .me-lg-1 {
        margin-right: .25rem!important
    }

    .me-lg-2 {
        margin-right: .5rem!important
    }

    .me-lg-3 {
        margin-right: .75rem!important
    }

    .me-lg-4 {
        margin-right: 1rem!important
    }

    .me-lg-5 {
        margin-right: 1.25rem!important
    }

    .me-lg-6 {
        margin-right: 1.5rem!important
    }

    .me-lg-7 {
        margin-right: 1.75rem!important
    }

    .me-lg-8 {
        margin-right: 2rem!important
    }

    .me-lg-9 {
        margin-right: 2.5rem!important
    }

    .me-lg-10 {
        margin-right: 3rem!important
    }

    .me-lg-11 {
        margin-right: 3.5rem!important
    }

    .me-lg-12 {
        margin-right: 4rem!important
    }

    .me-lg-13 {
        margin-right: 4.5rem!important
    }

    .me-lg-14 {
        margin-right: 5rem!important
    }

    .me-lg-15 {
        margin-right: 5.5rem!important
    }

    .me-lg-16 {
        margin-right: 6rem!important
    }

    .me-lg-17 {
        margin-right: 6.5rem!important
    }

    .me-lg-18 {
        margin-right: 7rem!important
    }

    .me-lg-19 {
        margin-right: 7.5rem!important
    }

    .me-lg-20 {
        margin-right: 8rem!important
    }

    .me-lg-21 {
        margin-right: 8.5rem!important
    }

    .me-lg-22 {
        margin-right: 9rem!important
    }

    .me-lg-23 {
        margin-right: 9.5rem!important
    }

    .me-lg-24 {
        margin-right: 10rem!important
    }

    .me-lg-25 {
        margin-right: 10.5rem!important
    }

    .me-lg-26 {
        margin-right: 11rem!important
    }

    .me-lg-27 {
        margin-right: 12rem!important
    }

    .me-lg-28 {
        margin-right: 13rem!important
    }

    .me-lg-29 {
        margin-right: 14rem!important
    }

    .me-lg-30 {
        margin-right: 15rem!important
    }

    .me-lg-31 {
        margin-right: 16rem!important
    }

    .me-lg-32 {
        margin-right: 17rem!important
    }

    .me-lg-33 {
        margin-right: 18rem!important
    }

    .me-lg-34 {
        margin-right: 19rem!important
    }

    .me-lg-35 {
        margin-right: 20rem!important
    }

    .me-lg-auto {
        margin-right: auto!important
    }

    .mb-lg-0 {
        margin-bottom: 0!important
    }

    .mb-lg-1 {
        margin-bottom: .25rem!important
    }

    .mb-lg-2 {
        margin-bottom: .5rem!important
    }

    .mb-lg-3 {
        margin-bottom: .75rem!important
    }

    .mb-lg-4 {
        margin-bottom: 1rem!important
    }

    .mb-lg-5 {
        margin-bottom: 1.25rem!important
    }

    .mb-lg-6 {
        margin-bottom: 1.5rem!important
    }

    .mb-lg-7 {
        margin-bottom: 1.75rem!important
    }

    .mb-lg-8 {
        margin-bottom: 2rem!important
    }

    .mb-lg-9 {
        margin-bottom: 2.5rem!important
    }

    .mb-lg-10 {
        margin-bottom: 3rem!important
    }

    .mb-lg-11 {
        margin-bottom: 3.5rem!important
    }

    .mb-lg-12 {
        margin-bottom: 4rem!important
    }

    .mb-lg-13 {
        margin-bottom: 4.5rem!important
    }

    .mb-lg-14 {
        margin-bottom: 5rem!important
    }

    .mb-lg-15 {
        margin-bottom: 5.5rem!important
    }

    .mb-lg-16 {
        margin-bottom: 6rem!important
    }

    .mb-lg-17 {
        margin-bottom: 6.5rem!important
    }

    .mb-lg-18 {
        margin-bottom: 7rem!important
    }

    .mb-lg-19 {
        margin-bottom: 7.5rem!important
    }

    .mb-lg-20 {
        margin-bottom: 8rem!important
    }

    .mb-lg-21 {
        margin-bottom: 8.5rem!important
    }

    .mb-lg-22 {
        margin-bottom: 9rem!important
    }

    .mb-lg-23 {
        margin-bottom: 9.5rem!important
    }

    .mb-lg-24 {
        margin-bottom: 10rem!important
    }

    .mb-lg-25 {
        margin-bottom: 10.5rem!important
    }

    .mb-lg-26 {
        margin-bottom: 11rem!important
    }

    .mb-lg-27 {
        margin-bottom: 12rem!important
    }

    .mb-lg-28 {
        margin-bottom: 13rem!important
    }

    .mb-lg-29 {
        margin-bottom: 14rem!important
    }

    .mb-lg-30 {
        margin-bottom: 15rem!important
    }

    .mb-lg-31 {
        margin-bottom: 16rem!important
    }

    .mb-lg-32 {
        margin-bottom: 17rem!important
    }

    .mb-lg-33 {
        margin-bottom: 18rem!important
    }

    .mb-lg-34 {
        margin-bottom: 19rem!important
    }

    .mb-lg-35 {
        margin-bottom: 20rem!important
    }

    .mb-lg-auto {
        margin-bottom: auto!important
    }

    .ms-lg-0 {
        margin-left: 0!important
    }

    .ms-lg-1 {
        margin-left: .25rem!important
    }

    .ms-lg-2 {
        margin-left: .5rem!important
    }

    .ms-lg-3 {
        margin-left: .75rem!important
    }

    .ms-lg-4 {
        margin-left: 1rem!important
    }

    .ms-lg-5 {
        margin-left: 1.25rem!important
    }

    .ms-lg-6 {
        margin-left: 1.5rem!important
    }

    .ms-lg-7 {
        margin-left: 1.75rem!important
    }

    .ms-lg-8 {
        margin-left: 2rem!important
    }

    .ms-lg-9 {
        margin-left: 2.5rem!important
    }

    .ms-lg-10 {
        margin-left: 3rem!important
    }

    .ms-lg-11 {
        margin-left: 3.5rem!important
    }

    .ms-lg-12 {
        margin-left: 4rem!important
    }

    .ms-lg-13 {
        margin-left: 4.5rem!important
    }

    .ms-lg-14 {
        margin-left: 5rem!important
    }

    .ms-lg-15 {
        margin-left: 5.5rem!important
    }

    .ms-lg-16 {
        margin-left: 6rem!important
    }

    .ms-lg-17 {
        margin-left: 6.5rem!important
    }

    .ms-lg-18 {
        margin-left: 7rem!important
    }

    .ms-lg-19 {
        margin-left: 7.5rem!important
    }

    .ms-lg-20 {
        margin-left: 8rem!important
    }

    .ms-lg-21 {
        margin-left: 8.5rem!important
    }

    .ms-lg-22 {
        margin-left: 9rem!important
    }

    .ms-lg-23 {
        margin-left: 9.5rem!important
    }

    .ms-lg-24 {
        margin-left: 10rem!important
    }

    .ms-lg-25 {
        margin-left: 10.5rem!important
    }

    .ms-lg-26 {
        margin-left: 11rem!important
    }

    .ms-lg-27 {
        margin-left: 12rem!important
    }

    .ms-lg-28 {
        margin-left: 13rem!important
    }

    .ms-lg-29 {
        margin-left: 14rem!important
    }

    .ms-lg-30 {
        margin-left: 15rem!important
    }

    .ms-lg-31 {
        margin-left: 16rem!important
    }

    .ms-lg-32 {
        margin-left: 17rem!important
    }

    .ms-lg-33 {
        margin-left: 18rem!important
    }

    .ms-lg-34 {
        margin-left: 19rem!important
    }

    .ms-lg-35 {
        margin-left: 20rem!important
    }

    .ms-lg-auto {
        margin-left: auto!important
    }

    .p-lg-0 {
        padding: 0!important
    }

    .p-lg-1 {
        padding: .25rem!important
    }

    .p-lg-2 {
        padding: .5rem!important
    }

    .p-lg-3 {
        padding: .75rem!important
    }

    .p-lg-4 {
        padding: 1rem!important
    }

    .p-lg-5 {
        padding: 1.25rem!important
    }

    .p-lg-6 {
        padding: 1.5rem!important
    }

    .p-lg-7 {
        padding: 1.75rem!important
    }

    .p-lg-8 {
        padding: 2rem!important
    }

    .p-lg-9 {
        padding: 2.5rem!important
    }

    .p-lg-10 {
        padding: 3rem!important
    }

    .p-lg-11 {
        padding: 3.5rem!important
    }

    .p-lg-12 {
        padding: 4rem!important
    }

    .p-lg-13 {
        padding: 4.5rem!important
    }

    .p-lg-14 {
        padding: 5rem!important
    }

    .p-lg-15 {
        padding: 5.5rem!important
    }

    .p-lg-16 {
        padding: 6rem!important
    }

    .p-lg-17 {
        padding: 6.5rem!important
    }

    .p-lg-18 {
        padding: 7rem!important
    }

    .p-lg-19 {
        padding: 7.5rem!important
    }

    .p-lg-20 {
        padding: 8rem!important
    }

    .p-lg-21 {
        padding: 8.5rem!important
    }

    .p-lg-22 {
        padding: 9rem!important
    }

    .p-lg-23 {
        padding: 9.5rem!important
    }

    .p-lg-24 {
        padding: 10rem!important
    }

    .p-lg-25 {
        padding: 10.5rem!important
    }

    .p-lg-26 {
        padding: 11rem!important
    }

    .p-lg-27 {
        padding: 12rem!important
    }

    .p-lg-28 {
        padding: 13rem!important
    }

    .p-lg-29 {
        padding: 14rem!important
    }

    .p-lg-30 {
        padding: 15rem!important
    }

    .p-lg-31 {
        padding: 16rem!important
    }

    .p-lg-32 {
        padding: 17rem!important
    }

    .p-lg-33 {
        padding: 18rem!important
    }

    .p-lg-34 {
        padding: 19rem!important
    }

    .p-lg-35 {
        padding: 20rem!important
    }

    .px-lg-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-lg-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }

    .px-lg-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }

    .px-lg-3 {
        padding-right: .75rem!important;
        padding-left: .75rem!important
    }

    .px-lg-4 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-lg-5 {
        padding-right: 1.25rem!important;
        padding-left: 1.25rem!important
    }

    .px-lg-6 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-lg-7 {
        padding-right: 1.75rem!important;
        padding-left: 1.75rem!important
    }

    .px-lg-8 {
        padding-right: 2rem!important;
        padding-left: 2rem!important
    }

    .px-lg-9 {
        padding-right: 2.5rem!important;
        padding-left: 2.5rem!important
    }

    .px-lg-10 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .px-lg-11 {
        padding-right: 3.5rem!important;
        padding-left: 3.5rem!important
    }

    .px-lg-12 {
        padding-right: 4rem!important;
        padding-left: 4rem!important
    }

    .px-lg-13 {
        padding-right: 4.5rem!important;
        padding-left: 4.5rem!important
    }

    .px-lg-14 {
        padding-right: 5rem!important;
        padding-left: 5rem!important
    }

    .px-lg-15 {
        padding-right: 5.5rem!important;
        padding-left: 5.5rem!important
    }

    .px-lg-16 {
        padding-right: 6rem!important;
        padding-left: 6rem!important
    }

    .px-lg-17 {
        padding-right: 6.5rem!important;
        padding-left: 6.5rem!important
    }

    .px-lg-18 {
        padding-right: 7rem!important;
        padding-left: 7rem!important
    }

    .px-lg-19 {
        padding-right: 7.5rem!important;
        padding-left: 7.5rem!important
    }

    .px-lg-20 {
        padding-right: 8rem!important;
        padding-left: 8rem!important
    }

    .px-lg-21 {
        padding-right: 8.5rem!important;
        padding-left: 8.5rem!important
    }

    .px-lg-22 {
        padding-right: 9rem!important;
        padding-left: 9rem!important
    }

    .px-lg-23 {
        padding-right: 9.5rem!important;
        padding-left: 9.5rem!important
    }

    .px-lg-24 {
        padding-right: 10rem!important;
        padding-left: 10rem!important
    }

    .px-lg-25 {
        padding-right: 10.5rem!important;
        padding-left: 10.5rem!important
    }

    .px-lg-26 {
        padding-right: 11rem!important;
        padding-left: 11rem!important
    }

    .px-lg-27 {
        padding-right: 12rem!important;
        padding-left: 12rem!important
    }

    .px-lg-28 {
        padding-right: 13rem!important;
        padding-left: 13rem!important
    }

    .px-lg-29 {
        padding-right: 14rem!important;
        padding-left: 14rem!important
    }

    .px-lg-30 {
        padding-right: 15rem!important;
        padding-left: 15rem!important
    }

    .px-lg-31 {
        padding-right: 16rem!important;
        padding-left: 16rem!important
    }

    .px-lg-32 {
        padding-right: 17rem!important;
        padding-left: 17rem!important
    }

    .px-lg-33 {
        padding-right: 18rem!important;
        padding-left: 18rem!important
    }

    .px-lg-34 {
        padding-right: 19rem!important;
        padding-left: 19rem!important
    }

    .px-lg-35 {
        padding-right: 20rem!important;
        padding-left: 20rem!important
    }

    .py-lg-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-lg-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }

    .py-lg-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }

    .py-lg-3 {
        padding-top: .75rem!important;
        padding-bottom: .75rem!important
    }

    .py-lg-4 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-lg-5 {
        padding-top: 1.25rem!important;
        padding-bottom: 1.25rem!important
    }

    .py-lg-6 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-lg-7 {
        padding-top: 1.75rem!important;
        padding-bottom: 1.75rem!important
    }

    .py-lg-8 {
        padding-top: 2rem!important;
        padding-bottom: 2rem!important
    }

    .py-lg-9 {
        padding-top: 2.5rem!important;
        padding-bottom: 2.5rem!important
    }

    .py-lg-10 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .py-lg-11 {
        padding-top: 3.5rem!important;
        padding-bottom: 3.5rem!important
    }

    .py-lg-12 {
        padding-top: 4rem!important;
        padding-bottom: 4rem!important
    }

    .py-lg-13 {
        padding-top: 4.5rem!important;
        padding-bottom: 4.5rem!important
    }

    .py-lg-14 {
        padding-top: 5rem!important;
        padding-bottom: 5rem!important
    }

    .py-lg-15 {
        padding-top: 5.5rem!important;
        padding-bottom: 5.5rem!important
    }

    .py-lg-16 {
        padding-top: 6rem!important;
        padding-bottom: 6rem!important
    }

    .py-lg-17 {
        padding-top: 6.5rem!important;
        padding-bottom: 6.5rem!important
    }

    .py-lg-18 {
        padding-top: 7rem!important;
        padding-bottom: 7rem!important
    }

    .py-lg-19 {
        padding-top: 7.5rem!important;
        padding-bottom: 7.5rem!important
    }

    .py-lg-20 {
        padding-top: 8rem!important;
        padding-bottom: 8rem!important
    }

    .py-lg-21 {
        padding-top: 8.5rem!important;
        padding-bottom: 8.5rem!important
    }

    .py-lg-22 {
        padding-top: 9rem!important;
        padding-bottom: 9rem!important
    }

    .py-lg-23 {
        padding-top: 9.5rem!important;
        padding-bottom: 9.5rem!important
    }

    .py-lg-24 {
        padding-top: 10rem!important;
        padding-bottom: 10rem!important
    }

    .py-lg-25 {
        padding-top: 10.5rem!important;
        padding-bottom: 10.5rem!important
    }

    .py-lg-26 {
        padding-top: 11rem!important;
        padding-bottom: 11rem!important
    }

    .py-lg-27 {
        padding-top: 12rem!important;
        padding-bottom: 12rem!important
    }

    .py-lg-28 {
        padding-top: 13rem!important;
        padding-bottom: 13rem!important
    }

    .py-lg-29 {
        padding-top: 14rem!important;
        padding-bottom: 14rem!important
    }

    .py-lg-30 {
        padding-top: 15rem!important;
        padding-bottom: 15rem!important
    }

    .py-lg-31 {
        padding-top: 16rem!important;
        padding-bottom: 16rem!important
    }

    .py-lg-32 {
        padding-top: 17rem!important;
        padding-bottom: 17rem!important
    }

    .py-lg-33 {
        padding-top: 18rem!important;
        padding-bottom: 18rem!important
    }

    .py-lg-34 {
        padding-top: 19rem!important;
        padding-bottom: 19rem!important
    }

    .py-lg-35 {
        padding-top: 20rem!important;
        padding-bottom: 20rem!important
    }

    .pt-lg-0 {
        padding-top: 0!important
    }

    .pt-lg-1 {
        padding-top: .25rem!important
    }

    .pt-lg-2 {
        padding-top: .5rem!important
    }

    .pt-lg-3 {
        padding-top: .75rem!important
    }

    .pt-lg-4 {
        padding-top: 1rem!important
    }

    .pt-lg-5 {
        padding-top: 1.25rem!important
    }

    .pt-lg-6 {
        padding-top: 1.5rem!important
    }

    .pt-lg-7 {
        padding-top: 1.75rem!important
    }

    .pt-lg-8 {
        padding-top: 2rem!important
    }

    .pt-lg-9 {
        padding-top: 2.5rem!important
    }

    .pt-lg-10 {
        padding-top: 3rem!important
    }

    .pt-lg-11 {
        padding-top: 3.5rem!important
    }

    .pt-lg-12 {
        padding-top: 4rem!important
    }

    .pt-lg-13 {
        padding-top: 4.5rem!important
    }

    .pt-lg-14 {
        padding-top: 5rem!important
    }

    .pt-lg-15 {
        padding-top: 5.5rem!important
    }

    .pt-lg-16 {
        padding-top: 6rem!important
    }

    .pt-lg-17 {
        padding-top: 6.5rem!important
    }

    .pt-lg-18 {
        padding-top: 7rem!important
    }

    .pt-lg-19 {
        padding-top: 7.5rem!important
    }

    .pt-lg-20 {
        padding-top: 8rem!important
    }

    .pt-lg-21 {
        padding-top: 8.5rem!important
    }

    .pt-lg-22 {
        padding-top: 9rem!important
    }

    .pt-lg-23 {
        padding-top: 9.5rem!important
    }

    .pt-lg-24 {
        padding-top: 10rem!important
    }

    .pt-lg-25 {
        padding-top: 10.5rem!important
    }

    .pt-lg-26 {
        padding-top: 11rem!important
    }

    .pt-lg-27 {
        padding-top: 12rem!important
    }

    .pt-lg-28 {
        padding-top: 13rem!important
    }

    .pt-lg-29 {
        padding-top: 14rem!important
    }

    .pt-lg-30 {
        padding-top: 15rem!important
    }

    .pt-lg-31 {
        padding-top: 16rem!important
    }

    .pt-lg-32 {
        padding-top: 17rem!important
    }

    .pt-lg-33 {
        padding-top: 18rem!important
    }

    .pt-lg-34 {
        padding-top: 19rem!important
    }

    .pt-lg-35 {
        padding-top: 20rem!important
    }

    .pe-lg-0 {
        padding-right: 0!important
    }

    .pe-lg-1 {
        padding-right: .25rem!important
    }

    .pe-lg-2 {
        padding-right: .5rem!important
    }

    .pe-lg-3 {
        padding-right: .75rem!important
    }

    .pe-lg-4 {
        padding-right: 1rem!important
    }

    .pe-lg-5 {
        padding-right: 1.25rem!important
    }

    .pe-lg-6 {
        padding-right: 1.5rem!important
    }

    .pe-lg-7 {
        padding-right: 1.75rem!important
    }

    .pe-lg-8 {
        padding-right: 2rem!important
    }

    .pe-lg-9 {
        padding-right: 2.5rem!important
    }

    .pe-lg-10 {
        padding-right: 3rem!important
    }

    .pe-lg-11 {
        padding-right: 3.5rem!important
    }

    .pe-lg-12 {
        padding-right: 4rem!important
    }

    .pe-lg-13 {
        padding-right: 4.5rem!important
    }

    .pe-lg-14 {
        padding-right: 5rem!important
    }

    .pe-lg-15 {
        padding-right: 5.5rem!important
    }

    .pe-lg-16 {
        padding-right: 6rem!important
    }

    .pe-lg-17 {
        padding-right: 6.5rem!important
    }

    .pe-lg-18 {
        padding-right: 7rem!important
    }

    .pe-lg-19 {
        padding-right: 7.5rem!important
    }

    .pe-lg-20 {
        padding-right: 8rem!important
    }

    .pe-lg-21 {
        padding-right: 8.5rem!important
    }

    .pe-lg-22 {
        padding-right: 9rem!important
    }

    .pe-lg-23 {
        padding-right: 9.5rem!important
    }

    .pe-lg-24 {
        padding-right: 10rem!important
    }

    .pe-lg-25 {
        padding-right: 10.5rem!important
    }

    .pe-lg-26 {
        padding-right: 11rem!important
    }

    .pe-lg-27 {
        padding-right: 12rem!important
    }

    .pe-lg-28 {
        padding-right: 13rem!important
    }

    .pe-lg-29 {
        padding-right: 14rem!important
    }

    .pe-lg-30 {
        padding-right: 15rem!important
    }

    .pe-lg-31 {
        padding-right: 16rem!important
    }

    .pe-lg-32 {
        padding-right: 17rem!important
    }

    .pe-lg-33 {
        padding-right: 18rem!important
    }

    .pe-lg-34 {
        padding-right: 19rem!important
    }

    .pe-lg-35 {
        padding-right: 20rem!important
    }

    .pb-lg-0 {
        padding-bottom: 0!important
    }

    .pb-lg-1 {
        padding-bottom: .25rem!important
    }

    .pb-lg-2 {
        padding-bottom: .5rem!important
    }

    .pb-lg-3 {
        padding-bottom: .75rem!important
    }

    .pb-lg-4 {
        padding-bottom: 1rem!important
    }

    .pb-lg-5 {
        padding-bottom: 1.25rem!important
    }

    .pb-lg-6 {
        padding-bottom: 1.5rem!important
    }

    .pb-lg-7 {
        padding-bottom: 1.75rem!important
    }

    .pb-lg-8 {
        padding-bottom: 2rem!important
    }

    .pb-lg-9 {
        padding-bottom: 2.5rem!important
    }

    .pb-lg-10 {
        padding-bottom: 3rem!important
    }

    .pb-lg-11 {
        padding-bottom: 3.5rem!important
    }

    .pb-lg-12 {
        padding-bottom: 4rem!important
    }

    .pb-lg-13 {
        padding-bottom: 4.5rem!important
    }

    .pb-lg-14 {
        padding-bottom: 5rem!important
    }

    .pb-lg-15 {
        padding-bottom: 5.5rem!important
    }

    .pb-lg-16 {
        padding-bottom: 6rem!important
    }

    .pb-lg-17 {
        padding-bottom: 6.5rem!important
    }

    .pb-lg-18 {
        padding-bottom: 7rem!important
    }

    .pb-lg-19 {
        padding-bottom: 7.5rem!important
    }

    .pb-lg-20 {
        padding-bottom: 8rem!important
    }

    .pb-lg-21 {
        padding-bottom: 8.5rem!important
    }

    .pb-lg-22 {
        padding-bottom: 9rem!important
    }

    .pb-lg-23 {
        padding-bottom: 9.5rem!important
    }

    .pb-lg-24 {
        padding-bottom: 10rem!important
    }

    .pb-lg-25 {
        padding-bottom: 10.5rem!important
    }

    .pb-lg-26 {
        padding-bottom: 11rem!important
    }

    .pb-lg-27 {
        padding-bottom: 12rem!important
    }

    .pb-lg-28 {
        padding-bottom: 13rem!important
    }

    .pb-lg-29 {
        padding-bottom: 14rem!important
    }

    .pb-lg-30 {
        padding-bottom: 15rem!important
    }

    .pb-lg-31 {
        padding-bottom: 16rem!important
    }

    .pb-lg-32 {
        padding-bottom: 17rem!important
    }

    .pb-lg-33 {
        padding-bottom: 18rem!important
    }

    .pb-lg-34 {
        padding-bottom: 19rem!important
    }

    .pb-lg-35 {
        padding-bottom: 20rem!important
    }

    .ps-lg-0 {
        padding-left: 0!important
    }

    .ps-lg-1 {
        padding-left: .25rem!important
    }

    .ps-lg-2 {
        padding-left: .5rem!important
    }

    .ps-lg-3 {
        padding-left: .75rem!important
    }

    .ps-lg-4 {
        padding-left: 1rem!important
    }

    .ps-lg-5 {
        padding-left: 1.25rem!important
    }

    .ps-lg-6 {
        padding-left: 1.5rem!important
    }

    .ps-lg-7 {
        padding-left: 1.75rem!important
    }

    .ps-lg-8 {
        padding-left: 2rem!important
    }

    .ps-lg-9 {
        padding-left: 2.5rem!important
    }

    .ps-lg-10 {
        padding-left: 3rem!important
    }

    .ps-lg-11 {
        padding-left: 3.5rem!important
    }

    .ps-lg-12 {
        padding-left: 4rem!important
    }

    .ps-lg-13 {
        padding-left: 4.5rem!important
    }

    .ps-lg-14 {
        padding-left: 5rem!important
    }

    .ps-lg-15 {
        padding-left: 5.5rem!important
    }

    .ps-lg-16 {
        padding-left: 6rem!important
    }

    .ps-lg-17 {
        padding-left: 6.5rem!important
    }

    .ps-lg-18 {
        padding-left: 7rem!important
    }

    .ps-lg-19 {
        padding-left: 7.5rem!important
    }

    .ps-lg-20 {
        padding-left: 8rem!important
    }

    .ps-lg-21 {
        padding-left: 8.5rem!important
    }

    .ps-lg-22 {
        padding-left: 9rem!important
    }

    .ps-lg-23 {
        padding-left: 9.5rem!important
    }

    .ps-lg-24 {
        padding-left: 10rem!important
    }

    .ps-lg-25 {
        padding-left: 10.5rem!important
    }

    .ps-lg-26 {
        padding-left: 11rem!important
    }

    .ps-lg-27 {
        padding-left: 12rem!important
    }

    .ps-lg-28 {
        padding-left: 13rem!important
    }

    .ps-lg-29 {
        padding-left: 14rem!important
    }

    .ps-lg-30 {
        padding-left: 15rem!important
    }

    .ps-lg-31 {
        padding-left: 16rem!important
    }

    .ps-lg-32 {
        padding-left: 17rem!important
    }

    .ps-lg-33 {
        padding-left: 18rem!important
    }

    .ps-lg-34 {
        padding-left: 19rem!important
    }

    .ps-lg-35 {
        padding-left: 20rem!important
    }

    .gap-lg-0 {
        gap: 0!important
    }

    .gap-lg-1 {
        gap: .25rem!important
    }

    .gap-lg-2 {
        gap: .5rem!important
    }

    .gap-lg-3 {
        gap: .75rem!important
    }

    .gap-lg-4 {
        gap: 1rem!important
    }

    .gap-lg-5 {
        gap: 1.25rem!important
    }

    .gap-lg-6 {
        gap: 1.5rem!important
    }

    .gap-lg-7 {
        gap: 1.75rem!important
    }

    .gap-lg-8 {
        gap: 2rem!important
    }

    .gap-lg-9 {
        gap: 2.5rem!important
    }

    .gap-lg-10 {
        gap: 3rem!important
    }

    .gap-lg-11 {
        gap: 3.5rem!important
    }

    .gap-lg-12 {
        gap: 4rem!important
    }

    .gap-lg-13 {
        gap: 4.5rem!important
    }

    .gap-lg-14 {
        gap: 5rem!important
    }

    .gap-lg-15 {
        gap: 5.5rem!important
    }

    .gap-lg-16 {
        gap: 6rem!important
    }

    .gap-lg-17 {
        gap: 6.5rem!important
    }

    .gap-lg-18 {
        gap: 7rem!important
    }

    .gap-lg-19 {
        gap: 7.5rem!important
    }

    .gap-lg-20 {
        gap: 8rem!important
    }

    .gap-lg-21 {
        gap: 8.5rem!important
    }

    .gap-lg-22 {
        gap: 9rem!important
    }

    .gap-lg-23 {
        gap: 9.5rem!important
    }

    .gap-lg-24 {
        gap: 10rem!important
    }

    .gap-lg-25 {
        gap: 10.5rem!important
    }

    .gap-lg-26 {
        gap: 11rem!important
    }

    .gap-lg-27 {
        gap: 12rem!important
    }

    .gap-lg-28 {
        gap: 13rem!important
    }

    .gap-lg-29 {
        gap: 14rem!important
    }

    .gap-lg-30 {
        gap: 15rem!important
    }

    .gap-lg-31 {
        gap: 16rem!important
    }

    .gap-lg-32 {
        gap: 17rem!important
    }

    .gap-lg-33 {
        gap: 18rem!important
    }

    .gap-lg-34 {
        gap: 19rem!important
    }

    .gap-lg-35 {
        gap: 20rem!important
    }

    .row-gap-lg-0 {
        row-gap: 0!important
    }

    .row-gap-lg-1 {
        row-gap: .25rem!important
    }

    .row-gap-lg-2 {
        row-gap: .5rem!important
    }

    .row-gap-lg-3 {
        row-gap: .75rem!important
    }

    .row-gap-lg-4 {
        row-gap: 1rem!important
    }

    .row-gap-lg-5 {
        row-gap: 1.25rem!important
    }

    .row-gap-lg-6 {
        row-gap: 1.5rem!important
    }

    .row-gap-lg-7 {
        row-gap: 1.75rem!important
    }

    .row-gap-lg-8 {
        row-gap: 2rem!important
    }

    .row-gap-lg-9 {
        row-gap: 2.5rem!important
    }

    .row-gap-lg-10 {
        row-gap: 3rem!important
    }

    .row-gap-lg-11 {
        row-gap: 3.5rem!important
    }

    .row-gap-lg-12 {
        row-gap: 4rem!important
    }

    .row-gap-lg-13 {
        row-gap: 4.5rem!important
    }

    .row-gap-lg-14 {
        row-gap: 5rem!important
    }

    .row-gap-lg-15 {
        row-gap: 5.5rem!important
    }

    .row-gap-lg-16 {
        row-gap: 6rem!important
    }

    .row-gap-lg-17 {
        row-gap: 6.5rem!important
    }

    .row-gap-lg-18 {
        row-gap: 7rem!important
    }

    .row-gap-lg-19 {
        row-gap: 7.5rem!important
    }

    .row-gap-lg-20 {
        row-gap: 8rem!important
    }

    .row-gap-lg-21 {
        row-gap: 8.5rem!important
    }

    .row-gap-lg-22 {
        row-gap: 9rem!important
    }

    .row-gap-lg-23 {
        row-gap: 9.5rem!important
    }

    .row-gap-lg-24 {
        row-gap: 10rem!important
    }

    .row-gap-lg-25 {
        row-gap: 10.5rem!important
    }

    .row-gap-lg-26 {
        row-gap: 11rem!important
    }

    .row-gap-lg-27 {
        row-gap: 12rem!important
    }

    .row-gap-lg-28 {
        row-gap: 13rem!important
    }

    .row-gap-lg-29 {
        row-gap: 14rem!important
    }

    .row-gap-lg-30 {
        row-gap: 15rem!important
    }

    .row-gap-lg-31 {
        row-gap: 16rem!important
    }

    .row-gap-lg-32 {
        row-gap: 17rem!important
    }

    .row-gap-lg-33 {
        row-gap: 18rem!important
    }

    .row-gap-lg-34 {
        row-gap: 19rem!important
    }

    .row-gap-lg-35 {
        row-gap: 20rem!important
    }

    .column-gap-lg-0 {
        -moz-column-gap: 0!important;
        column-gap: 0!important
    }

    .column-gap-lg-1 {
        -moz-column-gap: .25rem!important;
        column-gap: .25rem!important
    }

    .column-gap-lg-2 {
        -moz-column-gap: .5rem!important;
        column-gap: .5rem!important
    }

    .column-gap-lg-3 {
        -moz-column-gap: .75rem!important;
        column-gap: .75rem!important
    }

    .column-gap-lg-4 {
        -moz-column-gap: 1rem!important;
        column-gap: 1rem!important
    }

    .column-gap-lg-5 {
        -moz-column-gap: 1.25rem!important;
        column-gap: 1.25rem!important
    }

    .column-gap-lg-6 {
        -moz-column-gap: 1.5rem!important;
        column-gap: 1.5rem!important
    }

    .column-gap-lg-7 {
        -moz-column-gap: 1.75rem!important;
        column-gap: 1.75rem!important
    }

    .column-gap-lg-8 {
        -moz-column-gap: 2rem!important;
        column-gap: 2rem!important
    }

    .column-gap-lg-9 {
        -moz-column-gap: 2.5rem!important;
        column-gap: 2.5rem!important
    }

    .column-gap-lg-10 {
        -moz-column-gap: 3rem!important;
        column-gap: 3rem!important
    }

    .column-gap-lg-11 {
        -moz-column-gap: 3.5rem!important;
        column-gap: 3.5rem!important
    }

    .column-gap-lg-12 {
        -moz-column-gap: 4rem!important;
        column-gap: 4rem!important
    }

    .column-gap-lg-13 {
        -moz-column-gap: 4.5rem!important;
        column-gap: 4.5rem!important
    }

    .column-gap-lg-14 {
        -moz-column-gap: 5rem!important;
        column-gap: 5rem!important
    }

    .column-gap-lg-15 {
        -moz-column-gap: 5.5rem!important;
        column-gap: 5.5rem!important
    }

    .column-gap-lg-16 {
        -moz-column-gap: 6rem!important;
        column-gap: 6rem!important
    }

    .column-gap-lg-17 {
        -moz-column-gap: 6.5rem!important;
        column-gap: 6.5rem!important
    }

    .column-gap-lg-18 {
        -moz-column-gap: 7rem!important;
        column-gap: 7rem!important
    }

    .column-gap-lg-19 {
        -moz-column-gap: 7.5rem!important;
        column-gap: 7.5rem!important
    }

    .column-gap-lg-20 {
        -moz-column-gap: 8rem!important;
        column-gap: 8rem!important
    }

    .column-gap-lg-21 {
        -moz-column-gap: 8.5rem!important;
        column-gap: 8.5rem!important
    }

    .column-gap-lg-22 {
        -moz-column-gap: 9rem!important;
        column-gap: 9rem!important
    }

    .column-gap-lg-23 {
        -moz-column-gap: 9.5rem!important;
        column-gap: 9.5rem!important
    }

    .column-gap-lg-24 {
        -moz-column-gap: 10rem!important;
        column-gap: 10rem!important
    }

    .column-gap-lg-25 {
        -moz-column-gap: 10.5rem!important;
        column-gap: 10.5rem!important
    }

    .column-gap-lg-26 {
        -moz-column-gap: 11rem!important;
        column-gap: 11rem!important
    }

    .column-gap-lg-27 {
        -moz-column-gap: 12rem!important;
        column-gap: 12rem!important
    }

    .column-gap-lg-28 {
        -moz-column-gap: 13rem!important;
        column-gap: 13rem!important
    }

    .column-gap-lg-29 {
        -moz-column-gap: 14rem!important;
        column-gap: 14rem!important
    }

    .column-gap-lg-30 {
        -moz-column-gap: 15rem!important;
        column-gap: 15rem!important
    }

    .column-gap-lg-31 {
        -moz-column-gap: 16rem!important;
        column-gap: 16rem!important
    }

    .column-gap-lg-32 {
        -moz-column-gap: 17rem!important;
        column-gap: 17rem!important
    }

    .column-gap-lg-33 {
        -moz-column-gap: 18rem!important;
        column-gap: 18rem!important
    }

    .column-gap-lg-34 {
        -moz-column-gap: 19rem!important;
        column-gap: 19rem!important
    }

    .column-gap-lg-35 {
        -moz-column-gap: 20rem!important;
        column-gap: 20rem!important
    }

    .text-lg-start {
        text-align: left!important
    }

    .text-lg-end {
        text-align: right!important
    }

    .text-lg-center {
        text-align: center!important
    }
}

@media (min-width: 1260px) {
    .float-xl-start {
        float:left!important
    }

    .float-xl-end {
        float: right!important
    }

    .float-xl-none {
        float: none!important
    }

    .object-fit-xl-contain {
        -o-object-fit: contain!important;
        object-fit: contain!important
    }

    .object-fit-xl-cover {
        -o-object-fit: cover!important;
        object-fit: cover!important
    }

    .object-fit-xl-fill {
        -o-object-fit: fill!important;
        object-fit: fill!important
    }

    .object-fit-xl-scale {
        -o-object-fit: scale-down!important;
        object-fit: scale-down!important
    }

    .object-fit-xl-none {
        -o-object-fit: none!important;
        object-fit: none!important
    }

    .d-xl-inline {
        display: inline!important
    }

    .d-xl-inline-block {
        display: inline-block!important
    }

    .d-xl-block {
        display: block!important
    }

    .d-xl-grid {
        display: grid!important
    }

    .d-xl-table {
        display: table!important
    }

    .d-xl-table-row {
        display: table-row!important
    }

    .d-xl-table-cell {
        display: table-cell!important
    }

    .d-xl-flex {
        display: flex!important
    }

    .d-xl-inline-flex {
        display: inline-flex!important
    }

    .d-xl-none {
        display: none!important
    }

    .flex-xl-fill {
        flex: 1 1 auto!important
    }

    .flex-xl-row {
        flex-direction: row!important
    }

    .flex-xl-column {
        flex-direction: column!important
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse!important
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse!important
    }

    .flex-xl-grow-0 {
        flex-grow: 0!important
    }

    .flex-xl-grow-1 {
        flex-grow: 1!important
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0!important
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1!important
    }

    .flex-xl-wrap {
        flex-wrap: wrap!important
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap!important
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }

    .justify-content-xl-start {
        justify-content: flex-start!important
    }

    .justify-content-xl-end {
        justify-content: flex-end!important
    }

    .justify-content-xl-center {
        justify-content: center!important
    }

    .justify-content-xl-between {
        justify-content: space-between!important
    }

    .justify-content-xl-around {
        justify-content: space-around!important
    }

    .justify-content-xl-evenly {
        justify-content: space-evenly!important
    }

    .align-items-xl-start {
        align-items: flex-start!important
    }

    .align-items-xl-end {
        align-items: flex-end!important
    }

    .align-items-xl-center {
        align-items: center!important
    }

    .align-items-xl-baseline {
        align-items: baseline!important
    }

    .align-items-xl-stretch {
        align-items: stretch!important
    }

    .align-content-xl-start {
        align-content: flex-start!important
    }

    .align-content-xl-end {
        align-content: flex-end!important
    }

    .align-content-xl-center {
        align-content: center!important
    }

    .align-content-xl-between {
        align-content: space-between!important
    }

    .align-content-xl-around {
        align-content: space-around!important
    }

    .align-content-xl-stretch {
        align-content: stretch!important
    }

    .align-self-xl-auto {
        align-self: auto!important
    }

    .align-self-xl-start {
        align-self: flex-start!important
    }

    .align-self-xl-end {
        align-self: flex-end!important
    }

    .align-self-xl-center {
        align-self: center!important
    }

    .align-self-xl-baseline {
        align-self: baseline!important
    }

    .align-self-xl-stretch {
        align-self: stretch!important
    }

    .order-xl-first {
        order: -1!important
    }

    .order-xl-0 {
        order: 0!important
    }

    .order-xl-1 {
        order: 1!important
    }

    .order-xl-2 {
        order: 2!important
    }

    .order-xl-3 {
        order: 3!important
    }

    .order-xl-4 {
        order: 4!important
    }

    .order-xl-5 {
        order: 5!important
    }

    .order-xl-last {
        order: 6!important
    }

    .m-xl-0 {
        margin: 0!important
    }

    .m-xl-1 {
        margin: .25rem!important
    }

    .m-xl-2 {
        margin: .5rem!important
    }

    .m-xl-3 {
        margin: .75rem!important
    }

    .m-xl-4 {
        margin: 1rem!important
    }

    .m-xl-5 {
        margin: 1.25rem!important
    }

    .m-xl-6 {
        margin: 1.5rem!important
    }

    .m-xl-7 {
        margin: 1.75rem!important
    }

    .m-xl-8 {
        margin: 2rem!important
    }

    .m-xl-9 {
        margin: 2.5rem!important
    }

    .m-xl-10 {
        margin: 3rem!important
    }

    .m-xl-11 {
        margin: 3.5rem!important
    }

    .m-xl-12 {
        margin: 4rem!important
    }

    .m-xl-13 {
        margin: 4.5rem!important
    }

    .m-xl-14 {
        margin: 5rem!important
    }

    .m-xl-15 {
        margin: 5.5rem!important
    }

    .m-xl-16 {
        margin: 6rem!important
    }

    .m-xl-17 {
        margin: 6.5rem!important
    }

    .m-xl-18 {
        margin: 7rem!important
    }

    .m-xl-19 {
        margin: 7.5rem!important
    }

    .m-xl-20 {
        margin: 8rem!important
    }

    .m-xl-21 {
        margin: 8.5rem!important
    }

    .m-xl-22 {
        margin: 9rem!important
    }

    .m-xl-23 {
        margin: 9.5rem!important
    }

    .m-xl-24 {
        margin: 10rem!important
    }

    .m-xl-25 {
        margin: 10.5rem!important
    }

    .m-xl-26 {
        margin: 11rem!important
    }

    .m-xl-27 {
        margin: 12rem!important
    }

    .m-xl-28 {
        margin: 13rem!important
    }

    .m-xl-29 {
        margin: 14rem!important
    }

    .m-xl-30 {
        margin: 15rem!important
    }

    .m-xl-31 {
        margin: 16rem!important
    }

    .m-xl-32 {
        margin: 17rem!important
    }

    .m-xl-33 {
        margin: 18rem!important
    }

    .m-xl-34 {
        margin: 19rem!important
    }

    .m-xl-35 {
        margin: 20rem!important
    }

    .m-xl-auto {
        margin: auto!important
    }

    .mx-xl-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-xl-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }

    .mx-xl-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }

    .mx-xl-3 {
        margin-right: .75rem!important;
        margin-left: .75rem!important
    }

    .mx-xl-4 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-xl-5 {
        margin-right: 1.25rem!important;
        margin-left: 1.25rem!important
    }

    .mx-xl-6 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-xl-7 {
        margin-right: 1.75rem!important;
        margin-left: 1.75rem!important
    }

    .mx-xl-8 {
        margin-right: 2rem!important;
        margin-left: 2rem!important
    }

    .mx-xl-9 {
        margin-right: 2.5rem!important;
        margin-left: 2.5rem!important
    }

    .mx-xl-10 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-xl-11 {
        margin-right: 3.5rem!important;
        margin-left: 3.5rem!important
    }

    .mx-xl-12 {
        margin-right: 4rem!important;
        margin-left: 4rem!important
    }

    .mx-xl-13 {
        margin-right: 4.5rem!important;
        margin-left: 4.5rem!important
    }

    .mx-xl-14 {
        margin-right: 5rem!important;
        margin-left: 5rem!important
    }

    .mx-xl-15 {
        margin-right: 5.5rem!important;
        margin-left: 5.5rem!important
    }

    .mx-xl-16 {
        margin-right: 6rem!important;
        margin-left: 6rem!important
    }

    .mx-xl-17 {
        margin-right: 6.5rem!important;
        margin-left: 6.5rem!important
    }

    .mx-xl-18 {
        margin-right: 7rem!important;
        margin-left: 7rem!important
    }

    .mx-xl-19 {
        margin-right: 7.5rem!important;
        margin-left: 7.5rem!important
    }

    .mx-xl-20 {
        margin-right: 8rem!important;
        margin-left: 8rem!important
    }

    .mx-xl-21 {
        margin-right: 8.5rem!important;
        margin-left: 8.5rem!important
    }

    .mx-xl-22 {
        margin-right: 9rem!important;
        margin-left: 9rem!important
    }

    .mx-xl-23 {
        margin-right: 9.5rem!important;
        margin-left: 9.5rem!important
    }

    .mx-xl-24 {
        margin-right: 10rem!important;
        margin-left: 10rem!important
    }

    .mx-xl-25 {
        margin-right: 10.5rem!important;
        margin-left: 10.5rem!important
    }

    .mx-xl-26 {
        margin-right: 11rem!important;
        margin-left: 11rem!important
    }

    .mx-xl-27 {
        margin-right: 12rem!important;
        margin-left: 12rem!important
    }

    .mx-xl-28 {
        margin-right: 13rem!important;
        margin-left: 13rem!important
    }

    .mx-xl-29 {
        margin-right: 14rem!important;
        margin-left: 14rem!important
    }

    .mx-xl-30 {
        margin-right: 15rem!important;
        margin-left: 15rem!important
    }

    .mx-xl-31 {
        margin-right: 16rem!important;
        margin-left: 16rem!important
    }

    .mx-xl-32 {
        margin-right: 17rem!important;
        margin-left: 17rem!important
    }

    .mx-xl-33 {
        margin-right: 18rem!important;
        margin-left: 18rem!important
    }

    .mx-xl-34 {
        margin-right: 19rem!important;
        margin-left: 19rem!important
    }

    .mx-xl-35 {
        margin-right: 20rem!important;
        margin-left: 20rem!important
    }

    .mx-xl-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-xl-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-xl-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }

    .my-xl-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .my-xl-3 {
        margin-top: .75rem!important;
        margin-bottom: .75rem!important
    }

    .my-xl-4 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-xl-5 {
        margin-top: 1.25rem!important;
        margin-bottom: 1.25rem!important
    }

    .my-xl-6 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-xl-7 {
        margin-top: 1.75rem!important;
        margin-bottom: 1.75rem!important
    }

    .my-xl-8 {
        margin-top: 2rem!important;
        margin-bottom: 2rem!important
    }

    .my-xl-9 {
        margin-top: 2.5rem!important;
        margin-bottom: 2.5rem!important
    }

    .my-xl-10 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-xl-11 {
        margin-top: 3.5rem!important;
        margin-bottom: 3.5rem!important
    }

    .my-xl-12 {
        margin-top: 4rem!important;
        margin-bottom: 4rem!important
    }

    .my-xl-13 {
        margin-top: 4.5rem!important;
        margin-bottom: 4.5rem!important
    }

    .my-xl-14 {
        margin-top: 5rem!important;
        margin-bottom: 5rem!important
    }

    .my-xl-15 {
        margin-top: 5.5rem!important;
        margin-bottom: 5.5rem!important
    }

    .my-xl-16 {
        margin-top: 6rem!important;
        margin-bottom: 6rem!important
    }

    .my-xl-17 {
        margin-top: 6.5rem!important;
        margin-bottom: 6.5rem!important
    }

    .my-xl-18 {
        margin-top: 7rem!important;
        margin-bottom: 7rem!important
    }

    .my-xl-19 {
        margin-top: 7.5rem!important;
        margin-bottom: 7.5rem!important
    }

    .my-xl-20 {
        margin-top: 8rem!important;
        margin-bottom: 8rem!important
    }

    .my-xl-21 {
        margin-top: 8.5rem!important;
        margin-bottom: 8.5rem!important
    }

    .my-xl-22 {
        margin-top: 9rem!important;
        margin-bottom: 9rem!important
    }

    .my-xl-23 {
        margin-top: 9.5rem!important;
        margin-bottom: 9.5rem!important
    }

    .my-xl-24 {
        margin-top: 10rem!important;
        margin-bottom: 10rem!important
    }

    .my-xl-25 {
        margin-top: 10.5rem!important;
        margin-bottom: 10.5rem!important
    }

    .my-xl-26 {
        margin-top: 11rem!important;
        margin-bottom: 11rem!important
    }

    .my-xl-27 {
        margin-top: 12rem!important;
        margin-bottom: 12rem!important
    }

    .my-xl-28 {
        margin-top: 13rem!important;
        margin-bottom: 13rem!important
    }

    .my-xl-29 {
        margin-top: 14rem!important;
        margin-bottom: 14rem!important
    }

    .my-xl-30 {
        margin-top: 15rem!important;
        margin-bottom: 15rem!important
    }

    .my-xl-31 {
        margin-top: 16rem!important;
        margin-bottom: 16rem!important
    }

    .my-xl-32 {
        margin-top: 17rem!important;
        margin-bottom: 17rem!important
    }

    .my-xl-33 {
        margin-top: 18rem!important;
        margin-bottom: 18rem!important
    }

    .my-xl-34 {
        margin-top: 19rem!important;
        margin-bottom: 19rem!important
    }

    .my-xl-35 {
        margin-top: 20rem!important;
        margin-bottom: 20rem!important
    }

    .my-xl-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-xl-0 {
        margin-top: 0!important
    }

    .mt-xl-1 {
        margin-top: .25rem!important
    }

    .mt-xl-2 {
        margin-top: .5rem!important
    }

    .mt-xl-3 {
        margin-top: .75rem!important
    }

    .mt-xl-4 {
        margin-top: 1rem!important
    }

    .mt-xl-5 {
        margin-top: 1.25rem!important
    }

    .mt-xl-6 {
        margin-top: 1.5rem!important
    }

    .mt-xl-7 {
        margin-top: 1.75rem!important
    }

    .mt-xl-8 {
        margin-top: 2rem!important
    }

    .mt-xl-9 {
        margin-top: 2.5rem!important
    }

    .mt-xl-10 {
        margin-top: 3rem!important
    }

    .mt-xl-11 {
        margin-top: 3.5rem!important
    }

    .mt-xl-12 {
        margin-top: 4rem!important
    }

    .mt-xl-13 {
        margin-top: 4.5rem!important
    }

    .mt-xl-14 {
        margin-top: 5rem!important
    }

    .mt-xl-15 {
        margin-top: 5.5rem!important
    }

    .mt-xl-16 {
        margin-top: 6rem!important
    }

    .mt-xl-17 {
        margin-top: 6.5rem!important
    }

    .mt-xl-18 {
        margin-top: 7rem!important
    }

    .mt-xl-19 {
        margin-top: 7.5rem!important
    }

    .mt-xl-20 {
        margin-top: 8rem!important
    }

    .mt-xl-21 {
        margin-top: 8.5rem!important
    }

    .mt-xl-22 {
        margin-top: 9rem!important
    }

    .mt-xl-23 {
        margin-top: 9.5rem!important
    }

    .mt-xl-24 {
        margin-top: 10rem!important
    }

    .mt-xl-25 {
        margin-top: 10.5rem!important
    }

    .mt-xl-26 {
        margin-top: 11rem!important
    }

    .mt-xl-27 {
        margin-top: 12rem!important
    }

    .mt-xl-28 {
        margin-top: 13rem!important
    }

    .mt-xl-29 {
        margin-top: 14rem!important
    }

    .mt-xl-30 {
        margin-top: 15rem!important
    }

    .mt-xl-31 {
        margin-top: 16rem!important
    }

    .mt-xl-32 {
        margin-top: 17rem!important
    }

    .mt-xl-33 {
        margin-top: 18rem!important
    }

    .mt-xl-34 {
        margin-top: 19rem!important
    }

    .mt-xl-35 {
        margin-top: 20rem!important
    }

    .mt-xl-auto {
        margin-top: auto!important
    }

    .me-xl-0 {
        margin-right: 0!important
    }

    .me-xl-1 {
        margin-right: .25rem!important
    }

    .me-xl-2 {
        margin-right: .5rem!important
    }

    .me-xl-3 {
        margin-right: .75rem!important
    }

    .me-xl-4 {
        margin-right: 1rem!important
    }

    .me-xl-5 {
        margin-right: 1.25rem!important
    }

    .me-xl-6 {
        margin-right: 1.5rem!important
    }

    .me-xl-7 {
        margin-right: 1.75rem!important
    }

    .me-xl-8 {
        margin-right: 2rem!important
    }

    .me-xl-9 {
        margin-right: 2.5rem!important
    }

    .me-xl-10 {
        margin-right: 3rem!important
    }

    .me-xl-11 {
        margin-right: 3.5rem!important
    }

    .me-xl-12 {
        margin-right: 4rem!important
    }

    .me-xl-13 {
        margin-right: 4.5rem!important
    }

    .me-xl-14 {
        margin-right: 5rem!important
    }

    .me-xl-15 {
        margin-right: 5.5rem!important
    }

    .me-xl-16 {
        margin-right: 6rem!important
    }

    .me-xl-17 {
        margin-right: 6.5rem!important
    }

    .me-xl-18 {
        margin-right: 7rem!important
    }

    .me-xl-19 {
        margin-right: 7.5rem!important
    }

    .me-xl-20 {
        margin-right: 8rem!important
    }

    .me-xl-21 {
        margin-right: 8.5rem!important
    }

    .me-xl-22 {
        margin-right: 9rem!important
    }

    .me-xl-23 {
        margin-right: 9.5rem!important
    }

    .me-xl-24 {
        margin-right: 10rem!important
    }

    .me-xl-25 {
        margin-right: 10.5rem!important
    }

    .me-xl-26 {
        margin-right: 11rem!important
    }

    .me-xl-27 {
        margin-right: 12rem!important
    }

    .me-xl-28 {
        margin-right: 13rem!important
    }

    .me-xl-29 {
        margin-right: 14rem!important
    }

    .me-xl-30 {
        margin-right: 15rem!important
    }

    .me-xl-31 {
        margin-right: 16rem!important
    }

    .me-xl-32 {
        margin-right: 17rem!important
    }

    .me-xl-33 {
        margin-right: 18rem!important
    }

    .me-xl-34 {
        margin-right: 19rem!important
    }

    .me-xl-35 {
        margin-right: 20rem!important
    }

    .me-xl-auto {
        margin-right: auto!important
    }

    .mb-xl-0 {
        margin-bottom: 0!important
    }

    .mb-xl-1 {
        margin-bottom: .25rem!important
    }

    .mb-xl-2 {
        margin-bottom: .5rem!important
    }

    .mb-xl-3 {
        margin-bottom: .75rem!important
    }

    .mb-xl-4 {
        margin-bottom: 1rem!important
    }

    .mb-xl-5 {
        margin-bottom: 1.25rem!important
    }

    .mb-xl-6 {
        margin-bottom: 1.5rem!important
    }

    .mb-xl-7 {
        margin-bottom: 1.75rem!important
    }

    .mb-xl-8 {
        margin-bottom: 2rem!important
    }

    .mb-xl-9 {
        margin-bottom: 2.5rem!important
    }

    .mb-xl-10 {
        margin-bottom: 3rem!important
    }

    .mb-xl-11 {
        margin-bottom: 3.5rem!important
    }

    .mb-xl-12 {
        margin-bottom: 4rem!important
    }

    .mb-xl-13 {
        margin-bottom: 4.5rem!important
    }

    .mb-xl-14 {
        margin-bottom: 5rem!important
    }

    .mb-xl-15 {
        margin-bottom: 5.5rem!important
    }

    .mb-xl-16 {
        margin-bottom: 6rem!important
    }

    .mb-xl-17 {
        margin-bottom: 6.5rem!important
    }

    .mb-xl-18 {
        margin-bottom: 7rem!important
    }

    .mb-xl-19 {
        margin-bottom: 7.5rem!important
    }

    .mb-xl-20 {
        margin-bottom: 8rem!important
    }

    .mb-xl-21 {
        margin-bottom: 8.5rem!important
    }

    .mb-xl-22 {
        margin-bottom: 9rem!important
    }

    .mb-xl-23 {
        margin-bottom: 9.5rem!important
    }

    .mb-xl-24 {
        margin-bottom: 10rem!important
    }

    .mb-xl-25 {
        margin-bottom: 10.5rem!important
    }

    .mb-xl-26 {
        margin-bottom: 11rem!important
    }

    .mb-xl-27 {
        margin-bottom: 12rem!important
    }

    .mb-xl-28 {
        margin-bottom: 13rem!important
    }

    .mb-xl-29 {
        margin-bottom: 14rem!important
    }

    .mb-xl-30 {
        margin-bottom: 15rem!important
    }

    .mb-xl-31 {
        margin-bottom: 16rem!important
    }

    .mb-xl-32 {
        margin-bottom: 17rem!important
    }

    .mb-xl-33 {
        margin-bottom: 18rem!important
    }

    .mb-xl-34 {
        margin-bottom: 19rem!important
    }

    .mb-xl-35 {
        margin-bottom: 20rem!important
    }

    .mb-xl-auto {
        margin-bottom: auto!important
    }

    .ms-xl-0 {
        margin-left: 0!important
    }

    .ms-xl-1 {
        margin-left: .25rem!important
    }

    .ms-xl-2 {
        margin-left: .5rem!important
    }

    .ms-xl-3 {
        margin-left: .75rem!important
    }

    .ms-xl-4 {
        margin-left: 1rem!important
    }

    .ms-xl-5 {
        margin-left: 1.25rem!important
    }

    .ms-xl-6 {
        margin-left: 1.5rem!important
    }

    .ms-xl-7 {
        margin-left: 1.75rem!important
    }

    .ms-xl-8 {
        margin-left: 2rem!important
    }

    .ms-xl-9 {
        margin-left: 2.5rem!important
    }

    .ms-xl-10 {
        margin-left: 3rem!important
    }

    .ms-xl-11 {
        margin-left: 3.5rem!important
    }

    .ms-xl-12 {
        margin-left: 4rem!important
    }

    .ms-xl-13 {
        margin-left: 4.5rem!important
    }

    .ms-xl-14 {
        margin-left: 5rem!important
    }

    .ms-xl-15 {
        margin-left: 5.5rem!important
    }

    .ms-xl-16 {
        margin-left: 6rem!important
    }

    .ms-xl-17 {
        margin-left: 6.5rem!important
    }

    .ms-xl-18 {
        margin-left: 7rem!important
    }

    .ms-xl-19 {
        margin-left: 7.5rem!important
    }

    .ms-xl-20 {
        margin-left: 8rem!important
    }

    .ms-xl-21 {
        margin-left: 8.5rem!important
    }

    .ms-xl-22 {
        margin-left: 9rem!important
    }

    .ms-xl-23 {
        margin-left: 9.5rem!important
    }

    .ms-xl-24 {
        margin-left: 10rem!important
    }

    .ms-xl-25 {
        margin-left: 10.5rem!important
    }

    .ms-xl-26 {
        margin-left: 11rem!important
    }

    .ms-xl-27 {
        margin-left: 12rem!important
    }

    .ms-xl-28 {
        margin-left: 13rem!important
    }

    .ms-xl-29 {
        margin-left: 14rem!important
    }

    .ms-xl-30 {
        margin-left: 15rem!important
    }

    .ms-xl-31 {
        margin-left: 16rem!important
    }

    .ms-xl-32 {
        margin-left: 17rem!important
    }

    .ms-xl-33 {
        margin-left: 18rem!important
    }

    .ms-xl-34 {
        margin-left: 19rem!important
    }

    .ms-xl-35 {
        margin-left: 20rem!important
    }

    .ms-xl-auto {
        margin-left: auto!important
    }

    .p-xl-0 {
        padding: 0!important
    }

    .p-xl-1 {
        padding: .25rem!important
    }

    .p-xl-2 {
        padding: .5rem!important
    }

    .p-xl-3 {
        padding: .75rem!important
    }

    .p-xl-4 {
        padding: 1rem!important
    }

    .p-xl-5 {
        padding: 1.25rem!important
    }

    .p-xl-6 {
        padding: 1.5rem!important
    }

    .p-xl-7 {
        padding: 1.75rem!important
    }

    .p-xl-8 {
        padding: 2rem!important
    }

    .p-xl-9 {
        padding: 2.5rem!important
    }

    .p-xl-10 {
        padding: 3rem!important
    }

    .p-xl-11 {
        padding: 3.5rem!important
    }

    .p-xl-12 {
        padding: 4rem!important
    }

    .p-xl-13 {
        padding: 4.5rem!important
    }

    .p-xl-14 {
        padding: 5rem!important
    }

    .p-xl-15 {
        padding: 5.5rem!important
    }

    .p-xl-16 {
        padding: 6rem!important
    }

    .p-xl-17 {
        padding: 6.5rem!important
    }

    .p-xl-18 {
        padding: 7rem!important
    }

    .p-xl-19 {
        padding: 7.5rem!important
    }

    .p-xl-20 {
        padding: 8rem!important
    }

    .p-xl-21 {
        padding: 8.5rem!important
    }

    .p-xl-22 {
        padding: 9rem!important
    }

    .p-xl-23 {
        padding: 9.5rem!important
    }

    .p-xl-24 {
        padding: 10rem!important
    }

    .p-xl-25 {
        padding: 10.5rem!important
    }

    .p-xl-26 {
        padding: 11rem!important
    }

    .p-xl-27 {
        padding: 12rem!important
    }

    .p-xl-28 {
        padding: 13rem!important
    }

    .p-xl-29 {
        padding: 14rem!important
    }

    .p-xl-30 {
        padding: 15rem!important
    }

    .p-xl-31 {
        padding: 16rem!important
    }

    .p-xl-32 {
        padding: 17rem!important
    }

    .p-xl-33 {
        padding: 18rem!important
    }

    .p-xl-34 {
        padding: 19rem!important
    }

    .p-xl-35 {
        padding: 20rem!important
    }

    .px-xl-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-xl-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }

    .px-xl-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }

    .px-xl-3 {
        padding-right: .75rem!important;
        padding-left: .75rem!important
    }

    .px-xl-4 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-xl-5 {
        padding-right: 1.25rem!important;
        padding-left: 1.25rem!important
    }

    .px-xl-6 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-xl-7 {
        padding-right: 1.75rem!important;
        padding-left: 1.75rem!important
    }

    .px-xl-8 {
        padding-right: 2rem!important;
        padding-left: 2rem!important
    }

    .px-xl-9 {
        padding-right: 2.5rem!important;
        padding-left: 2.5rem!important
    }

    .px-xl-10 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .px-xl-11 {
        padding-right: 3.5rem!important;
        padding-left: 3.5rem!important
    }

    .px-xl-12 {
        padding-right: 4rem!important;
        padding-left: 4rem!important
    }

    .px-xl-13 {
        padding-right: 4.5rem!important;
        padding-left: 4.5rem!important
    }

    .px-xl-14 {
        padding-right: 5rem!important;
        padding-left: 5rem!important
    }

    .px-xl-15 {
        padding-right: 5.5rem!important;
        padding-left: 5.5rem!important
    }

    .px-xl-16 {
        padding-right: 6rem!important;
        padding-left: 6rem!important
    }

    .px-xl-17 {
        padding-right: 6.5rem!important;
        padding-left: 6.5rem!important
    }

    .px-xl-18 {
        padding-right: 7rem!important;
        padding-left: 7rem!important
    }

    .px-xl-19 {
        padding-right: 7.5rem!important;
        padding-left: 7.5rem!important
    }

    .px-xl-20 {
        padding-right: 8rem!important;
        padding-left: 8rem!important
    }

    .px-xl-21 {
        padding-right: 8.5rem!important;
        padding-left: 8.5rem!important
    }

    .px-xl-22 {
        padding-right: 9rem!important;
        padding-left: 9rem!important
    }

    .px-xl-23 {
        padding-right: 9.5rem!important;
        padding-left: 9.5rem!important
    }

    .px-xl-24 {
        padding-right: 10rem!important;
        padding-left: 10rem!important
    }

    .px-xl-25 {
        padding-right: 10.5rem!important;
        padding-left: 10.5rem!important
    }

    .px-xl-26 {
        padding-right: 11rem!important;
        padding-left: 11rem!important
    }

    .px-xl-27 {
        padding-right: 12rem!important;
        padding-left: 12rem!important
    }

    .px-xl-28 {
        padding-right: 13rem!important;
        padding-left: 13rem!important
    }

    .px-xl-29 {
        padding-right: 14rem!important;
        padding-left: 14rem!important
    }

    .px-xl-30 {
        padding-right: 15rem!important;
        padding-left: 15rem!important
    }

    .px-xl-31 {
        padding-right: 16rem!important;
        padding-left: 16rem!important
    }

    .px-xl-32 {
        padding-right: 17rem!important;
        padding-left: 17rem!important
    }

    .px-xl-33 {
        padding-right: 18rem!important;
        padding-left: 18rem!important
    }

    .px-xl-34 {
        padding-right: 19rem!important;
        padding-left: 19rem!important
    }

    .px-xl-35 {
        padding-right: 20rem!important;
        padding-left: 20rem!important
    }

    .py-xl-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-xl-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }

    .py-xl-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }

    .py-xl-3 {
        padding-top: .75rem!important;
        padding-bottom: .75rem!important
    }

    .py-xl-4 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-xl-5 {
        padding-top: 1.25rem!important;
        padding-bottom: 1.25rem!important
    }

    .py-xl-6 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-xl-7 {
        padding-top: 1.75rem!important;
        padding-bottom: 1.75rem!important
    }

    .py-xl-8 {
        padding-top: 2rem!important;
        padding-bottom: 2rem!important
    }

    .py-xl-9 {
        padding-top: 2.5rem!important;
        padding-bottom: 2.5rem!important
    }

    .py-xl-10 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .py-xl-11 {
        padding-top: 3.5rem!important;
        padding-bottom: 3.5rem!important
    }

    .py-xl-12 {
        padding-top: 4rem!important;
        padding-bottom: 4rem!important
    }

    .py-xl-13 {
        padding-top: 4.5rem!important;
        padding-bottom: 4.5rem!important
    }

    .py-xl-14 {
        padding-top: 5rem!important;
        padding-bottom: 5rem!important
    }

    .py-xl-15 {
        padding-top: 5.5rem!important;
        padding-bottom: 5.5rem!important
    }

    .py-xl-16 {
        padding-top: 6rem!important;
        padding-bottom: 6rem!important
    }

    .py-xl-17 {
        padding-top: 6.5rem!important;
        padding-bottom: 6.5rem!important
    }

    .py-xl-18 {
        padding-top: 7rem!important;
        padding-bottom: 7rem!important
    }

    .py-xl-19 {
        padding-top: 7.5rem!important;
        padding-bottom: 7.5rem!important
    }

    .py-xl-20 {
        padding-top: 8rem!important;
        padding-bottom: 8rem!important
    }

    .py-xl-21 {
        padding-top: 8.5rem!important;
        padding-bottom: 8.5rem!important
    }

    .py-xl-22 {
        padding-top: 9rem!important;
        padding-bottom: 9rem!important
    }

    .py-xl-23 {
        padding-top: 9.5rem!important;
        padding-bottom: 9.5rem!important
    }

    .py-xl-24 {
        padding-top: 10rem!important;
        padding-bottom: 10rem!important
    }

    .py-xl-25 {
        padding-top: 10.5rem!important;
        padding-bottom: 10.5rem!important
    }

    .py-xl-26 {
        padding-top: 11rem!important;
        padding-bottom: 11rem!important
    }

    .py-xl-27 {
        padding-top: 12rem!important;
        padding-bottom: 12rem!important
    }

    .py-xl-28 {
        padding-top: 13rem!important;
        padding-bottom: 13rem!important
    }

    .py-xl-29 {
        padding-top: 14rem!important;
        padding-bottom: 14rem!important
    }

    .py-xl-30 {
        padding-top: 15rem!important;
        padding-bottom: 15rem!important
    }

    .py-xl-31 {
        padding-top: 16rem!important;
        padding-bottom: 16rem!important
    }

    .py-xl-32 {
        padding-top: 17rem!important;
        padding-bottom: 17rem!important
    }

    .py-xl-33 {
        padding-top: 18rem!important;
        padding-bottom: 18rem!important
    }

    .py-xl-34 {
        padding-top: 19rem!important;
        padding-bottom: 19rem!important
    }

    .py-xl-35 {
        padding-top: 20rem!important;
        padding-bottom: 20rem!important
    }

    .pt-xl-0 {
        padding-top: 0!important
    }

    .pt-xl-1 {
        padding-top: .25rem!important
    }

    .pt-xl-2 {
        padding-top: .5rem!important
    }

    .pt-xl-3 {
        padding-top: .75rem!important
    }

    .pt-xl-4 {
        padding-top: 1rem!important
    }

    .pt-xl-5 {
        padding-top: 1.25rem!important
    }

    .pt-xl-6 {
        padding-top: 1.5rem!important
    }

    .pt-xl-7 {
        padding-top: 1.75rem!important
    }

    .pt-xl-8 {
        padding-top: 2rem!important
    }

    .pt-xl-9 {
        padding-top: 2.5rem!important
    }

    .pt-xl-10 {
        padding-top: 3rem!important
    }

    .pt-xl-11 {
        padding-top: 3.5rem!important
    }

    .pt-xl-12 {
        padding-top: 4rem!important
    }

    .pt-xl-13 {
        padding-top: 4.5rem!important
    }

    .pt-xl-14 {
        padding-top: 5rem!important
    }

    .pt-xl-15 {
        padding-top: 5.5rem!important
    }

    .pt-xl-16 {
        padding-top: 6rem!important
    }

    .pt-xl-17 {
        padding-top: 6.5rem!important
    }

    .pt-xl-18 {
        padding-top: 7rem!important
    }

    .pt-xl-19 {
        padding-top: 7.5rem!important
    }

    .pt-xl-20 {
        padding-top: 8rem!important
    }

    .pt-xl-21 {
        padding-top: 8.5rem!important
    }

    .pt-xl-22 {
        padding-top: 9rem!important
    }

    .pt-xl-23 {
        padding-top: 9.5rem!important
    }

    .pt-xl-24 {
        padding-top: 10rem!important
    }

    .pt-xl-25 {
        padding-top: 10.5rem!important
    }

    .pt-xl-26 {
        padding-top: 11rem!important
    }

    .pt-xl-27 {
        padding-top: 12rem!important
    }

    .pt-xl-28 {
        padding-top: 13rem!important
    }

    .pt-xl-29 {
        padding-top: 14rem!important
    }

    .pt-xl-30 {
        padding-top: 15rem!important
    }

    .pt-xl-31 {
        padding-top: 16rem!important
    }

    .pt-xl-32 {
        padding-top: 17rem!important
    }

    .pt-xl-33 {
        padding-top: 18rem!important
    }

    .pt-xl-34 {
        padding-top: 19rem!important
    }

    .pt-xl-35 {
        padding-top: 20rem!important
    }

    .pe-xl-0 {
        padding-right: 0!important
    }

    .pe-xl-1 {
        padding-right: .25rem!important
    }

    .pe-xl-2 {
        padding-right: .5rem!important
    }

    .pe-xl-3 {
        padding-right: .75rem!important
    }

    .pe-xl-4 {
        padding-right: 1rem!important
    }

    .pe-xl-5 {
        padding-right: 1.25rem!important
    }

    .pe-xl-6 {
        padding-right: 1.5rem!important
    }

    .pe-xl-7 {
        padding-right: 1.75rem!important
    }

    .pe-xl-8 {
        padding-right: 2rem!important
    }

    .pe-xl-9 {
        padding-right: 2.5rem!important
    }

    .pe-xl-10 {
        padding-right: 3rem!important
    }

    .pe-xl-11 {
        padding-right: 3.5rem!important
    }

    .pe-xl-12 {
        padding-right: 4rem!important
    }

    .pe-xl-13 {
        padding-right: 4.5rem!important
    }

    .pe-xl-14 {
        padding-right: 5rem!important
    }

    .pe-xl-15 {
        padding-right: 5.5rem!important
    }

    .pe-xl-16 {
        padding-right: 6rem!important
    }

    .pe-xl-17 {
        padding-right: 6.5rem!important
    }

    .pe-xl-18 {
        padding-right: 7rem!important
    }

    .pe-xl-19 {
        padding-right: 7.5rem!important
    }

    .pe-xl-20 {
        padding-right: 8rem!important
    }

    .pe-xl-21 {
        padding-right: 8.5rem!important
    }

    .pe-xl-22 {
        padding-right: 9rem!important
    }

    .pe-xl-23 {
        padding-right: 9.5rem!important
    }

    .pe-xl-24 {
        padding-right: 10rem!important
    }

    .pe-xl-25 {
        padding-right: 10.5rem!important
    }

    .pe-xl-26 {
        padding-right: 11rem!important
    }

    .pe-xl-27 {
        padding-right: 12rem!important
    }

    .pe-xl-28 {
        padding-right: 13rem!important
    }

    .pe-xl-29 {
        padding-right: 14rem!important
    }

    .pe-xl-30 {
        padding-right: 15rem!important
    }

    .pe-xl-31 {
        padding-right: 16rem!important
    }

    .pe-xl-32 {
        padding-right: 17rem!important
    }

    .pe-xl-33 {
        padding-right: 18rem!important
    }

    .pe-xl-34 {
        padding-right: 19rem!important
    }

    .pe-xl-35 {
        padding-right: 20rem!important
    }

    .pb-xl-0 {
        padding-bottom: 0!important
    }

    .pb-xl-1 {
        padding-bottom: .25rem!important
    }

    .pb-xl-2 {
        padding-bottom: .5rem!important
    }

    .pb-xl-3 {
        padding-bottom: .75rem!important
    }

    .pb-xl-4 {
        padding-bottom: 1rem!important
    }

    .pb-xl-5 {
        padding-bottom: 1.25rem!important
    }

    .pb-xl-6 {
        padding-bottom: 1.5rem!important
    }

    .pb-xl-7 {
        padding-bottom: 1.75rem!important
    }

    .pb-xl-8 {
        padding-bottom: 2rem!important
    }

    .pb-xl-9 {
        padding-bottom: 2.5rem!important
    }

    .pb-xl-10 {
        padding-bottom: 3rem!important
    }

    .pb-xl-11 {
        padding-bottom: 3.5rem!important
    }

    .pb-xl-12 {
        padding-bottom: 4rem!important
    }

    .pb-xl-13 {
        padding-bottom: 4.5rem!important
    }

    .pb-xl-14 {
        padding-bottom: 5rem!important
    }

    .pb-xl-15 {
        padding-bottom: 5.5rem!important
    }

    .pb-xl-16 {
        padding-bottom: 6rem!important
    }

    .pb-xl-17 {
        padding-bottom: 6.5rem!important
    }

    .pb-xl-18 {
        padding-bottom: 7rem!important
    }

    .pb-xl-19 {
        padding-bottom: 7.5rem!important
    }

    .pb-xl-20 {
        padding-bottom: 8rem!important
    }

    .pb-xl-21 {
        padding-bottom: 8.5rem!important
    }

    .pb-xl-22 {
        padding-bottom: 9rem!important
    }

    .pb-xl-23 {
        padding-bottom: 9.5rem!important
    }

    .pb-xl-24 {
        padding-bottom: 10rem!important
    }

    .pb-xl-25 {
        padding-bottom: 10.5rem!important
    }

    .pb-xl-26 {
        padding-bottom: 11rem!important
    }

    .pb-xl-27 {
        padding-bottom: 12rem!important
    }

    .pb-xl-28 {
        padding-bottom: 13rem!important
    }

    .pb-xl-29 {
        padding-bottom: 14rem!important
    }

    .pb-xl-30 {
        padding-bottom: 15rem!important
    }

    .pb-xl-31 {
        padding-bottom: 16rem!important
    }

    .pb-xl-32 {
        padding-bottom: 17rem!important
    }

    .pb-xl-33 {
        padding-bottom: 18rem!important
    }

    .pb-xl-34 {
        padding-bottom: 19rem!important
    }

    .pb-xl-35 {
        padding-bottom: 20rem!important
    }

    .ps-xl-0 {
        padding-left: 0!important
    }

    .ps-xl-1 {
        padding-left: .25rem!important
    }

    .ps-xl-2 {
        padding-left: .5rem!important
    }

    .ps-xl-3 {
        padding-left: .75rem!important
    }

    .ps-xl-4 {
        padding-left: 1rem!important
    }

    .ps-xl-5 {
        padding-left: 1.25rem!important
    }

    .ps-xl-6 {
        padding-left: 1.5rem!important
    }

    .ps-xl-7 {
        padding-left: 1.75rem!important
    }

    .ps-xl-8 {
        padding-left: 2rem!important
    }

    .ps-xl-9 {
        padding-left: 2.5rem!important
    }

    .ps-xl-10 {
        padding-left: 3rem!important
    }

    .ps-xl-11 {
        padding-left: 3.5rem!important
    }

    .ps-xl-12 {
        padding-left: 4rem!important
    }

    .ps-xl-13 {
        padding-left: 4.5rem!important
    }

    .ps-xl-14 {
        padding-left: 5rem!important
    }

    .ps-xl-15 {
        padding-left: 5.5rem!important
    }

    .ps-xl-16 {
        padding-left: 6rem!important
    }

    .ps-xl-17 {
        padding-left: 6.5rem!important
    }

    .ps-xl-18 {
        padding-left: 7rem!important
    }

    .ps-xl-19 {
        padding-left: 7.5rem!important
    }

    .ps-xl-20 {
        padding-left: 8rem!important
    }

    .ps-xl-21 {
        padding-left: 8.5rem!important
    }

    .ps-xl-22 {
        padding-left: 9rem!important
    }

    .ps-xl-23 {
        padding-left: 9.5rem!important
    }

    .ps-xl-24 {
        padding-left: 10rem!important
    }

    .ps-xl-25 {
        padding-left: 10.5rem!important
    }

    .ps-xl-26 {
        padding-left: 11rem!important
    }

    .ps-xl-27 {
        padding-left: 12rem!important
    }

    .ps-xl-28 {
        padding-left: 13rem!important
    }

    .ps-xl-29 {
        padding-left: 14rem!important
    }

    .ps-xl-30 {
        padding-left: 15rem!important
    }

    .ps-xl-31 {
        padding-left: 16rem!important
    }

    .ps-xl-32 {
        padding-left: 17rem!important
    }

    .ps-xl-33 {
        padding-left: 18rem!important
    }

    .ps-xl-34 {
        padding-left: 19rem!important
    }

    .ps-xl-35 {
        padding-left: 20rem!important
    }

    .gap-xl-0 {
        gap: 0!important
    }

    .gap-xl-1 {
        gap: .25rem!important
    }

    .gap-xl-2 {
        gap: .5rem!important
    }

    .gap-xl-3 {
        gap: .75rem!important
    }

    .gap-xl-4 {
        gap: 1rem!important
    }

    .gap-xl-5 {
        gap: 1.25rem!important
    }

    .gap-xl-6 {
        gap: 1.5rem!important
    }

    .gap-xl-7 {
        gap: 1.75rem!important
    }

    .gap-xl-8 {
        gap: 2rem!important
    }

    .gap-xl-9 {
        gap: 2.5rem!important
    }

    .gap-xl-10 {
        gap: 3rem!important
    }

    .gap-xl-11 {
        gap: 3.5rem!important
    }

    .gap-xl-12 {
        gap: 4rem!important
    }

    .gap-xl-13 {
        gap: 4.5rem!important
    }

    .gap-xl-14 {
        gap: 5rem!important
    }

    .gap-xl-15 {
        gap: 5.5rem!important
    }

    .gap-xl-16 {
        gap: 6rem!important
    }

    .gap-xl-17 {
        gap: 6.5rem!important
    }

    .gap-xl-18 {
        gap: 7rem!important
    }

    .gap-xl-19 {
        gap: 7.5rem!important
    }

    .gap-xl-20 {
        gap: 8rem!important
    }

    .gap-xl-21 {
        gap: 8.5rem!important
    }

    .gap-xl-22 {
        gap: 9rem!important
    }

    .gap-xl-23 {
        gap: 9.5rem!important
    }

    .gap-xl-24 {
        gap: 10rem!important
    }

    .gap-xl-25 {
        gap: 10.5rem!important
    }

    .gap-xl-26 {
        gap: 11rem!important
    }

    .gap-xl-27 {
        gap: 12rem!important
    }

    .gap-xl-28 {
        gap: 13rem!important
    }

    .gap-xl-29 {
        gap: 14rem!important
    }

    .gap-xl-30 {
        gap: 15rem!important
    }

    .gap-xl-31 {
        gap: 16rem!important
    }

    .gap-xl-32 {
        gap: 17rem!important
    }

    .gap-xl-33 {
        gap: 18rem!important
    }

    .gap-xl-34 {
        gap: 19rem!important
    }

    .gap-xl-35 {
        gap: 20rem!important
    }

    .row-gap-xl-0 {
        row-gap: 0!important
    }

    .row-gap-xl-1 {
        row-gap: .25rem!important
    }

    .row-gap-xl-2 {
        row-gap: .5rem!important
    }

    .row-gap-xl-3 {
        row-gap: .75rem!important
    }

    .row-gap-xl-4 {
        row-gap: 1rem!important
    }

    .row-gap-xl-5 {
        row-gap: 1.25rem!important
    }

    .row-gap-xl-6 {
        row-gap: 1.5rem!important
    }

    .row-gap-xl-7 {
        row-gap: 1.75rem!important
    }

    .row-gap-xl-8 {
        row-gap: 2rem!important
    }

    .row-gap-xl-9 {
        row-gap: 2.5rem!important
    }

    .row-gap-xl-10 {
        row-gap: 3rem!important
    }

    .row-gap-xl-11 {
        row-gap: 3.5rem!important
    }

    .row-gap-xl-12 {
        row-gap: 4rem!important
    }

    .row-gap-xl-13 {
        row-gap: 4.5rem!important
    }

    .row-gap-xl-14 {
        row-gap: 5rem!important
    }

    .row-gap-xl-15 {
        row-gap: 5.5rem!important
    }

    .row-gap-xl-16 {
        row-gap: 6rem!important
    }

    .row-gap-xl-17 {
        row-gap: 6.5rem!important
    }

    .row-gap-xl-18 {
        row-gap: 7rem!important
    }

    .row-gap-xl-19 {
        row-gap: 7.5rem!important
    }

    .row-gap-xl-20 {
        row-gap: 8rem!important
    }

    .row-gap-xl-21 {
        row-gap: 8.5rem!important
    }

    .row-gap-xl-22 {
        row-gap: 9rem!important
    }

    .row-gap-xl-23 {
        row-gap: 9.5rem!important
    }

    .row-gap-xl-24 {
        row-gap: 10rem!important
    }

    .row-gap-xl-25 {
        row-gap: 10.5rem!important
    }

    .row-gap-xl-26 {
        row-gap: 11rem!important
    }

    .row-gap-xl-27 {
        row-gap: 12rem!important
    }

    .row-gap-xl-28 {
        row-gap: 13rem!important
    }

    .row-gap-xl-29 {
        row-gap: 14rem!important
    }

    .row-gap-xl-30 {
        row-gap: 15rem!important
    }

    .row-gap-xl-31 {
        row-gap: 16rem!important
    }

    .row-gap-xl-32 {
        row-gap: 17rem!important
    }

    .row-gap-xl-33 {
        row-gap: 18rem!important
    }

    .row-gap-xl-34 {
        row-gap: 19rem!important
    }

    .row-gap-xl-35 {
        row-gap: 20rem!important
    }

    .column-gap-xl-0 {
        -moz-column-gap: 0!important;
        column-gap: 0!important
    }

    .column-gap-xl-1 {
        -moz-column-gap: .25rem!important;
        column-gap: .25rem!important
    }

    .column-gap-xl-2 {
        -moz-column-gap: .5rem!important;
        column-gap: .5rem!important
    }

    .column-gap-xl-3 {
        -moz-column-gap: .75rem!important;
        column-gap: .75rem!important
    }

    .column-gap-xl-4 {
        -moz-column-gap: 1rem!important;
        column-gap: 1rem!important
    }

    .column-gap-xl-5 {
        -moz-column-gap: 1.25rem!important;
        column-gap: 1.25rem!important
    }

    .column-gap-xl-6 {
        -moz-column-gap: 1.5rem!important;
        column-gap: 1.5rem!important
    }

    .column-gap-xl-7 {
        -moz-column-gap: 1.75rem!important;
        column-gap: 1.75rem!important
    }

    .column-gap-xl-8 {
        -moz-column-gap: 2rem!important;
        column-gap: 2rem!important
    }

    .column-gap-xl-9 {
        -moz-column-gap: 2.5rem!important;
        column-gap: 2.5rem!important
    }

    .column-gap-xl-10 {
        -moz-column-gap: 3rem!important;
        column-gap: 3rem!important
    }

    .column-gap-xl-11 {
        -moz-column-gap: 3.5rem!important;
        column-gap: 3.5rem!important
    }

    .column-gap-xl-12 {
        -moz-column-gap: 4rem!important;
        column-gap: 4rem!important
    }

    .column-gap-xl-13 {
        -moz-column-gap: 4.5rem!important;
        column-gap: 4.5rem!important
    }

    .column-gap-xl-14 {
        -moz-column-gap: 5rem!important;
        column-gap: 5rem!important
    }

    .column-gap-xl-15 {
        -moz-column-gap: 5.5rem!important;
        column-gap: 5.5rem!important
    }

    .column-gap-xl-16 {
        -moz-column-gap: 6rem!important;
        column-gap: 6rem!important
    }

    .column-gap-xl-17 {
        -moz-column-gap: 6.5rem!important;
        column-gap: 6.5rem!important
    }

    .column-gap-xl-18 {
        -moz-column-gap: 7rem!important;
        column-gap: 7rem!important
    }

    .column-gap-xl-19 {
        -moz-column-gap: 7.5rem!important;
        column-gap: 7.5rem!important
    }

    .column-gap-xl-20 {
        -moz-column-gap: 8rem!important;
        column-gap: 8rem!important
    }

    .column-gap-xl-21 {
        -moz-column-gap: 8.5rem!important;
        column-gap: 8.5rem!important
    }

    .column-gap-xl-22 {
        -moz-column-gap: 9rem!important;
        column-gap: 9rem!important
    }

    .column-gap-xl-23 {
        -moz-column-gap: 9.5rem!important;
        column-gap: 9.5rem!important
    }

    .column-gap-xl-24 {
        -moz-column-gap: 10rem!important;
        column-gap: 10rem!important
    }

    .column-gap-xl-25 {
        -moz-column-gap: 10.5rem!important;
        column-gap: 10.5rem!important
    }

    .column-gap-xl-26 {
        -moz-column-gap: 11rem!important;
        column-gap: 11rem!important
    }

    .column-gap-xl-27 {
        -moz-column-gap: 12rem!important;
        column-gap: 12rem!important
    }

    .column-gap-xl-28 {
        -moz-column-gap: 13rem!important;
        column-gap: 13rem!important
    }

    .column-gap-xl-29 {
        -moz-column-gap: 14rem!important;
        column-gap: 14rem!important
    }

    .column-gap-xl-30 {
        -moz-column-gap: 15rem!important;
        column-gap: 15rem!important
    }

    .column-gap-xl-31 {
        -moz-column-gap: 16rem!important;
        column-gap: 16rem!important
    }

    .column-gap-xl-32 {
        -moz-column-gap: 17rem!important;
        column-gap: 17rem!important
    }

    .column-gap-xl-33 {
        -moz-column-gap: 18rem!important;
        column-gap: 18rem!important
    }

    .column-gap-xl-34 {
        -moz-column-gap: 19rem!important;
        column-gap: 19rem!important
    }

    .column-gap-xl-35 {
        -moz-column-gap: 20rem!important;
        column-gap: 20rem!important
    }

    .text-xl-start {
        text-align: left!important
    }

    .text-xl-end {
        text-align: right!important
    }

    .text-xl-center {
        text-align: center!important
    }
}

@media (min-width: 1400px) {
    .float-xxl-start {
        float:left!important
    }

    .float-xxl-end {
        float: right!important
    }

    .float-xxl-none {
        float: none!important
    }

    .object-fit-xxl-contain {
        -o-object-fit: contain!important;
        object-fit: contain!important
    }

    .object-fit-xxl-cover {
        -o-object-fit: cover!important;
        object-fit: cover!important
    }

    .object-fit-xxl-fill {
        -o-object-fit: fill!important;
        object-fit: fill!important
    }

    .object-fit-xxl-scale {
        -o-object-fit: scale-down!important;
        object-fit: scale-down!important
    }

    .object-fit-xxl-none {
        -o-object-fit: none!important;
        object-fit: none!important
    }

    .d-xxl-inline {
        display: inline!important
    }

    .d-xxl-inline-block {
        display: inline-block!important
    }

    .d-xxl-block {
        display: block!important
    }

    .d-xxl-grid {
        display: grid!important
    }

    .d-xxl-table {
        display: table!important
    }

    .d-xxl-table-row {
        display: table-row!important
    }

    .d-xxl-table-cell {
        display: table-cell!important
    }

    .d-xxl-flex {
        display: flex!important
    }

    .d-xxl-inline-flex {
        display: inline-flex!important
    }

    .d-xxl-none {
        display: none!important
    }

    .flex-xxl-fill {
        flex: 1 1 auto!important
    }

    .flex-xxl-row {
        flex-direction: row!important
    }

    .flex-xxl-column {
        flex-direction: column!important
    }

    .flex-xxl-row-reverse {
        flex-direction: row-reverse!important
    }

    .flex-xxl-column-reverse {
        flex-direction: column-reverse!important
    }

    .flex-xxl-grow-0 {
        flex-grow: 0!important
    }

    .flex-xxl-grow-1 {
        flex-grow: 1!important
    }

    .flex-xxl-shrink-0 {
        flex-shrink: 0!important
    }

    .flex-xxl-shrink-1 {
        flex-shrink: 1!important
    }

    .flex-xxl-wrap {
        flex-wrap: wrap!important
    }

    .flex-xxl-nowrap {
        flex-wrap: nowrap!important
    }

    .flex-xxl-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }

    .justify-content-xxl-start {
        justify-content: flex-start!important
    }

    .justify-content-xxl-end {
        justify-content: flex-end!important
    }

    .justify-content-xxl-center {
        justify-content: center!important
    }

    .justify-content-xxl-between {
        justify-content: space-between!important
    }

    .justify-content-xxl-around {
        justify-content: space-around!important
    }

    .justify-content-xxl-evenly {
        justify-content: space-evenly!important
    }

    .align-items-xxl-start {
        align-items: flex-start!important
    }

    .align-items-xxl-end {
        align-items: flex-end!important
    }

    .align-items-xxl-center {
        align-items: center!important
    }

    .align-items-xxl-baseline {
        align-items: baseline!important
    }

    .align-items-xxl-stretch {
        align-items: stretch!important
    }

    .align-content-xxl-start {
        align-content: flex-start!important
    }

    .align-content-xxl-end {
        align-content: flex-end!important
    }

    .align-content-xxl-center {
        align-content: center!important
    }

    .align-content-xxl-between {
        align-content: space-between!important
    }

    .align-content-xxl-around {
        align-content: space-around!important
    }

    .align-content-xxl-stretch {
        align-content: stretch!important
    }

    .align-self-xxl-auto {
        align-self: auto!important
    }

    .align-self-xxl-start {
        align-self: flex-start!important
    }

    .align-self-xxl-end {
        align-self: flex-end!important
    }

    .align-self-xxl-center {
        align-self: center!important
    }

    .align-self-xxl-baseline {
        align-self: baseline!important
    }

    .align-self-xxl-stretch {
        align-self: stretch!important
    }

    .order-xxl-first {
        order: -1!important
    }

    .order-xxl-0 {
        order: 0!important
    }

    .order-xxl-1 {
        order: 1!important
    }

    .order-xxl-2 {
        order: 2!important
    }

    .order-xxl-3 {
        order: 3!important
    }

    .order-xxl-4 {
        order: 4!important
    }

    .order-xxl-5 {
        order: 5!important
    }

    .order-xxl-last {
        order: 6!important
    }

    .m-xxl-0 {
        margin: 0!important
    }

    .m-xxl-1 {
        margin: .25rem!important
    }

    .m-xxl-2 {
        margin: .5rem!important
    }

    .m-xxl-3 {
        margin: .75rem!important
    }

    .m-xxl-4 {
        margin: 1rem!important
    }

    .m-xxl-5 {
        margin: 1.25rem!important
    }

    .m-xxl-6 {
        margin: 1.5rem!important
    }

    .m-xxl-7 {
        margin: 1.75rem!important
    }

    .m-xxl-8 {
        margin: 2rem!important
    }

    .m-xxl-9 {
        margin: 2.5rem!important
    }

    .m-xxl-10 {
        margin: 3rem!important
    }

    .m-xxl-11 {
        margin: 3.5rem!important
    }

    .m-xxl-12 {
        margin: 4rem!important
    }

    .m-xxl-13 {
        margin: 4.5rem!important
    }

    .m-xxl-14 {
        margin: 5rem!important
    }

    .m-xxl-15 {
        margin: 5.5rem!important
    }

    .m-xxl-16 {
        margin: 6rem!important
    }

    .m-xxl-17 {
        margin: 6.5rem!important
    }

    .m-xxl-18 {
        margin: 7rem!important
    }

    .m-xxl-19 {
        margin: 7.5rem!important
    }

    .m-xxl-20 {
        margin: 8rem!important
    }

    .m-xxl-21 {
        margin: 8.5rem!important
    }

    .m-xxl-22 {
        margin: 9rem!important
    }

    .m-xxl-23 {
        margin: 9.5rem!important
    }

    .m-xxl-24 {
        margin: 10rem!important
    }

    .m-xxl-25 {
        margin: 10.5rem!important
    }

    .m-xxl-26 {
        margin: 11rem!important
    }

    .m-xxl-27 {
        margin: 12rem!important
    }

    .m-xxl-28 {
        margin: 13rem!important
    }

    .m-xxl-29 {
        margin: 14rem!important
    }

    .m-xxl-30 {
        margin: 15rem!important
    }

    .m-xxl-31 {
        margin: 16rem!important
    }

    .m-xxl-32 {
        margin: 17rem!important
    }

    .m-xxl-33 {
        margin: 18rem!important
    }

    .m-xxl-34 {
        margin: 19rem!important
    }

    .m-xxl-35 {
        margin: 20rem!important
    }

    .m-xxl-auto {
        margin: auto!important
    }

    .mx-xxl-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-xxl-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }

    .mx-xxl-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }

    .mx-xxl-3 {
        margin-right: .75rem!important;
        margin-left: .75rem!important
    }

    .mx-xxl-4 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-xxl-5 {
        margin-right: 1.25rem!important;
        margin-left: 1.25rem!important
    }

    .mx-xxl-6 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-xxl-7 {
        margin-right: 1.75rem!important;
        margin-left: 1.75rem!important
    }

    .mx-xxl-8 {
        margin-right: 2rem!important;
        margin-left: 2rem!important
    }

    .mx-xxl-9 {
        margin-right: 2.5rem!important;
        margin-left: 2.5rem!important
    }

    .mx-xxl-10 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-xxl-11 {
        margin-right: 3.5rem!important;
        margin-left: 3.5rem!important
    }

    .mx-xxl-12 {
        margin-right: 4rem!important;
        margin-left: 4rem!important
    }

    .mx-xxl-13 {
        margin-right: 4.5rem!important;
        margin-left: 4.5rem!important
    }

    .mx-xxl-14 {
        margin-right: 5rem!important;
        margin-left: 5rem!important
    }

    .mx-xxl-15 {
        margin-right: 5.5rem!important;
        margin-left: 5.5rem!important
    }

    .mx-xxl-16 {
        margin-right: 6rem!important;
        margin-left: 6rem!important
    }

    .mx-xxl-17 {
        margin-right: 6.5rem!important;
        margin-left: 6.5rem!important
    }

    .mx-xxl-18 {
        margin-right: 7rem!important;
        margin-left: 7rem!important
    }

    .mx-xxl-19 {
        margin-right: 7.5rem!important;
        margin-left: 7.5rem!important
    }

    .mx-xxl-20 {
        margin-right: 8rem!important;
        margin-left: 8rem!important
    }

    .mx-xxl-21 {
        margin-right: 8.5rem!important;
        margin-left: 8.5rem!important
    }

    .mx-xxl-22 {
        margin-right: 9rem!important;
        margin-left: 9rem!important
    }

    .mx-xxl-23 {
        margin-right: 9.5rem!important;
        margin-left: 9.5rem!important
    }

    .mx-xxl-24 {
        margin-right: 10rem!important;
        margin-left: 10rem!important
    }

    .mx-xxl-25 {
        margin-right: 10.5rem!important;
        margin-left: 10.5rem!important
    }

    .mx-xxl-26 {
        margin-right: 11rem!important;
        margin-left: 11rem!important
    }

    .mx-xxl-27 {
        margin-right: 12rem!important;
        margin-left: 12rem!important
    }

    .mx-xxl-28 {
        margin-right: 13rem!important;
        margin-left: 13rem!important
    }

    .mx-xxl-29 {
        margin-right: 14rem!important;
        margin-left: 14rem!important
    }

    .mx-xxl-30 {
        margin-right: 15rem!important;
        margin-left: 15rem!important
    }

    .mx-xxl-31 {
        margin-right: 16rem!important;
        margin-left: 16rem!important
    }

    .mx-xxl-32 {
        margin-right: 17rem!important;
        margin-left: 17rem!important
    }

    .mx-xxl-33 {
        margin-right: 18rem!important;
        margin-left: 18rem!important
    }

    .mx-xxl-34 {
        margin-right: 19rem!important;
        margin-left: 19rem!important
    }

    .mx-xxl-35 {
        margin-right: 20rem!important;
        margin-left: 20rem!important
    }

    .mx-xxl-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-xxl-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-xxl-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }

    .my-xxl-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .my-xxl-3 {
        margin-top: .75rem!important;
        margin-bottom: .75rem!important
    }

    .my-xxl-4 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-xxl-5 {
        margin-top: 1.25rem!important;
        margin-bottom: 1.25rem!important
    }

    .my-xxl-6 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-xxl-7 {
        margin-top: 1.75rem!important;
        margin-bottom: 1.75rem!important
    }

    .my-xxl-8 {
        margin-top: 2rem!important;
        margin-bottom: 2rem!important
    }

    .my-xxl-9 {
        margin-top: 2.5rem!important;
        margin-bottom: 2.5rem!important
    }

    .my-xxl-10 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-xxl-11 {
        margin-top: 3.5rem!important;
        margin-bottom: 3.5rem!important
    }

    .my-xxl-12 {
        margin-top: 4rem!important;
        margin-bottom: 4rem!important
    }

    .my-xxl-13 {
        margin-top: 4.5rem!important;
        margin-bottom: 4.5rem!important
    }

    .my-xxl-14 {
        margin-top: 5rem!important;
        margin-bottom: 5rem!important
    }

    .my-xxl-15 {
        margin-top: 5.5rem!important;
        margin-bottom: 5.5rem!important
    }

    .my-xxl-16 {
        margin-top: 6rem!important;
        margin-bottom: 6rem!important
    }

    .my-xxl-17 {
        margin-top: 6.5rem!important;
        margin-bottom: 6.5rem!important
    }

    .my-xxl-18 {
        margin-top: 7rem!important;
        margin-bottom: 7rem!important
    }

    .my-xxl-19 {
        margin-top: 7.5rem!important;
        margin-bottom: 7.5rem!important
    }

    .my-xxl-20 {
        margin-top: 8rem!important;
        margin-bottom: 8rem!important
    }

    .my-xxl-21 {
        margin-top: 8.5rem!important;
        margin-bottom: 8.5rem!important
    }

    .my-xxl-22 {
        margin-top: 9rem!important;
        margin-bottom: 9rem!important
    }

    .my-xxl-23 {
        margin-top: 9.5rem!important;
        margin-bottom: 9.5rem!important
    }

    .my-xxl-24 {
        margin-top: 10rem!important;
        margin-bottom: 10rem!important
    }

    .my-xxl-25 {
        margin-top: 10.5rem!important;
        margin-bottom: 10.5rem!important
    }

    .my-xxl-26 {
        margin-top: 11rem!important;
        margin-bottom: 11rem!important
    }

    .my-xxl-27 {
        margin-top: 12rem!important;
        margin-bottom: 12rem!important
    }

    .my-xxl-28 {
        margin-top: 13rem!important;
        margin-bottom: 13rem!important
    }

    .my-xxl-29 {
        margin-top: 14rem!important;
        margin-bottom: 14rem!important
    }

    .my-xxl-30 {
        margin-top: 15rem!important;
        margin-bottom: 15rem!important
    }

    .my-xxl-31 {
        margin-top: 16rem!important;
        margin-bottom: 16rem!important
    }

    .my-xxl-32 {
        margin-top: 17rem!important;
        margin-bottom: 17rem!important
    }

    .my-xxl-33 {
        margin-top: 18rem!important;
        margin-bottom: 18rem!important
    }

    .my-xxl-34 {
        margin-top: 19rem!important;
        margin-bottom: 19rem!important
    }

    .my-xxl-35 {
        margin-top: 20rem!important;
        margin-bottom: 20rem!important
    }

    .my-xxl-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-xxl-0 {
        margin-top: 0!important
    }

    .mt-xxl-1 {
        margin-top: .25rem!important
    }

    .mt-xxl-2 {
        margin-top: .5rem!important
    }

    .mt-xxl-3 {
        margin-top: .75rem!important
    }

    .mt-xxl-4 {
        margin-top: 1rem!important
    }

    .mt-xxl-5 {
        margin-top: 1.25rem!important
    }

    .mt-xxl-6 {
        margin-top: 1.5rem!important
    }

    .mt-xxl-7 {
        margin-top: 1.75rem!important
    }

    .mt-xxl-8 {
        margin-top: 2rem!important
    }

    .mt-xxl-9 {
        margin-top: 2.5rem!important
    }

    .mt-xxl-10 {
        margin-top: 3rem!important
    }

    .mt-xxl-11 {
        margin-top: 3.5rem!important
    }

    .mt-xxl-12 {
        margin-top: 4rem!important
    }

    .mt-xxl-13 {
        margin-top: 4.5rem!important
    }

    .mt-xxl-14 {
        margin-top: 5rem!important
    }

    .mt-xxl-15 {
        margin-top: 5.5rem!important
    }

    .mt-xxl-16 {
        margin-top: 6rem!important
    }

    .mt-xxl-17 {
        margin-top: 6.5rem!important
    }

    .mt-xxl-18 {
        margin-top: 7rem!important
    }

    .mt-xxl-19 {
        margin-top: 7.5rem!important
    }

    .mt-xxl-20 {
        margin-top: 8rem!important
    }

    .mt-xxl-21 {
        margin-top: 8.5rem!important
    }

    .mt-xxl-22 {
        margin-top: 9rem!important
    }

    .mt-xxl-23 {
        margin-top: 9.5rem!important
    }

    .mt-xxl-24 {
        margin-top: 10rem!important
    }

    .mt-xxl-25 {
        margin-top: 10.5rem!important
    }

    .mt-xxl-26 {
        margin-top: 11rem!important
    }

    .mt-xxl-27 {
        margin-top: 12rem!important
    }

    .mt-xxl-28 {
        margin-top: 13rem!important
    }

    .mt-xxl-29 {
        margin-top: 14rem!important
    }

    .mt-xxl-30 {
        margin-top: 15rem!important
    }

    .mt-xxl-31 {
        margin-top: 16rem!important
    }

    .mt-xxl-32 {
        margin-top: 17rem!important
    }

    .mt-xxl-33 {
        margin-top: 18rem!important
    }

    .mt-xxl-34 {
        margin-top: 19rem!important
    }

    .mt-xxl-35 {
        margin-top: 20rem!important
    }

    .mt-xxl-auto {
        margin-top: auto!important
    }

    .me-xxl-0 {
        margin-right: 0!important
    }

    .me-xxl-1 {
        margin-right: .25rem!important
    }

    .me-xxl-2 {
        margin-right: .5rem!important
    }

    .me-xxl-3 {
        margin-right: .75rem!important
    }

    .me-xxl-4 {
        margin-right: 1rem!important
    }

    .me-xxl-5 {
        margin-right: 1.25rem!important
    }

    .me-xxl-6 {
        margin-right: 1.5rem!important
    }

    .me-xxl-7 {
        margin-right: 1.75rem!important
    }

    .me-xxl-8 {
        margin-right: 2rem!important
    }

    .me-xxl-9 {
        margin-right: 2.5rem!important
    }

    .me-xxl-10 {
        margin-right: 3rem!important
    }

    .me-xxl-11 {
        margin-right: 3.5rem!important
    }

    .me-xxl-12 {
        margin-right: 4rem!important
    }

    .me-xxl-13 {
        margin-right: 4.5rem!important
    }

    .me-xxl-14 {
        margin-right: 5rem!important
    }

    .me-xxl-15 {
        margin-right: 5.5rem!important
    }

    .me-xxl-16 {
        margin-right: 6rem!important
    }

    .me-xxl-17 {
        margin-right: 6.5rem!important
    }

    .me-xxl-18 {
        margin-right: 7rem!important
    }

    .me-xxl-19 {
        margin-right: 7.5rem!important
    }

    .me-xxl-20 {
        margin-right: 8rem!important
    }

    .me-xxl-21 {
        margin-right: 8.5rem!important
    }

    .me-xxl-22 {
        margin-right: 9rem!important
    }

    .me-xxl-23 {
        margin-right: 9.5rem!important
    }

    .me-xxl-24 {
        margin-right: 10rem!important
    }

    .me-xxl-25 {
        margin-right: 10.5rem!important
    }

    .me-xxl-26 {
        margin-right: 11rem!important
    }

    .me-xxl-27 {
        margin-right: 12rem!important
    }

    .me-xxl-28 {
        margin-right: 13rem!important
    }

    .me-xxl-29 {
        margin-right: 14rem!important
    }

    .me-xxl-30 {
        margin-right: 15rem!important
    }

    .me-xxl-31 {
        margin-right: 16rem!important
    }

    .me-xxl-32 {
        margin-right: 17rem!important
    }

    .me-xxl-33 {
        margin-right: 18rem!important
    }

    .me-xxl-34 {
        margin-right: 19rem!important
    }

    .me-xxl-35 {
        margin-right: 20rem!important
    }

    .me-xxl-auto {
        margin-right: auto!important
    }

    .mb-xxl-0 {
        margin-bottom: 0!important
    }

    .mb-xxl-1 {
        margin-bottom: .25rem!important
    }

    .mb-xxl-2 {
        margin-bottom: .5rem!important
    }

    .mb-xxl-3 {
        margin-bottom: .75rem!important
    }

    .mb-xxl-4 {
        margin-bottom: 1rem!important
    }

    .mb-xxl-5 {
        margin-bottom: 1.25rem!important
    }

    .mb-xxl-6 {
        margin-bottom: 1.5rem!important
    }

    .mb-xxl-7 {
        margin-bottom: 1.75rem!important
    }

    .mb-xxl-8 {
        margin-bottom: 2rem!important
    }

    .mb-xxl-9 {
        margin-bottom: 2.5rem!important
    }

    .mb-xxl-10 {
        margin-bottom: 3rem!important
    }

    .mb-xxl-11 {
        margin-bottom: 3.5rem!important
    }

    .mb-xxl-12 {
        margin-bottom: 4rem!important
    }

    .mb-xxl-13 {
        margin-bottom: 4.5rem!important
    }

    .mb-xxl-14 {
        margin-bottom: 5rem!important
    }

    .mb-xxl-15 {
        margin-bottom: 5.5rem!important
    }

    .mb-xxl-16 {
        margin-bottom: 6rem!important
    }

    .mb-xxl-17 {
        margin-bottom: 6.5rem!important
    }

    .mb-xxl-18 {
        margin-bottom: 7rem!important
    }

    .mb-xxl-19 {
        margin-bottom: 7.5rem!important
    }

    .mb-xxl-20 {
        margin-bottom: 8rem!important
    }

    .mb-xxl-21 {
        margin-bottom: 8.5rem!important
    }

    .mb-xxl-22 {
        margin-bottom: 9rem!important
    }

    .mb-xxl-23 {
        margin-bottom: 9.5rem!important
    }

    .mb-xxl-24 {
        margin-bottom: 10rem!important
    }

    .mb-xxl-25 {
        margin-bottom: 10.5rem!important
    }

    .mb-xxl-26 {
        margin-bottom: 11rem!important
    }

    .mb-xxl-27 {
        margin-bottom: 12rem!important
    }

    .mb-xxl-28 {
        margin-bottom: 13rem!important
    }

    .mb-xxl-29 {
        margin-bottom: 14rem!important
    }

    .mb-xxl-30 {
        margin-bottom: 15rem!important
    }

    .mb-xxl-31 {
        margin-bottom: 16rem!important
    }

    .mb-xxl-32 {
        margin-bottom: 17rem!important
    }

    .mb-xxl-33 {
        margin-bottom: 18rem!important
    }

    .mb-xxl-34 {
        margin-bottom: 19rem!important
    }

    .mb-xxl-35 {
        margin-bottom: 20rem!important
    }

    .mb-xxl-auto {
        margin-bottom: auto!important
    }

    .ms-xxl-0 {
        margin-left: 0!important
    }

    .ms-xxl-1 {
        margin-left: .25rem!important
    }

    .ms-xxl-2 {
        margin-left: .5rem!important
    }

    .ms-xxl-3 {
        margin-left: .75rem!important
    }

    .ms-xxl-4 {
        margin-left: 1rem!important
    }

    .ms-xxl-5 {
        margin-left: 1.25rem!important
    }

    .ms-xxl-6 {
        margin-left: 1.5rem!important
    }

    .ms-xxl-7 {
        margin-left: 1.75rem!important
    }

    .ms-xxl-8 {
        margin-left: 2rem!important
    }

    .ms-xxl-9 {
        margin-left: 2.5rem!important
    }

    .ms-xxl-10 {
        margin-left: 3rem!important
    }

    .ms-xxl-11 {
        margin-left: 3.5rem!important
    }

    .ms-xxl-12 {
        margin-left: 4rem!important
    }

    .ms-xxl-13 {
        margin-left: 4.5rem!important
    }

    .ms-xxl-14 {
        margin-left: 5rem!important
    }

    .ms-xxl-15 {
        margin-left: 5.5rem!important
    }

    .ms-xxl-16 {
        margin-left: 6rem!important
    }

    .ms-xxl-17 {
        margin-left: 6.5rem!important
    }

    .ms-xxl-18 {
        margin-left: 7rem!important
    }

    .ms-xxl-19 {
        margin-left: 7.5rem!important
    }

    .ms-xxl-20 {
        margin-left: 8rem!important
    }

    .ms-xxl-21 {
        margin-left: 8.5rem!important
    }

    .ms-xxl-22 {
        margin-left: 9rem!important
    }

    .ms-xxl-23 {
        margin-left: 9.5rem!important
    }

    .ms-xxl-24 {
        margin-left: 10rem!important
    }

    .ms-xxl-25 {
        margin-left: 10.5rem!important
    }

    .ms-xxl-26 {
        margin-left: 11rem!important
    }

    .ms-xxl-27 {
        margin-left: 12rem!important
    }

    .ms-xxl-28 {
        margin-left: 13rem!important
    }

    .ms-xxl-29 {
        margin-left: 14rem!important
    }

    .ms-xxl-30 {
        margin-left: 15rem!important
    }

    .ms-xxl-31 {
        margin-left: 16rem!important
    }

    .ms-xxl-32 {
        margin-left: 17rem!important
    }

    .ms-xxl-33 {
        margin-left: 18rem!important
    }

    .ms-xxl-34 {
        margin-left: 19rem!important
    }

    .ms-xxl-35 {
        margin-left: 20rem!important
    }

    .ms-xxl-auto {
        margin-left: auto!important
    }

    .p-xxl-0 {
        padding: 0!important
    }

    .p-xxl-1 {
        padding: .25rem!important
    }

    .p-xxl-2 {
        padding: .5rem!important
    }

    .p-xxl-3 {
        padding: .75rem!important
    }

    .p-xxl-4 {
        padding: 1rem!important
    }

    .p-xxl-5 {
        padding: 1.25rem!important
    }

    .p-xxl-6 {
        padding: 1.5rem!important
    }

    .p-xxl-7 {
        padding: 1.75rem!important
    }

    .p-xxl-8 {
        padding: 2rem!important
    }

    .p-xxl-9 {
        padding: 2.5rem!important
    }

    .p-xxl-10 {
        padding: 3rem!important
    }

    .p-xxl-11 {
        padding: 3.5rem!important
    }

    .p-xxl-12 {
        padding: 4rem!important
    }

    .p-xxl-13 {
        padding: 4.5rem!important
    }

    .p-xxl-14 {
        padding: 5rem!important
    }

    .p-xxl-15 {
        padding: 5.5rem!important
    }

    .p-xxl-16 {
        padding: 6rem!important
    }

    .p-xxl-17 {
        padding: 6.5rem!important
    }

    .p-xxl-18 {
        padding: 7rem!important
    }

    .p-xxl-19 {
        padding: 7.5rem!important
    }

    .p-xxl-20 {
        padding: 8rem!important
    }

    .p-xxl-21 {
        padding: 8.5rem!important
    }

    .p-xxl-22 {
        padding: 9rem!important
    }

    .p-xxl-23 {
        padding: 9.5rem!important
    }

    .p-xxl-24 {
        padding: 10rem!important
    }

    .p-xxl-25 {
        padding: 10.5rem!important
    }

    .p-xxl-26 {
        padding: 11rem!important
    }

    .p-xxl-27 {
        padding: 12rem!important
    }

    .p-xxl-28 {
        padding: 13rem!important
    }

    .p-xxl-29 {
        padding: 14rem!important
    }

    .p-xxl-30 {
        padding: 15rem!important
    }

    .p-xxl-31 {
        padding: 16rem!important
    }

    .p-xxl-32 {
        padding: 17rem!important
    }

    .p-xxl-33 {
        padding: 18rem!important
    }

    .p-xxl-34 {
        padding: 19rem!important
    }

    .p-xxl-35 {
        padding: 20rem!important
    }

    .px-xxl-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-xxl-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }

    .px-xxl-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }

    .px-xxl-3 {
        padding-right: .75rem!important;
        padding-left: .75rem!important
    }

    .px-xxl-4 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-xxl-5 {
        padding-right: 1.25rem!important;
        padding-left: 1.25rem!important
    }

    .px-xxl-6 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-xxl-7 {
        padding-right: 1.75rem!important;
        padding-left: 1.75rem!important
    }

    .px-xxl-8 {
        padding-right: 2rem!important;
        padding-left: 2rem!important
    }

    .px-xxl-9 {
        padding-right: 2.5rem!important;
        padding-left: 2.5rem!important
    }

    .px-xxl-10 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .px-xxl-11 {
        padding-right: 3.5rem!important;
        padding-left: 3.5rem!important
    }

    .px-xxl-12 {
        padding-right: 4rem!important;
        padding-left: 4rem!important
    }

    .px-xxl-13 {
        padding-right: 4.5rem!important;
        padding-left: 4.5rem!important
    }

    .px-xxl-14 {
        padding-right: 5rem!important;
        padding-left: 5rem!important
    }

    .px-xxl-15 {
        padding-right: 5.5rem!important;
        padding-left: 5.5rem!important
    }

    .px-xxl-16 {
        padding-right: 6rem!important;
        padding-left: 6rem!important
    }

    .px-xxl-17 {
        padding-right: 6.5rem!important;
        padding-left: 6.5rem!important
    }

    .px-xxl-18 {
        padding-right: 7rem!important;
        padding-left: 7rem!important
    }

    .px-xxl-19 {
        padding-right: 7.5rem!important;
        padding-left: 7.5rem!important
    }

    .px-xxl-20 {
        padding-right: 8rem!important;
        padding-left: 8rem!important
    }

    .px-xxl-21 {
        padding-right: 8.5rem!important;
        padding-left: 8.5rem!important
    }

    .px-xxl-22 {
        padding-right: 9rem!important;
        padding-left: 9rem!important
    }

    .px-xxl-23 {
        padding-right: 9.5rem!important;
        padding-left: 9.5rem!important
    }

    .px-xxl-24 {
        padding-right: 10rem!important;
        padding-left: 10rem!important
    }

    .px-xxl-25 {
        padding-right: 10.5rem!important;
        padding-left: 10.5rem!important
    }

    .px-xxl-26 {
        padding-right: 11rem!important;
        padding-left: 11rem!important
    }

    .px-xxl-27 {
        padding-right: 12rem!important;
        padding-left: 12rem!important
    }

    .px-xxl-28 {
        padding-right: 13rem!important;
        padding-left: 13rem!important
    }

    .px-xxl-29 {
        padding-right: 14rem!important;
        padding-left: 14rem!important
    }

    .px-xxl-30 {
        padding-right: 15rem!important;
        padding-left: 15rem!important
    }

    .px-xxl-31 {
        padding-right: 16rem!important;
        padding-left: 16rem!important
    }

    .px-xxl-32 {
        padding-right: 17rem!important;
        padding-left: 17rem!important
    }

    .px-xxl-33 {
        padding-right: 18rem!important;
        padding-left: 18rem!important
    }

    .px-xxl-34 {
        padding-right: 19rem!important;
        padding-left: 19rem!important
    }

    .px-xxl-35 {
        padding-right: 20rem!important;
        padding-left: 20rem!important
    }

    .py-xxl-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-xxl-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }

    .py-xxl-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }

    .py-xxl-3 {
        padding-top: .75rem!important;
        padding-bottom: .75rem!important
    }

    .py-xxl-4 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-xxl-5 {
        padding-top: 1.25rem!important;
        padding-bottom: 1.25rem!important
    }

    .py-xxl-6 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-xxl-7 {
        padding-top: 1.75rem!important;
        padding-bottom: 1.75rem!important
    }

    .py-xxl-8 {
        padding-top: 2rem!important;
        padding-bottom: 2rem!important
    }

    .py-xxl-9 {
        padding-top: 2.5rem!important;
        padding-bottom: 2.5rem!important
    }

    .py-xxl-10 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .py-xxl-11 {
        padding-top: 3.5rem!important;
        padding-bottom: 3.5rem!important
    }

    .py-xxl-12 {
        padding-top: 4rem!important;
        padding-bottom: 4rem!important
    }

    .py-xxl-13 {
        padding-top: 4.5rem!important;
        padding-bottom: 4.5rem!important
    }

    .py-xxl-14 {
        padding-top: 5rem!important;
        padding-bottom: 5rem!important
    }

    .py-xxl-15 {
        padding-top: 5.5rem!important;
        padding-bottom: 5.5rem!important
    }

    .py-xxl-16 {
        padding-top: 6rem!important;
        padding-bottom: 6rem!important
    }

    .py-xxl-17 {
        padding-top: 6.5rem!important;
        padding-bottom: 6.5rem!important
    }

    .py-xxl-18 {
        padding-top: 7rem!important;
        padding-bottom: 7rem!important
    }

    .py-xxl-19 {
        padding-top: 7.5rem!important;
        padding-bottom: 7.5rem!important
    }

    .py-xxl-20 {
        padding-top: 8rem!important;
        padding-bottom: 8rem!important
    }

    .py-xxl-21 {
        padding-top: 8.5rem!important;
        padding-bottom: 8.5rem!important
    }

    .py-xxl-22 {
        padding-top: 9rem!important;
        padding-bottom: 9rem!important
    }

    .py-xxl-23 {
        padding-top: 9.5rem!important;
        padding-bottom: 9.5rem!important
    }

    .py-xxl-24 {
        padding-top: 10rem!important;
        padding-bottom: 10rem!important
    }

    .py-xxl-25 {
        padding-top: 10.5rem!important;
        padding-bottom: 10.5rem!important
    }

    .py-xxl-26 {
        padding-top: 11rem!important;
        padding-bottom: 11rem!important
    }

    .py-xxl-27 {
        padding-top: 12rem!important;
        padding-bottom: 12rem!important
    }

    .py-xxl-28 {
        padding-top: 13rem!important;
        padding-bottom: 13rem!important
    }

    .py-xxl-29 {
        padding-top: 14rem!important;
        padding-bottom: 14rem!important
    }

    .py-xxl-30 {
        padding-top: 15rem!important;
        padding-bottom: 15rem!important
    }

    .py-xxl-31 {
        padding-top: 16rem!important;
        padding-bottom: 16rem!important
    }

    .py-xxl-32 {
        padding-top: 17rem!important;
        padding-bottom: 17rem!important
    }

    .py-xxl-33 {
        padding-top: 18rem!important;
        padding-bottom: 18rem!important
    }

    .py-xxl-34 {
        padding-top: 19rem!important;
        padding-bottom: 19rem!important
    }

    .py-xxl-35 {
        padding-top: 20rem!important;
        padding-bottom: 20rem!important
    }

    .pt-xxl-0 {
        padding-top: 0!important
    }

    .pt-xxl-1 {
        padding-top: .25rem!important
    }

    .pt-xxl-2 {
        padding-top: .5rem!important
    }

    .pt-xxl-3 {
        padding-top: .75rem!important
    }

    .pt-xxl-4 {
        padding-top: 1rem!important
    }

    .pt-xxl-5 {
        padding-top: 1.25rem!important
    }

    .pt-xxl-6 {
        padding-top: 1.5rem!important
    }

    .pt-xxl-7 {
        padding-top: 1.75rem!important
    }

    .pt-xxl-8 {
        padding-top: 2rem!important
    }

    .pt-xxl-9 {
        padding-top: 2.5rem!important
    }

    .pt-xxl-10 {
        padding-top: 3rem!important
    }

    .pt-xxl-11 {
        padding-top: 3.5rem!important
    }

    .pt-xxl-12 {
        padding-top: 4rem!important
    }

    .pt-xxl-13 {
        padding-top: 4.5rem!important
    }

    .pt-xxl-14 {
        padding-top: 5rem!important
    }

    .pt-xxl-15 {
        padding-top: 5.5rem!important
    }

    .pt-xxl-16 {
        padding-top: 6rem!important
    }

    .pt-xxl-17 {
        padding-top: 6.5rem!important
    }

    .pt-xxl-18 {
        padding-top: 7rem!important
    }

    .pt-xxl-19 {
        padding-top: 7.5rem!important
    }

    .pt-xxl-20 {
        padding-top: 8rem!important
    }

    .pt-xxl-21 {
        padding-top: 8.5rem!important
    }

    .pt-xxl-22 {
        padding-top: 9rem!important
    }

    .pt-xxl-23 {
        padding-top: 9.5rem!important
    }

    .pt-xxl-24 {
        padding-top: 10rem!important
    }

    .pt-xxl-25 {
        padding-top: 10.5rem!important
    }

    .pt-xxl-26 {
        padding-top: 11rem!important
    }

    .pt-xxl-27 {
        padding-top: 12rem!important
    }

    .pt-xxl-28 {
        padding-top: 13rem!important
    }

    .pt-xxl-29 {
        padding-top: 14rem!important
    }

    .pt-xxl-30 {
        padding-top: 15rem!important
    }

    .pt-xxl-31 {
        padding-top: 16rem!important
    }

    .pt-xxl-32 {
        padding-top: 17rem!important
    }

    .pt-xxl-33 {
        padding-top: 18rem!important
    }

    .pt-xxl-34 {
        padding-top: 19rem!important
    }

    .pt-xxl-35 {
        padding-top: 20rem!important
    }

    .pe-xxl-0 {
        padding-right: 0!important
    }

    .pe-xxl-1 {
        padding-right: .25rem!important
    }

    .pe-xxl-2 {
        padding-right: .5rem!important
    }

    .pe-xxl-3 {
        padding-right: .75rem!important
    }

    .pe-xxl-4 {
        padding-right: 1rem!important
    }

    .pe-xxl-5 {
        padding-right: 1.25rem!important
    }

    .pe-xxl-6 {
        padding-right: 1.5rem!important
    }

    .pe-xxl-7 {
        padding-right: 1.75rem!important
    }

    .pe-xxl-8 {
        padding-right: 2rem!important
    }

    .pe-xxl-9 {
        padding-right: 2.5rem!important
    }

    .pe-xxl-10 {
        padding-right: 3rem!important
    }

    .pe-xxl-11 {
        padding-right: 3.5rem!important
    }

    .pe-xxl-12 {
        padding-right: 4rem!important
    }

    .pe-xxl-13 {
        padding-right: 4.5rem!important
    }

    .pe-xxl-14 {
        padding-right: 5rem!important
    }

    .pe-xxl-15 {
        padding-right: 5.5rem!important
    }

    .pe-xxl-16 {
        padding-right: 6rem!important
    }

    .pe-xxl-17 {
        padding-right: 6.5rem!important
    }

    .pe-xxl-18 {
        padding-right: 7rem!important
    }

    .pe-xxl-19 {
        padding-right: 7.5rem!important
    }

    .pe-xxl-20 {
        padding-right: 8rem!important
    }

    .pe-xxl-21 {
        padding-right: 8.5rem!important
    }

    .pe-xxl-22 {
        padding-right: 9rem!important
    }

    .pe-xxl-23 {
        padding-right: 9.5rem!important
    }

    .pe-xxl-24 {
        padding-right: 10rem!important
    }

    .pe-xxl-25 {
        padding-right: 10.5rem!important
    }

    .pe-xxl-26 {
        padding-right: 11rem!important
    }

    .pe-xxl-27 {
        padding-right: 12rem!important
    }

    .pe-xxl-28 {
        padding-right: 13rem!important
    }

    .pe-xxl-29 {
        padding-right: 14rem!important
    }

    .pe-xxl-30 {
        padding-right: 15rem!important
    }

    .pe-xxl-31 {
        padding-right: 16rem!important
    }

    .pe-xxl-32 {
        padding-right: 17rem!important
    }

    .pe-xxl-33 {
        padding-right: 18rem!important
    }

    .pe-xxl-34 {
        padding-right: 19rem!important
    }

    .pe-xxl-35 {
        padding-right: 20rem!important
    }

    .pb-xxl-0 {
        padding-bottom: 0!important
    }

    .pb-xxl-1 {
        padding-bottom: .25rem!important
    }

    .pb-xxl-2 {
        padding-bottom: .5rem!important
    }

    .pb-xxl-3 {
        padding-bottom: .75rem!important
    }

    .pb-xxl-4 {
        padding-bottom: 1rem!important
    }

    .pb-xxl-5 {
        padding-bottom: 1.25rem!important
    }

    .pb-xxl-6 {
        padding-bottom: 1.5rem!important
    }

    .pb-xxl-7 {
        padding-bottom: 1.75rem!important
    }

    .pb-xxl-8 {
        padding-bottom: 2rem!important
    }

    .pb-xxl-9 {
        padding-bottom: 2.5rem!important
    }

    .pb-xxl-10 {
        padding-bottom: 3rem!important
    }

    .pb-xxl-11 {
        padding-bottom: 3.5rem!important
    }

    .pb-xxl-12 {
        padding-bottom: 4rem!important
    }

    .pb-xxl-13 {
        padding-bottom: 4.5rem!important
    }

    .pb-xxl-14 {
        padding-bottom: 5rem!important
    }

    .pb-xxl-15 {
        padding-bottom: 5.5rem!important
    }

    .pb-xxl-16 {
        padding-bottom: 6rem!important
    }

    .pb-xxl-17 {
        padding-bottom: 6.5rem!important
    }

    .pb-xxl-18 {
        padding-bottom: 7rem!important
    }

    .pb-xxl-19 {
        padding-bottom: 7.5rem!important
    }

    .pb-xxl-20 {
        padding-bottom: 8rem!important
    }

    .pb-xxl-21 {
        padding-bottom: 8.5rem!important
    }

    .pb-xxl-22 {
        padding-bottom: 9rem!important
    }

    .pb-xxl-23 {
        padding-bottom: 9.5rem!important
    }

    .pb-xxl-24 {
        padding-bottom: 10rem!important
    }

    .pb-xxl-25 {
        padding-bottom: 10.5rem!important
    }

    .pb-xxl-26 {
        padding-bottom: 11rem!important
    }

    .pb-xxl-27 {
        padding-bottom: 12rem!important
    }

    .pb-xxl-28 {
        padding-bottom: 13rem!important
    }

    .pb-xxl-29 {
        padding-bottom: 14rem!important
    }

    .pb-xxl-30 {
        padding-bottom: 15rem!important
    }

    .pb-xxl-31 {
        padding-bottom: 16rem!important
    }

    .pb-xxl-32 {
        padding-bottom: 17rem!important
    }

    .pb-xxl-33 {
        padding-bottom: 18rem!important
    }

    .pb-xxl-34 {
        padding-bottom: 19rem!important
    }

    .pb-xxl-35 {
        padding-bottom: 20rem!important
    }

    .ps-xxl-0 {
        padding-left: 0!important
    }

    .ps-xxl-1 {
        padding-left: .25rem!important
    }

    .ps-xxl-2 {
        padding-left: .5rem!important
    }

    .ps-xxl-3 {
        padding-left: .75rem!important
    }

    .ps-xxl-4 {
        padding-left: 1rem!important
    }

    .ps-xxl-5 {
        padding-left: 1.25rem!important
    }

    .ps-xxl-6 {
        padding-left: 1.5rem!important
    }

    .ps-xxl-7 {
        padding-left: 1.75rem!important
    }

    .ps-xxl-8 {
        padding-left: 2rem!important
    }

    .ps-xxl-9 {
        padding-left: 2.5rem!important
    }

    .ps-xxl-10 {
        padding-left: 3rem!important
    }

    .ps-xxl-11 {
        padding-left: 3.5rem!important
    }

    .ps-xxl-12 {
        padding-left: 4rem!important
    }

    .ps-xxl-13 {
        padding-left: 4.5rem!important
    }

    .ps-xxl-14 {
        padding-left: 5rem!important
    }

    .ps-xxl-15 {
        padding-left: 5.5rem!important
    }

    .ps-xxl-16 {
        padding-left: 6rem!important
    }

    .ps-xxl-17 {
        padding-left: 6.5rem!important
    }

    .ps-xxl-18 {
        padding-left: 7rem!important
    }

    .ps-xxl-19 {
        padding-left: 7.5rem!important
    }

    .ps-xxl-20 {
        padding-left: 8rem!important
    }

    .ps-xxl-21 {
        padding-left: 8.5rem!important
    }

    .ps-xxl-22 {
        padding-left: 9rem!important
    }

    .ps-xxl-23 {
        padding-left: 9.5rem!important
    }

    .ps-xxl-24 {
        padding-left: 10rem!important
    }

    .ps-xxl-25 {
        padding-left: 10.5rem!important
    }

    .ps-xxl-26 {
        padding-left: 11rem!important
    }

    .ps-xxl-27 {
        padding-left: 12rem!important
    }

    .ps-xxl-28 {
        padding-left: 13rem!important
    }

    .ps-xxl-29 {
        padding-left: 14rem!important
    }

    .ps-xxl-30 {
        padding-left: 15rem!important
    }

    .ps-xxl-31 {
        padding-left: 16rem!important
    }

    .ps-xxl-32 {
        padding-left: 17rem!important
    }

    .ps-xxl-33 {
        padding-left: 18rem!important
    }

    .ps-xxl-34 {
        padding-left: 19rem!important
    }

    .ps-xxl-35 {
        padding-left: 20rem!important
    }

    .gap-xxl-0 {
        gap: 0!important
    }

    .gap-xxl-1 {
        gap: .25rem!important
    }

    .gap-xxl-2 {
        gap: .5rem!important
    }

    .gap-xxl-3 {
        gap: .75rem!important
    }

    .gap-xxl-4 {
        gap: 1rem!important
    }

    .gap-xxl-5 {
        gap: 1.25rem!important
    }

    .gap-xxl-6 {
        gap: 1.5rem!important
    }

    .gap-xxl-7 {
        gap: 1.75rem!important
    }

    .gap-xxl-8 {
        gap: 2rem!important
    }

    .gap-xxl-9 {
        gap: 2.5rem!important
    }

    .gap-xxl-10 {
        gap: 3rem!important
    }

    .gap-xxl-11 {
        gap: 3.5rem!important
    }

    .gap-xxl-12 {
        gap: 4rem!important
    }

    .gap-xxl-13 {
        gap: 4.5rem!important
    }

    .gap-xxl-14 {
        gap: 5rem!important
    }

    .gap-xxl-15 {
        gap: 5.5rem!important
    }

    .gap-xxl-16 {
        gap: 6rem!important
    }

    .gap-xxl-17 {
        gap: 6.5rem!important
    }

    .gap-xxl-18 {
        gap: 7rem!important
    }

    .gap-xxl-19 {
        gap: 7.5rem!important
    }

    .gap-xxl-20 {
        gap: 8rem!important
    }

    .gap-xxl-21 {
        gap: 8.5rem!important
    }

    .gap-xxl-22 {
        gap: 9rem!important
    }

    .gap-xxl-23 {
        gap: 9.5rem!important
    }

    .gap-xxl-24 {
        gap: 10rem!important
    }

    .gap-xxl-25 {
        gap: 10.5rem!important
    }

    .gap-xxl-26 {
        gap: 11rem!important
    }

    .gap-xxl-27 {
        gap: 12rem!important
    }

    .gap-xxl-28 {
        gap: 13rem!important
    }

    .gap-xxl-29 {
        gap: 14rem!important
    }

    .gap-xxl-30 {
        gap: 15rem!important
    }

    .gap-xxl-31 {
        gap: 16rem!important
    }

    .gap-xxl-32 {
        gap: 17rem!important
    }

    .gap-xxl-33 {
        gap: 18rem!important
    }

    .gap-xxl-34 {
        gap: 19rem!important
    }

    .gap-xxl-35 {
        gap: 20rem!important
    }

    .row-gap-xxl-0 {
        row-gap: 0!important
    }

    .row-gap-xxl-1 {
        row-gap: .25rem!important
    }

    .row-gap-xxl-2 {
        row-gap: .5rem!important
    }

    .row-gap-xxl-3 {
        row-gap: .75rem!important
    }

    .row-gap-xxl-4 {
        row-gap: 1rem!important
    }

    .row-gap-xxl-5 {
        row-gap: 1.25rem!important
    }

    .row-gap-xxl-6 {
        row-gap: 1.5rem!important
    }

    .row-gap-xxl-7 {
        row-gap: 1.75rem!important
    }

    .row-gap-xxl-8 {
        row-gap: 2rem!important
    }

    .row-gap-xxl-9 {
        row-gap: 2.5rem!important
    }

    .row-gap-xxl-10 {
        row-gap: 3rem!important
    }

    .row-gap-xxl-11 {
        row-gap: 3.5rem!important
    }

    .row-gap-xxl-12 {
        row-gap: 4rem!important
    }

    .row-gap-xxl-13 {
        row-gap: 4.5rem!important
    }

    .row-gap-xxl-14 {
        row-gap: 5rem!important
    }

    .row-gap-xxl-15 {
        row-gap: 5.5rem!important
    }

    .row-gap-xxl-16 {
        row-gap: 6rem!important
    }

    .row-gap-xxl-17 {
        row-gap: 6.5rem!important
    }

    .row-gap-xxl-18 {
        row-gap: 7rem!important
    }

    .row-gap-xxl-19 {
        row-gap: 7.5rem!important
    }

    .row-gap-xxl-20 {
        row-gap: 8rem!important
    }

    .row-gap-xxl-21 {
        row-gap: 8.5rem!important
    }

    .row-gap-xxl-22 {
        row-gap: 9rem!important
    }

    .row-gap-xxl-23 {
        row-gap: 9.5rem!important
    }

    .row-gap-xxl-24 {
        row-gap: 10rem!important
    }

    .row-gap-xxl-25 {
        row-gap: 10.5rem!important
    }

    .row-gap-xxl-26 {
        row-gap: 11rem!important
    }

    .row-gap-xxl-27 {
        row-gap: 12rem!important
    }

    .row-gap-xxl-28 {
        row-gap: 13rem!important
    }

    .row-gap-xxl-29 {
        row-gap: 14rem!important
    }

    .row-gap-xxl-30 {
        row-gap: 15rem!important
    }

    .row-gap-xxl-31 {
        row-gap: 16rem!important
    }

    .row-gap-xxl-32 {
        row-gap: 17rem!important
    }

    .row-gap-xxl-33 {
        row-gap: 18rem!important
    }

    .row-gap-xxl-34 {
        row-gap: 19rem!important
    }

    .row-gap-xxl-35 {
        row-gap: 20rem!important
    }

    .column-gap-xxl-0 {
        -moz-column-gap: 0!important;
        column-gap: 0!important
    }

    .column-gap-xxl-1 {
        -moz-column-gap: .25rem!important;
        column-gap: .25rem!important
    }

    .column-gap-xxl-2 {
        -moz-column-gap: .5rem!important;
        column-gap: .5rem!important
    }

    .column-gap-xxl-3 {
        -moz-column-gap: .75rem!important;
        column-gap: .75rem!important
    }

    .column-gap-xxl-4 {
        -moz-column-gap: 1rem!important;
        column-gap: 1rem!important
    }

    .column-gap-xxl-5 {
        -moz-column-gap: 1.25rem!important;
        column-gap: 1.25rem!important
    }

    .column-gap-xxl-6 {
        -moz-column-gap: 1.5rem!important;
        column-gap: 1.5rem!important
    }

    .column-gap-xxl-7 {
        -moz-column-gap: 1.75rem!important;
        column-gap: 1.75rem!important
    }

    .column-gap-xxl-8 {
        -moz-column-gap: 2rem!important;
        column-gap: 2rem!important
    }

    .column-gap-xxl-9 {
        -moz-column-gap: 2.5rem!important;
        column-gap: 2.5rem!important
    }

    .column-gap-xxl-10 {
        -moz-column-gap: 3rem!important;
        column-gap: 3rem!important
    }

    .column-gap-xxl-11 {
        -moz-column-gap: 3.5rem!important;
        column-gap: 3.5rem!important
    }

    .column-gap-xxl-12 {
        -moz-column-gap: 4rem!important;
        column-gap: 4rem!important
    }

    .column-gap-xxl-13 {
        -moz-column-gap: 4.5rem!important;
        column-gap: 4.5rem!important
    }

    .column-gap-xxl-14 {
        -moz-column-gap: 5rem!important;
        column-gap: 5rem!important
    }

    .column-gap-xxl-15 {
        -moz-column-gap: 5.5rem!important;
        column-gap: 5.5rem!important
    }

    .column-gap-xxl-16 {
        -moz-column-gap: 6rem!important;
        column-gap: 6rem!important
    }

    .column-gap-xxl-17 {
        -moz-column-gap: 6.5rem!important;
        column-gap: 6.5rem!important
    }

    .column-gap-xxl-18 {
        -moz-column-gap: 7rem!important;
        column-gap: 7rem!important
    }

    .column-gap-xxl-19 {
        -moz-column-gap: 7.5rem!important;
        column-gap: 7.5rem!important
    }

    .column-gap-xxl-20 {
        -moz-column-gap: 8rem!important;
        column-gap: 8rem!important
    }

    .column-gap-xxl-21 {
        -moz-column-gap: 8.5rem!important;
        column-gap: 8.5rem!important
    }

    .column-gap-xxl-22 {
        -moz-column-gap: 9rem!important;
        column-gap: 9rem!important
    }

    .column-gap-xxl-23 {
        -moz-column-gap: 9.5rem!important;
        column-gap: 9.5rem!important
    }

    .column-gap-xxl-24 {
        -moz-column-gap: 10rem!important;
        column-gap: 10rem!important
    }

    .column-gap-xxl-25 {
        -moz-column-gap: 10.5rem!important;
        column-gap: 10.5rem!important
    }

    .column-gap-xxl-26 {
        -moz-column-gap: 11rem!important;
        column-gap: 11rem!important
    }

    .column-gap-xxl-27 {
        -moz-column-gap: 12rem!important;
        column-gap: 12rem!important
    }

    .column-gap-xxl-28 {
        -moz-column-gap: 13rem!important;
        column-gap: 13rem!important
    }

    .column-gap-xxl-29 {
        -moz-column-gap: 14rem!important;
        column-gap: 14rem!important
    }

    .column-gap-xxl-30 {
        -moz-column-gap: 15rem!important;
        column-gap: 15rem!important
    }

    .column-gap-xxl-31 {
        -moz-column-gap: 16rem!important;
        column-gap: 16rem!important
    }

    .column-gap-xxl-32 {
        -moz-column-gap: 17rem!important;
        column-gap: 17rem!important
    }

    .column-gap-xxl-33 {
        -moz-column-gap: 18rem!important;
        column-gap: 18rem!important
    }

    .column-gap-xxl-34 {
        -moz-column-gap: 19rem!important;
        column-gap: 19rem!important
    }

    .column-gap-xxl-35 {
        -moz-column-gap: 20rem!important;
        column-gap: 20rem!important
    }

    .text-xxl-start {
        text-align: left!important
    }

    .text-xxl-end {
        text-align: right!important
    }

    .text-xxl-center {
        text-align: center!important
    }
}

@media (min-width: 1200px) {
    .fs-1 {
        font-size:2.53125rem!important
    }

    .fs-2 {
        font-size: 2.08125rem!important
    }

    .fs-3 {
        font-size: 1.575rem!important
    }

    .fs-4 {
        font-size: 1.35rem!important
    }
}

@media print {
    .d-print-inline {
        display: inline!important
    }

    .d-print-inline-block {
        display: inline-block!important
    }

    .d-print-block {
        display: block!important
    }

    .d-print-grid {
        display: grid!important
    }

    .d-print-table {
        display: table!important
    }

    .d-print-table-row {
        display: table-row!important
    }

    .d-print-table-cell {
        display: table-cell!important
    }

    .d-print-flex {
        display: flex!important
    }

    .d-print-inline-flex {
        display: inline-flex!important
    }

    .d-print-none {
        display: none!important
    }
}

@media (max-width: 767.98px) {
    .hero-carousel .carousel-item,.hero-carousel .image-wrapper,.hero-carousel .video-wrapper,.hero-image .carousel-item,.hero-image .image-wrapper,.hero-image .video-wrapper,.hero-video .carousel-item,.hero-video .image-wrapper,.hero-video .video-wrapper {
        height:465px
    }

    .hero-carousel .carousel-caption,.hero-carousel .carousel-caption-outside,.hero-carousel .image-caption,.hero-carousel .video-caption,.hero-image .carousel-caption,.hero-image .carousel-caption-outside,.hero-image .image-caption,.hero-image .video-caption,.hero-video .carousel-caption,.hero-video .carousel-caption-outside,.hero-video .image-caption,.hero-video .video-caption {
        padding-top: 4rem
    }

    .hero-carousel .check-room,.hero-image .check-room,.hero-video .check-room {
        background-color: rgba(var(--bs-black-rgb),1)
    }

    .hero-carousel .check-room .card-check-room,.hero-image .check-room .card-check-room,.hero-video .check-room .card-check-room {
        background-color: rgba(var(--bs-black-rgb),1)
    }
}

@media (min-width: 768px) {
    .hero-carousel .carousel-item .bg-image,.hero-carousel .image-wrapper,.hero-carousel .video-wrapper,.hero-image .carousel-item .bg-image,.hero-image .image-wrapper,.hero-image .video-wrapper,.hero-video .carousel-item .bg-image,.hero-video .image-wrapper,.hero-video .video-wrapper {
        height:100vh
    }

    .hero-carousel .check-room,.hero-image .check-room,.hero-video .check-room {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        margin-bottom: 7rem
    }

    .hero-carousel .check-room .card-check-room,.hero-image .check-room .card-check-room,.hero-video .check-room .card-check-room {
        background-color: rgba(var(--bs-black-rgb),.75)
    }
}


.scroll-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 25px;
    bottom: 25px;
    z-index: 999;
    background: rgba(var(--bs-primary-rgb),.5);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: all .4s;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.15rem
}

.scroll-top:hover {
    background: rgba(var(--bs-primary-rgb),.85)
}

.scroll-top.active {
    visibility: visible;
    opacity: 1
}

.code-wrapper {
    margin-bottom: 0;
    position: relative
}

.code-wrapper .btn-clipboard {
    position: absolute;
    top: .5rem;
    right: .5rem;
    cursor: pointer;
    transform: none;
    font-size: .65rem;
    padding: .1rem .45rem .1rem;
    z-index: 3
}

.code-wrapper pre>code[class*=language-] {
    padding: 2.25em
}

.ff-heading {
    /* font-family: 'Lobster', sans-serif !important; */
    font-family: 'Cookie', cursive !important; 
   font-size: 45px !important;
}

.ff-sub {
    font-family: 'Roboto', sans-serif !important;
 
}

.ls-1 {
    letter-spacing: .15rem
}

.ls-2 {
    letter-spacing: .25rem
}

.ls-3 {
    letter-spacing: .35rem
}

.hicon {
    --hicon-size: 90%;
    font-family: icons;
    display: inline-flex;
    align-items: center;
    line-height: 1;
    font-weight: normal;
    font-style: normal;
    speak: none;
    text-decoration: inherit;
    text-transform: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: var(--hicon-size)
}

.hicon.hicon-bold,.fw-bold > .hicon,.fw-bolder > .hicon,.fw-medium > .hicon,.fw-semibold > .hicon {
    font-weight: bold
}

.hicon.hicon-60 {
    font-size: calc(0.6 * var(--hicon-size))
}

.hicon.hicon-70 {
    font-size: calc(0.7 * var(--hicon-size))
}

.hicon.hicon-80,.hicon.hicon-sm {
    font-size: calc(0.8 * var(--hicon-size))
}

.hicon.hicon-90 {
    font-size: calc(0.8 * var(--hicon-size))
}

.hicon.hicon-100,.hicon.hicon-md {
    font-size: calc(1 * var(--hicon-size))
}

.hicon.hicon-110 {
    font-size: calc(1.1 * var(--hicon-size))
}

.hicon.hicon-120,.hicon.hicon-lg {
    font-size: calc(1.2 * var(--hicon-size))
}

@font-face {
    font-family: icons;
    

    font-weight: 400;
    font-style: normal
}

.hicon-1ah:before {
    content: "\F101"
}

.hicon-24-hour-finess-center:before {
    content: "\F102"
}

.hicon-24hour-check-in:before {
    content: "\F103"
}

.hicon-24hour-frontdesk:before {
    content: "\F104"
}

.hicon-24hour-room-service:before {
    content: "\F105"
}

.hicon-24hour-security:before {
    content: "\F106"
}

.hicon-5-star-deal:before {
    content: "\F107"
}

.hicon-desktop-ic-black-down:before {
    content: "\F108"
}

.hicon-desktop-ic-black-down-arrow:before {
    content: "\F109"
}

.hicon-desktop-ic-profile:before {
    content: "\F10A"
}

.hicon-installment-payment:before {
    content: "\F10B"
}

.hicon-internet:before {
    content: "\F10C"
}

.hicon-iron:before {
    content: "\F10D"
}

.hicon-add-a-website:before {
    content: "\F10E"
}

.hicon-additional-bathroom:before {
    content: "\F10F"
}

.hicon-additional-fee-for-pets:before {
    content: "\F110"
}

.hicon-additional-information:before {
    content: "\F111"
}

.hicon-additional-toilet:before {
    content: "\F112"
}

.hicon-address:before {
    content: "\F113"
}

.hicon-adults-line:before {
    content: "\F114"
}

.hicon-adults-one:before {
    content: "\F115"
}

.hicon-after-hours:before {
    content: "\F116"
}

.hicon-afternoon-tea:before {
    content: "\F117"
}

.hicon-agoda-cash:before {
    content: "\F118"
}

.hicon-agoda-homes:before {
    content: "\F119"
}

.hicon-agoda-price-guarante-filled:before {
    content: "\F11A"
}

.hicon-agoda-price-guarante:before {
    content: "\F11B"
}

.hicon-air-bath-access:before {
    content: "\F11C"
}

.hicon-air-conditioning:before {
    content: "\F11D"
}

.hicon-air-purifier:before {
    content: "\F11E"
}

.hicon-airport-transfer-big:before {
    content: "\F11F"
}

.hicon-airport-transfer-oneway:before {
    content: "\F120"
}

.hicon-airport-transfer-roundtrip:before {
    content: "\F121"
}

.hicon-airport-transfer-small:before {
    content: "\F122"
}

.hicon-airport-transfer-solid:before {
    content: "\F123"
}

.hicon-airport-transfer:before {
    content: "\F124"
}

.hicon-airports-plane:before {
    content: "\F125"
}

.hicon-airports:before {
    content: "\F126"
}

.hicon-alarm-clock:before {
    content: "\F127"
}

.hicon-already-booking:before {
    content: "\F128"
}

.hicon-american-express:before {
    content: "\F129"
}

.hicon-apartment:before {
    content: "\F12A"
}

.hicon-app-android:before {
    content: "\F12B"
}

.hicon-app-apple:before {
    content: "\F12C"
}

.hicon-app-windos:before {
    content: "\F12D"
}

.hicon-aps-lock:before {
    content: "\F12E"
}

.hicon-area:before {
    content: "\F12F"
}

.hicon-arrow-big-down:before {
    content: "\F130"
}

.hicon-arrow-big-right:before {
    content: "\F131"
}

.hicon-arrow-big-up:before {
    content: "\F132"
}

.hicon-arrow-right-box:before {
    content: "\F133"
}

.hicon-arrow-right:before {
    content: "\F134"
}

.hicon-assembly-pin-border:before {
    content: "\F135"
}

.hicon-assembly-restaurant-line:before {
    content: "\F136"
}

.hicon-assembly-restaurant:before {
    content: "\F137"
}

.hicon-atm-cash-machine-on-site:before {
    content: "\F138"
}

.hicon-attractions:before {
    content: "\F139"
}

.hicon-avatar-hotel:before {
    content: "\F13A"
}

.hicon-avatar-property:before {
    content: "\F13B"
}

.hicon-baby-cot:before {
    content: "\F13C"
}

.hicon-babysitting:before {
    content: "\F13D"
}

.hicon-back-to-top:before {
    content: "\F13E"
}

.hicon-badge-insider:before {
    content: "\F13F"
}

.hicon-badminton-court:before {
    content: "\F140"
}

.hicon-balcony-terrace:before {
    content: "\F141"
}

.hicon-balloon-minus:before {
    content: "\F142"
}

.hicon-balloon-plus:before {
    content: "\F143"
}

.hicon-balloon:before {
    content: "\F144"
}

.hicon-bathrobes:before {
    content: "\F145"
}

.hicon-bathroom-basics:before {
    content: "\F146"
}

.hicon-bathroom-phone:before {
    content: "\F147"
}

.hicon-bathrooms:before {
    content: "\F148"
}

.hicon-bathtub:before {
    content: "\F149"
}

.hicon-bbq-facilities:before {
    content: "\F14A"
}

.hicon-bc-global-card:before {
    content: "\F14B"
}

.hicon-beach:before {
    content: "\F14C"
}

.hicon-bed-property:before {
    content: "\F14D"
}

.hicon-bed:before {
    content: "\F14E"
}

.hicon-bedroom-door:before {
    content: "\F14F"
}

.hicon-bedroom:before {
    content: "\F150"
}

.hicon-bell-alerts:before {
    content: "\F151"
}

.hicon-best-value:before {
    content: "\F152"
}

.hicon-bicycle-rental:before {
    content: "\F153"
}

.hicon-billiards:before {
    content: "\F154"
}

.hicon-blackout-curtains:before {
    content: "\F155"
}

.hicon-blizzard:before {
    content: "\F156"
}

.hicon-blowing-drifting-snow:before {
    content: "\F157"
}

.hicon-blowing-dust-sandstorm:before {
    content: "\F158"
}

.hicon-blowing-spray-windy:before {
    content: "\F159"
}

.hicon-boat:before {
    content: "\F15A"
}

.hicon-bold-baby-diaper:before {
    content: "\F15B"
}

.hicon-bold-baby:before {
    content: "\F15C"
}

.hicon-bold-bath-materials:before {
    content: "\F15D"
}

.hicon-bold-bath-temperature:before {
    content: "\F15E"
}

.hicon-bold-bath-water:before {
    content: "\F15F"
}

.hicon-bold-capacity:before {
    content: "\F160"
}

.hicon-bold-change-flight:before {
    content: "\F161"
}

.hicon-bold-child-meal-a:before {
    content: "\F162"
}

.hicon-bold-child-meal-b:before {
    content: "\F163"
}

.hicon-bold-children-yukata:before {
    content: "\F164"
}

.hicon-bold-family-with-teens-new:before {
    content: "\F165"
}

.hicon-bold-female-only:before {
    content: "\F166"
}

.hicon-bold-first-aid:before {
    content: "\F167"
}

.hicon-bold-floor-plan:before {
    content: "\F168"
}

.hicon-bold-gender:before {
    content: "\F169"
}

.hicon-bold-group-travelers:before {
    content: "\F16A"
}

.hicon-bold-hot-spring-access:before {
    content: "\F16B"
}

.hicon-bold-hot-spring-bath:before {
    content: "\F16C"
}

.hicon-bold-infant:before {
    content: "\F16D"
}

.hicon-bold-itinerary:before {
    content: "\F16E"
}

.hicon-bold-male-only:before {
    content: "\F16F"
}

.hicon-bold-meal:before {
    content: "\F170"
}

.hicon-bold-modify-search:before {
    content: "\F171"
}

.hicon-bold-mountain-view:before {
    content: "\F172"
}

.hicon-bold-package-savings:before {
    content: "\F173"
}

.hicon-bold-package:before {
    content: "\F174"
}

.hicon-bold-preselected-flight:before {
    content: "\F175"
}

.hicon-bold-price-fluctuates:before {
    content: "\F176"
}

.hicon-bold-salt:before {
    content: "\F177"
}

.hicon-bold-spa-sauna:before {
    content: "\F178"
}

.hicon-bold-tap-water:before {
    content: "\F179"
}

.hicon-bold-time-icon:before {
    content: "\F17A"
}

.hicon-bold-travel-protection:before {
    content: "\F17B"
}

.hicon-bold-walkability:before {
    content: "\F17C"
}

.hicon-book-now-pay-later:before {
    content: "\F17D"
}

.hicon-book-without-a-creditcard:before {
    content: "\F17E"
}

.hicon-bottle-of-sparkling-wine:before {
    content: "\F17F"
}

.hicon-bottle-of-wine:before {
    content: "\F180"
}

.hicon-bowling-alley:before {
    content: "\F181"
}

.hicon-bracket-left:before {
    content: "\F182"
}

.hicon-bracket-right:before {
    content: "\F183"
}

.hicon-breakfast-buffet:before {
    content: "\F184"
}

.hicon-breakfast-one-person:before {
    content: "\F185"
}

.hicon-breakfast:before {
    content: "\F186"
}

.hicon-breezy:before {
    content: "\F187"
}

.hicon-broken-clouds:before {
    content: "\F188"
}

.hicon-budget:before {
    content: "\F189"
}

.hicon-bullet:before {
    content: "\F18A"
}

.hicon-bungalow:before {
    content: "\F18B"
}

.hicon-bunk-bed:before {
    content: "\F18C"
}

.hicon-bus-and-rail-stations:before {
    content: "\F18D"
}

.hicon-bus-station:before {
    content: "\F18E"
}

.hicon-business-center:before {
    content: "\F18F"
}

.hicon-business-facilities:before {
    content: "\F190"
}

.hicon-business-hover:before {
    content: "\F191"
}

.hicon-business:before {
    content: "\F192"
}

.hicon-buzzer-wireless-intercom:before {
    content: "\F193"
}

.hicon-c-coupon:before {
    content: "\F194"
}

.hicon-cable-car-station:before {
    content: "\F195"
}

.hicon-calendar-onmap:before {
    content: "\F196"
}

.hicon-callcenter-24hour:before {
    content: "\F197"
}

.hicon-callcenter-line:before {
    content: "\F198"
}

.hicon-callcenter:before {
    content: "\F199"
}

.hicon-camera-hover:before {
    content: "\F19A"
}

.hicon-cancel-anytime:before {
    content: "\F19B"
}

.hicon-cancel-booking:before {
    content: "\F19C"
}

.hicon-cancellation-policy-non-refund-special-condition:before {
    content: "\F19D"
}

.hicon-canoe:before {
    content: "\F19E"
}

.hicon-capsule:before {
    content: "\F19F"
}

.hicon-car-hire:before {
    content: "\F1A0"
}

.hicon-car-park-charges:before {
    content: "\F1A1"
}

.hicon-car-park-free-charge:before {
    content: "\F1A2"
}

.hicon-car-park-nearby:before {
    content: "\F1A3"
}

.hicon-car-park-onsite:before {
    content: "\F1A4"
}

.hicon-car-park:before {
    content: "\F1A5"
}

.hicon-car-power-charging-station:before {
    content: "\F1A6"
}

.hicon-carbon-monoxide-detector:before {
    content: "\F1A7"
}

.hicon-carpeting:before {
    content: "\F1A8"
}

.hicon-carrouselarrow-left:before {
    content: "\F1A9"
}

.hicon-carrouselarrow-right:before {
    content: "\F1AA"
}

.hicon-cash-a-line-new:before {
    content: "\F1AB"
}

.hicon-cash-a-solid-new:before {
    content: "\F1AC"
}

.hicon-cash-circle-h:before {
    content: "\F1AD"
}

.hicon-cash-circle-s:before {
    content: "\F1AE"
}

.hicon-cash-h:before {
    content: "\F1AF"
}

.hicon-cash-line-circle-h:before {
    content: "\F1B0"
}

.hicon-cash-line-circle-s:before {
    content: "\F1B1"
}

.hicon-cash-line-h:before {
    content: "\F1B2"
}

.hicon-cash-line-new:before {
    content: "\F1B3"
}

.hicon-cash-line-s:before {
    content: "\F1B4"
}

.hicon-cash-s:before {
    content: "\F1B5"
}

.hicon-cash-solid-new:before {
    content: "\F1B6"
}

.hicon-cash:before {
    content: "\F1B7"
}

.hicon-casino:before {
    content: "\F1B8"
}

.hicon-cats-allowed:before {
    content: "\F1B9"
}

.hicon-chapel:before {
    content: "\F1BA"
}

.hicon-chat:before {
    content: "\F1BB"
}

.hicon-check-box:before {
    content: "\F1BC"
}

.hicon-check-circle-o:before {
    content: "\F1BD"
}

.hicon-check-circle:before {
    content: "\F1BE"
}

.hicon-check-in:before {
    content: "\F1BF"
}

.hicon-check-out:before {
    content: "\F1C0"
}

.hicon-check-valid-state:before {
    content: "\F1C1"
}

.hicon-check:before {
    content: "\F1C2"
}

.hicon-checkbox-icon:before {
    content: "\F1C3"
}

.hicon-checkin-date:before {
    content: "\F1C4"
}

.hicon-checkin-hover-date:before {
    content: "\F1C5"
}

.hicon-checkout-date:before {
    content: "\F1C6"
}

.hicon-checkout-hover-date:before {
    content: "\F1C7"
}

.hicon-child-icon:before {
    content: "\F1C8"
}

.hicon-child-line:before {
    content: "\F1C9"
}

.hicon-child:before {
    content: "\F1CA"
}

.hicon-children-high-chair:before {
    content: "\F1CB"
}

.hicon-children-one:before {
    content: "\F1CC"
}

.hicon-children-playground:before {
    content: "\F1CD"
}

.hicon-china-event:before {
    content: "\F1CE"
}

.hicon-china-only:before {
    content: "\F1CF"
}

.hicon-chinese-friendly:before {
    content: "\F1D0"
}

.hicon-circle-05:before {
    content: "\F1D1"
}

.hicon-circle-1:before {
    content: "\F1D2"
}

.hicon-circle-15:before {
    content: "\F1D3"
}

.hicon-circle-2:before {
    content: "\F1D4"
}

.hicon-circle-25:before {
    content: "\F1D5"
}

.hicon-circle-3:before {
    content: "\F1D6"
}

.hicon-circle-35:before {
    content: "\F1D7"
}

.hicon-circle-4:before {
    content: "\F1D8"
}

.hicon-circle-45:before {
    content: "\F1D9"
}

.hicon-circle-5:before {
    content: "\F1DA"
}

.hicon-circle-arrow-left:before {
    content: "\F1DB"
}

.hicon-circle-arrow-right:before {
    content: "\F1DC"
}

.hicon-circle-bus:before {
    content: "\F1DD"
}

.hicon-circle-home:before {
    content: "\F1DE"
}

.hicon-circle-pin:before {
    content: "\F1DF"
}

.hicon-circle-plane:before {
    content: "\F1E0"
}

.hicon-circle-star:before {
    content: "\F1E1"
}

.hicon-cities:before {
    content: "\F1E2"
}

.hicon-city-buses:before {
    content: "\F1E3"
}

.hicon-city-view:before {
    content: "\F1E4"
}

.hicon-cleaning-products:before {
    content: "\F1E5"
}

.hicon-clear-mark:before {
    content: "\F1E6"
}

.hicon-clear-sky-b:before {
    content: "\F1E7"
}

.hicon-clear-sky:before {
    content: "\F1E8"
}

.hicon-clear:before {
    content: "\F1E9"
}

.hicon-close-popup-solid:before {
    content: "\F1EA"
}

.hicon-close-popup:before {
    content: "\F1EB"
}

.hicon-closest-bar:before {
    content: "\F1EC"
}

.hicon-closest-market:before {
    content: "\F1ED"
}

.hicon-closet:before {
    content: "\F1EE"
}

.hicon-clothes-dryer-pay:before {
    content: "\F1EF"
}

.hicon-clothes-rack:before {
    content: "\F1F0"
}

.hicon-clothes-washer-free:before {
    content: "\F1F1"
}

.hicon-cloudy:before {
    content: "\F1F2"
}

.hicon-club-lounge-access:before {
    content: "\F1F3"
}

.hicon-coffee-shop:before {
    content: "\F1F4"
}

.hicon-coffee-tea-maker:before {
    content: "\F1F5"
}

.hicon-complimentary-bottled-water:before {
    content: "\F1F6"
}

.hicon-complimentary-instant-coffee:before {
    content: "\F1F7"
}

.hicon-complimentary-tea:before {
    content: "\F1F8"
}

.hicon-compset-comparison:before {
    content: "\F1F9"
}

.hicon-concierge:before {
    content: "\F1FA"
}

.hicon-confirmation-instant:before {
    content: "\F1FB"
}

.hicon-confirmation-later:before {
    content: "\F1FC"
}

.hicon-confirmation-solid:before {
    content: "\F1FD"
}

.hicon-confirmation:before {
    content: "\F1FE"
}

.hicon-congratulations:before {
    content: "\F1FF"
}

.hicon-continental-breakfast:before {
    content: "\F200"
}

.hicon-control-close-circle:before {
    content: "\F201"
}

.hicon-control-expand:before {
    content: "\F203"
}

.hicon-cookie:before {
    content: "\F204"
}

.hicon-couple-hover:before {
    content: "\F205"
}

.hicon-couple:before {
    content: "\F206"
}

.hicon-coupon-card-no-circle:before {
    content: "\F207"
}

.hicon-coupon-card-solid:before {
    content: "\F208"
}

.hicon-coupon-card:before {
    content: "\F209"
}

.hicon-coupon-promo:before {
    content: "\F20A"
}

.hicon-coupon:before {
    content: "\F20B"
}

.hicon-credit-card-bf:before {
    content: "\F20C"
}

.hicon-credit-card-required:before {
    content: "\F20D"
}

.hicon-credit-card:before {
    content: "\F20E"
}

.hicon-culture:before {
    content: "\F20F"
}

.hicon-currency-exchange:before {
    content: "\F210"
}

.hicon-daily-housekeeping:before {
    content: "\F211"
}

.hicon-daily-newspaper:before {
    content: "\F212"
}

.hicon-dart-board:before {
    content: "\F213"
}

.hicon-dashboard:before {
    content: "\F214"
}

.hicon-data-at-your-fingertips:before {
    content: "\F215"
}

.hicon-deal-gift-card:before {
    content: "\F216"
}

.hicon-deal-insider:before {
    content: "\F217"
}

.hicon-default-feedback:before {
    content: "\F218"
}

.hicon-desk:before {
    content: "\F219"
}

.hicon-diners-clubs-card:before {
    content: "\F21A"
}

.hicon-dinner:before {
    content: "\F21B"
}

.hicon-discount:before {
    content: "\F21C"
}

.hicon-discover-card:before {
    content: "\F21D"
}

.hicon-dishwasher:before {
    content: "\F21E"
}

.hicon-distance-from-city-center:before {
    content: "\F21F"
}

.hicon-distance-to-airport:before {
    content: "\F220"
}

.hicon-diving:before {
    content: "\F221"
}

.hicon-document:before {
    content: "\F222"
}

.hicon-dog-allowed:before {
    content: "\F223"
}

.hicon-domestic-rates:before {
    content: "\F224"
}

.hicon-dot-seperater:before {
    content: "\F225"
}

.hicon-double-bed:before {
    content: "\F226"
}

.hicon-double-super-king-queen:before {
    content: "\F227"
}

.hicon-download-apps:before {
    content: "\F228"
}

.hicon-download:before {
    content: "\F229"
}

.hicon-dressing-room:before {
    content: "\F22A"
}

.hicon-drinks:before {
    content: "\F22B"
}

.hicon-drizzle:before {
    content: "\F22C"
}

.hicon-drug-stores:before {
    content: "\F22D"
}

.hicon-dryer:before {
    content: "\F22E"
}

.hicon-dvd-cd-player:before {
    content: "\F22F"
}

.hicon-dynamic-banner:before {
    content: "\F230"
}

.hicon-early-bird-deal-solid:before {
    content: "\F231"
}

.hicon-early-bird-deal:before {
    content: "\F232"
}

.hicon-early-check-in:before {
    content: "\F233"
}

.hicon-easy-integration:before {
    content: "\F234"
}

.hicon-edge-arrow-left:before {
    content: "\F235"
}

.hicon-edge-arrow-right:before {
    content: "\F236"
}

.hicon-edit-filled:before {
    content: "\F237"
}

.hicon-edit:before {
    content: "\F238"
}

.hicon-electric-blanket:before {
    content: "\F239"
}

.hicon-elevator:before {
    content: "\F23A"
}

.hicon-email-envelope:before {
    content: "\F23B"
}

.hicon-emo-dislike-ani1:before {
    content: "\F23C"
}

.hicon-emo-dislike-ani2:before {
    content: "\F23D"
}

.hicon-emo-dislike-ani3:before {
    content: "\F23E"
}

.hicon-emo-dislike-click:before {
    content: "\F23F"
}

.hicon-emo-dont-care-ani1:before {
    content: "\F240"
}

.hicon-emo-dont-care-ani2:before {
    content: "\F241"
}

.hicon-emo-dont-care-ani3:before {
    content: "\F242"
}

.hicon-emo-dont-care-click:before {
    content: "\F243"
}

.hicon-emo-great-ani1:before {
    content: "\F244"
}

.hicon-emo-great-ani2:before {
    content: "\F245"
}

.hicon-emo-great-ani3:before {
    content: "\F246"
}

.hicon-emo-great-click:before {
    content: "\F247"
}

.hicon-emo-nice-ani1:before {
    content: "\F248"
}

.hicon-emo-nice-ani2:before {
    content: "\F249"
}

.hicon-emo-nice-ani3:before {
    content: "\F24A"
}

.hicon-emo-nice-click:before {
    content: "\F24B"
}

.hicon-emo-sad-ani1:before {
    content: "\F24C"
}

.hicon-emo-sad-ani2:before {
    content: "\F24D"
}

.hicon-emo-sad-ani3:before {
    content: "\F24E"
}

.hicon-emo-sad-click:before {
    content: "\F24F"
}

.hicon-entire-place:before {
    content: "\F250"
}

.hicon-essentials:before {
    content: "\F251"
}

.hicon-everybody-fits:before {
    content: "\F252"
}

.hicon-exclusive-sale:before {
    content: "\F253"
}

.hicon-executive-floor:before {
    content: "\F254"
}

.hicon-executive-lounge-access:before {
    content: "\F255"
}

.hicon-export-calendar:before {
    content: "\F256"
}

.hicon-express-check-in-check-out:before {
    content: "\F257"
}

.hicon-exterior:before {
    content: "\F258"
}

.hicon-extra-bed:before {
    content: "\F259"
}

.hicon-extra-long-beds:before {
    content: "\F25A"
}

.hicon-facebook-logo:before {
    content: "\F25B"
}

.hicon-facilities-for-disabled-guests:before {
    content: "\F25C"
}

.hicon-facilities-rich:before {
    content: "\F25D"
}

.hicon-failed-o:before {
    content: "\F25E"
}

.hicon-failed:before {
    content: "\F25F"
}

.hicon-fair-mostly-clear:before {
    content: "\F260"
}

.hicon-fair-mostly-sunny:before {
    content: "\F261"
}

.hicon-family-friendly:before {
    content: "\F262"
}

.hicon-family-fun:before {
    content: "\F263"
}

.hicon-family-line:before {
    content: "\F264"
}

.hicon-family-room:before {
    content: "\F265"
}

.hicon-family-special-deal:before {
    content: "\F266"
}

.hicon-family-with-small-kids-hover:before {
    content: "\F267"
}

.hicon-family-with-teens-hover:before {
    content: "\F268"
}

.hicon-family-with-teens:before {
    content: "\F269"
}

.hicon-fan:before {
    content: "\F26A"
}

.hicon-faq:before {
    content: "\F26B"
}

.hicon-favorite-filled:before {
    content: "\F26C"
}

.hicon-favorite:before {
    content: "\F26D"
}

.hicon-fax-machine:before {
    content: "\F26E"
}

.hicon-fax-or-photo:before {
    content: "\F26F"
}

.hicon-feedback:before {
    content: "\F270"
}

.hicon-female-capsule:before {
    content: "\F271"
}

.hicon-few-clouds:before {
    content: "\F272"
}

.hicon-filled-baby-diaper:before {
    content: "\F273"
}

.hicon-filled-baby:before {
    content: "\F274"
}

.hicon-filled-bath-materials:before {
    content: "\F275"
}

.hicon-filled-bath-temperature:before {
    content: "\F276"
}

.hicon-filled-bath-water:before {
    content: "\F277"
}

.hicon-filled-capacity:before {
    content: "\F278"
}

.hicon-filled-change-flight:before {
    content: "\F279"
}

.hicon-filled-child-meal-a:before {
    content: "\F27A"
}

.hicon-filled-child-meal-b:before {
    content: "\F27B"
}

.hicon-filled-children-yukata:before {
    content: "\F27C"
}

.hicon-filled-family-with-teens-new:before {
    content: "\F27D"
}

.hicon-filled-female-only:before {
    content: "\F27E"
}

.hicon-filled-first-aid:before {
    content: "\F27F"
}

.hicon-filled-gender:before {
    content: "\F280"
}

.hicon-filled-hot-spring-access:before {
    content: "\F281"
}

.hicon-filled-hot-spring-bath:before {
    content: "\F282"
}

.hicon-filled-infant:before {
    content: "\F283"
}

.hicon-filled-itinerary:before {
    content: "\F284"
}

.hicon-filled-male-only:before {
    content: "\F285"
}

.hicon-filled-meal:before {
    content: "\F286"
}

.hicon-filled-modify-search:before {
    content: "\F287"
}

.hicon-filled-mountain-view:before {
    content: "\F288"
}

.hicon-filled-package-savings:before {
    content: "\F289"
}

.hicon-filled-package:before {
    content: "\F28A"
}

.hicon-filled-preselected-flight:before {
    content: "\F28B"
}

.hicon-filled-price-fluctuates:before {
    content: "\F28C"
}

.hicon-filled-salt:before {
    content: "\F28D"
}

.hicon-filled-spa-sauna:before {
    content: "\F28E"
}

.hicon-filled-tap-water:before {
    content: "\F28F"
}

.hicon-filled-time-icon:before {
    content: "\F290"
}

.hicon-filled-travel-protection:before {
    content: "\F291"
}

.hicon-filled-walkability:before {
    content: "\F292"
}

.hicon-filter-icon:before {
    content: "\F293"
}

.hicon-filter-line:before {
    content: "\F294"
}

.hicon-fire-extinguisher:before {
    content: "\F295"
}

.hicon-fire-solid:before {
    content: "\F296"
}

.hicon-fireplace:before {
    content: "\F297"
}

.hicon-first-aid-kit:before {
    content: "\F298"
}

.hicon-fishing:before {
    content: "\F299"
}

.hicon-fitness-center-charge:before {
    content: "\F29A"
}

.hicon-fitness-center:before {
    content: "\F29B"
}

.hicon-fitness-club:before {
    content: "\F29C"
}

.hicon-flash-deal-solid:before {
    content: "\F29D"
}

.hicon-flash-deal:before {
    content: "\F29E"
}

.hicon-flash-sale:before {
    content: "\F29F"
}

.hicon-flight-earn:before {
    content: "\F2A0"
}

.hicon-flights-airplane:before {
    content: "\F2A1"
}

.hicon-flights-destination-line:before {
    content: "\F2A2"
}

.hicon-flights-hotel-line:before {
    content: "\F2A3"
}

.hicon-flights-layover-exchange-line:before {
    content: "\F2A4"
}

.hicon-flights-one-ways:before {
    content: "\F2A5"
}

.hicon-flights-pin:before {
    content: "\F2A6"
}

.hicon-flights-round-trip:before {
    content: "\F2A7"
}

.hicon-flights-stop-layover-line:before {
    content: "\F2A8"
}

.hicon-foggy:before {
    content: "\F2A9"
}

.hicon-forgot-pass:before {
    content: "\F2AA"
}

.hicon-free-bicycle:before {
    content: "\F2AB"
}

.hicon-free-breakfast-line:before {
    content: "\F2AC"
}

.hicon-free-breakfast:before {
    content: "\F2AD"
}

.hicon-free-cancellation:before {
    content: "\F2AE"
}

.hicon-free-fitness-center:before {
    content: "\F2AF"
}

.hicon-free-night-stay-circle:before {
    content: "\F2B0"
}

.hicon-free-night-stay-solid:before {
    content: "\F2B1"
}

.hicon-free-night-stay:before {
    content: "\F2B2"
}

.hicon-free-night:before {
    content: "\F2B3"
}

.hicon-free-welcome-drink:before {
    content: "\F2B4"
}

.hicon-free-wifi-in-all-rooms:before {
    content: "\F2B5"
}

.hicon-freezing-drizzle:before {
    content: "\F2B6"
}

.hicon-freezing-rain:before {
    content: "\F2B7"
}

.hicon-frequent-traveler:before {
    content: "\F2B8"
}

.hicon-fresh-newly-built-property:before {
    content: "\F2B9"
}

.hicon-frigid-ice-crystals:before {
    content: "\F2BA"
}

.hicon-g-giftcard:before {
    content: "\F2BB"
}

.hicon-garden:before {
    content: "\F2BC"
}

.hicon-gca-wreath-left:before {
    content: "\F2BD"
}

.hicon-gca-wreath-right:before {
    content: "\F2BE"
}

.hicon-get-extra-space:before {
    content: "\F2BF"
}

.hicon-gift-card:before {
    content: "\F2C0"
}

.hicon-gift-souvenir-shop:before {
    content: "\F2C1"
}

.hicon-giftcard-instant:before {
    content: "\F2C2"
}

.hicon-giftcard:before {
    content: "\F2C3"
}

.hicon-give-us-feedback:before {
    content: "\F2C4"
}

.hicon-global:before {
    content: "\F2C5"
}

.hicon-golf-course-on-site:before {
    content: "\F2C6"
}

.hicon-golf-course-within-3k:before {
    content: "\F2C7"
}

.hicon-grocery-deliveries:before {
    content: "\F2C8"
}

.hicon-ground-floor:before {
    content: "\F2C9"
}

.hicon-group-travelers-hover:before {
    content: "\F2CA"
}

.hicon-group-travelers:before {
    content: "\F2CB"
}

.hicon-guest-house:before {
    content: "\F2CC"
}

.hicon-gym:before {
    content: "\F2CD"
}

.hicon-hail:before {
    content: "\F2CE"
}

.hicon-hair-dryer:before {
    content: "\F2CF"
}

.hicon-halal-restaurant-b:before {
    content: "\F2D0"
}

.hicon-halal-restaurant:before {
    content: "\F2D1"
}

.hicon-half-full-board:before {
    content: "\F2D2"
}

.hicon-hamburger-dote:before {
    content: "\F2D3"
}

.hicon-hamburger-menu:before {
    content: "\F2D4"
}

.hicon-hangers:before {
    content: "\F2D5"
}

.hicon-haze-windy:before {
    content: "\F2D6"
}

.hicon-heart-of-the-city:before {
    content: "\F2D7"
}

.hicon-heating:before {
    content: "\F2D8"
}

.hicon-heavy-rain:before {
    content: "\F2D9"
}

.hicon-heavy-snow:before {
    content: "\F2DA"
}

.hicon-high-floor:before {
    content: "\F2DB"
}

.hicon-high-to-low:before {
    content: "\F2DC"
}

.hicon-hiking-trails:before {
    content: "\F2DD"
}

.hicon-holiday-house:before {
    content: "\F2DE"
}

.hicon-homestay:before {
    content: "\F2DF"
}

.hicon-horse-riding:before {
    content: "\F2E0"
}

.hicon-hospitals-clinics:before {
    content: "\F2E1"
}

.hicon-hot-spring-access:before {
    content: "\F2E2"
}

.hicon-hot-spring-bath:before {
    content: "\F2E3"
}

.hicon-hot-tub:before {
    content: "\F2E4"
}

.hicon-hot:before {
    content: "\F2E5"
}

.hicon-hotel-benefit:before {
    content: "\F2E6"
}

.hicon-hotel-book-last:before {
    content: "\F2E7"
}

.hicon-hotel-data:before {
    content: "\F2E8"
}

.hicon-hotel-great-location:before {
    content: "\F2E9"
}

.hicon-hotel-people-looking:before {
    content: "\F2EA"
}

.hicon-hotel-star-half:before {
    content: "\F2EB"
}

.hicon-hotel-star:before {
    content: "\F2EC"
}

.hicon-hotel-wifi:before {
    content: "\F2ED"
}

.hicon-hotel:before {
    content: "\F2EE"
}

.hicon-hover-details:before {
    content: "\F2EF"
}

.hicon-human-large:before {
    content: "\F2F0"
}

.hicon-humidifier:before {
    content: "\F2F1"
}

.hicon-hurricane:before {
    content: "\F2F2"
}

.hicon-ic-filter-bestseller:before {
    content: "\F2F3"
}

.hicon-icon-arrow-down:before {
    content: "\F2F4"
}

.hicon-icon-arrow-up:before {
    content: "\F2F5"
}

.hicon-iftar:before {
    content: "\F2F6"
}

.hicon-import-calendar:before {
    content: "\F2F7"
}

.hicon-in-room-safe:before {
    content: "\F2F8"
}

.hicon-in-room-tablet:before {
    content: "\F2F9"
}

.hicon-in-room-video-games:before {
    content: "\F2FA"
}

.hicon-indoor-poor:before {
    content: "\F2FB"
}

.hicon-infant:before {
    content: "\F2FC"
}

.hicon-infirmary:before {
    content: "\F2FD"
}

.hicon-info-alert:before {
    content: "\F2FE"
}

.hicon-info-with-circle:before {
    content: "\F2FF"
}

.hicon-information:before {
    content: "\F300"
}

.hicon-inhouse-movies:before {
    content: "\F301"
}

.hicon-insider-deal-desktop:before {
    content: "\F302"
}

.hicon-installment-graph:before {
    content: "\F303"
}

.hicon-installment-line:before {
    content: "\F304"
}

.hicon-installment-solid:before {
    content: "\F305"
}

.hicon-instant-booking:before {
    content: "\F306"
}

.hicon-instant:before {
    content: "\F307"
}

.hicon-insure-your-hotel:before {
    content: "\F308"
}

.hicon-interconnecting-room-available:before {
    content: "\F309"
}

.hicon-invalided-file:before {
    content: "\F30A"
}

.hicon-ipod-docking-station:before {
    content: "\F30B"
}

.hicon-islamic-prayer-room:before {
    content: "\F30C"
}

.hicon-isolated-thunderstorms:before {
    content: "\F30D"
}

.hicon-jacuzzi-bathtub:before {
    content: "\F30E"
}

.hicon-japanese-futon:before {
    content: "\F30F"
}

.hicon-jcb:before {
    content: "\F310"
}

.hicon-karaoke:before {
    content: "\F311"
}

.hicon-keyless-access:before {
    content: "\F312"
}

.hicon-kids-club:before {
    content: "\F313"
}

.hicon-king-bed:before {
    content: "\F314"
}

.hicon-kitchen-new:before {
    content: "\F315"
}

.hicon-kitchen:before {
    content: "\F316"
}

.hicon-kitchenette-bold:before {
    content: "\F317"
}

.hicon-kitchenette:before {
    content: "\F318"
}

.hicon-kitchenware:before {
    content: "\F319"
}

.hicon-kosher-restaurant:before {
    content: "\F31A"
}

.hicon-lake-view:before {
    content: "\F31B"
}

.hicon-landmark:before {
    content: "\F31C"
}

.hicon-laptop-friendly-workspace:before {
    content: "\F31D"
}

.hicon-laptop-safe-box:before {
    content: "\F31E"
}

.hicon-last-minute-deal-2:before {
    content: "\F31F"
}

.hicon-last-minute-deal-solid:before {
    content: "\F320"
}

.hicon-last-minute-deal:before {
    content: "\F321"
}

.hicon-last-viewed:before {
    content: "\F322"
}

.hicon-late-check-out:before {
    content: "\F323"
}

.hicon-laundromat:before {
    content: "\F324"
}

.hicon-laundry-service:before {
    content: "\F325"
}

.hicon-length-of-stay:before {
    content: "\F326"
}

.hicon-library:before {
    content: "\F327"
}

.hicon-light-rain:before {
    content: "\F328"
}

.hicon-light-snow:before {
    content: "\F329"
}

.hicon-lightbox:before {
    content: "\F32A"
}

.hicon-limit-deal:before {
    content: "\F32B"
}

.hicon-limited-access-floor:before {
    content: "\F32C"
}

.hicon-line-close:before {
    content: "\F32D"
}

.hicon-line-empty-circle:before {
    content: "\F32E"
}

.hicon-linens:before {
    content: "\F32F"
}

.hicon-link-out-bold:before {
    content: "\F330"
}

.hicon-link-out:before {
    content: "\F331"
}

.hicon-lobby:before {
    content: "\F332"
}

.hicon-lockers:before {
    content: "\F333"
}

.hicon-logo-ah:before {
    content: "\F334"
}

.hicon-logo-wechat:before {
    content: "\F335"
}

.hicon-long-stay-deal:before {
    content: "\F336"
}

.hicon-long-stays-promotion:before {
    content: "\F337"
}

.hicon-long-stays:before {
    content: "\F338"
}

.hicon-low-floor:before {
    content: "\F339"
}

.hicon-low-to-high:before {
    content: "\F33A"
}

.hicon-luggage-storage:before {
    content: "\F33B"
}

.hicon-luggage:before {
    content: "\F33C"
}

.hicon-lunch:before {
    content: "\F33D"
}

.hicon-luxury:before {
    content: "\F33E"
}

.hicon-m-and-s:before {
    content: "\F33F"
}

.hicon-male-capsule-b:before {
    content: "\F340"
}

.hicon-male-capsule:before {
    content: "\F341"
}

.hicon-mandarin:before {
    content: "\F342"
}

.hicon-map-airport:before {
    content: "\F343"
}

.hicon-map-attraction:before {
    content: "\F344"
}

.hicon-map-city:before {
    content: "\F345"
}

.hicon-map-night:before {
    content: "\F346"
}

.hicon-map-pin-fat:before {
    content: "\F347"
}

.hicon-map-room:before {
    content: "\F348"
}

.hicon-map-transportation:before {
    content: "\F349"
}

.hicon-map-view:before {
    content: "\F34A"
}

.hicon-map-entry:before {
    content: "\F34B"
}

.hicon-massage:before {
    content: "\F34C"
}

.hicon-mastercard:before {
    content: "\F34D"
}

.hicon-max-occupancy-plus:before {
    content: "\F34E"
}

.hicon-max-occupancy:before {
    content: "\F34F"
}

.hicon-meeting-banquet:before {
    content: "\F350"
}

.hicon-meeting-facilities:before {
    content: "\F351"
}

.hicon-menu-about:before {
    content: "\F352"
}

.hicon-menu-account-hover:before {
    content: "\F353"
}

.hicon-menu-account:before {
    content: "\F354"
}

.hicon-menu-bookings:before {
    content: "\F355"
}

.hicon-menu-bug:before {
    content: "\F356"
}

.hicon-menu-calendar-hover:before {
    content: "\F357"
}

.hicon-menu-calendar:before {
    content: "\F358"
}

.hicon-menu-contact-us:before {
    content: "\F359"
}

.hicon-menu-favorite:before {
    content: "\F35A"
}

.hicon-menu-inbox-hover:before {
    content: "\F35B"
}

.hicon-menu-inbox:before {
    content: "\F35C"
}

.hicon-menu-listings-hover:before {
    content: "\F35D"
}

.hicon-menu-listings:before {
    content: "\F35E"
}

.hicon-menu-overviews-hover:before {
    content: "\F35F"
}

.hicon-menu-overviews:before {
    content: "\F360"
}

.hicon-menu-price-display:before {
    content: "\F361"
}

.hicon-menu-reservations-hover:before {
    content: "\F362"
}

.hicon-menu-reservations:before {
    content: "\F363"
}

.hicon-menu-reviews:before {
    content: "\F364"
}

.hicon-menu-today-deals:before {
    content: "\F365"
}

.hicon-message-left:before {
    content: "\F366"
}

.hicon-message-right:before {
    content: "\F367"
}

.hicon-metro-subway-station:before {
    content: "\F368"
}

.hicon-microwave:before {
    content: "\F369"
}

.hicon-mini-bar:before {
    content: "\F36A"
}

.hicon-minibar-b:before {
    content: "\F36B"
}

.hicon-minus-thin:before {
    content: "\F36C"
}

.hicon-minus-with-circle:before {
    content: "\F36D"
}

.hicon-mirror:before {
    content: "\F36E"
}

.hicon-mist:before {
    content: "\F36F"
}

.hicon-mixed-rain-hail:before {
    content: "\F370"
}

.hicon-mmb-account:before {
    content: "\F371"
}

.hicon-mmb-booking:before {
    content: "\F372"
}

.hicon-mmb-g-giftcard:before {
    content: "\F373"
}

.hicon-mmb-gift-cards:before {
    content: "\F374"
}

.hicon-mmb-inbox:before {
    content: "\F375"
}

.hicon-mmb-my-booking:before {
    content: "\F376"
}

.hicon-mmb-my-rewards:before {
    content: "\F377"
}

.hicon-mmb-payment-methods:before {
    content: "\F378"
}

.hicon-mmb-pointsmax:before {
    content: "\F379"
}

.hicon-mmb-refer-a-friend:before {
    content: "\F37A"
}

.hicon-mmb-reviews-b:before {
    content: "\F37B"
}

.hicon-mmb-reviews:before {
    content: "\F37C"
}

.hicon-mmb-vip:before {
    content: "\F37D"
}

.hicon-more-bedrooms:before {
    content: "\F37E"
}

.hicon-more-images:before {
    content: "\F37F"
}

.hicon-more-money:before {
    content: "\F380"
}

.hicon-mosquitonet:before {
    content: "\F381"
}

.hicon-most-popular-destinations:before {
    content: "\F382"
}

.hicon-mostly-cloudy-day:before {
    content: "\F383"
}

.hicon-mostly-cloudy-night:before {
    content: "\F384"
}

.hicon-motorbike:before {
    content: "\F385"
}

.hicon-mountain-view:before {
    content: "\F386"
}

.hicon-mse-icon:before {
    content: "\F387"
}

.hicon-mse-price-icon:before {
    content: "\F388"
}

.hicon-museum-arts:before {
    content: "\F389"
}

.hicon-nav-back:before {
    content: "\F38A"
}

.hicon-nav-down-bold:before {
    content: "\F38B"
}

.hicon-nav-down-thin:before {
    content: "\F38C"
}

.hicon-nav-left-bold:before {
    content: "\F38D"
}

.hicon-nav-left-thin:before {
    content: "\F38E"
}

.hicon-nav-right-bold:before {
    content: "\F38F"
}

.hicon-nav-right-thin:before {
    content: "\F390"
}

.hicon-nav-up-bold:before {
    content: "\F391"
}

.hicon-nav-up-thin:before {
    content: "\F392"
}

.hicon-negative:before {
    content: "\F393"
}

.hicon-neighborhood-line:before {
    content: "\F394"
}

.hicon-neighborhood:before {
    content: "\F395"
}

.hicon-new-property:before {
    content: "\F396"
}

.hicon-nha-icon:before {
    content: "\F397"
}

.hicon-nha-logo:before {
    content: "\F398"
}

.hicon-nightclub:before {
    content: "\F399"
}

.hicon-nightlife:before {
    content: "\F39A"
}

.hicon-no-breakfast-a:before {
    content: "\F39B"
}

.hicon-no-breakfast-b:before {
    content: "\F39C"
}

.hicon-no-cc-fee:before {
    content: "\F39D"
}

.hicon-no-cc:before {
    content: "\F39E"
}

.hicon-no-children-allowed:before {
    content: "\F39F"
}

.hicon-non-smoking-room:before {
    content: "\F3A0"
}

.hicon-non-smoking:before {
    content: "\F3A1"
}

.hicon-not-available:before {
    content: "\F3A2"
}

.hicon-noti-balloon-answer:before {
    content: "\F3A3"
}

.hicon-noti-balloon-question:before {
    content: "\F3A4"
}

.hicon-noti-check-mark-rounded-inner:before {
    content: "\F3A5"
}

.hicon-noti-check-mark-sharp:before {
    content: "\F3A6"
}

.hicon-notice-info:before {
    content: "\F3A7"
}

.hicon-number-of-floors:before {
    content: "\F3A8"
}

.hicon-number-of-rooms:before {
    content: "\F3A9"
}

.hicon-number-reviews:before {
    content: "\F3AA"
}

.hicon-number1:before {
    content: "\F3AB"
}

.hicon-occupancy:before {
    content: "\F3AC"
}

.hicon-ocean-view:before {
    content: "\F3AD"
}

.hicon-one-click:before {
    content: "\F3AE"
}

.hicon-open-a-new-tab:before {
    content: "\F3AF"
}

.hicon-outdoor-pool:before {
    content: "\F3B0"
}

.hicon-paperclip:before {
    content: "\F3B1"
}

.hicon-partly-cloudy-day:before {
    content: "\F3B2"
}

.hicon-partly-cloudy-night:before {
    content: "\F3B3"
}

.hicon-password:before {
    content: "\F3B4"
}

.hicon-pay-at-hotel-in-cash:before {
    content: "\F3B5"
}

.hicon-pay-at-the-place:before {
    content: "\F3B6"
}

.hicon-pay-on-checkin:before {
    content: "\F3B7"
}

.hicon-payment-option-no-credit-card:before {
    content: "\F3B8"
}

.hicon-payments-active:before {
    content: "\F3B9"
}

.hicon-payments:before {
    content: "\F3BA"
}

.hicon-pending-bold:before {
    content: "\F3BB"
}

.hicon-pending:before {
    content: "\F3BC"
}

.hicon-personal-cheque:before {
    content: "\F3BD"
}

.hicon-personal-details-filled:before {
    content: "\F3BE"
}

.hicon-personal-details:before {
    content: "\F3BF"
}

.hicon-pet-allowed-room:before {
    content: "\F3C0"
}

.hicon-pets-allowed:before {
    content: "\F3C1"
}

.hicon-photo-uploader:before {
    content: "\F3C2"
}

.hicon-photocopying:before {
    content: "\F3C3"
}

.hicon-pin-airport:before {
    content: "\F3C4"
}

.hicon-pin-beach:before {
    content: "\F3C5"
}

.hicon-pin-business:before {
    content: "\F3C6"
}

.hicon-pin-casino:before {
    content: "\F3C7"
}

.hicon-pin-culture:before {
    content: "\F3C8"
}

.hicon-pin-excellent:before {
    content: "\F3C9"
}

.hicon-pin-family-fun:before {
    content: "\F3CA"
}

.hicon-pin-heart-of-city-building:before {
    content: "\F3CB"
}

.hicon-pin-heart-of-city-mind:before {
    content: "\F3CC"
}

.hicon-pin-heart-of-city:before {
    content: "\F3CD"
}

.hicon-pin-heart-of-the-city:before {
    content: "\F3CE"
}

.hicon-pin-mountain-view:before {
    content: "\F3CF"
}

.hicon-pin-museum-arts:before {
    content: "\F3D0"
}

.hicon-pin-nightlife:before {
    content: "\F3D1"
}

.hicon-pin-poi:before {
    content: "\F3D2"
}

.hicon-pin-religious:before {
    content: "\F3D3"
}

.hicon-pin-restaurant:before {
    content: "\F3D4"
}

.hicon-pin-romance:before {
    content: "\F3D5"
}

.hicon-pin-shopping-markets:before {
    content: "\F3D6"
}

.hicon-pin-sightseeing:before {
    content: "\F3D7"
}

.hicon-pin-skiing:before {
    content: "\F3D8"
}

.hicon-pin-spas:before {
    content: "\F3D9"
}

.hicon-pin-tennis-courts:before {
    content: "\F3DA"
}

.hicon-pin-transportation-hub:before {
    content: "\F3DB"
}

.hicon-pin:before {
    content: "\F3DC"
}

.hicon-pin-star:before {
    content: "\F3DD"
}

.hicon-plus-thin:before {
    content: "\F3DE"
}

.hicon-plus-with-circle:before {
    content: "\F3DF"
}

.hicon-poi-text-search:before {
    content: "\F3E0"
}

.hicon-poi:before {
    content: "\F3E1"
}

.hicon-pointsmax-line-logo:before {
    content: "\F3E2"
}

.hicon-pointsmax-logo:before {
    content: "\F3E3"
}

.hicon-pointsmax-placeholder:before {
    content: "\F3E4"
}

.hicon-pointsmax:before {
    content: "\F3E5"
}

.hicon-police:before {
    content: "\F3E6"
}

.hicon-pool-kids:before {
    content: "\F3E7"
}

.hicon-pool:before {
    content: "\F3E8"
}

.hicon-poolside-bar:before {
    content: "\F3E9"
}

.hicon-popular-guests:before {
    content: "\F3EA"
}

.hicon-popular-icon:before {
    content: "\F3EB"
}

.hicon-portable-wifi-rental:before {
    content: "\F3EC"
}

.hicon-positive:before {
    content: "\F3ED"
}

.hicon-postal-service:before {
    content: "\F3EE"
}

.hicon-prepayment:before {
    content: "\F3EF"
}

.hicon-price-display:before {
    content: "\F3F0"
}

.hicon-price-drop:before {
    content: "\F3F1"
}

.hicon-price-messaging:before {
    content: "\F3F2"
}

.hicon-price-seen-user:before {
    content: "\F3F3"
}

.hicon-printer:before {
    content: "\F3F4"
}

.hicon-privacy-policy:before {
    content: "\F3F5"
}

.hicon-private-bath:before {
    content: "\F3F6"
}

.hicon-private-beach:before {
    content: "\F3F7"
}

.hicon-private-entrance:before {
    content: "\F3F8"
}

.hicon-private-pool:before {
    content: "\F3F9"
}

.hicon-profiles:before {
    content: "\F3FA"
}

.hicon-promo-code:before {
    content: "\F3FB"
}

.hicon-promo-score:before {
    content: "\F3FC"
}

.hicon-promoeligible-star:before {
    content: "\F3FD"
}

.hicon-promotion-right:before {
    content: "\F3FE"
}

.hicon-properties-without-price:before {
    content: "\F3FF"
}

.hicon-properties:before {
    content: "\F400"
}

.hicon-property-name:before {
    content: "\F401"
}

.hicon-property-owner:before {
    content: "\F402"
}

.hicon-property-tooltip:before {
    content: "\F403"
}

.hicon-property-upgrades-line:before {
    content: "\F404"
}

.hicon-queen-bed-bold:before {
    content: "\F405"
}

.hicon-queen-bed:before {
    content: "\F406"
}

.hicon-question-mark:before {
    content: "\F407"
}

.hicon-questions:before {
    content: "\F408"
}

.hicon-quick-filter:before {
    content: "\F409"
}

.hicon-rain-sleet:before {
    content: "\F40A"
}

.hicon-rain-to-snow-showers:before {
    content: "\F40B"
}

.hicon-rain:before {
    content: "\F40C"
}

.hicon-ratings:before {
    content: "\F40D"
}

.hicon-recently:before {
    content: "\F40E"
}

.hicon-reception:before {
    content: "\F40F"
}

.hicon-recommendations:before {
    content: "\F410"
}

.hicon-refresh-bold:before {
    content: "\F411"
}

.hicon-refresh:before {
    content: "\F412"
}

.hicon-refrigerator:before {
    content: "\F413"
}

.hicon-regular-in-room-emergency-alarm:before {
    content: "\F414"
}

.hicon-regular-in-room-toilet-and-bathtub-with-handrails:before {
    content: "\F415"
}

.hicon-regular-internet-access-connecting-port-only:before {
    content: "\F416"
}

.hicon-regular-accept-guide-dog-in-guest-room:before {
    content: "\F417"
}

.hicon-regular-accept-guide-dog:before {
    content: "\F418"
}

.hicon-regular-aesthetic-salon:before {
    content: "\F419"
}

.hicon-regular-airline-counter:before {
    content: "\F41A"
}

.hicon-regular-amusement-arcade:before {
    content: "\F41B"
}

.hicon-regular-amusement-park:before {
    content: "\F41C"
}

.hicon-regular-ana-crowne-plaza:before {
    content: "\F41D"
}

.hicon-regular-annex:before {
    content: "\F41E"
}

.hicon-regular-archery:before {
    content: "\F41F"
}

.hicon-regular-aromatherapy:before {
    content: "\F420"
}

.hicon-regular-automatic-mahjong:before {
    content: "\F421"
}

.hicon-regular-baby-diaper:before {
    content: "\F422"
}

.hicon-regular-baby-food-prepared:before {
    content: "\F423"
}

.hicon-regular-baby-kids-room:before {
    content: "\F424"
}

.hicon-regular-baby:before {
    content: "\F425"
}

.hicon-regular-barber-shop:before {
    content: "\F426"
}

.hicon-regular-baseball:before {
    content: "\F427"
}

.hicon-regular-bath-materials:before {
    content: "\F428"
}

.hicon-regular-bath-temperature:before {
    content: "\F429"
}

.hicon-regular-bath-water:before {
    content: "\F42A"
}

.hicon-regular-beauty-salon:before {
    content: "\F42B"
}

.hicon-regular-bicycle-rental-for-kids:before {
    content: "\F42C"
}

.hicon-regular-big-dog-allowed:before {
    content: "\F42D"
}

.hicon-regular-botanical-observation:before {
    content: "\F42E"
}

.hicon-regular-braille-support-in-rooms-and-public-spaces:before {
    content: "\F42F"
}

.hicon-regular-braille-support:before {
    content: "\F430"
}

.hicon-regular-capacity:before {
    content: "\F431"
}

.hicon-regular-change-flight:before {
    content: "\F432"
}

.hicon-regular-chargeable-internet-access:before {
    content: "\F433"
}

.hicon-regular-child-meal-a:before {
    content: "\F434"
}

.hicon-regular-child-meal-b:before {
    content: "\F435"
}

.hicon-regular-children-yukata:before {
    content: "\F436"
}

.hicon-regular-chinese-cuisine:before {
    content: "\F437"
}

.hicon-regular-clam-digging:before {
    content: "\F438"
}

.hicon-regular-complimentary-shuttle-service-reservation-required:before {
    content: "\F439"
}

.hicon-regular-complimentary-shuttle-service:before {
    content: "\F43A"
}

.hicon-regular-computer:before {
    content: "\F43B"
}

.hicon-regular-cormorant-fishing:before {
    content: "\F43C"
}

.hicon-regular-cosmetics:before {
    content: "\F43D"
}

.hicon-regular-countryside:before {
    content: "\F43E"
}

.hicon-regular-craft-workshop:before {
    content: "\F43F"
}

.hicon-regular-cycling:before {
    content: "\F440"
}

.hicon-regular-dance-hall:before {
    content: "\F441"
}

.hicon-regular-electric-hot-water-pot:before {
    content: "\F442"
}

.hicon-regular-elevator-for-wheelchair:before {
    content: "\F443"
}

.hicon-regular-emergency-alarm-for-hearing-impaired:before {
    content: "\F444"
}

.hicon-regular-emergency-alarm:before {
    content: "\F445"
}

.hicon-regular-english:before {
    content: "\F446"
}

.hicon-regular-exposition-hall:before {
    content: "\F447"
}

.hicon-regular-family-bath:before {
    content: "\F448"
}

.hicon-regular-family-with-teens-new:before {
    content: "\F449"
}

.hicon-regular-farm-fish-tourism:before {
    content: "\F44A"
}

.hicon-regular-female-only:before {
    content: "\F44B"
}

.hicon-regular-first-aid:before {
    content: "\F44C"
}

.hicon-regular-fishing-gear-rental:before {
    content: "\F44D"
}

.hicon-regular-gallery:before {
    content: "\F44E"
}

.hicon-regular-gateball-field:before {
    content: "\F44F"
}

.hicon-regular-gateball:before {
    content: "\F450"
}

.hicon-regular-gender:before {
    content: "\F451"
}

.hicon-regular-go-game:before {
    content: "\F452"
}

.hicon-regular-gravel-path-at-entrance:before {
    content: "\F453"
}

.hicon-regular-gymnasium:before {
    content: "\F454"
}

.hicon-regular-handrails-in-stairs-and-hallways:before {
    content: "\F455"
}

.hicon-regular-hang-gliding:before {
    content: "\F456"
}

.hicon-regular-hiking:before {
    content: "\F457"
}

.hicon-regular-hot-spring-access:before {
    content: "\F458"
}

.hicon-regular-hot-spring-bath:before {
    content: "\F459"
}

.hicon-regular-hotel-chain:before {
    content: "\F45A"
}

.hicon-regular-hotel-inside-accessible-by-wheelchair:before {
    content: "\F45B"
}

.hicon-regular-hunting:before {
    content: "\F45C"
}

.hicon-regular-in-room-emergency-alarm-for-hearing-impaired:before {
    content: "\F45D"
}

.hicon-regular-infant:before {
    content: "\F45E"
}

.hicon-regular-insect-collection:before {
    content: "\F45F"
}

.hicon-regular-internet-access-from-all-rooms:before {
    content: "\F460"
}

.hicon-regular-internet-access-limited-number-of-rooms:before {
    content: "\F461"
}

.hicon-regular-itinerary:before {
    content: "\F462"
}

.hicon-regular-japanese-cuisine:before {
    content: "\F463"
}

.hicon-regular-japanese-style-tea-room:before {
    content: "\F464"
}

.hicon-regular-japanese-style-toilet-squat-type:before {
    content: "\F465"
}

.hicon-regular-large-indoor-bath:before {
    content: "\F466"
}

.hicon-regular-light-meal-corner:before {
    content: "\F467"
}

.hicon-regular-local-event:before {
    content: "\F468"
}

.hicon-regular-mahjong:before {
    content: "\F469"
}

.hicon-regular-male-only:before {
    content: "\F46A"
}

.hicon-regular-martial-arts-gym:before {
    content: "\F46B"
}

.hicon-regular-meal:before {
    content: "\F46C"
}

.hicon-regular-meditation-hall:before {
    content: "\F46D"
}

.hicon-regular-modify-search:before {
    content: "\F46E"
}

.hicon-regular-mountain-view:before {
    content: "\F46F"
}

.hicon-regular-multipurpose-sports-ground:before {
    content: "\F470"
}

.hicon-regular-night:before {
    content: "\F471"
}

.hicon-regular-noh-stage:before {
    content: "\F472"
}

.hicon-regular-open-air-bath-mixed-gender:before {
    content: "\F473"
}

.hicon-regular-open-air-bath-none-mixed:before {
    content: "\F474"
}

.hicon-regular-open-air-workshop:before {
    content: "\F475"
}

.hicon-regular-orienteering:before {
    content: "\F476"
}

.hicon-regular-package-savings:before {
    content: "\F477"
}

.hicon-regular-package:before {
    content: "\F478"
}

.hicon-regular-pet-can-bath-in-room:before {
    content: "\F479"
}

.hicon-regular-pet-can-eat-in-room:before {
    content: "\F47A"
}

.hicon-regular-pharmacy:before {
    content: "\F47B"
}

.hicon-regular-pool-seasonal-opening:before {
    content: "\F47C"
}

.hicon-regular-pool-year-round:before {
    content: "\F47D"
}

.hicon-regular-prayer-room:before {
    content: "\F47E"
}

.hicon-regular-preselected-flight:before {
    content: "\F47F"
}

.hicon-regular-price-fluctuates:before {
    content: "\F480"
}

.hicon-regular-private-open-air-bath:before {
    content: "\F481"
}

.hicon-regular-queen-bed:before {
    content: "\F482"
}

.hicon-regular-razor:before {
    content: "\F483"
}

.hicon-regular-reading-room:before {
    content: "\F484"
}

.hicon-regular-rooms-with-kotatsu:before {
    content: "\F485"
}

.hicon-regular-ropes-course:before {
    content: "\F486"
}

.hicon-regular-rugby:before {
    content: "\F487"
}

.hicon-regular-salt:before {
    content: "\F488"
}

.hicon-regular-scuba-diving:before {
    content: "\F489"
}

.hicon-regular-seat-with-leg-room:before {
    content: "\F48A"
}

.hicon-regular-seine-fishing:before {
    content: "\F48B"
}

.hicon-regular-shared-acccessible-toilet:before {
    content: "\F48C"
}

.hicon-regular-shared-private-hot-bath-accessible-by-wheelchair:before {
    content: "\F48D"
}

.hicon-regular-shared-western-style-toilets:before {
    content: "\F48E"
}

.hicon-regular-shogi:before {
    content: "\F48F"
}

.hicon-regular-show-more:before {
    content: "\F490"
}

.hicon-regular-shower-booth:before {
    content: "\F491"
}

.hicon-regular-sign-language-support-at-reception:before {
    content: "\F492"
}

.hicon-regular-skating:before {
    content: "\F493"
}

.hicon-regular-ski-clothes-rental:before {
    content: "\F494"
}

.hicon-regular-ski-equipment-rentals-for-kids:before {
    content: "\F495"
}

.hicon-regular-ski-rental:before {
    content: "\F496"
}

.hicon-regular-ski-shoes-rental:before {
    content: "\F497"
}

.hicon-regular-ski-slope:before {
    content: "\F498"
}

.hicon-regular-skin-diving:before {
    content: "\F499"
}

.hicon-regular-skylounge:before {
    content: "\F49A"
}

.hicon-regular-sled-rental-for-kids:before {
    content: "\F49B"
}

.hicon-regular-slippers-for-kids:before {
    content: "\F49C"
}

.hicon-regular-slope-at-entrance:before {
    content: "\F49D"
}

.hicon-regular-small-dog-allowed-indoor-dogs:before {
    content: "\F49E"
}

.hicon-regular-snowboard-rental:before {
    content: "\F49F"
}

.hicon-regular-soccer:before {
    content: "\F4A0"
}

.hicon-regular-spa-sauna:before {
    content: "\F4A1"
}

.hicon-regular-sudate:before {
    content: "\F4A2"
}

.hicon-regular-tap-water:before {
    content: "\F4A3"
}

.hicon-regular-tea-lounge:before {
    content: "\F4A4"
}

.hicon-regular-tennis-racket-rental:before {
    content: "\F4A5"
}

.hicon-regular-tennis:before {
    content: "\F4A6"
}

.hicon-regular-theatre:before {
    content: "\F4A7"
}

.hicon-regular-time-icon:before {
    content: "\F4A8"
}

.hicon-regular-toilet-and-bathtub-with-handrails:before {
    content: "\F4A9"
}

.hicon-regular-toilet-with-bidet:before {
    content: "\F4AA"
}

.hicon-regular-toilet:before {
    content: "\F4AB"
}

.hicon-regular-toothbrush:before {
    content: "\F4AC"
}

.hicon-regular-travel-agency:before {
    content: "\F4AD"
}

.hicon-regular-travel-protection:before {
    content: "\F4AE"
}

.hicon-regular-travelers:before {
    content: "\F4AF"
}

.hicon-regular-valley:before {
    content: "\F4B0"
}

.hicon-regular-voleyball:before {
    content: "\F4B1"
}

.hicon-regular-walkability:before {
    content: "\F4B2"
}

.hicon-regular-wedding-venue:before {
    content: "\F4B3"
}

.hicon-regular-western-cuisine:before {
    content: "\F4B4"
}

.hicon-regular-wheel-chair-friendly-rooms:before {
    content: "\F4B5"
}

.hicon-regular-wheelchair-accessible-rooms:before {
    content: "\F4B6"
}

.hicon-regular-wild-bird-observation:before {
    content: "\F4B7"
}

.hicon-regular-workshop:before {
    content: "\F4B8"
}

.hicon-regular-yachting:before {
    content: "\F4B9"
}

.hicon-regular-yukata-for-kids:before {
    content: "\F4BA"
}

.hicon-regular-yukata-japanese-pajamas:before {
    content: "\F4BB"
}

.hicon-religious:before {
    content: "\F4BC"
}

.hicon-reporting-property:before {
    content: "\F4BD"
}

.hicon-reporting:before {
    content: "\F4BE"
}

.hicon-reservation-active:before {
    content: "\F4BF"
}

.hicon-reservation:before {
    content: "\F4C0"
}

.hicon-residence:before {
    content: "\F4C1"
}

.hicon-resort-property-plan:before {
    content: "\F4C2"
}

.hicon-restaurant-credit:before {
    content: "\F4C3"
}

.hicon-restaurant:before {
    content: "\F4C4"
}

.hicon-review-icon:before {
    content: "\F4C5"
}

.hicon-review-line:before {
    content: "\F4C6"
}

.hicon-review-your-stay:before {
    content: "\F4C7"
}

.hicon-ribbon-card-no-circle:before {
    content: "\F4C8"
}

.hicon-ribbon-card-solid:before {
    content: "\F4C9"
}

.hicon-ribbon-card:before {
    content: "\F4CA"
}

.hicon-right-tick:before {
    content: "\F4CB"
}

.hicon-romance:before {
    content: "\F4CC"
}

.hicon-room-plan:before {
    content: "\F4CD"
}

.hicon-room-promotion-for-flashdeal:before {
    content: "\F4CE"
}

.hicon-room-promotion-for-mobiledeal:before {
    content: "\F4CF"
}

.hicon-room-promotion-for-otherdeal:before {
    content: "\F4D0"
}

.hicon-room-promotion-for-smartdeal:before {
    content: "\F4D1"
}

.hicon-room-promotion-super-savedeal:before {
    content: "\F4D2"
}

.hicon-room-promotion:before {
    content: "\F4D3"
}

.hicon-room-service:before {
    content: "\F4D4"
}

.hicon-room-size:before {
    content: "\F4D5"
}

.hicon-room-voltage:before {
    content: "\F4D6"
}

.hicon-round-trip:before {
    content: "\F4D7"
}

.hicon-safety-deposit-boxes:before {
    content: "\F4D8"
}

.hicon-salon:before {
    content: "\F4D9"
}

.hicon-satellite-cable-channels:before {
    content: "\F4DA"
}

.hicon-satellite-television:before {
    content: "\F4DB"
}

.hicon-sauna:before {
    content: "\F4DC"
}

.hicon-save-to-pdf:before {
    content: "\F4DD"
}

.hicon-scale:before {
    content: "\F4DE"
}

.hicon-scattered-clouds:before {
    content: "\F4DF"
}

.hicon-scattered-flurries:before {
    content: "\F4E0"
}

.hicon-scattered-showers:before {
    content: "\F4E1"
}

.hicon-scattered-snow-showe:before {
    content: "\F4E2"
}

.hicon-scattered-thunderstorms:before {
    content: "\F4E3"
}

.hicon-scissor:before {
    content: "\F4E4"
}

.hicon-search-box:before {
    content: "\F4E5"
}

.hicon-search-calendar:before {
    content: "\F4E6"
}

.hicon-search-icon:before {
    content: "\F4E7"
}

.hicon-seating-area:before {
    content: "\F4E8"
}

.hicon-sec:before {
    content: "\F4E9"
}

.hicon-secure-icon:before {
    content: "\F4EA"
}

.hicon-secure-payment:before {
    content: "\F4EB"
}

.hicon-selected-property:before {
    content: "\F4EC"
}

.hicon-self-parking:before {
    content: "\F4ED"
}

.hicon-semi-double-bed-b:before {
    content: "\F4EE"
}

.hicon-semi-double-bed:before {
    content: "\F4EF"
}

.hicon-send-arrow:before {
    content: "\F4F0"
}

.hicon-separate-dining-area:before {
    content: "\F4F1"
}

.hicon-separate-dinning-area-regular:before {
    content: "\F4F2"
}

.hicon-separate-living-room:before {
    content: "\F4F3"
}

.hicon-separate-shower-and-tub:before {
    content: "\F4F4"
}

.hicon-seriously-multilingual:before {
    content: "\F4F5"
}

.hicon-sewing-kit:before {
    content: "\F4F6"
}

.hicon-shampoo:before {
    content: "\F4F7"
}

.hicon-share:before {
    content: "\F4F8"
}

.hicon-shared-bath:before {
    content: "\F4F9"
}

.hicon-shared-kitchen:before {
    content: "\F4FA"
}

.hicon-shoeshine-kit:before {
    content: "\F4FB"
}

.hicon-shopping-markets:before {
    content: "\F4FC"
}

.hicon-shopping:before {
    content: "\F4FD"
}

.hicon-shops:before {
    content: "\F4FE"
}

.hicon-show-more:before {
    content: "\F4FF"
}

.hicon-shower-and-bathtub:before {
    content: "\F500"
}

.hicon-shower-rain:before {
    content: "\F501"
}

.hicon-shower:before {
    content: "\F502"
}

.hicon-shrine:before {
    content: "\F503"
}

.hicon-shuttle-service:before {
    content: "\F504"
}

.hicon-sightseeing:before {
    content: "\F505"
}

.hicon-single-bed-b:before {
    content: "\F506"
}

.hicon-single-bed:before {
    content: "\F507"
}

.hicon-size-of-rooms:before {
    content: "\F508"
}

.hicon-ski-equipment-rentals:before {
    content: "\F509"
}

.hicon-ski-lessons:before {
    content: "\F50A"
}

.hicon-skiing:before {
    content: "\F50B"
}

.hicon-sleet:before {
    content: "\F50C"
}

.hicon-slippers:before {
    content: "\F50D"
}

.hicon-smoke-detector:before {
    content: "\F50E"
}

.hicon-smoke-windy:before {
    content: "\F50F"
}

.hicon-smoking-allowed:before {
    content: "\F510"
}

.hicon-smoking-area:before {
    content: "\F511"
}

.hicon-smorking-yes-no:before {
    content: "\F512"
}

.hicon-snorkeling:before {
    content: "\F513"
}

.hicon-snow:before {
    content: "\F514"
}

.hicon-sofa-bed:before {
    content: "\F515"
}

.hicon-sofa:before {
    content: "\F516"
}

.hicon-solarium:before {
    content: "\F517"
}

.hicon-solid-24hour-front-desk:before {
    content: "\F518"
}

.hicon-solid-24hour-room-service:before {
    content: "\F519"
}

.hicon-solid-adults-b:before {
    content: "\F51A"
}

.hicon-solid-adults-c:before {
    content: "\F51B"
}

.hicon-solid-adults:before {
    content: "\F51C"
}

.hicon-solid-air-conditioning:before {
    content: "\F51D"
}

.hicon-solid-airport-transfer-service:before {
    content: "\F51E"
}

.hicon-solid-airport-transfer:before {
    content: "\F51F"
}

.hicon-solid-babies:before {
    content: "\F520"
}

.hicon-solid-bar:before {
    content: "\F521"
}

.hicon-solid-beach:before {
    content: "\F522"
}

.hicon-solid-bedroom-door:before {
    content: "\F523"
}

.hicon-solid-bedroom:before {
    content: "\F524"
}

.hicon-solid-best-seller:before {
    content: "\F525"
}

.hicon-solid-breakfast:before {
    content: "\F526"
}

.hicon-solid-business-hover:before {
    content: "\F527"
}

.hicon-solid-business-travel:before {
    content: "\F528"
}

.hicon-solid-calendar:before {
    content: "\F529"
}

.hicon-solid-car-park:before {
    content: "\F52A"
}

.hicon-solid-chat:before {
    content: "\F52B"
}

.hicon-solid-cleanliness:before {
    content: "\F52C"
}

.hicon-solid-clock:before {
    content: "\F52D"
}

.hicon-solid-compset-comparison:before {
    content: "\F52E"
}

.hicon-solid-contact-details:before {
    content: "\F52F"
}

.hicon-solid-couple-hover:before {
    content: "\F530"
}

.hicon-solid-couple:before {
    content: "\F531"
}

.hicon-solid-email-envelope:before {
    content: "\F532"
}

.hicon-solid-everybody-fits:before {
    content: "\F533"
}

.hicon-solid-families-b:before {
    content: "\F534"
}

.hicon-solid-families:before {
    content: "\F535"
}

.hicon-solid-family-friendly:before {
    content: "\F536"
}

.hicon-solid-family-with-teens:before {
    content: "\F537"
}

.hicon-solid-fitness:before {
    content: "\F538"
}

.hicon-solid-flash:before {
    content: "\F539"
}

.hicon-solid-flights-airplane:before {
    content: "\F53A"
}

.hicon-solid-flights-destination:before {
    content: "\F53B"
}

.hicon-solid-flights-hotel:before {
    content: "\F53C"
}

.hicon-solid-flights-layover-exchange:before {
    content: "\F53D"
}

.hicon-solid-flights-stop-layover:before {
    content: "\F53E"
}

.hicon-solid-free-cancellation:before {
    content: "\F53F"
}

.hicon-solid-free-extra-bed:before {
    content: "\F540"
}

.hicon-solid-free-wi-fi:before {
    content: "\F541"
}

.hicon-solid-garden:before {
    content: "\F542"
}

.hicon-solid-generic-traveler-type:before {
    content: "\F543"
}

.hicon-solid-green-credit-card:before {
    content: "\F544"
}

.hicon-solid-groups-b:before {
    content: "\F545"
}

.hicon-solid-groups:before {
    content: "\F546"
}

.hicon-solid-hair-dryer:before {
    content: "\F547"
}

.hicon-solid-insider-deal-flag:before {
    content: "\F548"
}

.hicon-solid-jtb-loyalty:before {
    content: "\F549"
}

.hicon-solid-ms-key-dark-grey:before {
    content: "\F54A"
}

.hicon-solid-number-of-rooms:before {
    content: "\F54B"
}

.hicon-solid-payment-options:before {
    content: "\F54C"
}

.hicon-solid-price-match:before {
    content: "\F54D"
}

.hicon-solid-private-entrance:before {
    content: "\F54E"
}

.hicon-solid-property-upgrades:before {
    content: "\F54F"
}

.hicon-solid-restaurant:before {
    content: "\F550"
}

.hicon-solid-room-offers:before {
    content: "\F551"
}

.hicon-solid-secure-icon:before {
    content: "\F552"
}

.hicon-solid-spa:before {
    content: "\F553"
}

.hicon-solid-special-requests-new:before {
    content: "\F554"
}

.hicon-solid-special-requests:before {
    content: "\F555"
}

.hicon-solid-swimming-pool:before {
    content: "\F556"
}

.hicon-solid-tax-receipt:before {
    content: "\F557"
}

.hicon-solid-telephone:before {
    content: "\F558"
}

.hicon-solid-total-support:before {
    content: "\F559"
}

.hicon-solid-transportation:before {
    content: "\F55A"
}

.hicon-solid-unlock:before {
    content: "\F55B"
}

.hicon-solo-hover:before {
    content: "\F55C"
}

.hicon-solo:before {
    content: "\F55D"
}

.hicon-sort-line:before {
    content: "\F55E"
}

.hicon-soundproofing:before {
    content: "\F55F"
}

.hicon-spa-credit:before {
    content: "\F560"
}

.hicon-spa-sauna:before {
    content: "\F561"
}

.hicon-spas:before {
    content: "\F562"
}

.hicon-special-condition:before {
    content: "\F563"
}

.hicon-spoken-1:before {
    content: "\F564"
}

.hicon-spoken-10:before {
    content: "\F565"
}

.hicon-spoken-11:before {
    content: "\F566"
}

.hicon-spoken-12:before {
    content: "\F567"
}

.hicon-spoken-13:before {
    content: "\F568"
}

.hicon-spoken-2:before {
    content: "\F569"
}

.hicon-spoken-20:before {
    content: "\F56A"
}

.hicon-spoken-22:before {
    content: "\F56B"
}

.hicon-spoken-23:before {
    content: "\F56C"
}

.hicon-spoken-24:before {
    content: "\F56D"
}

.hicon-spoken-25:before {
    content: "\F56E"
}

.hicon-spoken-26:before {
    content: "\F56F"
}

.hicon-spoken-27:before {
    content: "\F570"
}

.hicon-spoken-28:before {
    content: "\F571"
}

.hicon-spoken-29:before {
    content: "\F572"
}

.hicon-spoken-3:before {
    content: "\F573"
}

.hicon-spoken-30:before {
    content: "\F574"
}

.hicon-spoken-31:before {
    content: "\F575"
}

.hicon-spoken-32:before {
    content: "\F576"
}

.hicon-spoken-33:before {
    content: "\F577"
}

.hicon-spoken-34:before {
    content: "\F578"
}

.hicon-spoken-35:before {
    content: "\F579"
}

.hicon-spoken-36:before {
    content: "\F57A"
}

.hicon-spoken-37:before {
    content: "\F57B"
}

.hicon-spoken-38:before {
    content: "\F57C"
}

.hicon-spoken-39:before {
    content: "\F57D"
}

.hicon-spoken-4:before {
    content: "\F57E"
}

.hicon-spoken-40:before {
    content: "\F57F"
}

.hicon-spoken-43:before {
    content: "\F580"
}

.hicon-spoken-46:before {
    content: "\F581"
}

.hicon-spoken-47:before {
    content: "\F582"
}

.hicon-spoken-48:before {
    content: "\F583"
}

.hicon-spoken-49:before {
    content: "\F584"
}

.hicon-spoken-5:before {
    content: "\F585"
}

.hicon-spoken-50:before {
    content: "\F586"
}

.hicon-spoken-6:before {
    content: "\F587"
}

.hicon-spoken-7:before {
    content: "\F588"
}

.hicon-spoken-8:before {
    content: "\F589"
}

.hicon-spoken-9:before {
    content: "\F58A"
}

.hicon-sqm:before {
    content: "\F58B"
}

.hicon-squash-courts:before {
    content: "\F58C"
}

.hicon-stack-of-square-papers:before {
    content: "\F58D"
}

.hicon-star-1:before {
    content: "\F58E"
}

.hicon-star-15:before {
    content: "\F58F"
}

.hicon-star-2:before {
    content: "\F590"
}

.hicon-star-25:before {
    content: "\F591"
}

.hicon-star-3:before {
    content: "\F592"
}

.hicon-star-35:before {
    content: "\F593"
}

.hicon-star-4:before {
    content: "\F594"
}

.hicon-star-45:before {
    content: "\F595"
}

.hicon-star-5:before {
    content: "\F596"
}

.hicon-steamroom:before {
    content: "\F597"
}

.hicon-strong-storms:before {
    content: "\F598"
}

.hicon-subways:before {
    content: "\F599"
}

.hicon-suitable-for-events:before {
    content: "\F59A"
}

.hicon-sunny:before {
    content: "\F59B"
}

.hicon-super-king-bed:before {
    content: "\F59C"
}

.hicon-surfing-lessons:before {
    content: "\F59D"
}

.hicon-swimming-pool-access:before {
    content: "\F59E"
}

.hicon-table-tennis:before {
    content: "\F59F"
}

.hicon-tamil:before {
    content: "\F5A0"
}

.hicon-tax-id:before {
    content: "\F5A1"
}

.hicon-tax-receipt-available:before {
    content: "\F5A2"
}

.hicon-taxi-service:before {
    content: "\F5A3"
}

.hicon-telephone:before {
    content: "\F5A4"
}

.hicon-television-plasma:before {
    content: "\F5A5"
}

.hicon-tennis-courts:before {
    content: "\F5A6"
}

.hicon-text-area:before {
    content: "\F5A7"
}

.hicon-text-links:before {
    content: "\F5A8"
}

.hicon-theme-park:before {
    content: "\F5A9"
}

.hicon-thin-arrow-down:before {
    content: "\F5AA"
}

.hicon-thin-arrow-left:before {
    content: "\F5AB"
}

.hicon-thin-arrow-right:before {
    content: "\F5AC"
}

.hicon-thin-arrow-up:before {
    content: "\F5AD"
}

.hicon-thin-circle-arrow-left:before {
    content: "\F5AE"
}

.hicon-thin-sub-arrow:before {
    content: "\F5AF"
}

.hicon-thumb-down-line:before {
    content: "\F5B0"
}

.hicon-thumb-up-line:before {
    content: "\F5B1"
}

.hicon-thumb-up-solid-map:before {
    content: "\F5B2"
}

.hicon-thumb-up-solid:before {
    content: "\F5B3"
}

.hicon-thumb-up:before {
    content: "\F5B4"
}

.hicon-thunder-and-hail:before {
    content: "\F5B5"
}

.hicon-thunderstorm:before {
    content: "\F5B6"
}

.hicon-ticket-service:before {
    content: "\F5B7"
}

.hicon-time-clock:before {
    content: "\F5B8"
}

.hicon-time-filled-icon:before {
    content: "\F5B9"
}

.hicon-time-icon:before {
    content: "\F5BA"
}

.hicon-time-to-airport:before {
    content: "\F5BB"
}

.hicon-timer:before {
    content: "\F5BC"
}

.hicon-toiletries:before {
    content: "\F5BD"
}

.hicon-tonight-only:before {
    content: "\F5BE"
}

.hicon-tools:before {
    content: "\F5BF"
}

.hicon-tooltip-details:before {
    content: "\F5C0"
}

.hicon-tooltip:before {
    content: "\F5C1"
}

.hicon-top-floor:before {
    content: "\F5C2"
}

.hicon-top-rated:before {
    content: "\F5C3"
}

.hicon-topic-calendar:before {
    content: "\F5C4"
}

.hicon-topic-filter:before {
    content: "\F5C5"
}

.hicon-topic-hotel-highlight:before {
    content: "\F5C6"
}

.hicon-topic-search:before {
    content: "\F5C7"
}

.hicon-topic-user:before {
    content: "\F5C8"
}

.hicon-tornado:before {
    content: "\F5C9"
}

.hicon-total-savings:before {
    content: "\F5CA"
}

.hicon-total-support:before {
    content: "\F5CB"
}

.hicon-tours:before {
    content: "\F5CC"
}

.hicon-towels:before {
    content: "\F5CD"
}

.hicon-train-new-solid:before {
    content: "\F5CE"
}

.hicon-train-new:before {
    content: "\F5CF"
}

.hicon-train-station:before {
    content: "\F5D0"
}

.hicon-tram-station:before {
    content: "\F5D1"
}

.hicon-transfer-both-ways:before {
    content: "\F5D2"
}

.hicon-transfer-one-ways:before {
    content: "\F5D3"
}

.hicon-transportation-hub:before {
    content: "\F5D4"
}

.hicon-transportation:before {
    content: "\F5D5"
}

.hicon-trash-b:before {
    content: "\F5D6"
}

.hicon-trash:before {
    content: "\F5D7"
}

.hicon-travelers:before {
    content: "\F5D8"
}

.hicon-trending-up:before {
    content: "\F5D9"
}

.hicon-triangle-warning:before {
    content: "\F5DA"
}

.hicon-tropical-storm:before {
    content: "\F5DB"
}

.hicon-trouser-press:before {
    content: "\F5DC"
}

.hicon-tv-area:before {
    content: "\F5DD"
}

.hicon-tv-flat-screen:before {
    content: "\F5DE"
}

.hicon-tv:before {
    content: "\F5DF"
}

.hicon-twin-bed:before {
    content: "\F5E0"
}

.hicon-umbrella:before {
    content: "\F5E1"
}

.hicon-unionpay:before {
    content: "\F5E2"
}

.hicon-unlock:before {
    content: "\F5E3"
}

.hicon-upload-your-logo:before {
    content: "\F5E4"
}

.hicon-user-b:before {
    content: "\F5E5"
}

.hicon-user-font-icon:before {
    content: "\F5E6"
}

.hicon-user:before {
    content: "\F5E7"
}

.hicon-valet-parking:before {
    content: "\F5E8"
}

.hicon-vending-machine:before {
    content: "\F5E9"
}

.hicon-verified-checkmark:before {
    content: "\F5EA"
}

.hicon-view-point:before {
    content: "\F5EB"
}

.hicon-views:before {
    content: "\F5EC"
}

.hicon-villa:before {
    content: "\F5ED"
}

.hicon-vip:before {
    content: "\F5EE"
}

.hicon-visa-stamp:before {
    content: "\F5EF"
}

.hicon-visa:before {
    content: "\F5F0"
}

.hicon-wake-up-service:before {
    content: "\F5F1"
}

.hicon-walking:before {
    content: "\F5F2"
}

.hicon-want-to-talk:before {
    content: "\F5F3"
}

.hicon-washer:before {
    content: "\F5F4"
}

.hicon-watch:before {
    content: "\F5F5"
}

.hicon-water-park:before {
    content: "\F5F6"
}

.hicon-water-sports-motorized:before {
    content: "\F5F7"
}

.hicon-water-sports-non-motorized:before {
    content: "\F5F8"
}

.hicon-watersports-equipment-rentals:before {
    content: "\F5F9"
}

.hicon-weekend-discount:before {
    content: "\F5FA"
}

.hicon-wheelchair-accessible:before {
    content: "\F5FB"
}

.hicon-wifi-additional-charge:before {
    content: "\F5FC"
}

.hicon-wifi-in-public-areas:before {
    content: "\F5FD"
}

.hicon-wifi:before {
    content: "\F5FE"
}

.hicon-wind-surfing:before {
    content: "\F5FF"
}

.hicon-wintry-mix-snow-sleet:before {
    content: "\F600"
}

.hicon-wired-internet:before {
    content: "\F601"
}

.hicon-wooden-parqueted-flooring:before {
    content: "\F602"
}

.hicon-world:before {
    content: "\F603"
}

.hicon-x-icon:before {
    content: "\F604"
}

.hicon-ycs-channels:before {
    content: "\F605"
}

.hicon-ycs-dashboard:before {
    content: "\F606"
}

.hicon-ycs-doc-csv:before {
    content: "\F607"
}

.hicon-ycs-doc-excel:before {
    content: "\F608"
}

.hicon-ycs-doc-pdf:before {
    content: "\F609"
}

.hicon-ycs-doc-update:before {
    content: "\F60A"
}

.hicon-ycs-generic:before {
    content: "\F60B"
}

.hicon-ycs-no-show:before {
    content: "\F60C"
}

.hicon-year-hotel-built:before {
    content: "\F60D"
}

.hicon-year-hotel-last-renovated:before {
    content: "\F60E"
}

.hicon-yoga-room:before {
    content: "\F60F"
}

.hicon-zoom-bold:before {
    content: "\F610"
}
.Form-Section {
    display: flex;
}
.Form-Image {
    height: 90vh;
    width: 100%;
}
.Form-Image img {
    height: 100%;
    width: 100%;
}
.Form-Data-con {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 50%;
}
.Form-Info {
    margin: 10px;
    text-align: left;
}
.HomeForm {
    display: grid;
    padding: 0;
}
.HomeForm label {
    margin: 10px;
}
label {
    display: inline-block;
}

.Home-Section-5 {
    /* border: 1px solid blue; */
}

.Form-Section {
    display: flex;
}

.Form-Image-con {
    /* display: flex; */
}

.Form-Image {
    /* border: 1px solid greenyellow; */
    width: 100%;
    height: 90vh;
}

.Form-Image img {
    height: 100%;
    width: 100%;
}

.Form-Data-con {
    /* border: 1px solid red; */
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Form-Data {
    /* border: 1px solid green; */
}

.Form-Info {
    text-align: left;
    margin: 10px;
}

.Form-Info h6 {
    font-size: 12px;
    font-family: 'Roboto', sans-serif !important;
 
    font-weight: 400;
    color: #000;
    text-transform: uppercase;
    position: relative;
    letter-spacing: 3px;
    margin-bottom: 0px;
}

.Form-Info h4 {
    font-size: 32px;
    color: #000;
    font-weight: 400;
    line-height: 1.75em;
}

.Form-Info h3 {
    font-size: 2.08125rem;
    font-family: var(---SecStyleFont);
}

.Form-Info p {
    font-family: var(---ParaFont);
    font-size: 15px;
}

.HomeForm {
    display: grid;
    padding: 0px;
}

.HomeForm label {
    margin: 10px;
}

.HomeForm input {
    padding: 10px;
    width: 100%;
}

.HomeForm button {
    border: 1px solid black;
    width: fit-content;
    margin: 10px;
    padding: 12px 40px;
    border-radius: 5px;
    background-color: white;
    font-family: 'Roboto', sans-serif !important;
   
}


@media(max-width:770px) {
    .HS-4-Gallery-1col-Image4 {
        height: 45vh;
    }

    .HS-4-Gallery-1col-Image5 {
        /* border: 1px solid gold; */
        height: 45vh;
    }

    .HS-4-Gallery-1col-Image6 {
        /* border: 1px solid green; */
        height: 45vh;
    }

    .Form-Section {
        display: block;
    }

    .Form-Data-con {
        width: 100%;
    }

    .nav_menu-2 {
        padding-left: 0px !important;
    }
}

@media(max-width:430px) {
    .Events-Three {
        padding: 15px 15px;
    }

    .Corporate-Image-con {
        margin: 15px 15px;
    }

    .Two-Events {
        display: block;
    }

    .Wedding-Events {
        width: 100%;
    }

    .EventPlaning {
        width: 100%;
        margin: 30px 0px 0px 0px;
    }

    .Home-AboutUs {
        /* border: 1px solid blue; */
        margin: 10px;
    }

    .HomeBannerText h3 {
        font-size: 30px;
    }

    .HomeBannerText h2 {
        font-size: 40px;
    }

    .HomeBannerText h5 {
        font-size: 14px;
    }

    .HS-2-Info {
        font-size: 30px;
    }

    .HS-2-Text {
        font-size: 11px;
        letter-spacing: 2px;
    }

    .HS-2-Info span {
        font-size: 70px;
    }

    .Corporate-Image {
        height: 45vh;
        background-color: rgba(51, 51, 51, 0.5);
    }

    .parties-Image {
        background-color: rgba(51, 51, 51, 0.5);
    }

    .Wedding-Events-Image {
        background-color: rgba(51, 51, 51, 0.5);
    }

    .EventPlaning-Image {
        background-color: rgba(51, 51, 51, 0.5);
    }

    .Home-About-Info {
        font-size: 30px;
    }

    .Home-About-Info span {
        font-size: 70px;
    }

    .home-AboutUs-Para {
        font-size: 13px;
    }

    .Form-Data {
        padding: 20px;
    }

    .Form-Info h4 {
        font-size: 27px;
        margin: 0px;
    }

    .Form-Info h3 {
        font-size: 2.08125rem;
    }

    .Form-Info p {
        font-size: 13px;
    }

    .Form-Image {
        height: 60vh;
    }

  

    .Home-Section-2 {
        margin-top: 5vh;
    }

    .Home-section-3 {
        padding: 10vh 0px;
    }

    .Home-Section-4 {
        padding-top: 5vh;
        background-color: whitesmoke;
        padding-bottom: 5vh;
    }
    .Home-About-Image-con {
        margin-bottom: 10vh;
    }
}

.textcolor{
    color: white !important;
}

.demostyle{
    /* border: 1px solid red !important ; */
}
.swiper-pagination-bullet-active{
background-color:#FFBE31 ;
}
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
     color: white; 
     background-color:transparent; 
   border-color: transparent; 
}