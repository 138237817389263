.CallBtn-con{
    /* border: 1px solid red; */
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    color: white;
    position: fixed;
}

.CallBtn{
    /* border: 1px solid blue; */
    padding: 10px 10px;
    background-color:#25D366;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CallBtn a{
    text-decoration: none;
    color: white;
}

.CallBtn a svg{
font-size: 25px;
}