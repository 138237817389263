.news_blog_wrapper {
    position: relative;
  }
  .news_blog_wrapper.news_blog_wrapper-1::after {
    top: 0%;
    left: 0;
    z-index: -2;
    width: 100%;
    height: calc(60% + 30px);
    content: "";
    position: absolute;
    /* background-image: url(../img/home_02/news_bg.png); */
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  @media (max-width: 990px) {
    .news_blog_wrapper.news_blog_wrapper-1::after {
      height: calc(40% + 30px);
    }
  }
  .news_blog_wrapper.news_blog_wrapper-1 .single_blog_widget {
    background-color: transparent;
  }
  
  .single_blog_widget {
    background: rgba(182, 157, 116, 0.1);
    border-radius: 4px;
  }
  .single_blog_widget .single_blog_img {
    position: relative;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
    width: 100%;
    height: 300px;
  }
  .single_blog_widget .single_blog_img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .single_blog_widget .single_blog_img .author_info {
    position: absolute;
    z-index: 3;
    top: 20px;
    left: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 20px;
  }
  .single_blog_widget .single_blog_img .author_info .author_img {
    width: 40px;
    height: 40px;
    border-radius: 500px;
  }
  .single_blog_widget .single_blog_img .author_info h5 {
    color: #FFF;
  }
  .single_blog_widget .single_blog_img::after {
    position: absolute;
    clear: both;
    display: block;
    content: "";
    width: 0%;
    height: 100%;
    background: rgba(3, 58, 91, 0.4);
    right: 0%;
    top: 0%;
    z-index: 1;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .single_blog_widget .content {
    padding: 20px;
  }
  .single_blog_widget .content .blog_date a {
    padding: 0px 20px;
    border-left: 1px solid rgba(182, 157, 116, 0.2);
    color: #B69D74;
    text-transform: uppercase;
    text-decoration: none;
  }
  @media (max-width: 400px) {
    .single_blog_widget .content .blog_date a {
      padding: 0px 10px;
      font-size: 12px !important;
      line-height: 20px;
    }
  }
  .single_blog_widget .content .blog_date a:first-child {
    border-left: none;
    padding-left: 0;
  }
  .single_blog_widget .content .blog_date a i {
    margin-right: 5px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  .single_blog_widget .content h4 {
    margin: 20px 0px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    color: #033A5B;
  }
  @media (max-width: 768px) {
    .single_blog_widget .content h4 {
      font-size: 20px !important;
      line-height: 29px;
    }
  }
  .single_blog_widget .content a {
    text-decoration: underline;
    margin-bottom: 20px;
  }
  .single_blog_widget .content a i {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    margin-left: 7px;
  }
  .single_blog_widget .content a:hover {
    color: #B69D74;
  }
  .single_blog_widget:hover .single_blog_img::after {
    width: 100%;
    right: auto;
  }
  