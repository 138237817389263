.bg-img11 {
    background-image: url("../Asset//Banner/banner3.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    position: relative;
}

.ttm-bgcolor-darkgrey {
    background-color: #333;
    padding: 75px;
}

.ttm-bg.ttm-bgimage-yes>.ttm-bg-layer {
    opacity: .85;
}

.ttm-bgcolor-darkgrey>.ttm-bg-layer {
    background-color: #333;
}

.ttm-bg-layer {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.ttm-fid.style2 {
    background-color: #efe4ce;
    padding: 28px 30px;
    text-align: left;
}

.inside {
    position: relative;
    transition: all .4s;
}

.ttm-fid-view-lefticon .ttm-fid-icon-wrapper,
.ttm-fid-view-lefticon .ttm-fid-contents {
    display: inline-block;
    vertical-align: middle;
}

.ttm-textcolor-skincolor {
    color: #bf9456;
}

.ttm-fid.style2 .ttm-fid-icon-wrapper i {
    font-size: 40px;
    line-height: 40px;
    margin-right: 5px;
}

.ttm-fid-icon-wrapper i {
    display: block;
    font-size: 50px;
}

/* .flaticon-haircut:before {
    content: "\f110";
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before, [class^="flaticon-"]:after, [class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-style: normal;
} */

.ttm-fid.style2 .ttm-fid-icon-wrapper i {
    font-size: 40px;
    line-height: 40px;
    margin-right: 5px;
    height: 40px;
    width: 40px;
}

.ttm-fid.style2 .ttm-fid-icon-wrapper i img {
    height: 100%;
    width: 100%;
}

.ttm-fid-icon-wrapper i {
    display: block;
    font-size: 50px;
}

.ttm-fid.inside.style2 h4 {
    font-size: 38px;
    line-height: 45px;
    vertical-align: bottom;
}

.inside h4 span {
    display: inline-block;
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 35px;
    line-height: 35px;
    font-weight: 600;
}

.ttm-fid.inside.style2 h4,
.ttm-fid.inside.style2 h4 span {
    font-size: 25px;
    line-height: 45px;
    vertical-align: bottom;
    margin: 0px 0px 0px 0px;
}

.ttm-bgcolor-darkgrey h4 {
    color: #fff;
}


.ttm-fid-view-lefticon .ttm-fid-contents {
    padding-left: 3px;
    text-align: left;
}

.ttm-fid-view-lefticon .ttm-fid-icon-wrapper,
.ttm-fid-view-lefticon .ttm-fid-contents {
    display: inline-block;
    vertical-align: middle;
}

.text-left {
    text-align: left !important;
}

.ttm-fid.style2 .ttm-fid-contents .ttm-fid-title {
    margin-top: 10px;
}

.ttm-fid-view-lefticon .ttm-fid-contents .ttm-fid-title {
    font-size: 16px;
    line-height: 22px;
   
}

.ttm-fid.style2 .ttm-fid-contents .ttm-fid-title {
    margin-top: 10px;
    color: #fff;
}

.ttm-fid-view-lefticon .ttm-fid-contents .ttm-fid-title {
    font-size: 16px;
    line-height: 22px;
   
}

.ttm-fid-left {
    display: flex;
}

.ml-15 {
    margin-left: 15px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.featured-icon-box {
    position: relative;
}

.featured-icon-box.icon-align-before-content .featured-icon,
.featured-icon-box.icon-align-before-content .featured-content {
    display: table-cell;
    vertical-align: middle;
}

.featured-icon-box .ttm-icon {
    margin-bottom: 0;
}

.ttm-icon.ttm-icon_element-style-rounded {
    border-radius: 50%;
}

.ttm-icon.ttm-icon_element-size-sm {
    height: 58px;
    width: 58px;
    line-height: 58px;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 50%;
}
.ttm-bgcolor-white {
    color: #fff;
}

.featured-icon-box.icon-align-before-content .featured-icon i {
    display: inline-block;
    vertical-align: middle;
    position: unset;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}

.ttm-icon.ttm-icon_element-size-sm i {
    font-size: 23px;
}

.ttm-icon i {
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.featured-icon-box.icon-align-before-content .featured-content {
    padding-left: 18px;
}

.featured-icon-box.icon-align-before-content .featured-icon,
.featured-icon-box.icon-align-before-content .featured-content {
    display: table-cell;
    vertical-align: middle;
}

.featured-icon-box.style7 .featured-content .featured-title h3 {
    font-size: 30px;
    color: white;
}

.featured-icon-box.style7 .featured-content .featured-desc p {
    font-size: 18px;
}

.ttm-bgcolor-skincolor .title-desc,
.ttm-bgcolor-darkgrey .title-desc,
.ttm-bgcolor-skincolor p,
.ttm-bgcolor-darkgrey p {
    color: rgba(255, 255, 255, .85);
}

.featured-desc p {
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 0;
}

.ttm-icon.ttm-icon_element-size-sm svg {
    font-size: 30px;
}
.textcenterje{
    padding: 20px 30px;
    font-size: 18px;
    text-align: center;
}
@media (max-width:700px){
    .featured-icon-box{
  display: none;
    }
    .textcenterje{
        padding: 0px;
    }
    .ttm-fid.style2{
        padding: 0px;
    }
    .textcenterje{
        text-align: center;
        font-size: 18px;
    }
}