  
/* ======================================= Footer Section ============================== */
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Satisfy&display=swap');
ul {
    margin: 0px;
    padding: 0px;
  }
  
  .footer-section {
    background:black ;
    position: relative;
  }
  
  .footer-cta {
    border-bottom: 1px solid #373636;
  }
  
  .single-cta i {
    color:white;
    font-size: 30px;
    float: left;
    margin-top: 8px;
  }
  
  .cta-text {
    padding-left: 48px;
    display: inherit;
  }
  
  .cta-text h4 {
    color: white !important;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 2px;
  }
  
  .cta-text span {
    color: white;
    font-size: 15px;
  }
  
  .footer-content {
    position: relative;
    z-index: 2;
  }
  
  .footer-pattern img {
    position: absolute;
    top: 0;
    left: 0;
    height: 330px;
    background-size: cover;
    background-position: 100% 100%;
  }
  
  .footer-logo {
    margin-bottom: 30px;
  }
  
  .footer-logo img {
    max-width: 130px;
  }
  
  .footer-text p {
    margin-bottom: 14px;
    font-size: 14px;
    color: white;
    line-height: 28px;
  }
  
  .footer-social-icon span {
    color:white;
    display: block;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    
    margin-bottom: 20px;
  }
  
  .footer-social-icon a {
    color: var(---SecondColor);
    font-size: 16px;
    margin-right: 15px;
  }
  
  .footer-social-icon i {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
    color: #fff;
  }
  
  .facebook-bg {
    background: #3b5998;
  }
  
  .twitter-bg {
    background: #55acee;
  }
  
  .google-bg {
    background: #dd4b39;
  }
  .watsapp-bg {
    background: #47c757;
  }
  
  .footer-widget-heading h3 {
    color: white !important;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
    position: relative;
  }
  
  .footer-widget-heading h3::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -15px;
    height: 2px;
    width: 50px;
    background: var(---SecondColor);
  }
  
  .footer-widget ul li {
 
    
    width: 50%;
    margin-bottom: 12px;
  }
  
  .footer-widget ul li a:hover {
    color: var(---SecondColor);
  }
  
  .footer-widget ul li a {
    color: white;
    text-transform: capitalize;
  }
  
  .subscribe-form {
    position: relative;
    overflow: hidden;
  }
  
  .subscribe-form input {
    width: 100%;
    padding: 14px 28px;
    background: #eee;
    border: 1px solid #eee;
    color: #111;
  }
  
  .subscribe-form button {
    position: absolute;
    right: 0;
    background: var(---SecondColor);
    padding: 13px 20px;
    border: 2px solid var(---SecondColor);
    bottom: 10px;
  }
  
  .subscribe-form button i {
    color: #fff;
    font-size: 22px;
    transform: rotate(-6deg);
  }
  
  .copyright-area {
    background: #202020;
    padding: 25px 0;
  }
  
  .copyright-text p {
    margin: 0;
    font-size: 14px;
    color: #878787;
  }
  
  .copyright-text p a {
    color: #fff;
  }
  
  .footer-menu li {
    display: inline-block;
    margin-left: 20px;
  }
  
  .footer-menu li:hover a {
    color: var(---primeColor);
  }
  
  .footer-menu li a {
    font-size: 14px;
    color: #878787;
  }
  
 .bottomNavbar .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color: #808080 !important;
 }

 .bottomNavbar .MuiTabs-indicator{
  display: none;
 }
 
 .bottomNavbar .MuiBox-root{
  margin: auto;
 }

 .css-1elwnq4-MuiPaper-root-MuiAccordion-root{
  box-shadow: unset;
 }
 .carttotal{
  display: flex;
    position: relative;
    top: -43px;
    left: 11px;
    color: rgb(233, 140, 20);
}
.textwhite{
  color: white;
}
.liststyle{
  color: #fff;
}