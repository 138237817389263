



h3, p {
  margin: 0;
  padding-bottom: .5em;
}

h3 {
  font-size: 1.2em;
}

p {
  font-size: 14px;
  font-weight: 300;
}



/* PRODUCT CARD */

.product-card {
  background-color: #f5f6fa;
  border-radius: 10px;
  color: #2f3640;
  margin: 0 2em;
  transition: all linear .2s;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-card:hover,
.product-card:active {
  box-shadow: .2em .5em .5em rgba(0, 0, 0, 0.4);
  transform: scale(1.1);
}

.product-img {
  border-radius: 10px 10px 0 0;
  height: 150px;
  max-width: 100%;
}

.img-one {
  background-image: url(https://images.unsplash.com/photo-1517394834181-95ed159986c7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1506&q=80);
}

.img-two {
  background-image: url(https://images.unsplash.com/photo-1523889310790-cb91a98b017b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80);
}

.img-three {
  background-image: url(https://images.unsplash.com/photo-1566940973973-d499f3993bf4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80);
}

.img-one,
.img-two,
.img-three {
  background-size: cover;
  background-position: center;
}

.product-text {
  padding: 1em;
  text-align: center;
  height: 130px;
}

.product-cart button{
  border: 0;
  border-radius: 0 0 10px 10px;
  color: #f5f6fa;
  cursor: pointer;
  font-family: 'Roboto', sans-serif !important;
  padding: 1em;
  text-transform: uppercase;
  transition: all linear .3s;
  width: 100%;
}

.product-cart button:hover {
  font-weight: 500;
  letter-spacing: .1em;
}

/* color */
.product-card:first-child h3 {
  color: #e84118;
}

.product-card:first-child button {
  background-color: #e84118;
}

.product-card:nth-child(2) h3 {
  color: #fbc531;
}

.product-card:nth-child(2) button {
  background-color: #fbc531;
}

.product-card:last-child h3 {
  color: #0097e6;
}

.product-card:last-child button {
  background-color: #ffbe30;
  color: black;
}
.submitcolor{
 color:#fbc531 ; 
}

.button{
  color:#fbc531;
}
/* media queries */
@media screen and (max-width: 860px) {
  p {
    font-size: 14px;
    font-weight: 300;
  }
  
  .product-card {
    margin: 1.5em;
  }
}
.navbar-expand-lg .navbar-nav .nav-link{
  color: white;
}