/* MEDIA QUERY */
/* No direct translation to CSS; Sass/SCSS only */

/* VARIABLES */
/* No direct translation to CSS; Sass/SCSS only */
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Satisfy&display=swap');
/* RESET */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

/* GLOBAL */
html {
  box-sizing: border-box;
}

body {
 

 
}

/* MAIN CONTENT */
.grid {
  /* display: grid; */
  margin-top: 40px;
  background-color: #f5f5f5;
  grid-gap: 6rem;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  align-items: start;
}

.grid__item {
    
    margin-bottom: 40px;
  background-color: #fff;
  border-radius: .4rem;
  overflow: hidden;
  box-shadow: 0 3rem 6rem rgba(0, 0, 0, .1);
  cursor: pointer;
  transition: .2s;
}

.grid__item:hover {
  transform: translateY(-.5%);
  box-shadow: 0 4rem 8rem rgba(0, 0, 0, .2);
}

.card__img {
  display: block;
  width: 100%;
  height: 13rem;
  object-fit: cover;
}

.card__content {
  padding: 10px 10px 10px 20px;
}

.card__header {
  font-size: 16px;
  font-weight: 700;
  color: #0d0d0d;

}

.card__text {


  color: #404040;
  margin-bottom: 10px;
}

.card__btn {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 2rem;
  text-align: center;
  color: #3363ff;
  background-color: #e6ecff;
  border: none;
  border-radius: .4rem;
  transition: .2s;
  cursor: pointer;
}

.card__btn span {
  margin-left: 1rem;
  transition: .2s;
}

.card__btn:hover,
.card__btn:active {
  background-color: #c9d4ff;
}

.card__btn:hover span {
  margin-left: 1.5rem;
}

h6{
  color: #B69D74 !important;
}